var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", [
    _vm.data.column.field === "name"
      ? _c(
          "div",
          { staticClass: "name__wrapper" },
          [
            _c("ReadMore", {
              attrs: { text: _vm.data.row.name, to: _vm.viewLocation },
            }),
          ],
          1
        )
      : _vm.data.column.field === "insertDateTime"
      ? _c("div", [_c("DateColumn", { attrs: { data: _vm.data } })], 1)
      : _vm.data.column.field === "activations"
      ? _c("span", [
          _c(
            "div",
            { staticClass: "table-list-integrations-container" },
            [
              _c("IntegrationColumn", {
                attrs: {
                  "row-id": _vm.data.row.id,
                  activations: _vm.data.row.activations,
                },
              }),
            ],
            1
          ),
        ])
      : _vm.data.column.field === "actions"
      ? _c("span", [
          _c(
            "div",
            {
              staticClass:
                "table-list-switch-and-actions table-list-switch-and-actions--single",
            },
            [
              _c("div", { staticClass: "table-list-actions-container" }, [
                _c(
                  "span",
                  [
                    _c("DropdownActions", {
                      attrs: { items: _vm.actionItems },
                    }),
                  ],
                  1
                ),
              ]),
            ]
          ),
        ])
      : _c("span", [
          _vm._v(
            " " + _vm._s(_vm.data.formattedRow[_vm.data.column.field]) + " "
          ),
        ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }