var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.isLoading
    ? _c("div", { staticClass: "loading__container" }, [_c("LoadingBar")], 1)
    : _c(
        "section",
        { staticClass: "topics-view__wrapper view-screen__container" },
        [
          _c("div", { staticClass: "topics-view__container" }, [
            _c(
              "div",
              { staticClass: "container__wrapper topics-view__basic-info" },
              [
                _c(
                  "div",
                  {
                    staticClass:
                      "container__header segment-view__basic-info--header",
                  },
                  [
                    _c("h5", { staticClass: "container__header-title" }, [
                      _vm._v(_vm._s(_vm.$t("data.basicInfo"))),
                    ]),
                    _vm.topic.canEdit
                      ? _c(
                          "b-link",
                          {
                            staticClass: "segment-view__edit",
                            attrs: {
                              to: {
                                name: "topics-edit",
                                params: {
                                  organizationId: _vm.organizationId,
                                  topicId: _vm.topicId,
                                },
                              },
                            },
                          },
                          [
                            _c("GjIcon", {
                              attrs: { name: "Edit", size: "18" },
                            }),
                            _c("span", [_vm._v(_vm._s(_vm.$t("Edit")))]),
                          ],
                          1
                        )
                      : _vm._e(),
                  ],
                  1
                ),
                _c(
                  "div",
                  { staticClass: "container__content topic-basic__container" },
                  [
                    _c(
                      "div",
                      { staticClass: "segment-view__basic-info" },
                      [
                        _c("b-col", { staticClass: "input__wrapper pl-0" }, [
                          _c("label", [_vm._v(_vm._s(_vm.$t("Name")))]),
                          _c("span", { staticClass: "topic__text" }, [
                            _vm._v(_vm._s(_vm.topic.name)),
                          ]),
                        ]),
                        _c(
                          "b-col",
                          { staticClass: "input__wrapper pl-0" },
                          [
                            _c("label", [
                              _vm._v(_vm._s(_vm.$t("Description"))),
                            ]),
                            _c("ReadMore", {
                              attrs: {
                                text: _vm.topic.description,
                                mode: "view",
                              },
                            }),
                          ],
                          1
                        ),
                        _c("b-col", { staticClass: "input__wrapper pl-0" }, [
                          _c("div", { staticClass: "keyword__match-type" }, [
                            _c(
                              "label",
                              { staticClass: "total-analytics__title" },
                              [
                                _vm._v(
                                  _vm._s(
                                    _vm.$t("audience.topics.keywordMatchType")
                                  )
                                ),
                              ]
                            ),
                            _c(
                              "div",
                              { attrs: { id: "keywordMatchType-tooltip`" } },
                              [
                                _c("GjIcon", {
                                  attrs: { name: "Info", size: "21" },
                                }),
                                _c(
                                  "b-tooltip",
                                  {
                                    attrs: {
                                      target: "keywordMatchType-tooltip`",
                                      triggers: "hover",
                                      placement: "top",
                                    },
                                  },
                                  [
                                    _vm.topic.keywordMatchType === "Exact"
                                      ? _c("span", [
                                          _vm._v(
                                            _vm._s(
                                              _vm.$t(
                                                "audience.topics.exactTypeMatchingInfo"
                                              )
                                            )
                                          ),
                                        ])
                                      : _vm._e(),
                                    _vm.topic.keywordMatchType === "Phrase"
                                      ? _c("span", [
                                          _vm._v(
                                            _vm._s(
                                              _vm.$t(
                                                "audience.topics.phraseTypeMatchingInfo"
                                              )
                                            )
                                          ),
                                        ])
                                      : _vm._e(),
                                    _vm.topic.keywordMatchType === "Broad"
                                      ? _c("span", [
                                          _vm._v(
                                            _vm._s(
                                              _vm.$t(
                                                "audience.topics.broadTypeMatchingInfo"
                                              )
                                            )
                                          ),
                                        ])
                                      : _vm._e(),
                                  ]
                                ),
                              ],
                              1
                            ),
                          ]),
                          _c("span", { staticClass: "topic__text" }, [
                            _vm._v(_vm._s(_vm.topic.keywordMatchType)),
                          ]),
                        ]),
                      ],
                      1
                    ),
                  ]
                ),
              ]
            ),
            _c(
              "div",
              {
                staticClass:
                  "container__wrapper topics-view__selected-keywords",
              },
              [
                _c(
                  "div",
                  {
                    staticClass:
                      "container__header segment-view__container_header",
                  },
                  [
                    _c("h5", { staticClass: "container__header-title" }, [
                      _vm._v(
                        " " +
                          _vm._s(_vm.$t("audience.topics.addedKeywords")) +
                          " "
                      ),
                    ]),
                  ]
                ),
                _c("div", { staticClass: "selected-keywords__container" }, [
                  _c(
                    "div",
                    { staticClass: "selected-keywords__header" },
                    [
                      _vm.topic.keywords
                        ? _c(
                            "div",
                            { staticClass: "selected-keywords__total" },
                            [
                              _vm._v(
                                " " +
                                  _vm._s(
                                    `${_vm.$t("audience.topics.totalOf")} ${
                                      _vm.topic.keywords.length
                                    } ${_vm.$t(
                                      "audience.topics.keywordsAdded"
                                    )}`
                                  ) +
                                  " "
                              ),
                            ]
                          )
                        : _vm._e(),
                      _c("SearchInputField", {
                        attrs: {
                          id: "keywords-search",
                          data: _vm.keywordsTable,
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "div",
                    { staticClass: "total-analytics__container" },
                    [
                      _vm._l(_vm.analyticItems, function (item, index) {
                        return _c(
                          "div",
                          { key: index, staticClass: "total-analytics__item" },
                          [
                            _c("span", { staticClass: "item__name-and-icon" }, [
                              _vm._v(" " + _vm._s(item.name) + " "),
                              _c(
                                "div",
                                { attrs: { id: `${item.name}-anaytics` } },
                                [
                                  _c("GjIcon", {
                                    attrs: { name: "Info", size: "21" },
                                  }),
                                  _c(
                                    "b-tooltip",
                                    {
                                      attrs: {
                                        target: `${item.name}-anaytics`,
                                        triggers: "hover",
                                        placement: "top",
                                      },
                                    },
                                    [
                                      _vm._v(
                                        " " + _vm._s(item.tooltipText) + ". "
                                      ),
                                    ]
                                  ),
                                ],
                                1
                              ),
                            ]),
                            _c(
                              "span",
                              {
                                staticClass: "item__number",
                                class: {
                                  "item__number--disabled":
                                    _vm.isTotalAnalyticsLoading,
                                },
                              },
                              [
                                _vm._v(
                                  _vm._s(
                                    _vm
                                      .getAnalyticItem(item.name)
                                      .toLocaleString()
                                  )
                                ),
                              ]
                            ),
                          ]
                        )
                      }),
                      _vm.isTotalAnalyticsLoading
                        ? _c(
                            "span",
                            { staticClass: "total-analytics__loading" },
                            [
                              _c("LoadingBar", {
                                attrs: { classList: "medium" },
                              }),
                            ],
                            1
                          )
                        : _vm._e(),
                    ],
                    2
                  ),
                  _c("div", { staticClass: "selected-keywords__content" }, [
                    _vm.keywordsTable.rows.length > 0
                      ? _c(
                          "section",
                          { staticClass: "table__wrapper keywords__table" },
                          [
                            _c("Table", {
                              class: {
                                "keywords__table--loading": _vm.tableLoading,
                              },
                              attrs: {
                                data: _vm.keywordsTable,
                                "type-of-table": "keywords",
                                "is-loading": _vm.isLoading,
                              },
                              scopedSlots: _vm._u(
                                [
                                  {
                                    key: "row-data",
                                    fn: function ({ data }) {
                                      return [
                                        _c("KeywordsRow", {
                                          attrs: {
                                            data: data,
                                            "is-view-topic": "",
                                          },
                                        }),
                                      ]
                                    },
                                  },
                                ],
                                null,
                                false,
                                865973545
                              ),
                            }),
                          ],
                          1
                        )
                      : _vm._e(),
                  ]),
                  _vm.tableLoading
                    ? _c(
                        "div",
                        { staticClass: "keywords__table-overlay" },
                        [_c("LoadingBar")],
                        1
                      )
                    : _vm._e(),
                ]),
              ]
            ),
            _vm.topic.activations && _vm.topic.activations.length
              ? _c("div", { staticClass: "container__wrapper" }, [
                  _c(
                    "div",
                    {
                      staticClass:
                        "container__header segment-view__container_header",
                    },
                    [
                      _c("h5", { staticClass: "container__header-title" }, [
                        _vm._v(_vm._s(_vm.$t("Integrations"))),
                      ]),
                    ]
                  ),
                  _c(
                    "div",
                    { staticClass: "container__integrations" },
                    _vm._l(_vm.topic.activations, function (activation) {
                      return _c("IntegrationCard", {
                        key: activation.id,
                        attrs: { activation: activation, "view-mode": "" },
                      })
                    }),
                    1
                  ),
                ])
              : _vm._e(),
          ]),
          _c("Footer", {
            attrs: { "view-mode": "" },
            on: {
              back: function ($event) {
                return _vm.cancel()
              },
            },
          }),
        ],
        1
      )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }