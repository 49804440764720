import axiosIns from '@/libs/axios'
import { API_GATEWAY_URL } from '@/utils/constants'
import {
  destructJSONObject,
  formatFilterQuery,
  formatRequestQuery,
  prepareTableFilters
} from '@nodus/utilities-front'
import getSegmentView from './segmentFunctions'

export default {
  namespaced: true,
  state: {
    table: {
      columns: [
        {
          label: 'Name',
          field: 'name',
          thClass: 'custom-name-header name-column',
          width: '30%'
        },
        {
          label: 'Code',
          field: 'code',
          type: 'number',
          width: '10%'
        },
        {
          label: 'Date',
          field: 'insertDateTime',
          thClass: 'custom-data-class',
          width: '30%'
        },
        {
          label: 'Users',
          field: 'userCount',
          type: 'number',
          width: '10%'
        },
        {
          label: 'Integrations',
          field: 'integrations',
          thClass: 'not-sortable-headers',
          sortable: false,
          width: '10%'
        },
        {
          label: 'Actions',
          field: 'actions',
          thClass: 'not-sortable-headers',
          sortable: false,
          width: '10%'
        }
      ],
      rows: [],
      searchTerm: '',
      totalRecords: 0,
      page: 1,
      perPage: 10,
      orderField: '',
      orderType: '',
      filters: []
    },
    tableFilters: [],
    viewSegment: {},
    audienceTemplates: [],
    templateId: null,
    canCreate: null,
    activations: [],
    parameters: [],
    segmentTable: [],
    operators: [],
    organizations: [],
    filterSegments: [],
    topics: [],
    isContextualTargetingEnabled: null,
    IDtoDelete: -1,
    IDtoDuplicate: -1
  },
  getters: {
    table: (state) => state.table,
    tableFilters: (state) => state.tableFilters,
    canCreate: (state) => state.canCreate,
    activations: (state) => state.activations,
    parameters: (state) => state.parameters,
    segmentTable: (state) => state.segmentTable,
    parameterOperators: (state) => state.operators,
    IDtoDelete: (state) => state.IDtoDelete,
    IDtoDuplicate: (state) => state.IDtoDuplicate,
    audienceTemplates: (state) => state.audienceTemplates,
    templateId: (state) => state.templateId,
    organizations: (state) => state.organizations,
    filterSegments: (state) => state.filterSegments,
    viewSegment: (state) => state.viewSegment,
    topics: (state) => state.topics,
    isContextualTargetingEnable: (state) => state.isContextualTargetingEnabled
  },
  actions: {
    async getTable({ commit, state }, { id }) {
      const queryParamString = formatRequestQuery(
        state.table.searchTerm,
        state.table.page,
        state.table.perPage,
        state.table.orderField,
        state.table.orderType
      )
      const filterQuery = formatFilterQuery(
        state.tableFilters,
        state.table.filters
      )

      const response = await axiosIns.get(
        `/api/v2/${id}/segments?${queryParamString}${filterQuery}`
      )

      commit('SET_TABLE_ROWS', destructJSONObject(response, 'segments'))
      commit('SET_CAN_CREATE', destructJSONObject(response, 'canCreate'))
      commit('SET_TOTAL_RECORDS', destructJSONObject(response, 'totalCount'))
    },
    async getTableFilters({ commit }, { organizationId }) {
      const response = await axiosIns.get(
        `/api/v1/${organizationId}/segments/filters`
      )

      commit('SET_TABLE_FILTERS', {
        tableFilters: destructJSONObject(response, 'filters'),
        organizationId
      })
    },
    async getActivations({ commit }, { organizationId }) {
      const response = await axiosIns.get(
        `/api/v1/${organizationId}/integrations/segments/activations`
      )

      commit('SET_ACTIVATIONS', destructJSONObject(response, 'activations'))
    },
    async getSegmentTables(
      { commit },
      { organizationId, filterOrganizationId }
    ) {
      const response = await axiosIns.get(
        `/api/v2/${organizationId}/segments/tables/${filterOrganizationId}`
      )

      commit('SET_SEGMENT_TABLE', destructJSONObject(response, 'tables'))
      return destructJSONObject(response, 'tables')
    },
    async getParameters({ commit }, { organizationId, tableId }) {
      const response = await axiosIns.get(
        `/api/v1/${organizationId}/segments/filter/parameters/${tableId}`
      )

      commit(
        'SET_PARAMETERS',
        destructJSONObject(response, 'targetingParameterGroups')
      )
      return destructJSONObject(response, 'targetingParameterGroups')
    },
    async getParameterOperators({ commit }, { organizationId }) {
      const response = await axiosIns.get(
        `/api/v1/${organizationId}/segments/parameters/operators`
      )

      commit(
        'SET_PARAMETER_OPERATORS',
        destructJSONObject(response, 'columnTypeParameterOperators')
      )
    },
    async getAudienceTemplates({ commit }, { organizationId }) {
      const response = await axiosIns.get(
        `/api/v1/${organizationId}/segments/templates`
      )

      commit(
        'SET_AUDIENCE_TEMPLATES',
        destructJSONObject(response, 'segmentTemplates')
      )
    },
    async getOrganizations({ commit }, { organizationId }) {
      const response = await axiosIns.get(
        `/api/v2/${organizationId}/segments/organizations`
      )

      commit('SET_ORGANIZATIONS', destructJSONObject(response, 'organizations'))
      return destructJSONObject(response, 'organizations')
    },
    async getFile({ state }, { id, format }) {
      const queryParamString = formatRequestQuery(
        state.table.searchTerm,
        state.table.page,
        state.table.perPage,
        state.table.orderField,
        state.table.orderType
      )
      const filterQuery = formatFilterQuery(
        state.tableFilters,
        state.table.filters
      )

      return axiosIns
        .get(
          `/api/v2/${id}/segments/details/file?FileDownloadFormat=${format}&${queryParamString}${filterQuery}`,
          { responseType: 'blob' }
        )
        .then((response) => response.data)
    },
    async getFilterSegments({ commit }, { organizationId }) {
      const response = await axiosIns.get(
        `/api/v1/${organizationId}/segments/block/filter/activeSegments`
      )

      commit('SET_FILTER_SEGMENTS', destructJSONObject(response, 'segments'))
    },
    async getSegment({ commit }, { organizationId, segmentId }) {
      const response = await axiosIns.get(
        `/api/v1/${organizationId}/segments/${segmentId}`
      )

      commit('SET_SEGMENT_VIEW', response.data.data)
    },
    async getTopics({ commit }, { organizationId }) {
      const response = await axiosIns.get(
        `/api/v1/${organizationId}/contextual-targeting/topics/basic`,
        { baseURL: API_GATEWAY_URL }
      )

      commit('SET_TOPICS', destructJSONObject(response, 'topics'))
      commit(
        'SET_CONTEXTUAL_TARGETING_ENABLE',
        destructJSONObject(response, 'contextualTargetingEnabled')
      )
    }
  },
  mutations: {
    SET_TABLE_ROWS(state, segments) {
      state.table.rows = segments
    },
    SET_CAN_CREATE(state, create) {
      state.canCreate = create
    },
    SET_TOTAL_RECORDS(state, totalRecords) {
      state.table.totalRecords = totalRecords
    },
    SET_TABLE_FILTERS(state, { tableFilters, organizationId }) {
      const storageKey = `segmentFilters-${organizationId}`
      const localFilters = JSON.parse(localStorage.getItem(storageKey) || '[]')
      const { filters, appliedFilters } = prepareTableFilters(
        tableFilters,
        localFilters
      )

      localStorage.setItem(storageKey, JSON.stringify(appliedFilters))
      state.table.filters = appliedFilters
      state.tableFilters = filters
    },
    APPLY_TABLE_FILTERS(state, filters) {
      state.table.filters = filters
    },
    SET_ACTIVATIONS(state, activations) {
      state.activations = activations
    },
    SET_PARAMETERS(state, parameters) {
      state.parameters = parameters
    },
    SET_SEGMENT_TABLE(state, segmentTable) {
      state.segmentTable = segmentTable
    },
    SET_PARAMETER_OPERATORS(state, operators) {
      state.operators = operators
    },
    SET_ID_TO_DELETE(state, id) {
      state.IDtoDelete = id
    },
    SET_ID_TO_DUPLICATE(state, id) {
      state.IDtoDuplicate = id
    },
    SET_AUDIENCE_TEMPLATES(state, templates) {
      state.audienceTemplates = templates
    },
    SET_TEMPLATE_ID(state, id) {
      state.templateId = id
    },
    SET_ORGANIZATIONS(state, organizations) {
      state.organizations = organizations
    },
    SET_FILTER_SEGMENTS(state, segments) {
      state.filterSegments = segments
    },
    SET_SEGMENT_VIEW(state, segment) {
      state.viewSegment = getSegmentView(segment)
    },
    SET_TABLE_PAGE(state, page) {
      state.table.page = page
    },
    SET_TOPICS(state, topics) {
      state.topics = topics
    },
    SET_CONTEXTUAL_TARGETING_ENABLE(state, value) {
      state.isContextualTargetingEnabled = value
    }
  }
}
