<template>
  <div class="add-keyword add-keywords-manual">
    <div class="manual-keywords__content">
      <div class="enter-keyword__container input__wrapper">
        <span class="enter-keyword__header">{{
          $t('audience.topics.Keywords')
        }}</span>
        <div class="manual-and-upload__container">
          <b-form-input
            id="manual-input"
            v-model.trim="keywordInput"
            class="form__input manual__input"
            placeholder="Enter keyword"
            type="text"
            :class="{ 'form__input--invalid': manualInputError }"
            @keyup.enter="isTotalAnalyticsLoading ? {} : addManualKeyword()"
            @input="handleFormInput()"
          />
          <UploadListModal
            upload-type="keywords"
            :upload-function="topics.uploadFile"
            :upload-parameters="[keywordMatchType]"
            :upload-button-text="$t('audience.topics.uploadKeywords')"
            :upload-documentation="`${whitelabel.documentationUrl}/intro#introduction-to-contextual-targeting`"
            :upload-documentation-text="
              $t('audience.topics.uploadFileInformation')
            "
            :file-types="['CSV', 'TXT']"
            :upload-info="$t('audience.topics.fileWordPerRow')"
            :removed-entries-info="
              $t('audience.topics.removedKeywordsInformation')
            "
            @add-file-entries="addKeywordsFromFile"
          >
            <template #fileContent="data">
              <div class="keywords__container">
                <transition-group name="modal-keyword-list">
                  <div
                    v-for="(entry, index) in data.fileContent"
                    :key="entry.keyword"
                    class="keyword__row"
                  >
                    <span class="keyword__index">{{ index + 1 }}.</span>
                    <span class="keyword__name">
                      {{ entry.keyword }}
                      <span
                        class="remove__keyword-list"
                        @click="data.removeEntry(index)"
                      >
                        <img src="@/assets/svgs/minus.svg" />
                      </span>
                    </span>
                  </div>
                </transition-group>
              </div>
            </template>
          </UploadListModal>
        </div>
        <span
          v-if="!manualInputError && !duplicateError"
          class="enter-keyword__information-text"
          >*{{ $t('audience.topics.enterKeywordInformation') }}</span
        >
        <span v-if="manualInputError" class="error__info-message"
          >{{ $t('audience.topics.invalidKeyword') }}
          <a
            :href="`${whitelabel.documentationUrl}/intro#introduction-to-contextual-targeting`"
            target="_blank"
            >{{ $t('Documentation').toLowerCase() }}</a
          >
          {{ $t('audience.topics.forInstructions') }}.
        </span>
        <span v-if="duplicateError" class="error__info-message"
          >{{ $t('audience.topics.keywordAlreadyExists') }}!</span
        >
      </div>
      <div
        v-if="keywordsError && keywordsError.length > 0"
        class="keywords-error__container"
      >
        <span
          v-for="(error, index) in keywordsError"
          :key="index"
          class="error__info-message form__input--invalid"
        >
          {{ error }}
        </span>
      </div>
      <div>
        <div class="total-analytics__container">
          <div
            v-for="(item, index) in analyticItems"
            :key="index"
            class="total-analytics__item"
          >
            <span class="item__name-and-icon">
              {{ item.name }}
              <div :id="`${item.name}-anaytics`">
                <GjIcon name="Info" size="21" />
                <b-tooltip
                  :target="`${item.name}-anaytics`"
                  triggers="hover"
                  placement="top"
                >
                  {{ item.tooltipText }}.
                </b-tooltip>
              </div>
            </span>
            <span
              class="item__number"
              :class="{ 'item__number--disabled': isTotalAnalyticsLoading }"
              >{{ getAnalyticItem(item.name).toLocaleString() }}</span
            >
          </div>
          <span v-if="isTotalAnalyticsLoading" class="total-analytics__loading">
            <LoadingBar classList="medium" />
          </span>
        </div>
      </div>
      <div id="keywords-list" class="keywords-scrollable__area">
        <section
          v-if="keywordsTable.rows.length"
          class="table__wrapper keywords__table"
        >
          <Table
            :data="keywordsTable"
            type-of-table="keywords"
            :class="{ 'keywords__table--loading': tableLoading }"
          >
            <template #row-data="{ data }">
              <KeywordsRow :data="data" />
            </template>
          </Table>
        </section>
        <div
          v-else-if="!keywordsTable.rows.length && !tableLoading"
          class="no-keywords__container"
        >
          <span class="no-keywords__icon">
            <img src="@/assets/svgs/noKeywords.svg" />
          </span>
          <span class="no-keywords__text"
            >{{ $t('audience.topics.noKeywordsAdded') }}...</span
          >
        </div>
      </div>
      <div v-if="tableLoading" class="keywords__table-overlay">
        <LoadingBar />
      </div>
    </div>
    <div class="add-keywords__footer">
      <span
        id="delete-all-keywords"
        :class="{ 'span--disabled': keywordsTable.rows.length < 1 }"
        @click="
          keywordsTable.rows.length > 0
            ? $bvModal.show('modal-small-keywords')
            : {}
        "
        >{{ $t('deleteAll') }}</span
      >
    </div>
  </div>
</template>

<script>
import { topics } from '@/api'
import KeywordsRow from '@/components/table/table-data/KeywordsRow.vue'
import { t } from '@/libs/i18n/utils'
import store from '@/store'
import { whitelabel } from '@/utils/constants'
import {
  GjIcon,
  // utility components
  LoadingBar,
  Table,
  UploadListModal,
  // utility functions
  countWords
} from '@nodus/utilities-front'
import { BFormInput, BTooltip } from 'bootstrap-vue'
import { computed, getCurrentInstance, onUnmounted, ref } from 'vue'

export default {
  emits: [
    'add-manual-keyword',
    'add-suggested-keyword',
    'add-file-keyword',
    'handle-form-input'
  ],
  components: {
    BFormInput,
    BTooltip,
    Table,
    GjIcon,
    LoadingBar,
    UploadListModal,
    KeywordsRow
  },
  props: {
    keywordsError: {
      type: [String, Array]
    },
    keywordsTable: {
      type: [Object, Array]
    },
    tableLoading: {
      type: [Boolean]
    }
  },
  setup(props) {
    const vm = getCurrentInstance().proxy
    const { organizationId } = vm.$route.params
    const isTotalAnalyticsLoading = computed(
      () => store.getters['topics/totalAnalyticsLoading']
    )
    const totalAnalytics = computed(
      () => store.getters['topics/totalAnalytics']
    )
    const keywordMatchType = computed(
      () => store.getters['topics/keywordMatchType']
    )

    const keywordInput = ref('')
    const manualInputError = ref(false)
    const duplicateError = ref(false)

    const keywordExists = (keyword) =>
      props.keywordsTable.rows
        .map((kw) => kw.keyword.toLowerCase())
        .indexOf(keyword.toLowerCase()) !== -1

    const addManualKeyword = () => {
      if (
        keywordInput.value.length > 1 &&
        keywordInput.value.length <= 500 &&
        countWords(keywordInput.value) < 5
      ) {
        if (!keywordExists(keywordInput.value)) {
          vm.$emit('add-manual-keyword', keywordInput.value)
          keywordInput.value = ''
        } else duplicateError.value = true
      } else manualInputError.value = true
    }

    const addKeywordsFromFile = (keywords) => {
      keywords.forEach((keywordData) => {
        if (
          !keywordExists(keywordData.keyword) &&
          keywordData.keyword.length > 1
        )
          vm.$emit('add-file-keyword', keywordData)
      })
      store.dispatch('topics/getTotalAnalytics', {
        organizationId,
        keywordMatchType: keywordMatchType.value
      })
      vm.$emit('handle-form-input')
    }

    const handleFormInput = () => {
      manualInputError.value = false
      duplicateError.value = false
      vm.$emit('handle-form-input')
    }

    const analyticItems = [
      {
        name: t('Items'),
        tooltipText: t('audience.topics.itemsAnalyticsInfo')
      },
      {
        name: t('Users'),
        tooltipText: t('audience.topics.usersAnalyticsInfo')
      },
      {
        name: t('Events'),
        tooltipText: t('audience.topics.eventsAnalyticsInfo')
      }
    ]

    const getAnalyticItem = (item) => {
      const items = {
        Items: totalAnalytics.value.totalItems,
        Users: totalAnalytics.value.totalUsers,
        Events: totalAnalytics.value.totalEvents
      }

      return items[item]
    }

    onUnmounted(() => {
      store.commit('topics/SET_TOPIC_PRISTINE', true)
    })

    return {
      keywordInput,
      addManualKeyword,
      handleFormInput,
      manualInputError,
      duplicateError,
      addKeywordsFromFile,
      isTotalAnalyticsLoading,
      analyticItems,
      getAnalyticItem,
      totalAnalytics,
      keywordMatchType,
      topics,
      whitelabel
    }
  }
}
</script>
