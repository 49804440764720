import {
  columnTypes,
  durationConst,
  durationOperators,
  filterTypes,
  frequencyOptionsConst,
  parameterOperators,
  parameterOperatorsConst,
  withParametersConst,
} from "@/views/segments/segmentConfig";
import {
  formatDurationValues,
  getParameterTagsModel,
} from "@/views/segments/segmentCreateFunctions";

const getSegmentView = (segment) => {
  const viewSegment = {};
  viewSegment.id = segment.id;
  viewSegment.name = segment.name;
  viewSegment.description = segment.description;
  viewSegment.properties = segment.properties.map((p) => p.name);
  viewSegment.activations = segment.activations;
  viewSegment.canEdit = segment.canEdit;
  viewSegment.blocks = segment.blocks.map((block) => {
    const blockObject = { ...block };

    blockObject.filters = block.filters.map((filter) => {
      if (+filter.type === filterTypes.segment) {
        return {
          type: filter.type,
          segment: filter.segment,
          isDisabled: filter.isDisabled,
        };
      }

      const filterOrganization = filter.filterOrganization.name;
      const table = filter.table.name;
      const filterObject = { ...filter, filterOrganization, table };

      if (filter.durationOperator) {
        if (filter.durationOperator === durationOperators.between)
          filterObject.isBetweenDate = true;
        filterObject.durationValue = formatDurationValues(
          filter.durationValue,
          filter.durationOperator
        );
        filterObject.durationOperator = durationConst.find(
          (d) => d.value === filter.durationOperator
        );
      }
      if (filter.frequencyOperator)
        filterObject.frequencyOperator = frequencyOptionsConst.find(
          (fr) => fr.value === filter.frequencyOperator
        ).text;
      if (filter.parameters.length)
        filterObject.parameterLogicalOperator = withParametersConst.find(
          (pa) => pa.value === filter.parameterLogicalOperator
        ).text;

      filterObject.parameters = filter.parameters.map((param) => {
        const operator = parameterOperatorsConst.find(
          (p) => p.value === param.operator
        ).name;
        const { key } = param.parameterValues[0];
        const parameterValues = getParameterTagsModel(param);
        const paramObject = {
          ...param,
          operator,
          key,
          parameterValues,
        };

        if (
          param.columnType === columnTypes.date ||
          param.columnType === columnTypes.datetime
        ) {
          paramObject.isBetweenDate =
            param.operator === parameterOperators.between;
          paramObject.parameterValues[0] = formatDurationValues(
            param.parameterValues[0].value,
            durationOperators.between
          );
        }
        return paramObject;
      });
      if (filter.topics.length)
        filterObject.topics = filter.topics.map((topic) => topic.name);
      return filterObject;
    });
    return blockObject;
  });

  return viewSegment;
};

export default getSegmentView;
