const queryReprocessOperatorConst = [
  { text: "Once", value: 1 },
  { text: "Every", value: 2 },
];

const queryReprocessOperatorDefaultConst = { text: "Once", value: 1 };

const queryTimeUnitConst = [
  { text: "hours", value: 2 },
  { text: "days", value: 3 },
  { text: "weeks", value: 4 },
  { text: "months", value: 5 },
];

const queryTimeUnitDefaultConst = { text: "hours", value: 2 };

export {
  queryReprocessOperatorConst,
  queryReprocessOperatorDefaultConst,
  queryTimeUnitConst,
  queryTimeUnitDefaultConst,
};
