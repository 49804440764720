import axiosIns from '@/libs/axios'
import { destructJSONObject } from '@nodus/utilities-front'
import Vue from 'vue'
import Vuex from 'vuex'
import integrations from './integrations'
import queries from './queries'
import segments from './segments'
import topics from './topics'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    properties: [],
    userProperties: [],
  },
  getters: {
    allProperties: (state) => state.properties,
    allUserProperties: (state) => state.userProperties,
  },
  actions: {
    async getProperties({ commit }, { id }) {
      const response = await axiosIns.get(`/api/v1/${id}/properties`)

      commit('SET_PROPERTIES', destructJSONObject(response, 'properties'))
    },
    async getPropertiesByUserRole({ commit }, { id }) {
      const response = await axiosIns.get(`/api/v1/${id}/user/properties`)

      commit('SET_USER_PROPERTIES', destructJSONObject(response, 'properties'))
    },
  },
  mutations: {
    SET_PROPERTIES(state, properties) {
      state.properties = properties
    },
    SET_USER_PROPERTIES(state, properties) {
      state.userProperties = properties
    },
  },
  modules: {
    segments,
    queries,
    topics,
    integrations,
  },
})
