<template xmlns:div="http://www.w3.org/1999/html">
  <section
    class="create-segment-form-wrapper segment-view view-screen__container"
  >
    <div v-if="!formState.isFormLoaded" class="loading__container">
      <LoadingBar />
    </div>
    <b-form
      v-if="formState.isFormLoaded"
      novalidate
      class="needs-validation container--position"
      @submit.prevent
    >
      <div class="container__wrapper container__wrapper--height">
        <div class="container__header segment-view__basic-info--header">
          <h5 class="container__header-title">
            {{ $t('data.basicInfo') }}
          </h5>
          <b-link
            v-if="segment.canEdit"
            class="segment-view__edit"
            :to="{
              name: 'segments-edit',
              params: {
                organizationId,
                segmentId
              }
            }"
          >
            <GjIcon name="Edit" size="18" />
            <span>{{ $t('Edit') }}</span>
          </b-link>
        </div>
        <div class="container__content segment-view__basic-info">
          <b-col lg="8" class="input__wrapper pl-0">
            <label>{{ $t('Name') }}</label>
            <span>{{ segment.name }}</span>
          </b-col>
          <b-col lg="8" class="input__wrapper pl-0">
            <label>{{ $t('Description') }}</label>
            <ReadMore :text="segment.description" mode="view" />
          </b-col>
          <b-col lg="8" class="input__wrapper pl-0">
            <label>{{ $t('Properties') }}</label>
            <span>{{ segment.properties.join(', ') }}</span>
          </b-col>
        </div>
      </div>
      <div class="container__wrapper user__segmentation-wrapper">
        <div class="container__header segment-view__container_header">
          <h5 class="container__header-title">
            {{ $t('audience.userSegmentations') }}
          </h5>
          <div>{{ $t('audience.segmentViewTitle') }}:</div>
        </div>
        <div
          v-for="(block, blockIndex) in segment.blocks"
          :key="blockIndex"
          class="container__content"
        >
          <div
            v-for="(filter, filterIndex) in block.filters"
            :key="filterIndex"
            class="container__content--segments"
          >
            <div v-if="+filter.type === filterTypes.filter">
              <div v-if="block.filters.length > 1 && filterIndex !== 0">
                <b-button class="segment__button--or-multiple">
                  <span>{{ $t('audience.or') }}</span>
                </b-button>
              </div>
              <div class="segment-view__row">
                <span>
                  {{
                    filter.isPerformed
                      ? $t('audience.performed')
                      : $t('audience.didntPerform')
                  }}
                  <b> {{ filter.table }}</b> in
                  <b> {{ filter.filterOrganization }}</b>
                  {{
                    filter.filterOrganization === 'All'
                      ? 'organizations'
                      : 'organization'
                  }}
                </span>
                <i
                  v-if="filter.isDisabled"
                  class="segment-view__disabled-label"
                >
                  (disabled)
                </i>
                <InformationSidebar
                  v-if="filter.isDisabled"
                  :code="`${blockIndex}-${filterIndex}-disabled`"
                  size="18"
                  :title="$t('audience.disabledFilter')"
                  :content="$t('audience.disabledFilterInfo')"
                />
              </div>
              <div v-if="filter.durationOperator" class="segment-view__row">
                <span>
                  {{ filter.durationOperator.text }}
                  <b v-if="!filter.isBetweenDate">
                    {{ filter.durationValue }}</b
                  >
                  <span v-else>
                    <b>{{ filter.durationValue.split('_')[0] }}</b> and
                    <b>{{ filter.durationValue.split('_')[1] }}</b>
                  </span>
                </span>
              </div>
              <div v-if="filter.frequencyOperator" class="segment-view__row">
                <span>
                  {{ filter.frequencyOperator }}
                  <b> {{ filter.frequencyFirstValue }}</b>
                  <span v-if="!!filter.frequencySecondValue">
                    and
                    <b> {{ filter.frequencySecondValue }}</b>
                  </span>
                  <span
                    v-if="
                      filter.frequencyFirstValue > 1 ||
                      !!filter.frequencySecondValue
                    "
                  >
                    times</span
                  >
                  <span v-else> time</span>
                </span>
              </div>
              <div v-if="!!filter.parameters.length" class="segment-view__row">
                <div id="first-row">
                  <span>
                    With
                    <b>{{ filter.parameterLogicalOperator }}</b>
                    {{ $t('audience.ofFollowingParameters') }}:
                  </span>
                  <div class="segment-view__parameters">
                    <div
                      v-for="(param, paramIndex) in filter.parameters"
                      :key="paramIndex"
                      class="segment-view__parameter"
                    >
                      <div class="segment-view__parameter--header">
                        <span>
                          {{ param.name }}
                          <i v-if="param.key">{{ param.key }} </i>
                          <b>{{ param.operator }}</b>
                        </span>
                      </div>
                      <div class="segment-view__parameter--values">
                        <span v-if="param.isBetweenDate">
                          <i>{{ param.parameterValues[0].split('_')[0] }}</i>
                          and
                          <i>{{ param.parameterValues[0].split('_')[1] }}</i>
                        </span>
                        <i v-else>{{ param.parameterValues.join('; ') }}</i>
                        <span
                          v-if="paramIndex !== filter.parameters.length - 1"
                        >
                          <b>
                            {{
                              filter.parameterLogicalOperator === 'all'
                                ? 'and'
                                : 'or'
                            }}</b
                          >
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div v-if="filter.topics.length" class="segment-view__row">
                <span
                  >{{ $t('audience.topics.visitedFromTopics') }}:
                  <b>{{ filter.topics.join(', ') }}</b>
                </span>
              </div>
            </div>
            <div v-else-if="+filter.type === filterTypes.segment">
              <div v-if="block.filters.length > 1 && filterIndex !== 0">
                <b-button class="segment__button--or-multiple">
                  <span>{{ $t('audience.or') }}</span>
                </b-button>
              </div>
              <div class="segment-view__row">
                <span>
                  Users from segment:
                  <b>{{ filter.segment.name }}</b>
                  <i
                    v-if="filter.isDisabled"
                    class="segment-view__disabled-label"
                  >
                    ({{ filter.segment.label }})</i
                  >
                </span>
                <InformationSidebar
                  v-if="filter.isDisabled"
                  :code="`${blockIndex}-${filterIndex}`"
                  class="segment-view__info-icon"
                  size="18"
                  :title="$t('audience.disabledFilter')"
                  :content="`''${
                    filter.segment.name
                  }'' is ${filter.segment.label.toLowerCase()},
                    users targeted with this segment will no longer be part of ''${
                      segment.name
                    }''`"
                />
              </div>
            </div>
          </div>
          <div
            class="segment__button-and__container"
            :class="{
              'segment__button-and__container--multiple':
                segment.blocks.length > 1 &&
                blockIndex !== segment.blocks.length - 1
            }"
          >
            <div
              v-if="blockIndex !== segment.blocks.length - 1"
              class="segment__arrow segment__arrow--multiple"
            />
            <div
              v-if="
                segment.blocks.length > 1 &&
                blockIndex !== segment.blocks.length - 1
              "
            >
              <b-button
                class="segment__button--reversed segment__button--reversed--multiple"
              >
                <span>{{ $t('audience.and') }}</span>
              </b-button>
            </div>
          </div>
        </div>
      </div>
      <div v-if="segment.activations.length > 0" class="container__wrapper">
        <div class="container__header segment-view__container_header">
          <h5 class="container__header-title">
            {{ $t('Integrations') }}
          </h5>
        </div>
        <div class="container__integrations">
          <IntegrationCard
            v-for="activation in segment.activations"
            :key="activation.id"
            :activation="activation"
            view-mode
          />
        </div>
      </div>
    </b-form>
    <Footer v-if="formState.isFormLoaded" view-mode @back="cancel()" />
  </section>
</template>

<script>
import store from '@/store'
import {
  Footer,
  GjIcon,
  InformationSidebar,
  IntegrationCard,
  LoadingBar,
  ReadMore
} from '@nodus/utilities-front'
import { BButton, BCol, BForm, BLink } from 'bootstrap-vue'
import { computed, getCurrentInstance, onBeforeMount, reactive } from 'vue'
import { filterTypes } from './segmentConfig'

export default {
  name: 'SegmentView',
  components: {
    BCol,
    BButton,
    BForm,
    BLink,
    GjIcon,
    InformationSidebar,
    LoadingBar,
    IntegrationCard,
    Footer,
    ReadMore
  },
  setup() {
    const vm = getCurrentInstance().proxy
    const { organizationId, segmentId } = vm.$route.params

    const segment = computed(() => store.getters['segments/viewSegment'])

    const formState = reactive({
      isLoading: false,
      isFormLoaded: false
    })

    onBeforeMount(async () => {
      await store.dispatch('segments/getSegment', { organizationId, segmentId })
      formState.isFormLoaded = true
    })

    const cancel = () => {
      vm.$router.push({ name: 'segments' })
    }

    return {
      segment,
      formState,
      organizationId,
      segmentId,
      cancel,
      filterTypes
    }
  }
}
</script>

<style lang="scss">
@import '@/assets/scss/views/shared';
@import '@/assets/scss/views/segments/segmentCreate.scss';
@import '@/assets/scss/views/segments/segmentView.scss';
</style>
