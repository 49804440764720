var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", [
    _vm.data.column.field === "insertDateTime"
      ? _c("div", [_c("DateColumn", { attrs: { data: _vm.data } })], 1)
      : _vm.data.column.field === "name"
      ? _c("span", { staticClass: "dark-gray-color" }, [
          _c(
            "div",
            { staticClass: "name__wrapper" },
            [
              _c("IconHeading", {
                attrs: { id: _vm.data.row.code, data: _vm.data },
              }),
              _c("ReadMore", {
                attrs: { text: _vm.data.row.name, to: _vm.viewLocation },
              }),
            ],
            1
          ),
        ])
      : _vm.data.column.field === "userCount"
      ? _c("span", [
          _vm._v(" " + _vm._s(_vm.data.row.userCount.toLocaleString()) + " "),
        ])
      : _vm.data.column.field === "description"
      ? _c(
          "span",
          [_c("ReadMore", { attrs: { text: _vm.data.row.description } })],
          1
        )
      : _vm.data.column.field === "integrations"
      ? _c(
          "span",
          { staticClass: "integrations-and-actions" },
          [
            _c("IntegrationColumn", {
              attrs: {
                "row-id": _vm.data.row.code,
                activations: _vm.data.row.activations,
              },
            }),
          ],
          1
        )
      : _vm.data.column.field === "actions"
      ? _c("span", { staticClass: "integrations-and-actions" }, [
          _c("div", { staticClass: "table-list-switch-and-actions" }, [
            _c(
              "div",
              { staticClass: "switch-and-information" },
              [
                _c("b-form-checkbox", {
                  staticClass: "custom-control-primary",
                  attrs: {
                    id: `switch-status-${_vm.data.row.code}`,
                    disabled: !_vm.data.row.canActivate || _vm.isLoading,
                    checked: !!_vm.data.row.processingStatus,
                    name: "check-button",
                    switch: "",
                  },
                  on: {
                    change: function ($event) {
                      _vm.data.row.canActivate
                        ? _vm.changeProcessingStatus(
                            _vm.data.row.code,
                            _vm.data.row.processingStatus ? 0 : 1
                          )
                        : {}
                    },
                  },
                }),
                _c("InformationSidebar", {
                  attrs: {
                    title: _vm.$t("audience.segmentDisable"),
                    content: _vm.$t("audience.segmentDisableInformation"),
                    code: _vm.data.row.code.toLocaleString(),
                  },
                }),
              ],
              1
            ),
            _c(
              "div",
              { staticClass: "table-list-actions-container" },
              [_c("DropdownActions", { attrs: { items: _vm.actionItems } })],
              1
            ),
          ]),
        ])
      : _c("span", [
          _vm._v(
            " " + _vm._s(_vm.data.formattedRow[_vm.data.column.field]) + " "
          ),
        ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }