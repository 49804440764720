// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/*
      ************ MEDIA QUERY MANAGER ******************

   0 - 576px:         Small devices (landscape phones, 576px and up)
   576px - 768px:     Medium devices (tablets, 768px and up)
   768px - 992px:     Large devices (desktops, 992px and up)
   992px - 1200px:    Big devices (large desktops, 1200px and up)
   1200px - *:        Extra large devices

*/
/*================================================================================

NOTE:
------
PLACE HERE YOUR OWN SCSS CODES AND IF NEEDED, OVERRIDE THE STYLES FROM THE OTHER STYLESHEETS.
WE WILL RELEASE FUTURE UPDATES SO IN ORDER TO NOT OVERWRITE YOUR STYLES IT'S BETTER LIKE THIS.  */
.bisko-app__audience .custom-control.custom-switch label::before {
  height: 24px;
  width: 34px;
  border-radius: 14px;
  background-color: white !important;
  border: 1px solid #e2e2e2 !important;
}
.bisko-app__audience .custom-control.custom-switch label::after {
  width: 16px;
  height: 16px;
  top: 4px !important;
  background-color: #a3b0c2;
}

.bisko-app__audience .custom-switch .custom-control-input:checked ~ .custom-control-label::after {
  transform: translateX(11px);
  background-color: #1d79f2;
  box-shadow: 0px 2px 4px rgba(29, 121, 242, 0.4);
}

.bisko-app__audience .custom-control-input:disabled:checked ~ .custom-control-label::before {
  background-color: white !important;
}

.bisko-app__audience .custom-control-input:disabled:checked ~ .custom-control-label::after {
  background-color: #eaeaea;
  box-shadow: none;
}

.bisko-app__audience .custom-control-input:disabled ~ .custom-control-label::after {
  background-color: #eaeaea;
  box-shadow: none;
}

.bisko-app__audience .integrations-and-actions {
  display: flex;
  justify-content: space-between;
  gap: 12px;
}
@media (max-width: 1200px) {
  .bisko-app__audience .integrations-and-actions {
    flex-wrap: wrap;
  }
}

.bisko-app__audience .table-list-switch-and-actions {
  display: flex;
  justify-content: space-between;
  gap: 10px;
  padding-right: 10px;
}
.bisko-app__audience .table-list-switch-and-actions--single {
  justify-content: flex-end;
}

.bisko-app__audience .switch-and-information {
  display: flex;
  align-items: center;
  gap: 10px;
}
.bisko-app__audience .switch-and-information .custom-control-primary {
  width: 34px;
}
.bisko-app__audience .switch-and-information .custom-control-primary .custom-control-label {
  height: 24px;
  padding-left: 34px !important;
}

.bisko-app__audience .container__wrapper {
  flex: 1 0 auto;
  background: #ffffff;
  border-radius: 4px;
  padding-bottom: 20px;
  margin-bottom: 20px;
}

.bisko-app__audience .container__header {
  display: flex;
  padding-bottom: 16px;
}
.bisko-app__audience .container__header.container__header--padding {
  margin: 0 16px;
}

.bisko-app__audience .container__header-title {
  font-size: 16px;
  line-height: 18px;
  color: #052d61;
  margin-bottom: 0;
}

.bisko-app__audience .container__header.documentation__header--padding {
  padding-bottom: 8px;
}

.bisko-app__audience .input__wrapper,
.bisko-app__audience .connection__modal-input__wrapper {
  padding-bottom: 15px;
}
.bisko-app__audience .input__wrapper label,
.bisko-app__audience .connection__modal-input__wrapper label {
  font-size: 14px;
  line-height: 18px;
  color: #667c99;
  font-weight: 400;
}
.bisko-app__audience .input__wrapper select,
.bisko-app__audience .connection__modal-input__wrapper select {
  font-size: 14px;
  line-height: 18px;
  color: #a3b0c2;
}
.bisko-app__audience .input__wrapper textarea,
.bisko-app__audience .connection__modal-input__wrapper textarea {
  border-color: #e0e5eb;
}
.bisko-app__audience .input__wrapper .vs--disabled .vs__dropdown-toggle,
.bisko-app__audience .input__wrapper .vs--disabled .vs__clear,
.bisko-app__audience .input__wrapper .vs--disabled .vs__search,
.bisko-app__audience .input__wrapper .vs--disabled .vs__selected,
.bisko-app__audience .input__wrapper .vs--disabled .vs__open-indicator,
.bisko-app__audience .connection__modal-input__wrapper .vs--disabled .vs__dropdown-toggle,
.bisko-app__audience .connection__modal-input__wrapper .vs--disabled .vs__clear,
.bisko-app__audience .connection__modal-input__wrapper .vs--disabled .vs__search,
.bisko-app__audience .connection__modal-input__wrapper .vs--disabled .vs__selected,
.bisko-app__audience .connection__modal-input__wrapper .vs--disabled .vs__open-indicator {
  background-color: #efefef !important;
}
.bisko-app__audience .input__wrapper ::-webkit-input-placeholder,
.bisko-app__audience .connection__modal-input__wrapper ::-webkit-input-placeholder {
  /* Chrome/Opera/Safari */
  color: #a3b0c2;
  font-size: 14px;
  line-height: 18px;
}
.bisko-app__audience .input__wrapper ::-moz-placeholder,
.bisko-app__audience .connection__modal-input__wrapper ::-moz-placeholder {
  /* Firefox 19+ */
  color: #a3b0c2;
  font-size: 14px;
  line-height: 18px;
}
.bisko-app__audience .input__wrapper :-ms-input-placeholder,
.bisko-app__audience .connection__modal-input__wrapper :-ms-input-placeholder {
  /* IE 10+ */
  color: #a3b0c2;
  font-size: 14px;
  line-height: 18px;
}
.bisko-app__audience .input__wrapper :-moz-placeholder,
.bisko-app__audience .connection__modal-input__wrapper :-moz-placeholder {
  /* Firefox 18- */
  color: #a3b0c2;
  font-size: 14px;
  line-height: 18px;
}

.bisko-app__audience .loading__container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.bisko-app__audience .delete-modal .modal-content .modal-body {
  font-size: 14px;
  padding: 2rem 1rem;
  text-align: center;
  color: #052d61;
}
.bisko-app__audience .delete-modal .modal-content .modal-footer > .btn {
  width: 80px;
  margin: 0 0 0 1rem;
}

.bisko-app__audience .modal-header {
  background-color: white !important;
  border-bottom: 1px solid #e0e5eb !important;
}

.bisko-app__audience .modal-footer > .btn {
  border-color: #e0e5eb !important;
  color: #052d61;
  width: 80px;
  margin: 0 0 0 1rem;
}

/*#region export and record view*/
.bisko-app__audience .item__container {
  margin-bottom: 24px;
  display: flex;
  justify-content: flex-start;
  align-items: baseline;
}
.bisko-app__audience .item__container--center {
  align-items: center;
}
.bisko-app__audience .item__container .icon__margin {
  margin-left: -3px;
  margin-right: 7px;
}
.bisko-app__audience .item__container .icon__margin--mobile {
  margin-left: -5px;
}

.bisko-app__audience .template__text {
  display: inline-block;
  margin: 0 24px 0 0;
  color: #667c99;
  font-size: 14px;
  min-width: 145px;
  max-width: 145px;
  vertical-align: top;
  word-break: break-all;
}
.bisko-app__audience .template__text--no-break {
  max-width: unset;
  word-break: normal;
}

.bisko-app__audience .dynamic-data__text {
  display: inline-block;
  font-size: 14px;
  max-width: 450px;
  color: #052d61;
  word-break: break-word;
}

/*#endregion */
.bisko-app__audience .container__wrapper--height {
  height: 100%;
  position: relative;
}

.bisko-app__audience .form__wrapper {
  margin: 0 auto;
  min-width: 1117px;
  max-width: 1117px;
}
@media (max-width: 1440px) {
  .bisko-app__audience .form__wrapper {
    width: 100%;
    min-width: auto;
  }
}

.bisko-app__audience .modal .modal-content .modal-title {
  font-weight: 400;
}

.bisko-app__audience .modal .modal-header .close {
  background-color: transparent !important;
}
.bisko-app__audience .modal .modal-header .close:hover {
  box-shadow: none !important;
}

.bisko-app__audience .modal .modal-footer {
  border-top: 1px solid #e0e5eb;
}

.bisko-app__audience .text--red {
  color: #e34850;
}

.bisko-app__audience .documentation__info-text {
  font-size: 13px;
  line-height: 18px;
  color: #7483a1;
  display: flex;
  align-items: center;
  text-align: left;
}
.bisko-app__audience .documentation__info-text a {
  color: #052d61;
  text-decoration: underline;
}
.bisko-app__audience .documentation__info-text a:hover {
  color: #052d61;
}
.bisko-app__audience .documentation__info-text svg {
  min-width: 18px;
}

.bisko-app__audience .dropdown .dropdown-menu .dropdown-item:active {
  background-color: rgba(29, 121, 242, 0.12);
}

.bisko-app__audience .navigation {
  font-family: "Inter", "SFProText";
  font-weight: 400;
}

.bisko-app__audience .tabs ul.nav.nav-tabs li a.nav-link[aria-selected=true] {
  font-weight: 400;
}

.bisko-app__audience section.audience__section {
  width: 100%;
}
.bisko-app__audience section.audience__section table.vgt-table.bordered {
  width: 100%;
}
.bisko-app__audience section.audience__section table tbody tr td span {
  font-size: 14px;
  line-height: 18px;
  color: #11254a;
}
.bisko-app__audience section.audience__section table tbody tr td span.dark-gray-color {
  color: #052d61;
  cursor: pointer;
}

.bisko-app__audience .segment__spinner {
  width: 15px;
  height: 15px;
  margin-right: 5px;
}

.bisko-app__audience .segments__list {
  padding: 0 10px;
}

.bisko-app__audience .export-and-search {
  display: flex;
  gap: 0.5rem;
}
.bisko-app__audience .export-and-search .table__search {
  padding: 0;
}

.bisko-app__audience .integration-status {
  height: 6px;
  width: 6px;
  border-radius: 50%;
  display: inline-block;
  margin: 0 8px 2px;
}
.bisko-app__audience .integration-status--active {
  background-color: #2d9d78;
}
.bisko-app__audience .integration-status--inactive {
  background-color: #e34850;
}
.bisko-app__audience .integration-status + span {
  color: #052d61;
}

.bisko-app__audience .dropdown-item {
  padding: 0.65rem 1.28rem;
}
.bisko-app__audience .dropdown-item .disabled {
  opacity: 0.6;
}

.bisko-app__audience .container__title {
  font-size: 16px;
  color: #173163;
  line-height: 24px;
  margin-bottom: 16px;
}
.bisko-app__audience .container__title--margin {
  margin-bottom: 24px;
}

.bisko-app__audience .basic-information__container {
  margin-bottom: 35px;
  position: relative;
}
.bisko-app__audience .basic-information__container .edit__button {
  position: absolute;
  right: 0;
  top: 0;
  display: flex;
  align-items: center;
  color: #2c3e50;
  text-decoration: none;
}
.bisko-app__audience .basic-information__container .edit__button:hover {
  cursor: pointer;
}
.bisko-app__audience .basic-information__container .edit__button span {
  font-size: 14px;
  color: #667c99;
  margin-left: 8px;
}

.bisko-app__audience .record__text {
  display: inline-block;
  font-size: 14px;
  max-width: 450px;
  color: #052d61;
  word-break: break-word;
}

.bisko-app__audience .view-screen__container {
  max-width: 926px;
  min-width: 926px;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  min-height: calc(100vh - 120px);
}

@media only screen and (max-width: 1440px) {
  .bisko-app__audience .view-screen__container {
    width: 100%;
    min-width: auto;
  }
}`, ""]);
// Exports
export default ___CSS_LOADER_EXPORT___;
