// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/*
      ************ MEDIA QUERY MANAGER ******************

   0 - 576px:         Small devices (landscape phones, 576px and up)
   576px - 768px:     Medium devices (tablets, 768px and up)
   768px - 992px:     Large devices (desktops, 992px and up)
   992px - 1200px:    Big devices (large desktops, 1200px and up)
   1200px - *:        Extra large devices

*/
.bisko-app__audience .container__wrapper--height {
  height: 100%;
  position: relative;
}
.bisko-app__audience .integration-container__content {
  width: 50%;
  padding-top: 20px;
  font-size: 16px;
  color: #667c99;
}
@media (max-width: 576px) {
.bisko-app__audience .integration-container__content {
    width: 80%;
    padding: 0;
}
}
.bisko-app__audience .integration-container__content-header {
  display: flex;
  align-items: center;
}
@media (max-width: 576px) {
.bisko-app__audience .integration-container__content-header {
    margin-top: 20px;
    flex-direction: column;
}
}
.bisko-app__audience .integration-container__content-header__image {
  display: flex;
  justify-content: center;
  align-items: center;
  border: 1px solid #e0e5eb;
  border-radius: 4px;
  padding: 10px;
  width: 132px;
  height: 132px;
}
.bisko-app__audience .integration-container__content-header__image img {
  width: 70%;
}
.bisko-app__audience .integration-container__content-header__text {
  display: flex;
  flex-direction: column;
  margin-left: 36px;
}
@media (max-width: 576px) {
.bisko-app__audience .integration-container__content-header__text {
    text-align: center;
    margin-top: 20px;
}
}
.bisko-app__audience .integration__header-container-view {
  margin-bottom: 24px;
}
.bisko-app__audience .integration__header-name {
  font-size: 20px;
  color: #052d61;
  line-height: 30px;
}
.bisko-app__audience .integration__description {
  font-size: 14px;
  margin-top: 5px;
}
.bisko-app__audience .integration-container__content-body {
  padding-top: 20px;
}
.bisko-app__audience .integration-container__content-inputs {
  padding-bottom: 10px;
}
.bisko-app__audience .integration-container__content-inputs .input__wrapper {
  padding: 0 0 10px 0;
}
.bisko-app__audience .integration-container__content-inputs label {
  text-transform: capitalize;
  color: #667c99;
}
.bisko-app__audience .integration-container__content-inputs__errors {
  position: relative;
  bottom: 10px;
  color: #e34850;
  font-size: 13px;
}
.bisko-app__audience .status-and-actions {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.bisko-app__audience .status-and-actions > div:first-child {
  margin-top: -20px;
}
.bisko-app__audience .template__text {
  min-width: 145px;
  max-width: 145px;
  word-break: break-all;
}
.bisko-app__audience .integration__view__spinner-container {
  min-height: 400px;
}
.bisko-app__audience .integration__table-img-container {
  display: flex;
  align-items: center;
  width: 100%;
}
.bisko-app__audience .integration__table-img-content {
  display: flex;
  align-items: center;
  border: 1px solid #e0e5eb;
  border-radius: 4px;
  padding: 1px;
  width: 36px;
  min-width: 36px;
  height: 36px;
  margin-right: 10px;
}
.bisko-app__audience .integration__table-img-content img {
  width: 100%;
}
.bisko-app__audience .integration-name-col {
  width: 350px !important;
  padding-left: 62px;
}
.bisko-app__audience .integration-name-col::before, .bisko-app__audience .integration-name-col::after {
  left: 48px;
}
.bisko-app__audience .btn-scroll-to-top {
  display: none;
}
.bisko-app__audience .integration__general-error {
  font-size: 14px;
}
.bisko-app__audience .integration__general-error svg {
  margin-bottom: 3px;
}`, ""]);
// Exports
export default ___CSS_LOADER_EXPORT___;
