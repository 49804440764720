const durationConst = [
  { text: "Before", value: 2 },
  { text: "After", value: 3 },
  { text: "The last", value: 4 },
  { text: "Before the last", value: 6 },
  { text: "Between", value: 5 },
];

const eventStateConst = [
  { text: "was performed", value: true },
  { text: "wasn't performed", value: false },
];

const filterTypes = {
  filter: 1,
  segment: 2,
};

const eventStateDefaultConst = { text: "was performed", value: true };

const timeOptionsConst = ["hours", "days", "weeks", "months", "years"];

const eventTableIdDefault = "bc2aefaa-fc35-4edc-ba17-d4a6a172e609";

const frequencyOptionsConst = [
  { text: "Exactly", value: 1 },
  { text: "Not", value: 2 },
  { text: "At least", value: 3 },
  { text: "At most", value: 4 },
  { text: "Between", value: 5 },
];

const withParametersConst = [
  { text: "all", value: 1 },
  { text: "any", value: 2 },
];

const parameterOperatorsConst = [
  { name: "Is", value: 1 },
  { name: "Is not", value: 2 },
  { name: "Contains", value: 3 },
  { name: "Doesn't contain", value: 4 },
  { name: "Starts with", value: 5 },
  { name: "Ends with", value: 6 },
  { name: "Greater", value: 7 },
  { name: "Equal", value: 8 },
  { name: "Lower", value: 9 },
  { name: "Greater or equal", value: 10 },
  { name: "Lower or equal", value: 11 },
  { name: "After", value: 12 },
  { name: "Before", value: 13 },
  { name: "Between", value: 14 },
];

const parametersBooleanValuesConst = [
  { text: "true", value: "true" },
  { text: "false", value: "false" },
];

const parameterModel = {
  isCustomParam: false,
  isIntParam: false,
  isStringParam: false,
  columnId: {
    errors: [],
  },
  parameterOptionsModel: "",
  parameterOptions: [],
  parametersStringOrCustomModel: {},
  parametersBooleanValues: parametersBooleanValuesConst,
  paramTagsModel: [""],
  parametersStringKeyModel: "",
  parametersIntModel: {},
  // TODO defined needed attributes
  selectedParameterOptions: {},
  selectedParameterOptionModel: "",
  operator: {
    errors: [],
  },
  isBooleanParam: false,
  parametersBooleanModel: "",
  isDateParam: false,
  parameterFirstDate: "",
  parameterSecondDate: "",
  isParameterBetweenOperatorShown: false,
  parameterValues: {
    errors: [],
  },
};

const filterModel = {
  filters: {
    errors: [],
  },
  filterType: 1,
  segment: {
    value: "",
    errors: [],
  },
  filterOrganization: {
    value: "",
    errors: [],
  },
  eventState: eventStateConst,
  eventStateDefault: eventStateDefaultConst,
  durationOperator: {
    value: { text: "Before", value: 2 },
    errors: [],
  },
  durationOptions: durationConst,
  frequencyOperator: {
    value: { text: "Exactly", value: 1 },
    errors: [],
  },
  frequencyOptions: frequencyOptionsConst,
  withParametersDefault: { text: "all", value: 1 },
  withParameters: withParametersConst,
  timeLastOptions: timeOptionsConst,
  segmentationTableOptions: null,
  segmentationTableModel: "",
  tableId: {
    errors: [],
  },
  isTimeParamSet: false,
  isFrequencyParamSet: false,
  isParametersParamSet: false,
  isTopicsParamSet: false,
  isDurationDatepickerShown: true,
  isDurationLastParamShown: false,
  isDurationBetweenParamShown: false,
  showFrequencySecondParam: false,
  durationValue: {
    errors: [],
  },
  durationFirstDate: "",
  durationSecondDate: "",
  durationNumberParam: "",
  durationNumberFormatParam: "",
  frequencyFirstValue: {
    value: "",
    errors: [],
  },
  frequencySecondValue: {
    value: "",
    errors: [],
  },
  minDate: "",
  isDateDisabled: true,
  parameters: [parameterModel],
  topics: {
    value: [],
    errors: [],
  },
};

const blockModel = {
  filters: [filterModel],
};

const activationsModel = {
  id: "",
  name: "",
  imageUrl: "",
  false: "",
};

const segmentModel = {
  name: {
    value: "",
    errors: [],
  },
  description: {
    value: "",
    errors: [],
  },
  audienceProperties: [],
  blocks: [blockModel],
  activations: [activationsModel],
};

const dropdownOptions = [
  {
    id: 1,
    name: "Create new",
    clickHandler: null,
  },
  {
    id: 2,
    name: "Select from the list",
    clickHandler: null,
  },
];

const columnTypes = {
  int: 1,
  longInt: 4,
  string: 6,
  fixedString: 7,
  arrayString: 8,
  arrayInt: 9,
  keyValuePair: 10,
  date: 11,
  datetime: 12,
  decimal: 13,
  boolean: 14,
  mixed: 16,
  arrayDouble: 17,
  double: 18,
};

const durationOperators = {
  before: 2,
  after: 3,
  theLast: 4,
  beforeTheLast: 6,
  between: 5,
};

const parameterOperators = {
  is: 1,
  isNot: 2,
  contains: 3,
  doesNotContain: 4,
  startsWith: 5,
  endsWith: 6,
  greater: 7,
  equal: 8,
  lower: 9,
  greaterOrEqual: 10,
  lowerOrEqual: 11,
  after: 12,
  before: 13,
  between: 14,
};

export {
  durationConst,
  eventStateConst,
  eventStateDefaultConst,
  timeOptionsConst,
  frequencyOptionsConst,
  withParametersConst,
  parameterOperatorsConst,
  parametersBooleanValuesConst,
  segmentModel,
  filterModel,
  parameterModel,
  dropdownOptions,
  columnTypes,
  eventTableIdDefault,
  durationOperators,
  parameterOperators,
  filterTypes,
};
