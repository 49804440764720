import singleSpaVue from 'single-spa-vue'
import Vue from 'vue'
import './set-public-path'

import { i18n } from '@nodus/utilities-front'
import { BootstrapVue } from 'bootstrap-vue'
import App from './App.vue'
import router from './router'
import store from './store'

// Global components
import './global-components'

// 3rd party plugins
import '@/libs/vue-select'

require('@/assets/scss/index.scss')

Vue.use(BootstrapVue)

Vue.config.productionTip = false

const vueLifecycles = singleSpaVue({
  Vue,
  appOptions: {
    el: '#nodus-app',
    render: (h) => h(App),
    router,
    store,
    i18n
  }
})

export const { bootstrap } = vueLifecycles
export const mount = (props) =>
  vueLifecycles.mount(props).then((instance) => {
    window.requestAnimationFrame(() =>
      instance.$el.classList.add('bisko-app__audience')
    )
  })
export const { unmount } = vueLifecycles
