<script>
import {
  DateColumn,
  DropdownActions,
  IntegrationColumn,
  ReadMore
} from '@nodus/utilities-front'
import { getCurrentInstance } from 'vue'

export default {
  name: 'TopicsRow',
  components: {
    IntegrationColumn,
    DropdownActions,
    DateColumn,
    ReadMore
  },
  props: {
    data: {
      type: [Array, Object]
    }
  },
  setup(props) {
    const vm = getCurrentInstance().proxy
    const { organizationId } = vm.$route.params

    const { canEdit, canDelete, id } = props.data.row

    const viewLocation = {
      name: 'topics-view',
      params: {
        organizationId,
        topicId: id
      }
    }

    const editLocation = {
      name: 'topics-edit',
      params: {
        organizationId: organizationId,
        topicId: id
      }
    }

    const actionItems = [
      {
        id: 'view-topic',
        icon: 'IconparkPreviewOpen',
        text: vm.$t('View'),
        to: viewLocation
      },
      {
        id: 'edit-topic',
        icon: 'Edit',
        text: vm.$t('Edit'),
        disabled: !canEdit,
        to: editLocation
      },
      {
        id: 'delete-topic',
        icon: 'Delete',
        text: vm.$t('Delete'),
        type: 'danger',
        disabled: !canDelete,
        modal: 'modal-small-delete',
        action: () => vm.$store.commit('topics/SET_ID_TO_DELETE', id)
      }
    ]

    return {
      organizationId,
      actionItems,
      viewLocation
    }
  }
}
</script>
<template>
  <div>
    <!-- Column: Name -->
    <div v-if="data.column.field === 'name'" class="name__wrapper">
      <ReadMore :text="data.row.name" :to="viewLocation" />
    </div>

    <!-- Column: Create and Update Date -->
    <div v-else-if="data.column.field === 'insertDateTime'">
      <DateColumn :data="data" />
    </div>

    <!-- Column: Integration -->
    <span v-else-if="data.column.field === 'activations'">
      <div class="table-list-integrations-container">
        <IntegrationColumn
          :row-id="data.row.id"
          :activations="data.row.activations"
        />
      </div>
    </span>

    <span v-else-if="data.column.field === 'actions'">
      <div
        class="table-list-switch-and-actions table-list-switch-and-actions--single"
      >
        <div class="table-list-actions-container">
          <span>
            <DropdownActions :items="actionItems" />
          </span>
        </div>
      </div>
    </span>

    <!-- Column: Common -->
    <span v-else>
      {{ data.formattedRow[data.column.field] }}
    </span>
  </div>
</template>

<style>
.name__wrapper {
  cursor: pointer;
}
</style>
