<template>
  <div class="add-keyword add-keyword-suggested">
    <div class="suggested__container--flex">
      <div class="suggested-keywords__content">
        <div class="select-property">
          <span class="enter-keyword__header">{{
            $t("audience.topics.suggestedKeywords")
          }}</span>
          <MultipleProperties
            id="select-properties"
            :options="propertiesList"
            @update-data="updateData"
          />
        </div>

        <div v-if="!isSuggestedLoading" class="keywords-scrollable__area">
          <transition-group
            v-if="suggestedKeywordList && suggestedKeywordList.length > 0"
            name="suggested-keyword-list"
          >
            <div
              v-for="(keyword, index) in suggestedKeywordList"
              :key="keyword"
              class="keyword__row"
            >
              <span
                class="keyword__name"
                :class="{
                  'keyword__name--disabled': doesKeywordExist(keyword),
                }"
              >
                <span :id="`keyword-${index}`">{{ keyword }}</span>
                <span
                  v-if="!doesKeywordExist(keyword)"
                  :id="`add-keyword-${index}`"
                  class="add-suggested__keyword"
                  @click="addSuggestedKeyword(keyword, index)"
                >
                  <img src="@/assets/svgs/plus.svg" />
                </span>
                <span
                  v-else
                  :id="`keyword-information-${index}`"
                  class="add-suggested__keyword add-suggested__keyword-info"
                >
                  <img src="@/assets/svgs/InfoAltGreen.svg" />
                </span>
                <b-tooltip
                  v-if="doesKeywordExist(keyword)"
                  :target="`keyword-information-${index}`"
                  triggers="hover"
                  placement="top"
                >
                  {{ $t("audience.topics.keywordAlreadyExists") }}!
                </b-tooltip>
                <b-tooltip
                  :target="`keyword-${index}`"
                  triggers="hover"
                  placement="top"
                  boundary="viewport"
                >
                  {{ keyword }}
                </b-tooltip>
              </span>
            </div>
          </transition-group>
          <div v-else class="no-suggested__container">
            <span class="no-suggested__text"
              >{{ $t("audience.topics.missingSuggestedKeywords") }}!</span
            >
          </div>
        </div>
        <div v-if="isSuggestedLoading" class="loading__container">
          <LoadingBar />
        </div>
      </div>
      <div class="add-keywords__footer">
        <span
          id="add-all-suggested"
          :class="{ 'span--disabled': shouldDisableAddAll() }"
          @click="shouldDisableAddAll() ? {} : addAllSuggested()"
          >{{ $t("addAll") }}</span
        >
      </div>
    </div>
  </div>
</template>

<script>
import {LoadingBar, MultipleProperties, debounce} from '@nodus/utilities-front'
import {BTooltip} from 'bootstrap-vue'
import {computed, getCurrentInstance} from 'vue'

export default {
  emits: ['add-suggested-keyword', 'add-all-suggested', 'handle-form-input', 'update-data'],
  components: {
    BTooltip,
    MultipleProperties,
    LoadingBar
  },
  props: {
    propertiesList: {
      type: [Array]
    },
    suggestedKeywordList: {
      type : [Array]
    },
    keywordsList: {
      type: [Array]
    },
    isSuggestedLoading: {
      type: [Boolean]
    }
  },
  setup(props) {
    const vm = getCurrentInstance().proxy
    const filteredSuggested = computed(() =>
      props.suggestedKeywordList.filter(keyword => props.keywordsList.map(kw => kw.keyword).indexOf(keyword) !== - 1)
    )

    const handleFormInput = () => {
      vm.$emit('handle-form-input')
    }

    const addSuggestedKeyword = (keyword, index) => {
      vm.$emit('add-suggested-keyword', keyword, index)
      handleFormInput()
    }

    const addAllSuggested = () => {
      vm.$emit('add-all-suggested', filteredSuggested.value)
      handleFormInput()
    }

    const updateData = debounce(properties => {
      vm.$emit('update-data', properties)
    })

    const doesKeywordExist = keyword => props.keywordsList.map(kw => kw.keyword).indexOf(keyword) !== - 1

    const shouldDisableAddAll = () => props.suggestedKeywordList.length < 1 || filteredSuggested.value.length === props.suggestedKeywordList.length

    return {
      addSuggestedKeyword,
      addAllSuggested,
      handleFormInput,
      updateData,
      doesKeywordExist,
      filteredSuggested,
      shouldDisableAddAll
    }
  },
}
</script>
