<template>
  <div>
    <section
      class="properties-list segmentation-list"
      :class="{ blur: !isEnabled && !isLoading }"
    >
      <div class="table-list__header">
        <PrimaryCreateButton
          id="create-topic"
          :name="$t('audience.topics.createNew')"
          :can-add="canCreate"
          :is-loading="isLoading"
          :click-handler="createTopic"
        />
        <SearchInputField id="search-topics" :data="table" />
      </div>
      <div class="table__wrapper">
        <CustomTable
          :data="table"
          type-of-table="topics"
          :is-pagination-enabled="true"
          :is-loading="isLoading"
          :empty-state-action="{
            canAdd: canCreate,
            action: createTopic
          }"
          @update-table="getData"
        >
          <template #row-data="{ data }">
            <TopicsRow :data="data" />
          </template>
        </CustomTable>
      </div>
    </section>

    <div v-if="!isEnabled && !isLoading" class="info">
      <img alt="Vue logo" src="@/assets/svgs/i-warning.svg" />
      <span
        >{{ $t('audience.topics.notEnabledMessage') }}
        {{ $t('contactAdmin') }}</span
      >
    </div>

    <!-- Delete Topic Modal -->
    <DeleteModal
      :title="$t('audience.topics.deleteTopic')"
      :type="$t('Topic')"
      @delete="triggerDelete"
    />
  </div>
</template>

<script>
import { topics } from '@/api'
import TopicsRow from '@/components/table/table-data/TopicsRow.vue'
import router from '@/router'
import store from '@/store'
import {
  CustomTable,
  DeleteModal,
  // utlitiy components
  PrimaryCreateButton,
  SearchInputField,
  // utility functions
  handleLastRowDelete
} from '@nodus/utilities-front'
import { computed, getCurrentInstance, onMounted, ref } from 'vue'

export default {
  name: 'Topics',
  components: {
    PrimaryCreateButton,
    SearchInputField,
    CustomTable,
    DeleteModal,
    TopicsRow
  },
  setup() {
    const vm = getCurrentInstance().proxy
    const { organizationId } = vm.$route.params
    const table = computed(() => store.getters['topics/table'])
    const canCreate = computed(() => store.getters['topics/canCreate'])
    const IDToDelete = computed(() => store.getters['topics/IDToDelete'])
    const isEnabled = computed(() => store.getters['topics/isTopicsEnabled'])
    const isLoading = ref(false)

    const createTopic = () => {
      router.push({
        name: 'topic-create',
        params: { organizationId }
      })
    }

    const getData = async () => {
      isLoading.value = true
      await store.dispatch('topics/getTable', { id: organizationId })
      isLoading.value = false
    }

    onMounted(async () => getData())

    const triggerDelete = async () => {
      handleLastRowDelete(table.value, store, 'topics/SET_TABLE_PAGE')
      await topics.remove(organizationId, IDToDelete.value)

      getData()
    }

    return {
      table,
      canCreate,
      getData,
      isLoading,
      createTopic,
      triggerDelete,
      isEnabled
    }
  }
}
</script>

<style lang="scss">
.blur {
  filter: blur(2px);
  pointer-events: none;
  -webkit-touch-callout: none; /* iOS Safari */
  -webkit-user-select: none; /* Safari */
  -khtml-user-select: none; /* Konqueror HTML */
  -moz-user-select: none; /* Old versions of Firefox */
  -ms-user-select: none; /* Internet Explorer/Edge */
  user-select: none; /* Non-prefixed version, currently supported by Chrome, Edge, Opera and Firefox */
}

.info {
  display: flex;
  flex-direction: column;
  align-items: center;
  position: absolute;
  top: 60%;
  left: 50%;
  transform: translate(-50%, -50%);
  max-width: 500px;
  font-size: 14px;
  text-align: center;
}
</style>
