export default [
  {
    path: '/bisko/:organizationId/audience/segments',
    name: 'segments',
    component: () => import('@/views/segments/Segments.vue'),
    meta: {
      navActiveLink: 'segments',
      pageTitle: 'Segments',
      breadcrumb: [
        {
          text: 'Audience'
        },
        {
          text: 'Segments',
          active: true
        }
      ]
    }
  },
  {
    path: '/bisko/:organizationId/audience/segments/:templateId?/create',
    name: 'segments-create',
    component: () => import('@/views/segments/SegmentCreate.vue'),
    meta: {
      pageTitle: 'Create New Segment',
      breadcrumb: [
        {
          text: 'Audience'
        },
        {
          text: 'Segments',
          to: '/segments'
        },
        {
          text: 'Create Segment',
          active: true
        }
      ],
      navActiveLink: 'segments'
    }
  },
  {
    path: '/bisko/:organizationId/audience/segments/:segmentId/view',
    name: 'segments-view',
    component: () => import('@/views/segments/SegmentView.vue'),
    meta: {
      pageTitle: 'View Segment',
      breadcrumb: [
        {
          text: 'Audience'
        },
        {
          text: 'Segments',
          to: '/segments'
        },
        {
          text: 'View Segment',
          active: true
        }
      ],
      navActiveLink: 'segments'
    }
  },
  {
    path: '/bisko/:organizationId/audience/segments/:segmentId/edit',
    name: 'segments-edit',
    component: () => import('@/views/segments/SegmentCreate.vue'),
    meta: {
      pageTitle: 'Edit Segment',
      breadcrumb: [
        {
          text: 'Audience'
        },
        {
          text: 'Segments',
          to: '/segments'
        },
        {
          text: 'Edit Segment',
          active: true
        }
      ],
      navActiveLink: 'segments'
    }
  }
]
