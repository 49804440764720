<template>
  <div>
    <!-- Column: Name -->
    <span v-if="data.column.field === 'name'" class="dark-gray-color">
      <div class="name__wrapper">
        <IconHeading :id="data.row.code" :data="data" />
        <ReadMore :text="data.row.name" :to="viewLocation" />
      </div>
    </span>

    <!-- Column: Create and Update Date -->
    <div v-else-if="data.column.field === 'insertDateTime'">
      <DateColumn :data="data" />
    </div>

    <!-- Column: Description -->
    <span v-else-if="data.column.field === 'description'">
      <ReadMore :text="data.row.description" />
    </span>

    <!-- Column: Users -->
    <span v-else-if="data.column.field === 'userCount'">
      {{ data.row.userCount.toLocaleString() }}
    </span>

    <!-- Column: Integration -->
    <span
      v-else-if="data.column.field === 'integrations'"
      class="integrations-and-actions"
    >
      <IntegrationColumn
        :row-id="data.row.code"
        :activations="data.row.activations"
      />
    </span>

    <!-- Column: Actions -->
    <span v-else-if="data.column.field === 'actions'">
      <div class="table-list-switch-and-actions">
        <div class="switch-and-information">
          <b-form-checkbox
            :id="`switch-status-${data.row.code}`"
            :disabled="!data.row.canActivate || isLoading"
            :checked="!!data.row.processingStatus"
            class="custom-control-primary"
            name="check-button"
            switch
            @change="
              data.row.canActivate
                ? changeProcessingStatus(
                    data.row.code,
                    data.row.processingStatus ? 0 : 1
                  )
                : {}
            "
          />
          <InformationSidebar
            :title="$t('audience.queriesDisable')"
            :content="$t('audience.queriesDisableInformation')"
            :code="data.row.code.toLocaleString()"
          />
        </div>
        <div class="table-list-actions-container">
          <DropdownActions :items="actionItems" />
        </div>
      </div>
    </span>

    <!-- Column: Common -->
    <span v-else>
      {{ data.formattedRow[data.column.field] }}
    </span>
  </div>
</template>

<script>
import { queries } from '@/api'
import store from '@/store'
import {
  DateColumn,
  DropdownActions,
  IconHeading,
  InformationSidebar,
  IntegrationColumn,
  // utility components
  ReadMore,
  // utility functions
  formatDate,
  isEmail
} from '@nodus/utilities-front'
import { BFormCheckbox } from 'bootstrap-vue'
import { getCurrentInstance, ref } from 'vue'

export default {
  name: 'QueriesRow',
  components: {
    IntegrationColumn,
    ReadMore,
    IconHeading,
    DateColumn,
    InformationSidebar,
    DropdownActions,
    BFormCheckbox
  },
  props: {
    data: {
      type: [Array, Object]
    }
  },
  setup(props) {
    const vm = getCurrentInstance().proxy
    const { organizationId } = vm.$route.params
    const isLoading = ref(false)

    const isEmailValid = (email) => isEmail(email)

    const changeProcessingStatus = async (code, processingStatus) => {
      isLoading.value = true
      await queries.setStatus(organizationId, code, processingStatus)
      store.dispatch('queries/getTable', { id: organizationId }).then(() => {
        isLoading.value = false
      })
    }

    const { canEdit, canDuplicate, canDelete, code } = props.data.row

    const viewLocation = {
      name: 'query-segment-view',
      params: {
        organizationId,
        segmentId: code
      }
    }

    const editLocation = {
      name: 'query-segment-edit',
      params: {
        organizationId: organizationId,
        segmentId: code
      }
    }

    const actionItems = [
      {
        id: 'view-query',
        icon: 'IconparkPreviewOpen',
        text: vm.$t('View'),
        to: viewLocation
      },
      {
        id: 'edit-query',
        icon: 'Edit',
        text: vm.$t('Edit'),
        disabled: !canEdit,
        to: editLocation
      },
      {
        id: 'duplicate-query',
        icon: 'Copy',
        text: vm.$t('duplicate'),
        disabled: !canDuplicate,
        modal: 'modal-small-duplicate',
        action: () => vm.$store.commit('queries/SET_ID_TO_DUPLICATE', code)
      },
      {
        id: 'delete-query',
        icon: 'Delete',
        text: vm.$t('Delete'),
        type: 'danger',
        disabled: !canDelete,
        modal: 'modal-small-delete',
        action: () => vm.$store.commit('queries/SET_ID_TO_DELETE', code)
      }
    ]

    return {
      organizationId,
      isEmailValid,
      formatDate,
      changeProcessingStatus,
      isLoading,
      actionItems,
      viewLocation
    }
  }
}
</script>

<style scoped lang="scss"></style>
