export default [
  {
    path: '/bisko/:organizationId/audience/topics',
    name: 'topics',
    component: () => import('@/views/topics/Topics.vue'),
    meta: {
      navActiveLink: 'topics',
      pageTitle: 'Topics',
      breadcrumb: [
        {
          text: 'Audience'
        },
        {
          text: 'Topics',
          active: true
        }
      ]
    }
  },
  {
    path: '/bisko/:organizationId/audience/topics/create',
    name: 'topic-create',
    component: () => import('@/views/topics/TopicCreate.vue'),
    meta: {
      pageTitle: 'Contextual Targeting',
      breadcrumb: [
        {
          text: 'Audience'
        },
        {
          text: 'Topics',
          to: '/topics'
        },
        {
          text: 'Create Topic',
          active: true
        }
      ],
      navActiveLink: 'topics'
    }
  },
  {
    path: '/bisko/:organizationId/audience/topics/:topicId/view',
    name: 'topics-view',
    component: () => import('@/views/topics/TopicView.vue'),
    meta: {
      pageTitle: 'Contextual Targeting',
      breadcrumb: [
        {
          text: 'Audience'
        },
        {
          text: 'Topics',
          to: '/topics'
        },
        {
          text: 'View Topic',
          active: true
        }
      ],
      navActiveLink: 'topics'
    }
  },
  {
    path: '/bisko/:organizationId/audience/topics/:topicId/edit',
    name: 'topics-edit',
    component: () => import('@/views/topics/TopicCreate.vue'),
    meta: {
      pageTitle: 'Contextual Targeting',
      breadcrumb: [
        {
          text: 'Audience'
        },
        {
          text: 'Topics',
          to: '/topics'
        },
        {
          text: 'Edit Topic',
          active: true
        }
      ],
      navActiveLink: 'topics'
    }
  }
]
