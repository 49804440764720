<template>
  <section>
    <div class="container__header">
      <h5 class="container__header-title">
        {{ $t('shared.predictedAudienceSize') }}
      </h5>
    </div>
    <div>
      <b-button
        id="calculate-audience"
        class="create__button cancel__button ml-0"
        :disabled="!data.isValid"
        @click="response.loading ? cancelCalculate() : calculateAudienceSize()"
      >
        <div v-if="response.loading" class="loading__info">
          <GjIcon name="Loader" class="svg__animate" />
          <span>{{ $t('Cancel') }}</span>
        </div>
        <span v-if="!response.loading">{{
          $t('shared.calculateAudienceSize')
        }}</span>
      </b-button>
    </div>
    <div class="info__text">
      <span v-if="response.reach && !response.loading" class="success"
        >{{ $t('shared.audienceSegmentContains') }} {{ response.reach }}
        {{ $t('Users').toLowerCase() }}</span
      >
      <span v-if="response.hasErrors && !response.loading" class="danger"
        >Please fill out the required fields and try again</span
      >
      <span v-if="response.loading">{{
        $t('shared.calculatingAudienceSize')
      }}</span>
    </div>
  </section>
</template>

<script>
import { BButton } from 'bootstrap-vue'
import { queries, segments } from '@/api'
import { reactive, getCurrentInstance } from 'vue'
import {
  GjIcon,
  // utility functions
  removeErrors,
  appendErrors,
  unFlattenObject,
  scrollToElement,
} from '@nodus/utilities-front'
import axios from 'axios'

export default {
  name: 'AudienceSizeCalculator',
  components: {
    BButton,
    GjIcon,
  },
  props: {
    data: {
      type: [Object],
    },
  },
  setup(props) {
    const vm = getCurrentInstance().proxy
    const { organizationId } = vm.$route.params
    const response = reactive({
      reach: '',
      loading: false,
      hasErrors: false,
    })
    const { calculateQuery } = queries
    const { calculateSegment } = segments

    const request = reactive({
      cancel: '',
    })

    const getCancellationToken = () => {
      const { CancelToken } = axios
      return CancelToken.source()
    }

    const clearPreviousRequest = () => {
      request.value = null
    }

    const handleResponseData = (responseData) => {
      const {
        data: { errors, success, data },
      } = responseData
      if (!success) {
        const errorsObject = unFlattenObject(errors)
        removeErrors(props.data.segment)
        appendErrors(props.data.segment, errorsObject)

        setTimeout(
          () => scrollToElement('[class*="form__input--invalid"]'),
          200
        )

        response.reach = ''
        response.loading = false
        response.hasErrors = true
      } else if (data) {
        const { estimatedReach } = data
        if (estimatedReach.toString().length > 0)
          response.reach = estimatedReach.toLocaleString()
        response.loading = false
        if (props.data.type === 'query') removeErrors(props.data.query)
        else if (props.data.type === 'segment') removeErrors(props.data.segment)
      }
    }

    const calculateAudienceSize = async () => {
      const source = getCancellationToken()
      response.loading = true
      response.hasErrors = false
      request.value = { cancel: source.cancel }

      if (props.data.type === 'query') {
        await calculateQuery(organizationId, props.data.query, source.token)
          .then((responseData) => {
            handleResponseData(responseData)
          })
          .catch(() => {
            return
          })
      } else if (props.data.type === 'segment') {
        await calculateSegment(
          organizationId,
          ...props.data.query,
          source.token
        )
          .then((responseData) => {
            handleResponseData(responseData)
          })
          .catch(() => {
            return
          })
      }
    }

    const cancelCalculate = () => {
      request.value.cancel()
      response.loading = false
      response.reach = ''
      clearPreviousRequest()
    }

    return {
      organizationId,
      response,
      calculateAudienceSize,
      cancelCalculate,
    }
  },
}
</script>

<style lang="scss">
@import '@/assets/scss/views/shared';

.info__text {
  margin-bottom: 0;
  font-size: 13px;
  margin-top: 10px;
  color: #667c99;
}

.success {
  color: #36b37e;
}

.danger {
  color: #e34850;
}

.loading__info {
  display: flex;
  align-items: center;
  color: #052d61;

  svg {
    margin-right: 5px;
  }
}

.svg__animate {
  animation: rotation 2s infinite linear;
}

@keyframes rotation {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
</style>
