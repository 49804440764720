import Vue from 'vue'
import VueRouter from 'vue-router'
import integrations from './integrations'
import queries from './queries'
import segments from './segments'
import topics from './topics'

Vue.use(VueRouter)

const routes = [
  {
    path: '/bisko/:organizationId/audience',
    redirect: '/bisko/:organizationId/audience/segments'
  },
  ...segments,
  ...queries,
  ...topics,
  ...integrations,
  {
    path: '/bisko/:organizationId/audience/:pathMatch(.*)*',
    redirect: '/error/404'
  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

export default router
