import { whitelabel } from '@/utils/constants'

export default [
  {
    path: '/bisko/:organizationId/audience/queries',
    name: 'queries',
    component: () => import('@/views/queries/Queries.vue'),
    meta: {
      navActiveLink: 'queries',
      pageTitle: 'Queries',
      breadcrumb: [
        {
          text: 'Audience'
        },
        {
          text: 'Queries',
          active: true
        }
      ]
    },
    beforeEnter: (to, from, next) => {
      if (whitelabel.title !== 'integr8') {
        next()
      } else next('/error/404')
    }
  },
  {
    path: '/bisko/:organizationId/audience/queries/create',
    name: 'query-create',
    component: () => import('@/views/queries/QueryCreate.vue'),
    meta: {
      pageTitle: 'Create Query',
      breadcrumb: [
        {
          text: 'Audience'
        },
        {
          text: 'Queries',
          to: '/queries'
        },
        {
          text: 'Create Query',
          active: true
        }
      ],
      navActiveLink: 'queries'
    },
    beforeEnter: (to, from, next) => {
      if (whitelabel.title !== 'integr8') {
        next()
      } else next('/error/404')
    }
  },
  {
    path: '/bisko/:organizationId/audience/queries/:segmentId/view',
    name: 'query-segment-view',
    component: () => import('@/views/queries/QueryView.vue'),
    meta: {
      pageTitle: 'View Query',
      breadcrumb: [
        {
          text: 'Audience'
        },
        {
          text: 'Queries',
          to: '/queries'
        },
        {
          text: 'View Query',
          active: true
        }
      ],
      navActiveLink: 'queries'
    },
    beforeEnter: (to, from, next) => {
      if (whitelabel.title !== 'integr8') {
        next()
      } else next('/error/404')
    }
  },
  {
    path: '/bisko/:organizationId/audience/query/:segmentId/edit',
    name: 'query-segment-edit',
    component: () => import('@/views/queries/QueryCreate.vue'),
    meta: {
      pageTitle: 'Edit Query',
      breadcrumb: [
        {
          text: 'Audience'
        },
        {
          text: 'Queries',
          to: '/queries'
        },
        {
          text: 'Edit Query',
          active: true
        }
      ],
      navActiveLink: 'queries'
    },
    beforeEnter: (to, from, next) => {
      if (whitelabel.title !== 'integr8') {
        next()
      } else next('/error/404')
    }
  }
]
