<template>
  <span v-if="data.column.field === 'include'">
    <b-form-radio
      v-if="!isViewTopic"
      :id="`include-${data.row.keyword}`"
      :value="true"
      :checked="data.row.included"
      @change="includeChanged(data.row.originalIndex, true)"
    />
    <span v-else>
      <img
        v-if="data.row.included"
        src="@/assets/svgs/checkIcon.svg"
        alt="check"
      />
    </span>
  </span>

  <span v-else-if="data.column.field === 'exclude'">
    <div v-if="!isViewTopic" class="exclude__column">
      <b-form-radio
        :id="`exclude-${data.row.keyword}`"
        :value="false"
        :checked="data.row.included"
        @change="includeChanged(data.row.originalIndex, false)"
      />
      <div class="remove-keyword" @click="removeRow(data.row)">
        <GjIcon name="Close" class="mr-50" size="22" />
      </div>
    </div>
    <span v-else>
      <img
        v-if="!data.row.included"
        src="@/assets/svgs/checkIcon.svg"
        alt="check"
      />
    </span>
  </span>

  <span v-else-if="isNumberColumn(data.column.field)">
    {{ formatNumber(data.formattedRow[data.column.field]) }}
  </span>

  <span v-else-if="data.column.field === 'keyword'" :id="data.row.keyword">
    {{ data.row.keyword }}
  </span>

  <!-- Column: Common -->
  <span v-else>
    {{ data.formattedRow[data.column.field] }}
  </span>
</template>

<script>
import store from '@/store'
import { GjIcon, formatNumber } from '@nodus/utilities-front'
import { BFormRadio } from 'bootstrap-vue'
import { computed, getCurrentInstance } from 'vue'

export default {
  name: 'KeywordsRow',
  components: {
    GjIcon,
    BFormRadio
  },
  props: {
    data: {
      type: [Array, Object]
    },
    isViewTopic: {
      type: [Boolean],
      default: false
    }
  },
  setup() {
    const vm = getCurrentInstance().proxy
    const keywordMatchType = computed(
      () => store.getters['topics/keywordMatchType']
    )

    const { organizationId } = vm.$route.params
    const removeRow = async (row) => {
      store.commit('topics/SET_TOPIC_PRISTINE', false)
      store.dispatch('topics/removeKeywordRow', { row })
      await store.dispatch('topics/getTotalAnalytics', {
        organizationId,
        keywordMatchType: keywordMatchType.value
      })
    }

    const includeChanged = async (index, value) => {
      store.commit('topics/SET_INCLUDE_KEYWORD', { index, value })
      store.commit('topics/SET_TOPIC_PRISTINE', false)
      store.dispatch('topics/getTotalAnalytics', {
        organizationId,
        keywordMatchType: keywordMatchType.value
      })
    }

    const numberColumns = ['items', 'users', 'events']

    const isNumberColumn = (column) =>
      numberColumns.some((col) => col === column)

    return {
      removeRow,
      includeChanged,
      isNumberColumn,
      formatNumber
    }
  }
}
</script>

<style lang="scss">
.keywords__table {
  .vgt-wrap .vgt-responsive table thead {
    position: sticky;
    top: 0;
    z-index: 9;
  }

  .vgt-wrap .vgt-responsive table thead th span {
    font-size: 13px;
  }

  table thead tr th:not(:first-child) {
    padding-left: 6px;

    &::before,
    &::after {
      left: -6px;
    }
  }

  .vgt-wrap .vgt-responsive table tbody td {
    font-size: 13px;
    word-break: break-all;
    line-height: 18px;
    padding: 12px 6px 8px 16px !important;

    &:not(:first-child) {
      color: #667c99;
      padding-left: 6px;
    }
  }
}

.exclude__column {
  display: flex;
  justify-content: space-between;
  align-items: center;

  svg {
    color: #a3b0c2 !important;
  }
}

.remove-keyword {
  cursor: pointer;
}
</style>
