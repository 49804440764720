/* eslint-disable no-param-reassign */
import axiosIns from '@/libs/axios'
import { API_GATEWAY_URL } from '@/utils/constants'
import {
  destructJSONObject,
  formatRequestQuery,
  formatSuggestedKeywordsParam
} from '@nodus/utilities-front'

export default {
  namespaced: true,
  state: {
    table: {
      columns: [
        {
          label: 'Name',
          field: 'name',
          thClass: 'name-column',
          width: '30%'
        },
        {
          label: 'Code',
          field: 'id',
          type: 'number',
          width: '15%'
        },
        {
          label: 'Date',
          field: 'insertDateTime',
          thClass: 'custom-data-class',
          width: '33%'
        },
        {
          label: 'Integrations',
          field: 'activations',
          thClass: 'not-sortable-headers',
          sortable: false,
          width: '15%'
        },
        {
          label: 'Actions',
          field: 'actions',
          thClass: 'not-sortable-headers',
          sortable: false,
          width: '7%'
        }
      ],
      rows: [],
      searchTerm: '',
      totalRecords: 0,
      page: 1,
      perPage: 10,
      orderField: '',
      orderType: ''
    },
    keywordsTable: {
      columns: [
        {
          field: 'keyword',
          label: 'Keyword',
          thClass: 'keyword-table-header',
          width: '30%'
        },
        {
          field: 'items',
          label: 'Items',
          type: 'number',
          width: '12%'
        },
        {
          field: 'users',
          label: 'Users',
          type: 'number',
          width: '12%'
        },
        {
          field: 'events',
          label: 'Events',
          type: 'number',
          width: '12%'
        },
        {
          field: 'include',
          label: 'Include',
          sortable: false,
          thClass: 'not-sortable-headers',
          width: '8%'
        },
        {
          field: 'exclude',
          label: 'Exclude',
          sortable: false,
          thClass: 'not-sortable-headers',
          width: '16%'
        }
      ],
      rows: [],
      searchTerm: ''
    },
    canCreate: null,
    contextualTargetingEnabled: false,
    IDToDelete: -1,
    topic: {},
    suggestedKeywordList: [],
    activations: [],
    keywordsTotalAnalytics: {
      totalItems: 0,
      totalUsers: 0,
      totalEvents: 0
    },
    keywordMatchType: 'Exact',
    topicPristine: true,
    isTotalAnalyticsLoading: false
  },
  getters: {
    table: (state) => state.table,
    canCreate: (state) => state.canCreate,
    isTopicsEnabled: (state) => state.contextualTargetingEnabled,
    topic: (state) => state.topic,
    suggestedKeywordsList: (state) => state.suggestedKeywordList,
    IDToDelete: (state) => state.IDToDelete,
    activations: (state) => state.activations,
    keywordsTable: (state) => state.keywordsTable,
    totalAnalytics: (state) => state.keywordsTotalAnalytics,
    topicPristine: (state) => state.topicPristine,
    totalAnalyticsLoading: (state) => state.isTotalAnalyticsLoading,
    keywordMatchType: (state) => state.keywordMatchType
  },
  actions: {
    async getTable({ commit, state }, { id }) {
      const queryParamString = formatRequestQuery(
        state.table.searchTerm,
        state.table.page,
        state.table.perPage,
        state.table.orderField,
        state.table.orderType
      )
      const response = await axiosIns.get(
        `/api/v1/${id}/contextual-targeting/topics?${queryParamString}`,
        { baseURL: API_GATEWAY_URL }
      )

      commit('SET_TABLE_ROWS', destructJSONObject(response, 'topics'))
      commit('SET_CAN_CREATE', destructJSONObject(response, 'canCreate'))
      commit(
        'SET_TOPICS_ENABLED',
        destructJSONObject(response, 'contextualTargetingEnabled')
      )
      commit('SET_TOTAL_RECORDS', destructJSONObject(response, 'totalCount'))
    },
    async getTopic({ commit }, { id, topicId }) {
      const response = await axiosIns.get(
        `/api/v1/${id}/contextual-targeting/topics/${topicId}`,
        { baseURL: process.env.VUE_APP_API_GATEWAY_URL }
      )

      commit('SET_TOPIC', response.data.data)
      return response.data.data
    },
    async getSuggestedKeywords({ commit }, { id, propertyIds }) {
      const queryParamString = formatSuggestedKeywordsParam(propertyIds)
      const response = await axiosIns.get(
        `/api/v1/${id}/contextual-targeting/topics/keywords/suggested?${queryParamString}`,
        { baseURL: API_GATEWAY_URL }
      )

      commit('SET_SUGGESTED_KEYWORDS', destructJSONObject(response, 'keywords'))
    },
    async getActivations({ commit }, { id }) {
      const response = await axiosIns.get(
        `/api/v1/${id}/contextual-targeting/topics/activations`,
        { baseURL: API_GATEWAY_URL }
      )

      commit(
        'SET_ACTIVATIONS',
        destructJSONObject(response, 'topicActivations')
      )
    },
    async getTotalAnalytics(
      { commit, state },
      { organizationId, keywordMatchType }
    ) {
      const keywords = state.keywordsTable.rows.map((el) => ({
        keyword: el.keyword,
        included: el.included
      }))
      if (keywords && keywords.length > 0) {
        commit('SET_TOTAL_LOADING', true)
        const response = await axiosIns.post(
          `${API_GATEWAY_URL}/api/v1/${organizationId}/contextual-targeting/topics/analytics`,
          {
            keywords,
            keywordMatchType
          }
        )
        if (state.keywordsTable.rows.length > 0) {
          commit('SET_TOTAL_ANALYTICS', {
            totalItems: destructJSONObject(response, 'pageCount'),
            totalUsers: destructJSONObject(response, 'userCount'),
            totalEvents: destructJSONObject(response, 'eventCount')
          })
        }
        commit('SET_TOTAL_LOADING', false)
      } else
        commit('SET_TOTAL_ANALYTICS', {
          totalItems: 0,
          totalUsers: 0,
          totalEvents: 0
        })
    },
    async getKeywordsAnalytics(
      { commit },
      { organizationId, keywords, keywordMatchType, type }
    ) {
      const response = await axiosIns.post(
        `${API_GATEWAY_URL}/api/v1/${organizationId}/contextual-targeting/topics/analytics/keywords`,
        {
          keywords,
          keywordMatchType
        }
      )
      if (type === 'existing')
        commit(
          'MUTATE_EXISTING_ROWS',
          destructJSONObject(response, 'analytics')
        )
      else
        commit(
          'ADD_MULTIPLE_KEYWORD_ROWS',
          destructJSONObject(response, 'analytics')
        )
    },
    async removeKeywordRow({ commit }, { row }) {
      commit('REMOVE_KEYWORD_ROW', { row })
    }
  },
  mutations: {
    SET_TABLE_ROWS(state, topics) {
      state.table.rows = topics
    },
    SET_CAN_CREATE(state, value) {
      state.canCreate = value
    },
    SET_TOPICS_ENABLED(state, value) {
      state.contextualTargetingEnabled = value
    },
    SET_TOTAL_RECORDS(state, totalRecords) {
      state.table.totalRecords = totalRecords
    },
    SET_TOPIC(state, topic) {
      state.topic = topic
      // eslint-disable-next-line arrow-body-style
      state.keywordsTable.rows = topic.keywords.map((entry) => {
        return {
          keyword: entry.keyword,
          included: entry.included,
          users: 0,
          items: 0,
          events: 0
        }
      })
    },
    SET_SUGGESTED_KEYWORDS(state, keywords) {
      state.suggestedKeywordList = keywords
    },
    SET_ID_TO_DELETE(state, id) {
      state.IDToDelete = id
    },
    SET_ACTIVATIONS(state, activations) {
      state.activations = activations
    },
    ADD_FILE_KEYWORD(state, fileKeyword) {
      const keywordData = {
        keyword: fileKeyword.keyword,
        included: true,
        users: fileKeyword.users,
        items: fileKeyword.items,
        events: fileKeyword.events
      }
      state.keywordsTable.rows.unshift(keywordData)
    },
    ADD_MULTIPLE_KEYWORD_ROWS(state, keywords) {
      keywords.forEach((keywordData) => {
        const data = {
          keyword: keywordData.keyword,
          included: true,
          users: keywordData.userCount,
          items: keywordData.pageCount,
          events: keywordData.eventCount
        }
        state.keywordsTable.rows.unshift(data)
      })
    },
    MUTATE_EXISTING_ROWS(state, keywords) {
      keywords.forEach((item) => {
        const keyword = state.keywordsTable.rows.find(
          (obj) => obj.keyword.toLowerCase() === item.keyword.toLowerCase()
        )
        keyword.users = item.userCount
        keyword.items = item.pageCount
        keyword.events = item.eventCount
      })
    },
    SET_TOTAL_ANALYTICS(state, { totalItems, totalUsers, totalEvents }) {
      state.keywordsTotalAnalytics.totalItems = totalItems
      state.keywordsTotalAnalytics.totalUsers = totalUsers
      state.keywordsTotalAnalytics.totalEvents = totalEvents
    },
    REMOVE_KEYWORD_ROW(state, { row }) {
      state.keywordsTable.rows.splice(row.originalIndex, 1)
    },
    DELETE_ALL_KEYWORDS(state) {
      state.keywordsTable.rows = []
      state.keywordsTotalAnalytics = {
        totalItems: 0,
        totalUsers: 0,
        totalEvents: 0
      }
    },
    SET_INCLUDE_KEYWORD(state, { index, value }) {
      state.keywordsTable.rows[index].included = value
    },
    SET_TOPIC_PRISTINE(state, value) {
      state.topicPristine = value
    },
    SET_TOTAL_LOADING(state, value) {
      state.isTotalAnalyticsLoading = value
    },
    SET_KEYWORD_MATCH_TYPE(state, value) {
      state.keywordMatchType = value
    }
  }
}
