var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return !_vm.isLoading
    ? _c(
        "section",
        { staticClass: "form__wrapper" },
        [
          _c("div", { staticClass: "container__wrapper" }, [
            _c("div", { staticClass: "container__header" }, [
              _c("h5", { staticClass: "container__header-title" }, [
                _vm._v(_vm._s(_vm.$t("configuration"))),
              ]),
            ]),
            _c("div", { staticClass: "integration-container__content" }, [
              _c(
                "div",
                { staticClass: "integration-container__content-header" },
                [
                  _c(
                    "div",
                    {
                      staticClass:
                        "integration-container__content-header__image",
                    },
                    [_c("img", { attrs: { src: _vm.integration.imageUrl } })]
                  ),
                  _c(
                    "div",
                    {
                      staticClass:
                        "integration-container__content-header__text",
                    },
                    [
                      _c("span", [
                        _vm._v(_vm._s(_vm.integration.integrationName)),
                      ]),
                      _c("span", { staticClass: "integration__description" }, [
                        _vm._v(_vm._s(_vm.integration.description)),
                      ]),
                    ]
                  ),
                ]
              ),
              _c(
                "div",
                { staticClass: "integration-container__content-body" },
                [
                  !!_vm.integration.name
                    ? _c(
                        "div",
                        {
                          staticClass: "integration-container__content-inputs",
                        },
                        [
                          _c(
                            "b-col",
                            {
                              staticClass: "input__wrapper",
                              attrs: { lg: "12" },
                            },
                            [
                              _c("label", [
                                _vm._v(_vm._s(_vm.$t("Name")) + " *"),
                              ]),
                              _c("b-form-input", {
                                staticClass: "form__input",
                                class: {
                                  "form__input--invalid":
                                    _vm.integration.name.errors.length > 0,
                                },
                                attrs: {
                                  id: "integration-name",
                                  placeholder: _vm.$t("audience.enterName"),
                                  required: "",
                                },
                                on: {
                                  input: function ($event) {
                                    return _vm.handleFormInput(_vm.state, [
                                      _vm.integration.name,
                                    ])
                                  },
                                },
                                model: {
                                  value: _vm.integration.name.value,
                                  callback: function ($$v) {
                                    _vm.$set(
                                      _vm.integration.name,
                                      "value",
                                      typeof $$v === "string" ? $$v.trim() : $$v
                                    )
                                  },
                                  expression: "integration.name.value",
                                },
                              }),
                            ],
                            1
                          ),
                          _vm._l(
                            _vm.integration.name.errors,
                            function (error, index) {
                              return _c(
                                "div",
                                {
                                  key: index,
                                  staticClass:
                                    "integration-container__content-inputs__errors",
                                },
                                [_vm._v(" " + _vm._s(error) + " ")]
                              )
                            }
                          ),
                          _vm._l(
                            _vm.integration.parameters,
                            function (parameter) {
                              return _c(
                                "div",
                                { key: parameter.parameterId },
                                [
                                  _c(
                                    "b-col",
                                    {
                                      staticClass: "input__wrapper",
                                      attrs: { lg: "12" },
                                    },
                                    [
                                      _c("label", [
                                        _vm._v(
                                          _vm._s(
                                            parameter.parameterName +
                                              (parameter.required ? " *" : "")
                                          )
                                        ),
                                      ]),
                                      _c("b-form-input", {
                                        staticClass: "form__input",
                                        class: {
                                          "form__input--invalid":
                                            parameter.value.errors.length > 0,
                                        },
                                        attrs: {
                                          id: `integration-${parameter.parameterName}`,
                                          placeholder:
                                            "Enter " +
                                            parameter.parameterName +
                                            "...",
                                          required: parameter.required,
                                        },
                                        on: {
                                          input: function ($event) {
                                            return _vm.handleFormInput(
                                              _vm.state,
                                              [parameter.value]
                                            )
                                          },
                                        },
                                        model: {
                                          value: parameter.value.value,
                                          callback: function ($$v) {
                                            _vm.$set(
                                              parameter.value,
                                              "value",
                                              typeof $$v === "string"
                                                ? $$v.trim()
                                                : $$v
                                            )
                                          },
                                          expression: "parameter.value.value",
                                        },
                                      }),
                                    ],
                                    1
                                  ),
                                  _vm._l(
                                    parameter.value.errors,
                                    function (error, index) {
                                      return _c(
                                        "div",
                                        {
                                          key: index,
                                          staticClass:
                                            "integration-container__content-inputs__errors",
                                        },
                                        [_vm._v(" " + _vm._s(error) + " ")]
                                      )
                                    }
                                  ),
                                ],
                                2
                              )
                            }
                          ),
                        ],
                        2
                      )
                    : _vm._e(),
                ]
              ),
              _vm.integration.parameterValues
                ? _c(
                    "div",
                    _vm._l(
                      _vm.integration.parameterValues.errors,
                      function (error, index) {
                        return _c(
                          "div",
                          {
                            key: index,
                            staticClass:
                              "integration-container__content-inputs__errors",
                          },
                          [_vm._v(" " + _vm._s(error) + " ")]
                        )
                      }
                    ),
                    0
                  )
                : _vm._e(),
            ]),
            _vm.generalError
              ? _c(
                  "span",
                  { staticClass: "integration__general-error text--red" },
                  [
                    _c("GjIcon", { attrs: { name: "InfoAlt", size: "22" } }),
                    _vm._v(" " + _vm._s(_vm.generalError) + " "),
                  ],
                  1
                )
              : _vm._e(),
          ]),
          _c("Footer", {
            attrs: {
              "is-loading": _vm.isLoading,
              submitted: _vm.isLoading,
              "cancel-id": `cancel-create-integration`,
              "create-id": `add-integration`,
            },
            on: {
              back: function ($event) {
                return _vm.cancel()
              },
              create: function ($event) {
                return _vm.saveIntegration(
                  _vm.integration.integrationParameters
                )
              },
            },
          }),
        ],
        1
      )
    : _c("div", { staticClass: "loading__container" }, [_c("LoadingBar")], 1)
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }