<template xmlns:div="http://www.w3.org/1999/html">
  <section class="create-segment-form-wrapper">
    <div
      v-if="!formState.isFormLoaded && newSegment"
      class="loading__container"
    >
      <LoadingBar />
    </div>
    <b-form
      v-if="formState.isFormLoaded || !newSegment"
      novalidate
      class="form__wrapper"
      @submit.prevent
    >
      <div class="container__wrapper">
        <div class="container__header">
          <h5 class="container__header-title">{{ $t('data.basicInfo') }}</h5>
        </div>
        <div class="container__content">
          <b-col lg="6" class="input__wrapper pl-0">
            <label class="mr-1">{{ $t('Name') }} *</label>
            <b-form-input
              id="segment-name"
              v-model.trim="segment.name.value"
              :placeholder="$t('audience.enterAudienceName')"
              type="text"
              class="form__input"
              :class="{
                'form__input--invalid': segment.name.errors.length > 0
              }"
              @input="handleFormInput(formState, [segment.name])"
            />
            <div
              v-for="(error, errIndex) in segment.name.errors"
              :key="errIndex"
              class="error__info-message"
            >
              <span>{{ error }}</span>
            </div>
          </b-col>
          <b-col lg="6" class="input__wrapper pl-0">
            <label for="textarea-default">{{ $t('Description') }}</label>
            <b-form-textarea
              id="segment-descripton"
              v-model.trim="segment.description.value"
              class="form__input form__input-textarea"
              :class="{
                'form__input--invalid': segment.description.errors.length > 0
              }"
              :placeholder="$t('audience.enterAudienceDescription')"
              rows="3"
              @input="handleFormInput(formState, [segment.description])"
            />
            <div
              v-for="(error, errIndex) in segment.description.errors"
              :key="errIndex"
              class="error__info-message"
            >
              <span>{{ error }}</span>
            </div>
          </b-col>
          <b-col lg="6" class="input__wrapper pl-0">
            <div class="input__wrapper-properties">
              <label class="mr-1">{{ $t('Properties') }}</label>
              <MultipleProperties
                id="segment-propreties"
                :options="allProperties"
                v-bind="
                  !!segmentId ? { selected: segment.audienceProperties } : {}
                "
                :tags-to-show="3"
                no-debounce
                :classes="['multiple-properties--three']"
                :all-selected-classes="['multiple-properties--all']"
                @update-data="updateProperties"
              />
            </div>
          </b-col>
        </div>
      </div>
      <div class="container__wrapper user__segmentation-wrapper">
        <div class="container__header documentation__header--padding">
          <h5 class="container__header-title">
            {{ $t('audience.userSegmentations') }}
          </h5>
        </div>
        <div class="documentation__info-text mb-2">
          <GjIcon name="Info" class="mr-50" size="18" />
          <span
            >{{ $t('audience.segmentsDocumentationLink') }}
            <a
              :href="`${whitelabel.documentationUrl}/intro#introduction-to-segments`"
              target="_blank"
              >{{ $t('Documentation').toLowerCase() }}</a
            >.</span
          >
        </div>
        <div
          v-for="(block, blockIndex) in segment.blocks"
          :key="blockIndex"
          class="container__content"
        >
          <div
            v-for="(filter, filterIndex) in block.filters"
            :key="filterIndex"
            class="container__content--segments"
          >
            <div v-if="filter.filterType === filterTypes.filter">
              <div v-if="block.filters.length > 1 && filterIndex !== 0">
                <b-button class="segment__button--or-multiple">
                  <span>{{ $t('audience.or') }}</span>
                </b-button>
              </div>
              <div
                v-if="
                  !(segment.blocks.length === 1 && block.filters.length === 1)
                "
                class="container__content--segments_remove"
                @click="removeBlock(blockIndex, filterIndex)"
              >
                <GjIcon name="Close" class="mr-50 segment__icon" size="20" />
              </div>
              <div
                :class="{
                  'form__input--invalid': filter.filters.errors.length > 0
                }"
                style="display: hidden"
              ></div>
              <div class="input__wrapper input__wrapper--margin">
                <div class="segment__wrapper-select organization__select">
                  <v-select
                    id="segment-organization"
                    v-model="filter.filterOrganization.value"
                    :options="organizations"
                    label="name"
                    :placeholder="
                      organizations.length
                        ? 'Select organization'
                        : 'Loading...'
                    "
                    :disabled="!organizations.length || filter.isDisabled"
                    :loading="!organizations.length"
                    class="form__input segment__select"
                    :clearable="false"
                    @input="
                      onFilterOrganizationChange(filter),
                        handleFormInput(formState)
                    "
                  >
                    <template #spinner="{ loading }">
                      <LoadingBar v-if="loading" classList="small mr-1" />
                    </template>
                  </v-select>
                </div>
                <InformationSidebar
                  v-if="filter.isDisabled"
                  :code="`${blockIndex}-${filterIndex}`"
                  :title="$t('audience.disabledFilter')"
                  :content="$t('audience.disabledFilterInfo')"
                />
              </div>
              <div class="input__wrapper">
                <div class="segment__wrapper">
                  <div class="segment__wrapper-select">
                    <v-select
                      id="segment-table"
                      v-model="filter.segmentationTableModel"
                      :disabled="
                        filter.segmentationTableOptions === null ||
                        filter.isDisabled
                      "
                      :options="
                        filter.segmentationTableOptions === null
                          ? []
                          : filter.segmentationTableOptions
                      "
                      :placeholder="
                        filter.segmentationTableOptions !== null
                          ? 'Table'
                          : 'Loading...'
                      "
                      label="name"
                      :loading="
                        filter.segmentationTableOptions === null &&
                        !filter.segmentationTableModel
                      "
                      class="form__input segment__select"
                      :class="{
                        'form__input--invalid': filter.tableId.errors.length > 0
                      }"
                      :clearable="false"
                      @input="
                        onSegmentTableChange(filter),
                          handleFormInput(formState, [filter.tableId])
                      "
                    >
                      <template #spinner="{ loading }">
                        <LoadingBar v-if="loading" classList="small mr-1" />
                      </template>
                      <template #option="item">
                        {{ item.name }}
                        <span v-if="!item.enabled" class="disabled__prefix"
                          >disabled</span
                        >
                      </template>
                    </v-select>
                    <div
                      v-for="(error, errIndex) in filter.tableId.errors"
                      :key="errIndex"
                      class="error__info-message"
                    >
                      <span>{{ error }}</span>
                    </div>
                  </div>
                  <div class="segment__wrapper-select">
                    <v-select
                      id="segment-state"
                      v-model="filter.eventStateDefault"
                      :options="filter.eventState"
                      :disabled="filter.isDisabled"
                      label="text"
                      class="form__input"
                      :clearable="false"
                      @input="handleFormInput(formState)"
                    ></v-select>
                  </div>
                </div>
              </div>
              <div
                :id="`segment__button--time-${blockIndex}-${filterIndex}`"
                class="segment__button--container"
              >
                <b-button
                  v-if="!filter.isTimeParamSet"
                  id="add-time"
                  :disabled="
                    !filter.segmentationTableModel || filter.isDisabled
                  "
                  class="segment__button"
                  @click="addFilterParam(filter, 'time')"
                >
                  <div class="segment__button--icon">
                    <GjIcon name="Plus" class="mr-50" size="20" />
                  </div>
                  <span class="align-middle">{{ $t('Time') }}</span>
                  <b-tooltip
                    v-if="!filter.segmentationTableModel"
                    :target="`segment__button--time-${blockIndex}-${filterIndex}`"
                    placement="right"
                  >
                    {{ $t('audience.parameterTooltipMessage') }}
                  </b-tooltip>
                </b-button>
              </div>
              <div v-if="filter.isTimeParamSet" class="segment__param">
                <div
                  class="segment__param"
                  :class="{
                    duration__wrapper:
                      filter.isDurationLastParamShown ||
                      filter.isDurationBetweenParamShown
                  }"
                >
                  <div class="icon__wrapper">
                    <GjIcon name="Time" class="mr-50" size="20" />
                  </div>
                  <div class="input__wrapper" style="margin-bottom: 0">
                    <v-select
                      id="duration-operator"
                      v-model="filter.durationOperator.value"
                      :placeholder="$t('Select')"
                      :options="filter.durationOptions"
                      :disabled="filter.isDisabled"
                      label="text"
                      class="form__input segment__select"
                      :class="{
                        'form__input--invalid':
                          filter.durationOperator.errors.length > 0
                      }"
                      :clearable="false"
                      @input="
                        getSelectedDuration($event, filter),
                          handleFormInput(formState, [
                            filter.durationOperator,
                            filter.durationValue
                          ])
                      "
                    ></v-select>
                    <div
                      v-for="(error, errIndex) in filter.durationOperator
                        .errors"
                      :key="errIndex"
                      class="error__info-message error__info-message_no-height"
                    >
                      <span>{{ error }}</span>
                    </div>
                  </div>
                  <div
                    v-if="
                      filter.isDurationDatepickerShown ||
                      filter.isDurationBetweenParamShown
                    "
                    class="input__wrapper--date"
                    :class="{
                      'duration-dates__wrapper':
                        filter.isDurationBetweenParamShown
                    }"
                  >
                    <div>
                      <b-form-datepicker
                        :id="`duration-first-date-${blockIndex}-${filterIndex}`"
                        v-model="filter.durationFirstDate"
                        calendar-width="100%"
                        class="form__input"
                        :disabled="filter.isDisabled"
                        :class="{
                          'form__input--invalid':
                            filter.durationValue.errors.length > 0
                        }"
                        :max="maxDate"
                        :date-format-options="{
                          year: 'numeric',
                          month: 'short',
                          day: '2-digit',
                          weekday: 'short'
                        }"
                        @input="
                          datePickerValueChanged(filter),
                            handleFormInput(formState, [filter.durationValue])
                        "
                      ></b-form-datepicker>
                      <div
                        v-for="(error, errIndex) in filter.durationValue.errors"
                        :key="errIndex"
                        class="error__info-message error__info-message_no-height"
                      >
                        <span>{{ error }}</span>
                      </div>
                    </div>
                    <div
                      v-if="filter.isDurationBetweenParamShown"
                      class="input__wrapper--date"
                    >
                      <span>{{ $t('audience.and').toLowerCase() }}</span>
                      <b-form-datepicker
                        :id="`duration-second-date-${blockIndex}-${filterIndex}`"
                        v-model="filter.durationSecondDate"
                        :disabled="filter.isDisabled"
                        calendar-width="100%"
                        class="form__input"
                        :class="{
                          'form__input--invalid':
                            filter.durationValue.errors.length > 0
                        }"
                        :max="maxDate"
                        :min="filter.minDate"
                        :date-format-options="{
                          year: 'numeric',
                          month: 'short',
                          day: '2-digit',
                          weekday: 'short'
                        }"
                        @input="
                          handleFormInput(formState, [filter.durationValue])
                        "
                      ></b-form-datepicker>
                    </div>
                  </div>
                  <div
                    v-if="filter.isDurationLastParamShown"
                    class="input__wrapper time__last-param"
                    style="margin-bottom: 0"
                  >
                    <div>
                      <b-form-input
                        id="duration-number"
                        v-model="filter.durationNumberParam"
                        :disabled="filter.isDisabled"
                        placeholder="0"
                        type="number"
                        min="1"
                        class="form__input form__input-time form__input--small"
                        :class="{
                          'form__input--invalid':
                            filter.durationValue.errors.length > 0
                        }"
                        @input="
                          handleFormInput(formState, [filter.durationValue])
                        "
                      />
                      <div
                        v-for="(error, errIndex) in filter.durationValue.errors"
                        :key="errIndex"
                        class="error__info-message error__info-message_no-height"
                      >
                        <span>{{ error }}</span>
                      </div>
                    </div>
                    <v-select
                      id="duration-number-time-unit"
                      v-model="filter.durationNumberFormatParam"
                      :placeholder="$t('Select')"
                      :disabled="filter.isDisabled"
                      :options="filter.timeLastOptions"
                      class="form__input"
                      :class="{
                        'form__input--invalid':
                          filter.durationValue.errors.length > 0
                      }"
                      :clearable="false"
                      @input="
                        handleFormInput(formState, [filter.durationValue])
                      "
                    ></v-select>
                  </div>
                </div>
                <div
                  v-if="!filter.isDisabled"
                  id="remove-time-parameter"
                  class="segments__remove"
                  @click="removeFilterParam('time', filter)"
                >
                  <GjIcon name="Close" class="mr-50 segment__icon" size="20" />
                </div>
              </div>
              <div
                :id="`segment__button--frequency-${blockIndex}-${filterIndex}`"
                class="segment__button--container"
              >
                <b-button
                  v-if="!filter.isFrequencyParamSet"
                  id="add-frequency"
                  :disabled="
                    !filter.segmentationTableModel || filter.isDisabled
                  "
                  class="segment__button"
                  @click="addFilterParam(filter, 'frequency')"
                >
                  <div class="segment__button--icon">
                    <GjIcon name="Plus" class="mr-50" size="20" />
                  </div>
                  <span class="align-middle">{{
                    $t('audience.frequency')
                  }}</span>
                  <b-tooltip
                    v-if="!filter.segmentationTableModel"
                    :target="`segment__button--frequency-${blockIndex}-${filterIndex}`"
                    placement="right"
                  >
                    {{ $t('audience.parameterTooltipMessage') }}
                  </b-tooltip>
                </b-button>
              </div>
              <div v-if="filter.isFrequencyParamSet" class="segment__param">
                <div class="segment__param frequency__section">
                  <div class="icon__wrapper">
                    <GjIcon name="Grid" class="mr-50" size="20" />
                  </div>
                  <div class="input__wrapper">
                    <v-select
                      id="frequency-operator"
                      v-model="filter.frequencyOperator.value"
                      :options="filter.frequencyOptions"
                      :placeholder="$t('Select')"
                      :disabled="filter.isDisabled"
                      label="text"
                      class="form__input"
                      :class="{
                        'form__input--invalid':
                          filter.frequencyOperator.errors.length > 0
                      }"
                      :clearable="false"
                      @input="
                        getSelectedFrequency($event, filter),
                          handleFormInput(formState, [
                            filter.frequencyOperator,
                            filter.frequencyFirstValue,
                            filter.frequencySecondValue
                          ])
                      "
                    ></v-select>
                    <div
                      v-for="(error, errIndex) in filter.frequencyOperator
                        .errors"
                      :key="errIndex"
                      class="error__info-message"
                    >
                      <span>{{ error }}</span>
                    </div>
                  </div>
                  <div class="input__wrapper">
                    <b-form-input
                      id="frequency-first-value"
                      v-model="filter.frequencyFirstValue.value"
                      placeholder="0"
                      :disabled="filter.isDisabled"
                      type="number"
                      class="form__input"
                      :class="{
                        'form__input--invalid':
                          filter.frequencyFirstValue.errors.length > 0
                      }"
                      @input="
                        handleFormInput(formState, [filter.frequencyFirstValue])
                      "
                    />
                    <div
                      v-for="(error, errIndex) in filter.frequencyFirstValue
                        .errors"
                      :key="errIndex"
                      class="error__info-message error__info-message_text-wrap"
                    >
                      <span>{{ error }}</span>
                    </div>
                  </div>
                  <span
                    v-if="!filter.showFrequencySecondParam"
                    class="segment__param-span"
                    >{{ $t('audience.time') }}</span
                  >
                  <span
                    v-if="filter.showFrequencySecondParam"
                    class="segment__param-span"
                    >{{ $t('audience.and').toLowerCase() }}</span
                  >
                  <div
                    v-if="filter.showFrequencySecondParam"
                    class="input__wrapper"
                  >
                    <b-form-input
                      id="frequency-second-value"
                      v-model="filter.frequencySecondValue.value"
                      placeholder="0"
                      :disabled="filter.isDisabled"
                      type="number"
                      class="form__input"
                      :class="{
                        'form__input--invalid':
                          filter.frequencySecondValue.errors.length > 0
                      }"
                      @input="
                        handleFormInput(formState, [
                          filter.frequencySecondValue
                        ])
                      "
                    />
                    <div
                      v-for="(error, errIndex) in filter.frequencySecondValue
                        .errors"
                      :key="errIndex"
                      class="error__info-message error__info-message_text-wrap"
                    >
                      <span>{{ error }}</span>
                    </div>
                  </div>
                </div>
                <div
                  v-if="!filter.isDisabled"
                  id="remove-frequency"
                  class="segments__remove"
                  @click="removeFilterParam('frequency', filter)"
                >
                  <GjIcon name="Close" class="mr-50 segment__icon" size="20" />
                </div>
              </div>

              <!-- HERE -->
              <div
                :id="`segment__button--parameters-${blockIndex}-${filterIndex}`"
                class="segment__button--container"
              >
                <b-button
                  v-if="!filter.isParametersParamSet"
                  id="add-parameter"
                  :disabled="
                    filter.segmentationTableModel.length === 0 ||
                    filter.isDisabled
                  "
                  class="segment__button"
                  @click="addFilterParam(filter, 'parameters')"
                >
                  <div class="segment__button--icon">
                    <GjIcon name="Plus" class="mr-50" size="20" />
                  </div>
                  <span class="align-middle">{{
                    $t('audience.parameters')
                  }}</span>
                  <b-tooltip
                    v-if="!filter.segmentationTableModel"
                    :target="`segment__button--parameters-${blockIndex}-${filterIndex}`"
                    placement="right"
                  >
                    {{ $t('audience.parameterTooltipMessage') }}
                  </b-tooltip>
                </b-button>
              </div>
              <div v-if="filter.isParametersParamSet" class="segment__param">
                <div class="segment__param">
                  <div class="segment__param-selects">
                    <div class="segment__param-selects-content">
                      <GjIcon name="AlignLeftAlt" class="mr-50" size="20" />
                      <span
                        class="segment__param-title segment__param-title--width"
                        >{{ $t('audience.with') }}</span
                      >
                      <div class="segment__param-heading">
                        <div class="input__wrapper">
                          <v-select
                            id="parameters-default"
                            v-model="filter.withParametersDefault"
                            :options="filter.withParameters"
                            :disabled="filter.isDisabled"
                            label="text"
                            class="form__input"
                            :clearable="false"
                            @input="handleFormInput(formState)"
                          ></v-select>
                        </div>
                        <span class="segment__param-title"
                          >{{ $t('audience.ofFollowingParameters') }}:</span
                        >
                      </div>
                    </div>
                    <div
                      v-for="(param, paramIndex) in filter.parameters"
                      :key="param.paramIndex"
                      class="segment__param--wrapper"
                    >
                      <div
                        class="input__wrapper input__wrapper--flex input__wrapper--width"
                      >
                        <span
                          v-if="
                            paramIndex > 0 &&
                            filter.withParametersDefault.value === 1
                          "
                          class="parameters__operators"
                        >
                          {{ $t('audience.and') }}
                        </span>
                        <span
                          v-if="
                            paramIndex > 0 &&
                            filter.withParametersDefault.value === 2
                          "
                          class="parameters__operators"
                        >
                          {{ $t('audience.or') }}
                        </span>
                        <div
                          class="parameters__custom input__wrapper-parameters"
                        >
                          <v-select
                            :id="`parameter-options-${paramIndex}`"
                            v-model="param.parameterOptionsModel"
                            :placeholder="$t('audience.parameters')"
                            :options="param.parameterOptions"
                            :disabled="filter.isDisabled"
                            label="parameterName"
                            :selectable="(option) => !option.isParent"
                            :menu-props="{ top: true, offsetY: true }"
                            class="form__input segment__select"
                            :class="{
                              'form__input--invalid':
                                param.columnId.errors.length > 0
                            }"
                            :clearable="false"
                            @input="
                              getSelectedParameter($event, param)
                              setParameterOptions(param)
                              handleFormInput(formState, [
                                param.columnId,
                                param.operator,
                                param.parameterValues
                              ])
                            "
                          ></v-select>
                          <div
                            v-for="(error, errIndex) in param.columnId.errors"
                            :key="errIndex"
                            class="error__info-message"
                          >
                            <span>{{ error }}</span>
                          </div>
                        </div>
                        <div
                          class="parameters__custom-content"
                          :class="[
                            param.isDateParam &&
                            !param.selectedParameterOptionModel
                              ? 'parameters__custom-content--no-margin'
                              : '',
                            param.isDateParam &&
                            param.selectedParameterOptionModel &&
                            !param.isParameterBetweenOperatorShown
                              ? 'parameter-date--no-margin'
                              : '',
                            !param.isCustomParam && !param.isDateParam
                              ? 'parameters-not-custom'
                              : '',
                            param.isBooleanParam &&
                            !param.selectedParameterOptionModel
                              ? 'parameters__bolean-content'
                              : '',
                            param.isBooleanParam &&
                            param.selectedParameterOptionModel
                              ? 'parameters__bolean-content-is'
                              : '',
                            isDateTimeType(
                              param.parameterOptionsModel.columnType
                            ) &&
                            param.selectedParameterOptionModel &&
                            !param.isParameterBetweenOperatorShown
                              ? 'parameter-datetime--no-margin'
                              : ''
                          ]"
                        >
                          <div
                            v-if="
                              param.isCustomParam &&
                              param.parameterOptionsModel.columnType !== 16
                            "
                          >
                            <b-form-input
                              :id="`parameter-stringKey-${paramIndex}`"
                              v-model="param.parametersStringKeyModel"
                              :placeholder="$t('key')"
                              :disabled="filter.isDisabled"
                              type="text"
                              class="form__input form__input--small segment__key-input"
                              :class="{
                                'form__input--invalid':
                                  param.parameterValues.errors.length > 0
                              }"
                              @input="
                                handleFormInput(formState, [
                                  param.parameterValues
                                ])
                              "
                            />
                          </div>
                          <div
                            v-if="
                              param.parameterOptionsModel &&
                              (param.parameterOptionsModel.length > 0 ||
                                param.parameterOptionsModel.columnId.length > 0)
                            "
                            class="input__wrapper--flex"
                          >
                            <div
                              :class="{
                                'date-parameter-specific':
                                  param.isDateParam &&
                                  param.isParameterBetweenOperatorShown
                              }"
                            >
                              <v-select
                                :id="`selected-parameter-option-${paramIndex}`"
                                v-model="param.selectedParameterOptionModel"
                                :placeholder="$t('Select')"
                                :options="param.selectedParameterOptions"
                                :disabled="filter.isDisabled"
                                label="name"
                                class="form__input"
                                :class="{
                                  'form__input--invalid':
                                    param.operator.errors.length > 0
                                }"
                                :clearable="false"
                                @input="
                                  handleInputsBasedOnOperatorChosen(param),
                                    handleFormInput(formState, [
                                      param.operator,
                                      param.parameterValues
                                    ])
                                "
                              >
                              </v-select>
                              <div
                                v-for="(error, errIndex) in param.operator
                                  .errors"
                                :key="errIndex"
                                class="error__info-message"
                              >
                                <span>{{ error }}</span>
                              </div>
                            </div>
                            <div
                              v-if="
                                param.isDateParam &&
                                !!param.selectedParameterOptionModel
                              "
                              class="parameter__dates"
                            >
                              <div class="segment__datetime-picker">
                                <div>
                                  <b-form-datepicker
                                    v-if="
                                      !isDateTimeType(
                                        param.parameterOptionsModel.columnType
                                      )
                                    "
                                    :id="`parameter-first-date-${blockIndex}-${filterIndex}-${paramIndex}`"
                                    v-model="param.parameterFirstDate"
                                    calendar-width="100%"
                                    :disabled="filter.isDisabled"
                                    class="form__input"
                                    :class="{
                                      'form__input--invalid':
                                        param.parameterValues.errors.length > 0
                                    }"
                                    :max="maxDate"
                                    :date-format-options="{
                                      year: 'numeric',
                                      month: 'short',
                                      day: '2-digit',
                                      weekday: 'short'
                                    }"
                                    @input="
                                      parameterDatePickerValueChanged(param),
                                        handleFormInput(formState, [
                                          param.parameterValues
                                        ])
                                    "
                                  ></b-form-datepicker>
                                  <div
                                    v-else
                                    :class="{
                                      'form__input--invalid':
                                        param.parameterValues.errors.length > 0
                                    }"
                                  >
                                    <date-pick
                                      :id="`parameter-first-datetime-${blockIndex}-${filterIndex}-${paramIndex}`"
                                      v-model="param.parameterFirstDate"
                                      :editable="!filter.isDisabled"
                                      :pick-time="true"
                                      :use12hour-clock="true"
                                      :format="'YYYY-MM-DD HH:mm'"
                                      :display-format="'YYYY.MM.DD H:mm A'"
                                      :is-date-disabled="maximumDate"
                                      :input-attributes="{
                                        placeholder: 'Select'
                                      }"
                                      @input="
                                        parameterDatePickerValueChanged(param),
                                          handleFormInput(formState, [
                                            param.parameterValues
                                          ])
                                      "
                                    ></date-pick>
                                  </div>
                                  <div
                                    v-for="(error, errIndex) in param
                                      .parameterValues.errors"
                                    :key="errIndex"
                                    class="error__info-message"
                                  >
                                    <span>{{ error }}</span>
                                  </div>
                                </div>
                              </div>
                              <div
                                v-if="param.isParameterBetweenOperatorShown"
                                class="input__wrapper--date segment__datetime-picker"
                              >
                                <span>{{
                                  $t('audience.and').toLowerCase()
                                }}</span>
                                <b-form-datepicker
                                  v-if="
                                    !isDateTimeType(
                                      param.parameterOptionsModel.columnType
                                    )
                                  "
                                  :id="`parameter-second-date-${blockIndex}-${filterIndex}-${paramIndex}`"
                                  v-model="param.parameterSecondDate"
                                  :disabled="filter.isDisabled"
                                  calendar-width="100%"
                                  class="form__input"
                                  :class="{
                                    'form__input--invalid':
                                      param.parameterValues.errors.length > 0
                                  }"
                                  :max="maxDate"
                                  :min="param.parameterFirstDate"
                                  :date-format-options="{
                                    year: 'numeric',
                                    month: 'short',
                                    day: '2-digit',
                                    weekday: 'short'
                                  }"
                                  @input="
                                    handleFormInput(formState, [
                                      param.parameterValues
                                    ])
                                  "
                                ></b-form-datepicker>
                                <div
                                  v-else
                                  :class="{
                                    'form__input--invalid':
                                      param.parameterValues.errors.length > 0
                                  }"
                                >
                                  <date-pick
                                    :id="`parameter-second-datetime-${blockIndex}-${filterIndex}-${paramIndex}`"
                                    v-model="param.parameterSecondDate"
                                    :editable="!filter.isDisabled"
                                    :pick-time="true"
                                    :use12hour-clock="true"
                                    :format="'YYYY-MM-DD HH:mm'"
                                    :display-format="'YYYY.MM.DD H:mm A'"
                                    :is-date-disabled="
                                      (date) =>
                                        secondDateHandler(
                                          date,
                                          param.parameterFirstDate
                                        )
                                    "
                                    :input-attributes="{
                                      placeholder: 'Select'
                                    }"
                                    @input="
                                      handleFormInput(formState, [
                                        param.parameterValues
                                      ])
                                    "
                                  ></date-pick>
                                </div>
                              </div>
                            </div>
                            <div
                              v-if="
                                param.isBooleanParam &&
                                !!param.selectedParameterOptionModel
                              "
                            >
                              <v-select
                                :id="`parameter-boolean-${paramIndex}`"
                                v-model="param.parametersBooleanModel"
                                :disabled="filter.isDisabled"
                                :placeholder="$t('Select')"
                                :options="param.parametersBooleanValues"
                                label="text"
                                class="form__input"
                                :class="{
                                  'form__input--invalid':
                                    param.parameterValues.errors.length > 0
                                }"
                                :clearable="false"
                                @input="
                                  handleInputsBasedOnOperatorChosen(param),
                                    handleFormInput(formState, [
                                      param.parameterValues
                                    ])
                                "
                              >
                              </v-select>
                              <div
                                v-for="(error, errIndex) in param
                                  .parameterValues.errors"
                                :key="errIndex"
                                class="error__info-message"
                              >
                                <span>{{ error }}</span>
                              </div>
                            </div>
                            <div
                              v-if="!param.isDateParam && !param.isBooleanParam"
                              class="segment__param-info"
                            >
                              <b-form-tags
                                :id="`parameter-tags-${paramIndex}`"
                                v-model="param.paramTagsModel"
                                :disabled="filter.isDisabled"
                                separator=";"
                                :placeholder="
                                  filter.isDisabled ? '' : $t('value')
                                "
                                remove-on-delete
                                add-on-change
                                required
                                class="form__input form__input-params form__input-tags"
                                :class="{
                                  'form__input--invalid':
                                    param.parameterValues.errors.length > 0
                                }"
                                @input="
                                  handleFormInput(formState, [
                                    param.parameterValues
                                  ])
                                "
                              ></b-form-tags>
                              <span
                                v-if="
                                  param.parameterValues.errors.length < 1 &&
                                  !filter.isDisabled
                                "
                                class="parameter__tag-help"
                              >
                                * {{ $t('audience.enterTags') }}
                              </span>
                              <div
                                v-for="(error, errIndex) in param
                                  .parameterValues.errors"
                                :key="errIndex"
                                class="error__info-message"
                              >
                                <span>{{ error }}</span>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div
                          v-if="
                            filter.parameters.length > 0 && !filter.isDisabled
                          "
                          :id="`remove-parameter-${paramIndex}`"
                          class="params__remove"
                          @click="
                            removeParameter(blockIndex, filterIndex, paramIndex)
                          "
                        >
                          <GjIcon
                            name="Close"
                            class="mr-50 segment__icon"
                            size="20"
                          />
                        </div>
                      </div>
                      <div
                        v-if="paramIndex === filter.parameters.length - 1"
                        id="add_parameters"
                        :class="`add__parameters add__parameters-${
                          !!param.parameterOptionsModel && !filter.isDisabled
                            ? '-margin'
                            : 'disabled'
                        }`"
                        v-on="
                          !!param.parameterOptionsModel && !filter.isDisabled
                            ? {
                                click: () =>
                                  addParameter(blockIndex, filterIndex)
                              }
                            : {}
                        "
                      >
                        <GjIcon
                          name="Plus"
                          class="mr-50 segment__icon"
                          size="20"
                        />
                        {{ $t('audience.parameters') }}
                      </div>
                    </div>
                  </div>
                </div>
                <div
                  v-if="!filter.isDisabled"
                  id="remove-parameter"
                  class="segments__remove"
                  @click="removeFilterParam('parameters', filter)"
                >
                  <GjIcon name="Close" class="mr-50 segment__icon" size="20" />
                </div>
              </div>

              <div
                :id="`segment__button--topics-${blockIndex}-${filterIndex}`"
                class="segment__button--container"
              >
                <b-button
                  v-if="!filter.isTopicsParamSet"
                  id="add-topics"
                  :disabled="
                    !filter.segmentationTableModel ||
                    filter.isDisabled ||
                    !isContextualTargetingEnabled
                  "
                  class="segment__button"
                  @click="addFilterParam(filter, 'topics')"
                >
                  <div class="segment__button--icon">
                    <GjIcon name="Plus" class="mr-50" size="20" />
                  </div>
                  <span class="align-middle">{{ $t('Topics') }}</span>
                  <b-tooltip
                    v-if="!filter.segmentationTableModel"
                    :target="`segment__button--topics-${blockIndex}-${filterIndex}`"
                    placement="right"
                  >
                    {{ $t('audience.parameterTooltipMessage') }}
                  </b-tooltip>
                  <b-tooltip
                    v-if="
                      !isContextualTargetingEnabled &&
                      filter.segmentationTableModel
                    "
                    :target="`segment__button--topics-${blockIndex}-${filterIndex}`"
                    placement="right"
                    >{{ $t('audience.topics.notEnabledMessage') }}</b-tooltip
                  >
                </b-button>
              </div>

              <div v-if="filter.isTopicsParamSet" class="segment__param">
                <div class="segment__param segment__topics">
                  <div class="icon__wrapper">
                    <GjIcon name="File" class="mr-50" size="20" />
                  </div>
                  <div></div>
                  <div class="input__wrapper topics__select">
                    <div class="input__wrapper-properties">
                      <MultipleProperties
                        :id="`topics-operator-${blockIndex}-${filterIndex}`"
                        :options="allTopics"
                        :tags-to-show="3"
                        no-debounce
                        v-bind="
                          !!segmentId ? { selected: filter.topics.value } : {}
                        "
                        :classes="['multiple-properties--three']"
                        :all-selected-classes="['multiple-properties--all']"
                        is-topic
                        :is-disabled="filter.isDisabled || !allTopics.length"
                        :invalid-input="!!filter.topics.errors.length"
                        @update-data="
                          (selected) => {
                            updateTopics(selected, filter)
                          }
                        "
                      />
                      <b-tooltip
                        v-if="!allTopics.length"
                        :target="`topics-operator-${blockIndex}-${filterIndex}`"
                        placement="right"
                        >{{ $t('audience.topics.noTopicCreated') }}</b-tooltip
                      >
                      <div
                        v-for="(error, errIndex) in filter.topics.errors"
                        :key="errIndex"
                        class="error__info-message"
                      >
                        <span>{{ error }}</span>
                      </div>
                    </div>
                  </div>
                </div>
                <div
                  v-if="!filter.isDisabled"
                  id="remove-topics-parameter"
                  class="segments__remove"
                  @click="removeFilterParam('topics', filter)"
                >
                  <GjIcon name="Close" class="mr-50 segment__icon" size="20" />
                </div>
              </div>

              <div
                v-for="(error, errIndex) in filter.filters.errors"
                :key="errIndex"
                class="error__info-message"
              >
                <span>{{ error }}</span>
              </div>
            </div>
            <div v-if="filter.filterType === filterTypes.segment">
              <div v-if="block.filters.length > 1 && filterIndex !== 0">
                <b-button class="segment__button--or-multiple">
                  <span>{{ $t('audience.or') }}</span>
                </b-button>
              </div>
              <div
                v-if="
                  !(segment.blocks.length === 1 && block.filters.length === 1)
                "
                :id="`remove-block`"
                class="container__content--segments_remove"
                @click="removeBlock(blockIndex, filterIndex)"
              >
                <GjIcon name="Close" class="mr-50 segment__icon" size="20" />
              </div>
              <div class="input__wrapper input__wrapper--margin">
                <div
                  class="segment__wrapper-select organization__select segment__combine--input"
                >
                  <label class="mr-1">Segment</label>
                  <v-select
                    id="selected-segment"
                    v-model="filter.segment.value"
                    :options="
                      !!segmentId
                        ? filterSegments.filter((s) => s.id !== segmentId)
                        : filterSegments
                    "
                    label="name"
                    placeholder="Select segment"
                    :disabled="filter.isDisabled"
                    class="form__input segment__select mr-1"
                    :class="{
                      'form__input--invalid': filter.segment.errors.length > 0
                    }"
                    :clearable="false"
                    @input="handleFormInput(formState, [filter.segment])"
                  ></v-select>
                  <InformationSidebar
                    v-if="filter.isDisabled"
                    :code="`${blockIndex}-${filterIndex}`"
                    :title="$t('audience.disabledFilter')"
                    :content="`''${
                      filter.segment.value.name
                    }'' is ${filter.segment.value.label.toLowerCase()},
                      users targeted with this segment will no longer be part of ''${
                        segment.name.value
                      }''`"
                  />
                </div>
              </div>
              <div
                v-for="(error, errIndex) in filter.segment.errors"
                :key="errIndex"
                class="error__info-message"
              >
                <span>{{ error }}</span>
              </div>
            </div>
          </div>
          <div
            class="container__content--segments container__content--segments-last"
          >
            <div class="segment__arrow"></div>
            <div
              :id="`segment__button--or-${blockIndex}`"
              class="segment__button--wrapper"
            >
              <b-dropdown
                :id="`add-or-dropdown`"
                class="segment__button--reversed"
                :disabled="!isComplete"
                split
                size="sm"
                dropright
                @click="addFilter('or', blockIndex)"
              >
                <template #button-content>
                  <span class="align-middle">{{ $t('audience.or') }}</span>
                  <div class="segment__dropdown-divider"></div>
                </template>
                <b-dropdown-item
                  id="add-or-filter"
                  @click="addFilter('or', blockIndex)"
                >
                  <GjIcon name="Plus" class="mr-50" size="16" />
                  <span class="align-middle">Filter</span>
                </b-dropdown-item>
                <b-dropdown-item
                  id="add-or-segment"
                  @click="addSegment('or', blockIndex)"
                >
                  <GjIcon name="Plus" class="mr-50" size="16" />
                  <span class="align-middle">Segment</span>
                </b-dropdown-item>
                <b-tooltip
                  v-if="!isComplete"
                  :target="`segment__button--or-${blockIndex}`"
                  placement="right"
                  >{{ $t('audience.operatorTooltipMessage') }}</b-tooltip
                >
              </b-dropdown>
            </div>
          </div>

          <div
            class="segment__button-and__container"
            :class="{
              'segment__button-and__container--multiple':
                segment.blocks.length > 1 &&
                blockIndex !== segment.blocks.length - 1
            }"
          >
            <div
              class="segment__arrow"
              :class="{
                'segment__arrow--multiple':
                  segment.blocks.length > 1 &&
                  blockIndex !== segment.blocks.length - 1
              }"
            ></div>
            <div
              v-if="blockIndex === segment.blocks.length - 1"
              :id="`segment__button--and-${blockIndex}`"
              class="segment__button--wrapper"
            >
              <b-dropdown
                id="add-and-dropdown"
                class="segment__button--reversed"
                :disabled="!isComplete"
                split
                size="sm"
                dropright
                v-on="
                  blockIndex === segment.blocks.length - 1
                    ? { click: () => addFilter('and', blockIndex) }
                    : {}
                "
              >
                <template #button-content>
                  <span class="align-middle">{{ $t('audience.and') }}</span>
                  <div class="segment__dropdown-divider"></div>
                </template>
                <b-dropdown-item
                  id="add-and-filter"
                  @click="addFilter('and', blockIndex)"
                >
                  <GjIcon name="Plus" class="mr-50" size="16" />
                  <span class="align-middle">Filter</span>
                </b-dropdown-item>
                <b-dropdown-item
                  id="add-and-segment"
                  @click="addSegment('and', blockIndex)"
                >
                  <GjIcon name="Plus" class="mr-50" size="16" />
                  <span class="align-middle">Segment</span>
                </b-dropdown-item>
                <b-tooltip
                  v-if="
                    !isComplete &&
                    !(
                      segment.blocks.length > 1 &&
                      blockIndex !== segment.blocks.length - 1
                    )
                  "
                  :target="`segment__button--and-${blockIndex}`"
                  placement="right"
                  offset="5"
                  >{{ $t('audience.operatorTooltipMessage') }}</b-tooltip
                >
              </b-dropdown>
            </div>
            <div
              v-if="
                segment.blocks.length > 1 &&
                blockIndex !== segment.blocks.length - 1
              "
            >
              <b-button
                class="segment__button--reversed segment__button--reversed--multiple"
              >
                <span>{{ $t('audience.and') }}</span>
              </b-button>
            </div>
          </div>
        </div>
      </div>
      <div class="container__wrapper">
        <AudienceSizeCalculator
          :data="{
            segment: segment,
            query: createRequestObject(segment),
            isValid: isComplete,
            type: 'segment'
          }"
        ></AudienceSizeCalculator>
      </div>
      <div v-if="segment.activations.length > 0" class="container__wrapper">
        <div class="container__header">
          <h5 class="container__header-title">{{ $t('Integrations') }}</h5>
        </div>
        <div class="container__integrations">
          <IntegrationCard
            v-for="activation in segment.activations"
            :key="activation.id"
            :activation="activation"
            @state-change="handleFormInput(formState)"
          />
        </div>
      </div>
      <Footer
        :is-loading="formState.isLoading"
        :submitted="formState.isLoading"
        :cancel-id="`cancel-create-segment`"
        :create-id="`add-segment`"
        :edit-mode="!!segmentId"
        @back="cancel()"
        @create="segmentId ? openSaveModal() : triggerSave()"
      />
    </b-form>

    <SaveChangesModal :type="$t('Segment')" @save="triggerSave(segment.id)" />
  </section>
</template>

<script>
/* eslint-disable */
import { segments } from '@/api'
import AudienceSizeCalculator from '@/components/shared/AudienceSizeCalculator.vue'
import store from '@/store'
import { whitelabel } from '@/utils/constants'
import {
  // utility components
  Footer,
  GjIcon,
  InformationSidebar,
  IntegrationCard,
  LazyImageLoading,
  LoadingBar,
  MultipleProperties,
  SaveChangesModal,
  // utility functions
  confirmChanges,
  handleFormInput
} from '@nodus/utilities-front'
import {
  BButton,
  BCol,
  BDropdown,
  BDropdownItem,
  BForm,
  BFormDatepicker,
  BFormInput,
  BFormTags,
  BFormTextarea,
  BTooltip,
  VBModal
} from 'bootstrap-vue'
import {
  computed,
  getCurrentInstance,
  onBeforeMount,
  onMounted,
  reactive,
  ref
} from 'vue'
import DatePick from 'vue-date-pick'
import vSelect from 'vue-select'
import {
  columnTypes,
  durationConst,
  eventStateConst,
  eventTableIdDefault,
  filterModel,
  filterTypes,
  frequencyOptionsConst,
  parameterModel,
  parameterOperatorsConst,
  parametersBooleanValuesConst,
  segmentModel,
  withParametersConst
} from './segmentConfig'
import {
  createRequestObject,
  getParameterBooleanValue,
  getParameterTagsModel,
  getSegmentDurationValues,
  getSegmentParameterDurationValues,
  isDateTimeType,
  maximumDate,
  saveSegment,
  secondDateHandler
} from './segmentCreateFunctions'

/* eslint-disable arrow-body-style */
export default {
  name: 'SegmentCreate',
  directives: {
    'b-modal': VBModal
  },
  components: {
    BButton,
    BCol,
    BFormInput,
    BFormTextarea,
    BFormDatepicker,
    BFormTags,
    GjIcon,
    BForm,
    BTooltip,
    BDropdown,
    BDropdownItem,
    // Select Component
    vSelect,
    // Lazy Loading Component
    LazyImageLoading,
    AudienceSizeCalculator,
    DatePick,
    InformationSidebar,
    IntegrationCard,
    Footer,
    SaveChangesModal,
    MultipleProperties,
    LoadingBar
  },
  async beforeRouteLeave(to, from, next) {
    if (!this.formState.isSubmitted && !this.formState.isPristine) {
      const response = await confirmChanges(this.$bvModal)
      next(response)
    } else {
      next()
    }
  },
  async beforeRouteEnter(to, from, next) {
    const organizationId =
      to.params?.organizationId || from.params?.organizationId
    if (!to.params.segmentId) {
      if (store.getters['segments/canCreate'] === null) {
        await store.dispatch('segments/getTable', { id: organizationId })
      }
      if (store.getters['segments/canCreate']) next()
      else next('/error/401')
    } else {
      next()
    }
  },
  computed: {
    isComplete() {
      let isValidFilter = true
      this.segment.blocks.forEach((block) => {
        block.filters.forEach((filter) => {
          if (filter.filterType === filterTypes.filter)
            isValidFilter &&=
              filter.isTimeParamSet ||
              filter.isFrequencyParamSet ||
              filter.isParametersParamSet ||
              filter.isTopicsParamSet
        })
      })

      return isValidFilter
    }
  },
  beforeDestroy() {
    this.$store.commit('segments/SET_TEMPLATE_ID', null)
  },
  setup() {
    const vm = getCurrentInstance().proxy
    let response
    const { organizationId, segmentId, templateId } = vm.$route.params

    const durationOptions = durationConst
    const eventState = eventStateConst
    const frequencyOptions = frequencyOptionsConst
    const withParameters = withParametersConst
    const parametersOperatorsConst = parameterOperatorsConst
    const parametersBooleanValues = parametersBooleanValuesConst

    const segmentationTable = []
    const maxDate = new Date()
    const eventTableId = eventTableIdDefault

    const formState = reactive({
      isSubmitted: false,
      isLoading: false,
      isFormLoaded: false,
      isPristine: true
    })

    const newSegment = !!segmentId || !!templateId

    const getSegmentModelCopy = JSON.parse(JSON.stringify(segmentModel))
    const getParameterModelCopy = () =>
      JSON.parse(JSON.stringify(parameterModel))
    const getFilterModelCopy = () => JSON.parse(JSON.stringify(filterModel))

    const segment = reactive({
      ...getSegmentModelCopy
    })
    onMounted(async () => {
      store.dispatch('getPropertiesByUserRole', { id: organizationId })
      store.dispatch('segments/getActivations', { organizationId })
      store.dispatch('segments/getParameterOperators', { organizationId })
      store.dispatch('segments/getFilterSegments', { organizationId })
      store.dispatch('segments/getTopics', { organizationId })
    })

    const organizations = computed(
      () => store.getters['segments/organizations']
    )
    const parameterOperators = computed(
      () => store.getters['segments/parameterOperators']
    )
    const filterSegments = computed(
      () => store.getters['segments/filterSegments']
    )

    const getSelectedDuration = (event, filter) => {
      if (filter.durationFirstDate?.length > 0) filter.durationFirstDate = ''
      if (filter.durationSecondDate?.length > 0) filter.durationSecondDate = ''
      filter.durationNumberParam = ''
      if (filter.durationNumberFormatParam?.length > 0)
        filter.durationNumberFormatParam = ''
      filter.isDateDisabled = true
      const { value } = event
      switch (value) {
        case 2:
        case 3:
          filter.isDurationDatepickerShown = true
          filter.isDurationLastParamShown = false
          filter.isDurationBetweenParamShown = false
          break
        case 4:
        case 6:
          filter.isDurationLastParamShown = true
          filter.isDurationDatepickerShown = false
          filter.isDurationBetweenParamShown = false
          break
        case 5:
          filter.isDurationBetweenParamShown = true
          filter.isDurationDatepickerShown = false
          filter.isDurationLastParamShown = false
          break
        default:
          filter.isDurationDatepickerShown = false
          filter.isDurationLastParamShown = false
          filter.isDurationBetweenParamShown = false
          break
      }
    }

    const getSelectedParameter = (event, param) => {
      param.parametersStringKeyModel = ''
      param.paramTagsModel = ['']
      if (param.selectedParameterOptionModel)
        param.selectedParameterOptionModel = ''
      if (param.parameterFirstDate?.length > 0) param.parameterFirstDate = ''
      if (param.parameterSecondDate?.length > 0) param.parameterSecondDate = ''
      const { columnType } = event
      switch (columnType) {
        case 10:
        case 16:
          param.isCustomParam = true
          break
        case 6:
        case 8:
          param.isStringParam = true
          param.isIntParam = false
          param.isCustomParam = false
          break
        case 1:
          param.isIntParam = true
          param.isStringParam = false
          param.isCustomParam = false
          break
        default:
          param.isCustomParam = false
          param.isStringParam = false
          param.isIntParam = false
          break
      }
    }

    const getSelectedFrequency = (event, filter) => {
      const { value } = event
      filter.showFrequencySecondParam = value === 5
      filter.frequencyFirstValue.value = ''
      filter.frequencySecondValue.value = ''
    }

    const datePickerValueChanged = (filter) => {
      filter.minDate = filter.durationFirstDate
      filter.isDateDisabled = false
      if (filter.durationSecondDate?.length > 0) filter.durationSecondDate = ''
    }

    const parameterDatePickerValueChanged = (param) => {
      if (param.parameterSecondDate?.length > 0) param.parameterSecondDate = ''
    }

    const triggerSave = async (id) => {
      formState.isSubmitted = true
      formState.isLoading = true

      saveSegment(organizationId, segment, id, vm, formState)
    }

    const removeBlock = (blockIndex, filterIndex) => {
      formState.isPristine = false
      const filters = segment.blocks[blockIndex].filters

      if (filters.length > 1) filters.splice(filterIndex, 1)
      else segment.blocks.splice(blockIndex, 1)
    }

    const allProperties = computed(() => store.getters.allUserProperties)
    const allTopics = computed(() => store.getters['segments/topics'])
    const isContextualTargetingEnabled = computed(
      () => store.getters['segments/isContextualTargetingEnable']
    )
    const activations = computed(() => store.getters['segments/activations'])
    let segmentParameters = ref([])

    const blocksObject = []
    const parameters = []

    onBeforeMount(async () => {
      await store.dispatch('segments/getOrganizations', { organizationId })
      const filterTables = await store.dispatch('segments/getSegmentTables', {
        organizationId,
        filterOrganizationId: organizationId
      })
      segment.blocks[0].filters[0].segmentationTableOptions = filterTables
      segmentationTable.push(...filterTables)

      if (!segmentId) {
        const parameters = await getSegmentParameters({
          segmentationTableModel: {
            id: eventTableId
          }
        })
        segment.blocks[0].filters[0].parameters.forEach((param) => {
          param.parameterOptions = parameters
        })
        segment.blocks[0].filters[0].filterOrganization.value =
          organizations.value.find((org) => org.code === organizationId)
      }
      if (segmentId || templateId) {
        const { data } = segmentId
          ? await segments.getSegment(organizationId, segmentId)
          : await segments.getSegmentTemplate(organizationId, templateId)

        response = data.data
        if (segmentId && !response.canEdit) vm.$router.push('/error/401')

        if (response) {
          if (segmentId) {
            segment.id = response.id
            segment.audienceProperties = response.properties
            segment.activations = response.activations
          } else {
            segment.activations = activations
          }
          segment.name.value = response.name
          segment.description.value = response.description
          for (const block of response.blocks) {
            const filtersObject = []
            for (const filter of block.filters) {
              const defaultParameters = await getSegmentParameters({
                ...filter,
                segmentationTableModel: { id: filter.table?.id || eventTableId }
              })
              const paramsObject = []
              const filterTables = await getSegmentTables(filter)
              const filterOrganization = filter.filterOrganization
                ? {
                    code: filter.filterOrganization.code,
                    name: filter.filterOrganization.name
                  }
                : organizations.value.find((org) => org.code === organizationId)
              const selectedSegmentTable = filter.table?.id
                ? filterTables.find((f) => f.id === filter.table.id) || {
                    name: filter.table.name,
                    id: filter.table.id
                  }
                : { name: 'Event', id: eventTableId }
              for (const param of filter.parameters) {
                const paramValues = []
                for (const value of param.parameterValues) {
                  paramValues.push({
                    parametersStringKeyModel: value.key,
                    paramTagsModel: value.value
                  })
                }
                paramsObject.push({
                  ...getParameterModelCopy(),
                  isBooleanParam: param.columnType === 14,
                  isDateParam:
                    param.columnType === 11 ||
                    param.columnType === columnTypes.datetime,
                  isParameterBetweenOperatorShown: param.operator === 14,
                  isSpecificParameter:
                    param.columnType === 8 || param.columnType === 9,
                  isCustomParam:
                    param.columnType === 10 || param.columnType === 16,
                  parameterOptions: segmentParameters,
                  parameterOptionsModel: {
                    columnId: param.columnId,
                    parameterName: param.name,
                    columnType: param.columnType
                  },
                  selectedParameterOptions: parameterOperators.value[
                    param.columnType
                  ].reduce((acc, rec) => {
                    acc.push({ name: rec.value, value: rec.key })
                    return acc
                  }, []),
                  selectedParameterOptionModel: parametersOperatorsConst.find(
                    (o) => o.value === param.operator
                  ),
                  ...((param.columnType === 10 ||
                    param.columnType === 6 ||
                    param.columnType === 8 ||
                    param.columnType === 16) && {
                    parametersStringOrCustomModel:
                      parametersOperatorsConst.find(
                        (t) => t.value === param.operator
                      ) ||
                      parametersBooleanValues.find(
                        (t) => t.value === param.operator
                      ),
                    parametersIntModel: ''
                  }),
                  ...(param.columnType === 1 && {
                    parametersIntModel: parametersBooleanValues.find(
                      (t) => t.value === param.operator
                    ),
                    parametersStringOrCustomModel: ''
                  }),
                  parametersStringKeyModel: param.parameterValues[0].key,
                  parametersBooleanModel: getParameterBooleanValue(param),
                  paramTagsModel: getParameterTagsModel(param),
                  ...getSegmentParameterDurationValues(param)
                })
              }
              if (+filter.type === filterTypes.segment)
                filtersObject.push({
                  filterType: +filter.type,
                  segment: {
                    value:
                      +filter.type === filterTypes.segment
                        ? filter.segment
                        : '',
                    errors: []
                  },
                  isDisabled: filter.isDisabled
                })
              else
                filtersObject.push({
                  ...getFilterModelCopy(),
                  filterType: +filter.type,
                  isDisabled: filter.isDisabled,
                  filterOrganization: { value: filterOrganization, errors: [] },
                  segmentationTableOptions: filterTables,
                  segmentationTableModel: selectedSegmentTable,
                  eventStateDefault: eventState.find(
                    (e) => e.value === filter.isPerformed
                  ),
                  isTimeParamSet: !!filter.durationOperator,
                  durationOperator: {
                    value: filter.durationOperator
                      ? durationOptions.find(
                          (d) => d.value === filter.durationOperator
                        )
                      : { text: 'Before', value: 2 },
                    errors: []
                  },
                  isDurationDatepickerShown: filter.durationOperator
                    ? filter.durationOperator === 2 ||
                      filter.durationOperator === 3 ||
                      filter.durationOperator === 5
                    : true,
                  isDurationBetweenParamShown: filter.durationOperator === 5,
                  isDurationLastParamShown:
                    filter.durationOperator === 4 ||
                    filter.durationOperator === 6,
                  ...getSegmentDurationValues(filter),
                  isFrequencyParamSet: !!filter.frequencyOperator,
                  frequencyOperator: {
                    value: filter.frequencyOperator
                      ? frequencyOptions.find(
                          (fr) => fr.value === filter.frequencyOperator
                        )
                      : { text: 'Exactly', value: 1 },
                    errors: []
                  },
                  showFrequencySecondParam: !!filter.frequencySecondValue,
                  frequencyFirstValue: {
                    value: filter.frequencyFirstValue,
                    errors: []
                  },
                  frequencySecondValue: {
                    value: filter.frequencySecondValue,
                    errors: []
                  },
                  isParametersParamSet: filter.parameters.length > 0,
                  withParametersDefault: filter.parameterLogicalOperator
                    ? withParameters.find(
                        (pa) => pa.value === filter.parameterLogicalOperator
                      )
                    : { text: 'all', value: 1 },
                  parameters: paramsObject,
                  defaultOptions: defaultParameters,
                  isTopicsParamSet: filter.topics.length,
                  topics: { value: filter.topics, errors: [] }
                })
            }
            blocksObject.push({
              filters: filtersObject
            })
            segment.blocks = blocksObject
            formState.isFormLoaded = true
          }
        }
      } else {
        segment.activations = activations
      }
    })

    const addFilter = (operatorType, blockIndex) => {
      const value = organizations.value.find(
        (org) => org.code === organizationId
      )
      if (operatorType === 'and') {
        segment.blocks.push({
          filters: [
            {
              ...getFilterModelCopy(),
              filterOrganization: { value, errors: [] },
              segmentationTableOptions: segmentationTable,
              max: maxDate,
              minSecondDate: maxDate,
              parameters: [
                {
                  ...getParameterModelCopy(),
                  parameterOptions: segmentParameters
                }
              ]
            }
          ]
        })
      } else {
        segment.blocks[blockIndex].filters.push({
          ...getFilterModelCopy(),
          filterOrganization: { value, errors: [] },
          segmentationTableOptions: segmentationTable,
          parameters: [
            {
              ...getParameterModelCopy(),
              parameterOptions: segmentParameters
            }
          ]
        })
      }
      formState.isPristine = false
    }

    const addSegment = (operatorType, blockIndex) => {
      if (operatorType === 'and') {
        segment.blocks.push({
          filters: [
            {
              filterType: filterTypes.segment,
              segment: { value: '', errors: [] }
            }
          ]
        })
      } else {
        segment.blocks[blockIndex].filters.push({
          filterType: filterTypes.segment,
          segment: { value: '', errors: [] }
        })
      }
      formState.isPristine = false
    }

    const addParameter = (blockIndex, filterIndex) => {
      const filter = segment.blocks[blockIndex].filters[filterIndex]
      filter.parameters.push({
        ...getParameterModelCopy(),
        parameterOptions:
          filter.parameters[filter.parameters.length - 1].parameterOptions
      })
      formState.isPristine = false
    }

    const removeParameter = (blockIndex, filterIndex, paramIndex) => {
      const filter = segment.blocks[blockIndex].filters[filterIndex]
      filter.parameters.splice(paramIndex, 1)

      if (filter.parameters.length === 0) {
        removeFilterParam('parameters', filter)
      }
      formState.isPristine = false
    }

    const addFilterParam = (filter, type) => {
      switch (type) {
        case 'time':
          filter.isTimeParamSet = true
          break
        case 'frequency':
          filter.isFrequencyParamSet = !filter.isFrequencyParamSet
          break
        case 'parameters':
          filter.isParametersParamSet = !filter.isParametersParamSet
          filter.parameters = [
            {
              ...getParameterModelCopy(),
              parameterOptions: filter.defaultOptions
            }
          ]
          break
        case 'topics':
          filter.isTopicsParamSet = true
          break
        default:
          break
      }
      handleFormInput(formState, [filter.filters])
    }

    const removeFilterParam = (type, filter) => {
      /* eslint no-param-reassign: "error" */
      switch (type) {
        case 'time':
          filter.isTimeParamSet = false
          filter.isDurationBetweenParamShown = false
          filter.isDurationDatepickerShown = true
          filter.isDurationLastParamShown = false
          filter.durationOperator = {
            value: { text: 'Before', value: 2 },
            errors: []
          }
          filter.durationFirstDate = ''
          filter.durationSecondDate = ''
          filter.durationNumberParam = ''
          filter.durationNumberParamFormat = ''
          handleFormInput(formState, [
            filter.durationOperator,
            filter.durationValue
          ])
          break
        case 'frequency':
          filter.isFrequencyParamSet = false
          filter.showFrequencySecondParam = false
          filter.frequencyOperator = {
            value: { text: 'Exactly', value: 1 },
            errors: []
          }
          filter.frequencyFirstValue.value = ''
          filter.frequencySecondValue.value = ''
          handleFormInput(formState, [
            filter.frequencyOperator,
            filter.frequencyFirstValue,
            filter.frequencySecondValue
          ])
          break
        case 'parameters':
          filter.isParametersParamSet = false
          handleFormInput(formState)
          break
        case 'topics':
          filter.isTopicsParamSet = false
          filter.topics.value = []
          handleFormInput(formState, [filter.topics])
          break
        default:
          break
      }
    }

    const cancel = () => {
      vm.$router.push({ name: 'segments' })
    }

    const openSaveModal = () => {
      if (formState.isPristine) cancel()
      else vm.$bvModal.show('save-changes-modal')
    }

    const onFilterOrganizationChange = async (filter) => {
      filter.segmentationTableModel = ''
      filter.segmentationTableOptions = null
      if (filter.isParametersParamSet) {
        filter.parameters = [
          { ...getParameterModelCopy(), parameterOptions: segmentParameters }
        ]
        filter.isParametersParamSet = false
      }
      const code = filter.filterOrganization.value.code
      const response = await store.dispatch('segments/getSegmentTables', {
        organizationId,
        filterOrganizationId: code
      })
      filter.segmentationTableOptions = response
    }

    const onSegmentTableChange = async (filter) => {
      if (filter.isParametersParamSet) {
        filter.parameters = [
          { ...getParameterModelCopy(), parameterOptions: segmentParameters }
        ]
        filter.isParametersParamSet = false
      }
      await getSegmentParameters(filter)
    }

    const setParameterOptions = (parameterSelected) => {
      parameterSelected.selectedParameterOptions = parameterOperators.value[
        +parameterSelected.parameterOptionsModel.columnType
      ].reduce((acc, rec) => {
        acc.push({ name: rec.value, value: rec.key })
        return acc
      }, [])

      parameterSelected.isDateParam =
        parameterSelected.parameterOptionsModel.columnType ===
          columnTypes.datetime ||
        parameterSelected.parameterOptionsModel.columnType === 11
      parameterSelected.isBooleanParam =
        parameterSelected.parameterOptionsModel.columnType === 14
    }

    const handleInputsBasedOnOperatorChosen = (parameter) => {
      if (parameter.parameterFirstDate?.length > 0)
        parameter.parameterFirstDate = ''
      if (parameter.parameterSecondDate?.length > 0)
        parameter.parameterSecondDate = ''
      parameter.isParameterBetweenOperatorShown =
        parameter.selectedParameterOptionModel.value === 14
    }

    const getSegmentParameters = async (filter) => {
      const id = filter.segmentationTableModel.id
      if (id) {
        const parameters = await store.dispatch('segments/getParameters', {
          organizationId,
          tableId: id
        })
        segmentParameters = parameters.reduce((acc, rec) => {
          if (!rec.groupName) acc.unshift(...rec.targetingParameters)
          else
            acc.push(
              { parameterName: rec.groupName, isParent: true },
              ...rec.targetingParameters
            )
          return acc
        }, [])
        filter.parameters?.forEach((param) => {
          param.parameterOptions = segmentParameters
        })
        filter.defaultOptions = segmentParameters
        return segmentParameters
      }
    }

    const getSegmentTables = async (filter) => {
      const code = filter.filterOrganization?.code || organizationId
      const response = await store.dispatch('segments/getSegmentTables', {
        organizationId,
        filterOrganizationId: code
      })
      filter.segmentationTableOptions = response
      return response
    }

    const updateProperties = (selected) => {
      segment.audienceProperties = selected
      handleFormInput(formState)
    }

    const updateTopics = (selected, filter) => {
      filter.topics.value = selected
      handleFormInput(formState, [filter.topics])
    }

    return {
      allProperties,
      response,
      segment,
      audienceProperties: [],
      segmentId,
      maxDate,
      parameters,
      formState,
      newSegment,
      createRequestObject,
      addFilterParam,
      removeFilterParam,
      addFilter,
      addSegment,
      cancel,
      openSaveModal,
      triggerSave,
      getSelectedDuration,
      getSelectedParameter,
      getSelectedFrequency,
      addParameter,
      removeBlock,
      removeParameter,
      datePickerValueChanged,
      onSegmentTableChange,
      setParameterOptions,
      handleInputsBasedOnOperatorChosen,
      parameterDatePickerValueChanged,
      maximumDate,
      secondDateHandler,
      isDateTimeType,
      handleFormInput,
      organizations,
      onFilterOrganizationChange,
      getSegmentTables,
      filterTypes,
      filterSegments,
      updateProperties,
      allTopics,
      isContextualTargetingEnabled,
      updateTopics,
      whitelabel
    }
  }
}
</script>

<style lang="scss">
@import '@/assets/scss/views/segments/segmentCreate.scss';
@import '@/assets/scss/views/shared';
@import 'vue-date-pick/src/vueDatePick.scss';
</style>
