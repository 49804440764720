var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c(
        "section",
        {
          staticClass: "properties-list segmentation-list",
          class: { blur: !_vm.isEnabled && !_vm.isLoading },
        },
        [
          _c(
            "div",
            { staticClass: "table-list__header" },
            [
              _c("PrimaryCreateButton", {
                attrs: {
                  id: "create-topic",
                  name: _vm.$t("audience.topics.createNew"),
                  "can-add": _vm.canCreate,
                  "is-loading": _vm.isLoading,
                  "click-handler": _vm.createTopic,
                },
              }),
              _c("SearchInputField", {
                attrs: { id: "search-topics", data: _vm.table },
              }),
            ],
            1
          ),
          _c(
            "div",
            { staticClass: "table__wrapper" },
            [
              _c("CustomTable", {
                attrs: {
                  data: _vm.table,
                  "type-of-table": "topics",
                  "is-pagination-enabled": true,
                  "is-loading": _vm.isLoading,
                  "empty-state-action": {
                    canAdd: _vm.canCreate,
                    action: _vm.createTopic,
                  },
                },
                on: { "update-table": _vm.getData },
                scopedSlots: _vm._u([
                  {
                    key: "row-data",
                    fn: function ({ data }) {
                      return [_c("TopicsRow", { attrs: { data: data } })]
                    },
                  },
                ]),
              }),
            ],
            1
          ),
        ]
      ),
      !_vm.isEnabled && !_vm.isLoading
        ? _c("div", { staticClass: "info" }, [
            _c("img", {
              attrs: {
                alt: "Vue logo",
                src: require("@/assets/svgs/i-warning.svg"),
              },
            }),
            _c("span", [
              _vm._v(
                _vm._s(_vm.$t("audience.topics.notEnabledMessage")) +
                  " " +
                  _vm._s(_vm.$t("contactAdmin"))
              ),
            ]),
          ])
        : _vm._e(),
      _c("DeleteModal", {
        attrs: {
          title: _vm.$t("audience.topics.deleteTopic"),
          type: _vm.$t("Topic"),
        },
        on: { delete: _vm.triggerDelete },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }