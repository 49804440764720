// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.bisko-app__audience section.segments-list .vgt-wrap .vgt-responsive table thead {
  background: white;
  border-bottom: 1px solid #edeff3;
}
.bisko-app__audience section.segments-list .vgt-wrap .vgt-responsive table tbody td {
  vertical-align: middle;
}
.bisko-app__audience section.segments-list .table__wrapper {
  min-height: inherit;
}
.bisko-app__audience section.segments-list .table__wrapper .vgt-inner-wrap {
  min-height: calc(100vh - 180px);
}
.bisko-app__audience section.segments-list .user-roles__pagination {
  font-size: 0.875rem;
  padding: 8px 0;
  position: relative;
  bottom: 0;
  width: 100%;
  margin: 0;
}
@media (max-width: 576px) {
.bisko-app__audience section.segments-list table.vgt-table tbody tr td {
    padding: 21px 12px;
}
}
.bisko-app__audience .text__capitalize {
  text-transform: capitalize;
}
.bisko-app__audience .segment__table {
  width: 100%;
}
.bisko-app__audience .segment__table td {
  max-width: 250px;
}`, ""]);
// Exports
export default ___CSS_LOADER_EXPORT___;
