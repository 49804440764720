<template>
  <section class="segments-list">
    <div class="table-list__header">
      <primary-create-dropdown
        id="create-segment"
        :name="$t('accountManagement.createAudienceSegment')"
        :can-add="canCreate"
        :is-loading="isLoading"
        :create-new-handler="addNewSegment"
      />
      <div class="export-and-search">
        <export-button
          id="export-segments"
          :is-loading="isLoading"
          :get-file="getFile"
          :exporting="exporting"
          :has-data="table.totalRecords > 0"
        />
        <FilterList
          ref="filterSegments"
          :filters="tableFilters"
          :applied-filters="table.filters"
          :loading="filtersLoading"
          :has-data="table.totalRecords > 0"
          @update-listing="getData"
        />
        <SearchInputField id="search-segments" :data="table" />
      </div>
    </div>
    <div v-if="table.filters.length" class="table-list__header">
      <ActiveFilters
        :applied-filters="table.filters"
        :filters="tableFilters"
        @remove-filter="removeFilter"
      />
    </div>

    <div class="table__wrapper">
      <CustomTable
        :data="table"
        type-of-table="segments"
        :is-pagination-enabled="true"
        :is-loading="isLoading"
        :empty-state-action="{
          canAdd: canCreate,
          action: () =>
            $router.push({
              name: 'segments-create',
              params: { organizationId }
            })
        }"
        @update-table="getData"
        @clear-filters="clearFilters"
      >
        <template #row-data="{ data }">
          <SegmentsRow :key="data.row.code" :data="data" />
        </template>
      </CustomTable>
    </div>

    <!-- Pre-defined templates modal -->
    <CustomModal
      :select-options="selectOptions"
      :modal-title="$t('audience.chooseSegmentTemplate')"
      type="segment"
      @on-create="addNewSegment"
    />

    <DeleteModal
      :title="$t('audience.deleteAudienceSegment')"
      :type="$t('Segment')"
      @delete="triggerDelete"
    />

    <!-- Duplicate Audience Modal -->
    <div class="user-roles-tables__duplicate-modal">
      <b-modal
        id="modal-small-duplicate"
        :ok-title="$t('yes')"
        :cancel-title="$t('no')"
        modal-class="duplicate__modal"
        centered
        size="md"
        :title="$t('audience.duplicateSegmentTitle')"
        no-enforce-focus
        @ok="triggerDuplicate"
      >
        <span>{{ $t('audience.duplicateSegment') }} ?</span>
      </b-modal>
    </div>
  </section>
</template>

<script>
/* eslint arrow-body-style: "off" */
import { segments } from '@/api'
import SegmentsRow from '@/components/table/table-data/SegmentsRow.vue'
import router from '@/router'
import store from '@/store'
import {
  ActiveFilters,
  // utility components
  CustomModal,
  CustomTable,
  DeleteModal,
  ExportButton,
  FilterList,
  PrimaryCreateDropdown,
  SearchInputField,
  // utility functions
  createDownloadLink,
  handleLastRowDelete
} from '@nodus/utilities-front'
import { computed, getCurrentInstance, onMounted, ref } from 'vue'

export default {
  components: {
    CustomTable,
    SearchInputField,
    PrimaryCreateDropdown,
    ExportButton,
    FilterList,
    ActiveFilters,
    CustomModal,
    DeleteModal,
    SegmentsRow
  },
  setup() {
    const vm = getCurrentInstance().proxy
    const { organizationId } = vm.$route.params
    const { remove, duplicate } = segments

    const table = computed(() => store.getters['segments/table'])
    const tableFilters = computed(() => store.getters['segments/tableFilters'])
    const IDtoDelete = computed(() => store.getters['segments/IDtoDelete'])
    const IDtoDuplicate = computed(
      () => store.getters['segments/IDtoDuplicate']
    )
    const canCreate = computed(() => store.getters['segments/canCreate'])
    const isLoading = ref(true)
    const filtersLoading = ref(true)
    const selectOptions = computed(
      () => store.getters['segments/audienceTemplates']
    )

    onMounted(async () => {
      await store
        .dispatch('segments/getTableFilters', { organizationId })
        .then(() => (filtersLoading.value = false))
      store.dispatch('segments/getAudienceTemplates', { organizationId })
      await store.dispatch('segments/getTable', { id: organizationId })
      isLoading.value = false
    })

    const getData = async (filters) => {
      if (filters) {
        localStorage.setItem(
          `segmentFilters-${organizationId}`,
          JSON.stringify(filters)
        )
        store.commit('segments/APPLY_TABLE_FILTERS', filters)
        store.commit('segments/SET_TABLE_PAGE', 1)
      }
      isLoading.value = true
      await store.dispatch('segments/getTable', { id: organizationId })
      isLoading.value = false
    }

    const triggerDelete = async () => {
      handleLastRowDelete(table.value, store, 'segments/SET_TABLE_PAGE')
      await remove(organizationId, IDtoDelete.value)

      getData()
    }

    const triggerDuplicate = async () => {
      await duplicate(organizationId, IDtoDuplicate.value)

      getData()
    }

    const addNewSegment = (templateId) => {
      if (templateId) {
        store.commit('segments/SET_TEMPLATE_ID', templateId)
      }
      router.push({
        name: 'segments-create',
        params: { organizationId, templateId }
      })
    }

    const exporting = ref(false)

    const getFile = async (format) => {
      exporting.value = true
      const fileName = `Segments_${organizationId}.${
        format === 1 ? 'xlsx' : 'csv'
      }`
      const response = await store.dispatch('segments/getFile', {
        id: organizationId,
        format
      })
      createDownloadLink(response, fileName)
      exporting.value = false
    }

    const removeFilter = (filter) => vm.$refs.filterSegments.clearFilter(filter)
    const clearFilters = () => {
      isLoading.value = true
      vm.$refs.filterSegments.clearFilters()
    }

    return {
      organizationId,
      table,
      canCreate,
      triggerDelete,
      triggerDuplicate,
      addNewSegment,
      isLoading,
      selectOptions,
      getData,
      getFile,
      exporting,
      tableFilters,
      filtersLoading,
      removeFilter,
      clearFilters
    }
  }
}
</script>

<style lang="scss">
@import '@/assets/scss/views/segments';
</style>
