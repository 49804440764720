// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.bisko-app__audience .keywords__table .vgt-wrap .vgt-responsive table thead {
  position: sticky;
  top: 0;
  z-index: 9;
}
.bisko-app__audience .keywords__table .vgt-wrap .vgt-responsive table thead th span {
  font-size: 13px;
}
.bisko-app__audience .keywords__table table thead tr th:not(:first-child) {
  padding-left: 6px;
}
.bisko-app__audience .keywords__table table thead tr th:not(:first-child)::before, .bisko-app__audience .keywords__table table thead tr th:not(:first-child)::after {
  left: -6px;
}
.bisko-app__audience .keywords__table .vgt-wrap .vgt-responsive table tbody td {
  font-size: 13px;
  word-break: break-all;
  line-height: 18px;
  padding: 12px 6px 8px 16px !important;
}
.bisko-app__audience .keywords__table .vgt-wrap .vgt-responsive table tbody td:not(:first-child) {
  color: #667c99;
  padding-left: 6px;
}
.bisko-app__audience .exclude__column {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.bisko-app__audience .exclude__column svg {
  color: #a3b0c2 !important;
}
.bisko-app__audience .remove-keyword {
  cursor: pointer;
}`, ""]);
// Exports
export default ___CSS_LOADER_EXPORT___;
