var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "add-keyword add-keyword-suggested" }, [
    _c("div", { staticClass: "suggested__container--flex" }, [
      _c("div", { staticClass: "suggested-keywords__content" }, [
        _c(
          "div",
          { staticClass: "select-property" },
          [
            _c("span", { staticClass: "enter-keyword__header" }, [
              _vm._v(_vm._s(_vm.$t("audience.topics.suggestedKeywords"))),
            ]),
            _c("MultipleProperties", {
              attrs: { id: "select-properties", options: _vm.propertiesList },
              on: { "update-data": _vm.updateData },
            }),
          ],
          1
        ),
        !_vm.isSuggestedLoading
          ? _c(
              "div",
              { staticClass: "keywords-scrollable__area" },
              [
                _vm.suggestedKeywordList && _vm.suggestedKeywordList.length > 0
                  ? _c(
                      "transition-group",
                      { attrs: { name: "suggested-keyword-list" } },
                      _vm._l(
                        _vm.suggestedKeywordList,
                        function (keyword, index) {
                          return _c(
                            "div",
                            { key: keyword, staticClass: "keyword__row" },
                            [
                              _c(
                                "span",
                                {
                                  staticClass: "keyword__name",
                                  class: {
                                    "keyword__name--disabled":
                                      _vm.doesKeywordExist(keyword),
                                  },
                                },
                                [
                                  _c(
                                    "span",
                                    { attrs: { id: `keyword-${index}` } },
                                    [_vm._v(_vm._s(keyword))]
                                  ),
                                  !_vm.doesKeywordExist(keyword)
                                    ? _c(
                                        "span",
                                        {
                                          staticClass: "add-suggested__keyword",
                                          attrs: { id: `add-keyword-${index}` },
                                          on: {
                                            click: function ($event) {
                                              return _vm.addSuggestedKeyword(
                                                keyword,
                                                index
                                              )
                                            },
                                          },
                                        },
                                        [
                                          _c("img", {
                                            attrs: {
                                              src: require("@/assets/svgs/plus.svg"),
                                            },
                                          }),
                                        ]
                                      )
                                    : _c(
                                        "span",
                                        {
                                          staticClass:
                                            "add-suggested__keyword add-suggested__keyword-info",
                                          attrs: {
                                            id: `keyword-information-${index}`,
                                          },
                                        },
                                        [
                                          _c("img", {
                                            attrs: {
                                              src: require("@/assets/svgs/InfoAltGreen.svg"),
                                            },
                                          }),
                                        ]
                                      ),
                                  _vm.doesKeywordExist(keyword)
                                    ? _c(
                                        "b-tooltip",
                                        {
                                          attrs: {
                                            target: `keyword-information-${index}`,
                                            triggers: "hover",
                                            placement: "top",
                                          },
                                        },
                                        [
                                          _vm._v(
                                            " " +
                                              _vm._s(
                                                _vm.$t(
                                                  "audience.topics.keywordAlreadyExists"
                                                )
                                              ) +
                                              "! "
                                          ),
                                        ]
                                      )
                                    : _vm._e(),
                                  _c(
                                    "b-tooltip",
                                    {
                                      attrs: {
                                        target: `keyword-${index}`,
                                        triggers: "hover",
                                        placement: "top",
                                        boundary: "viewport",
                                      },
                                    },
                                    [_vm._v(" " + _vm._s(keyword) + " ")]
                                  ),
                                ],
                                1
                              ),
                            ]
                          )
                        }
                      ),
                      0
                    )
                  : _c("div", { staticClass: "no-suggested__container" }, [
                      _c("span", { staticClass: "no-suggested__text" }, [
                        _vm._v(
                          _vm._s(
                            _vm.$t("audience.topics.missingSuggestedKeywords")
                          ) + "!"
                        ),
                      ]),
                    ]),
              ],
              1
            )
          : _vm._e(),
        _vm.isSuggestedLoading
          ? _c(
              "div",
              { staticClass: "loading__container" },
              [_c("LoadingBar")],
              1
            )
          : _vm._e(),
      ]),
      _c("div", { staticClass: "add-keywords__footer" }, [
        _c(
          "span",
          {
            class: { "span--disabled": _vm.shouldDisableAddAll() },
            attrs: { id: "add-all-suggested" },
            on: {
              click: function ($event) {
                _vm.shouldDisableAddAll() ? {} : _vm.addAllSuggested()
              },
            },
          },
          [_vm._v(_vm._s(_vm.$t("addAll")))]
        ),
      ]),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }