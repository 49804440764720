var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("section", [
    !_vm.isLoading
      ? _c(
          "div",
          { staticClass: "view-screen__container" },
          [
            _c(
              "div",
              { staticClass: "container__wrapper container__wrapper--height" },
              [
                _vm.integration
                  ? _c(
                      "div",
                      { staticClass: "integration-container__content" },
                      [
                        _c(
                          "div",
                          {
                            staticClass:
                              "integration-container__content-header integration__header-container-view",
                          },
                          [
                            _c(
                              "div",
                              {
                                staticClass:
                                  "integration-container__content-header__image",
                              },
                              [
                                _c("img", {
                                  attrs: { src: _vm.integration.imageUrl },
                                }),
                              ]
                            ),
                            _c(
                              "div",
                              {
                                staticClass:
                                  "integration-container__content-header__text",
                              },
                              [
                                _c(
                                  "span",
                                  { staticClass: "integration__header-name" },
                                  [_vm._v(_vm._s(_vm.integration.type))]
                                ),
                                _c(
                                  "span",
                                  { staticClass: "integration__description" },
                                  [_vm._v(_vm._s(_vm.integration.description))]
                                ),
                              ]
                            ),
                          ]
                        ),
                        _c(
                          "div",
                          {
                            staticClass: "integration-container__content-body",
                          },
                          [
                            _c("div", { staticClass: "item__container" }, [
                              _c("span", { staticClass: "template__text" }, [
                                _vm._v(_vm._s(_vm.$t("Name")) + ":"),
                              ]),
                              _c("span", { staticClass: "record__text" }, [
                                _vm._v(_vm._s(_vm.integration.name)),
                              ]),
                            ]),
                            _vm._l(
                              _vm.integration.parameters,
                              function (parameter, index) {
                                return _c("div", { key: index }, [
                                  _c(
                                    "div",
                                    { staticClass: "item__container" },
                                    [
                                      _c(
                                        "span",
                                        { staticClass: "template__text" },
                                        [_vm._v(_vm._s(parameter.name) + ":")]
                                      ),
                                      _c(
                                        "span",
                                        { staticClass: "record__text" },
                                        [_vm._v(_vm._s(parameter.value))]
                                      ),
                                    ]
                                  ),
                                ])
                              }
                            ),
                          ],
                          2
                        ),
                      ]
                    )
                  : _vm._e(),
              ]
            ),
            _c("Footer", {
              attrs: { "view-mode": "" },
              on: {
                back: function ($event) {
                  return _vm.cancel()
                },
              },
            }),
          ],
          1
        )
      : _c("div", { staticClass: "loading__container" }, [_c("LoadingBar")], 1),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }