var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", [
    _vm.data.column.field === "created"
      ? _c("div", { staticClass: "block-elem" }, [
          _vm.data.row.created
            ? _c("span", [
                _vm._v(" " + _vm._s(_vm.formatDate(_vm.data.row.created))),
              ])
            : _vm._e(),
        ])
      : _vm.data.column.field === "name"
      ? _c(
          "span",
          {
            staticClass: "integration__table-img-container",
            class: { "dark-gray-color": _vm.data.row.canEdit },
          },
          [
            _c("div", { staticClass: "integration__table-img-content" }, [
              _c("img", { attrs: { src: _vm.data.row.imageUrl } }),
            ]),
            _c("ReadMore", {
              attrs: {
                text: _vm.data.row.name,
                to: _vm.data.row.canEdit
                  ? {
                      name: "integration-view",
                      params: {
                        organizationId: _vm.organizationId,
                        activationId: _vm.data.row.id,
                      },
                    }
                  : null,
              },
            }),
          ],
          1
        )
      : _vm.data.column.field === "status"
      ? _c("span", { staticClass: "status-and-actions" }, [
          _c(
            "div",
            [
              _c("b-form-checkbox", {
                staticClass: "custom-control-primary",
                attrs: {
                  id: `switch-status-${_vm.data.row.id}`,
                  disabled: !_vm.data.row.canEdit || _vm.isLoading,
                  checked: _vm.data.row.status ? true : false,
                  name: "check-button",
                  switch: "",
                },
                on: {
                  change: function ($event) {
                    _vm.data.row.canEdit
                      ? _vm.setStatus(
                          _vm.data.row.id,
                          _vm.data.row.status ? 0 : 1
                        )
                      : {}
                  },
                },
              }),
            ],
            1
          ),
        ])
      : _c("span", [
          _vm._v(
            " " + _vm._s(_vm.data.formattedRow[_vm.data.column.field]) + " "
          ),
        ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }