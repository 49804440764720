import axiosIns from '@/libs/axios'
import {
  queryReprocessOperatorConst,
  queryTimeUnitConst,
  queryTimeUnitDefaultConst
} from '@/views/queries/queryConfig'
import {
  destructJSONObject,
  formatFilterQuery,
  formatRequestQuery,
  prepareTableFilters
} from '@nodus/utilities-front'

export default {
  namespaced: true,
  state: {
    table: {
      columns: [
        {
          label: 'Name',
          field: 'name',
          thClass: 'custom-name-header name-column',
          width: '30%'
        },
        {
          label: 'Code',
          field: 'code',
          type: 'number',
          width: '10%'
        },
        {
          label: 'Date',
          field: 'insertDateTime',
          thClass: 'custom-data-class',
          width: '30%'
        },
        {
          label: 'Users',
          field: 'userCount',
          type: 'number',
          width: '10%'
        },
        {
          label: 'Integrations',
          field: 'integrations',
          thClass: 'not-sortable-headers',
          sortable: false,
          width: '10%'
        },
        {
          label: 'Actions',
          field: 'actions',
          thClass: 'not-sortable-headers',
          sortable: false,
          width: '10%'
        }
      ],
      rows: [],
      searchTerm: '',
      totalRecords: 0,
      page: 1,
      perPage: 10,
      orderField: '',
      orderType: '',
      filters: []
    },
    tableFilters: [],
    canCreate: null,
    querySegment: [],
    IDtoDelete: -1,
    IDtoDuplicate: -1
  },
  getters: {
    table: (state) => state.table,
    tableFilters: (state) => state.tableFilters,
    canCreate: (state) => state.canCreate,
    querySegment: (state) => state.querySegment,
    IDToDelete: (state) => state.IDtoDelete,
    IDToDuplicate: (state) => state.IDtoDuplicate
  },
  actions: {
    async getTable({ commit, state }, { id }) {
      const queryParamString = formatRequestQuery(
        state.table.searchTerm,
        state.table.page,
        state.table.perPage,
        state.table.orderField,
        state.table.orderType
      )
      const filterQuery = formatFilterQuery(
        state.tableFilters,
        state.table.filters
      )
      const response = await axiosIns.get(
        `/api/v2/${id}/segments/customquery?${queryParamString}${filterQuery}`
      )

      commit('SET_TABLE_ROWS', destructJSONObject(response, 'customSegments'))
      commit('SET_TOTAL_RECORDS', destructJSONObject(response, 'totalCount'))
      commit('SET_CAN_CREATE', destructJSONObject(response, 'canCreate'))
    },
    async getTableFilters({ commit }, { organizationId }) {
      const response = await axiosIns.get(
        `/api/v1/${organizationId}/segments/customquery/filters`
      )

      commit('SET_TABLE_FILTERS', {
        tableFilters: destructJSONObject(response, 'filters'),
        organizationId
      })
    },
    async getQuerySegment({ commit }, { id, segmentId }) {
      const { data } = await axiosIns.get(
        `/api/v1/${id}/segments/${segmentId}/customquery`
      )
      commit('SET_QUERY_SEGMENT', data.data)
    },
    async getFile({ state }, { id, format }) {
      const queryParamString = formatRequestQuery(
        state.table.searchTerm,
        state.table.page,
        state.table.perPage,
        state.table.orderField,
        state.table.orderType
      )
      const filterQuery = formatFilterQuery(
        state.tableFilters,
        state.table.filters
      )
      return axiosIns
        .get(
          `/api/v1/${id}/segments/customquery/details/file?FileDownloadFormat=${format}&${queryParamString}${filterQuery}`,
          { responseType: 'blob' }
        )
        .then((response) => response.data)
    }
  },
  mutations: {
    SET_TABLE_ROWS(state, segments) {
      state.table.rows = segments
    },
    SET_CAN_CREATE(state, create) {
      state.canCreate = create
    },
    SET_QUERY_SEGMENT(state, segment) {
      state.querySegment = {
        ...segment,
        name: { value: segment.name, errors: [] },
        description: { value: segment.description, errors: [] },
        query: { value: segment.query, errors: [] },
        reprocessValue: { value: segment.reprocessValue, errors: [] }
      }

      state.querySegment.reprocessOperator = queryReprocessOperatorConst.find(
        (ro) => ro.value === segment.reprocessOperator
      )
      if (segment.reprocessTimeUnit > 0) {
        state.querySegment.reprocessTimeUnit = queryTimeUnitConst.find(
          (tu) => tu.value === segment.reprocessTimeUnit
        )
      } else {
        state.querySegment.reprocessTimeUnit = queryTimeUnitDefaultConst
        state.querySegment.reprocessValue.value = 2
      }
    },
    SET_ID_TO_DELETE(state, id) {
      state.IDtoDelete = id
    },
    SET_ID_TO_DUPLICATE(state, id) {
      state.IDtoDuplicate = id
    },
    SET_TOTAL_RECORDS(state, totalRecords) {
      state.table.totalRecords = totalRecords
    },
    SET_TABLE_PAGE(state, page) {
      state.table.page = page
    },
    SET_TABLE_FILTERS(state, { tableFilters, organizationId }) {
      const storageKey = `queryFilters-${organizationId}`
      const localFilters = JSON.parse(localStorage.getItem(storageKey) || '[]')
      const { filters, appliedFilters } = prepareTableFilters(
        tableFilters,
        localFilters
      )

      localStorage.setItem(storageKey, JSON.stringify(appliedFilters))
      state.table.filters = appliedFilters
      state.tableFilters = filters
    },
    APPLY_TABLE_FILTERS(state, filters) {
      state.table.filters = filters
    }
  }
}
