<template>
  <section v-if="!isLoading" class="form__wrapper">
    <div class="container__wrapper">
      <div class="container__header">
        <h5 class="container__header-title">{{ $t('configuration') }}</h5>
      </div>
      <div class="integration-container__content">
        <div class="integration-container__content-header">
          <div class="integration-container__content-header__image">
            <img :src="integration.imageUrl" />
          </div>
          <div class="integration-container__content-header__text">
            <span>{{ integration.integrationName }}</span>
            <span class="integration__description">{{
              integration.description
            }}</span>
          </div>
        </div>
        <div class="integration-container__content-body">
          <div
            v-if="!!integration.name"
            class="integration-container__content-inputs"
          >
            <b-col lg="12" class="input__wrapper">
              <label>{{ $t('Name') }} *</label>
              <b-form-input
                id="integration-name"
                v-model.trim="integration.name.value"
                :placeholder="$t('audience.enterName')"
                class="form__input"
                :class="{
                  'form__input--invalid': integration.name.errors.length > 0
                }"
                required
                @input="handleFormInput(state, [integration.name])"
              />
            </b-col>
            <div
              v-for="(error, index) in integration.name.errors"
              :key="index"
              class="integration-container__content-inputs__errors"
            >
              {{ error }}
            </div>
            <div
              v-for="parameter in integration.parameters"
              :key="parameter.parameterId"
            >
              <b-col lg="12" class="input__wrapper">
                <label>{{
                  parameter.parameterName + (parameter.required ? ' *' : '')
                }}</label>
                <b-form-input
                  :id="`integration-${parameter.parameterName}`"
                  v-model.trim="parameter.value.value"
                  :placeholder="'Enter ' + parameter.parameterName + '...'"
                  class="form__input"
                  :class="{
                    'form__input--invalid': parameter.value.errors.length > 0
                  }"
                  :required="parameter.required"
                  @input="handleFormInput(state, [parameter.value])"
                />
              </b-col>
              <div
                v-for="(error, index) in parameter.value.errors"
                :key="index"
                class="integration-container__content-inputs__errors"
              >
                {{ error }}
              </div>
            </div>
          </div>
        </div>
        <div v-if="integration.parameterValues">
          <div
            v-for="(error, index) in integration.parameterValues.errors"
            :key="index"
            class="integration-container__content-inputs__errors"
          >
            {{ error }}
          </div>
        </div>
      </div>
      <span v-if="generalError" class="integration__general-error text--red">
        <GjIcon name="InfoAlt" size="22" />
        {{ generalError }}
      </span>
    </div>
    <Footer
      :is-loading="isLoading"
      :submitted="isLoading"
      :cancel-id="`cancel-create-integration`"
      :create-id="`add-integration`"
      @back="cancel()"
      @create="saveIntegration(integration.integrationParameters)"
    />
  </section>
  <div v-else class="loading__container">
    <LoadingBar />
  </div>
</template>

<script>
import { integrations } from '@/api'
import store from '@/store'
import {
  Footer,
  GjIcon,
  LoadingBar,
  appendErrors,
  confirmChanges,
  handleFormInput,
  removeErrors,
  unFlattenObject
} from '@nodus/utilities-front'
import { BCol, BFormInput } from 'bootstrap-vue'
import { computed, getCurrentInstance, onBeforeMount, reactive, ref } from 'vue'
/* eslint arrow-body-style: "off" */

export default {
  name: 'IntegrationCreate',
  components: {
    BCol,
    BFormInput,
    LoadingBar,
    GjIcon,
    Footer
  },
  async beforeRouteLeave(to, from, next) {
    if (!this.state.submitted && !this.state.isPristine) {
      const response = await confirmChanges(this.$bvModal)
      next(response)
    } else {
      next()
    }
  },
  setup() {
    const vm = getCurrentInstance().proxy
    const { organizationId, integrationId } = vm.$route.params
    const isLoading = ref(false)
    const state = reactive({
      submitted: false,
      isPristine: true
    })

    const generalError = ref('')

    const integration = computed(() => store.getters['integrations/parameters'])

    onBeforeMount(async () => {
      isLoading.value = true
      await store.dispatch('integrations/getParameters', {
        id: organizationId,
        integrationId
      })
      isLoading.value = false
    })

    const saveIntegration = async () => {
      const requestModel = {
        integrationId,
        name: integration.value.name.value,
        parameters: integration.value.parameters.reduce((acc, curr) => {
          acc.push({
            parameterId: curr.parameterId,
            value: curr.value.value
          })
          return acc
        }, [])
      }
      generalError.value = ''
      state.submitted = true
      const {
        data: { errors, success, validationMessage }
      } = await integrations.createIntegration(organizationId, requestModel)

      if (!success) {
        const errorsObject = unFlattenObject(errors)

        removeErrors(integration.value)
        appendErrors(integration.value, errorsObject)
        state.submitted = false
      } else if (success && validationMessage)
        generalError.value = validationMessage
      else vm.$router.push({ name: 'integrations' })
    }

    const cancel = () => {
      vm.$router.push({ name: 'integrations' })
    }

    return {
      integration,
      saveIntegration,
      cancel,
      state,
      isLoading,
      handleFormInput,
      generalError
    }
  }
}
</script>

<style lang="scss">
@import '@/assets/scss/views/integrations';
@import '@/assets/scss/views/shared';
</style>
