export default [
  {
    path: '/bisko/:organizationId/audience/integrations',
    name: 'integrations',
    component: () => import('@/views/integrations/Integrations.vue'),
    meta: {
      navActiveLink: 'integrations',
      pageTitle: 'Integrations',
      breadcrumb: [
        {
          text: 'Audience'
        },
        {
          text: 'Integrations',
          active: true
        }
      ]
    }
  },
  {
    path: '/bisko/:organizationId/audience/integration/:activationId/view',
    name: 'integration-view',
    component: () => import('@/views/integrations/IntegrationView.vue'),
    meta: {
      pageTitle: 'Integration',
      breadcrumb: [
        {
          text: 'Audience'
        },
        {
          text: 'Integrations',
          to: '/integrations'
        },
        {
          text: 'View Integration',
          active: true
        }
      ],
      navActiveLink: 'integrations'
    }
  },
  {
    path: '/bisko/:organizationId/audience/integration/:integrationId/create',
    name: 'integration-create',
    component: () => import('@/views/integrations/IntegrationCreate.vue'),
    meta: {
      pageTitle: 'Integration',
      breadcrumb: [
        {
          text: 'Audience'
        },
        {
          text: 'Integrations',
          to: '/integrations'
        },
        {
          text: 'Add Integration',
          active: true
        }
      ],
      navActiveLink: 'integrations'
    }
  }
]
