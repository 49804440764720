var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("section", [
    _c("div", { staticClass: "container__header" }, [
      _c("h5", { staticClass: "container__header-title" }, [
        _vm._v(" " + _vm._s(_vm.$t("shared.predictedAudienceSize")) + " "),
      ]),
    ]),
    _c(
      "div",
      [
        _c(
          "b-button",
          {
            staticClass: "create__button cancel__button ml-0",
            attrs: { id: "calculate-audience", disabled: !_vm.data.isValid },
            on: {
              click: function ($event) {
                _vm.response.loading
                  ? _vm.cancelCalculate()
                  : _vm.calculateAudienceSize()
              },
            },
          },
          [
            _vm.response.loading
              ? _c(
                  "div",
                  { staticClass: "loading__info" },
                  [
                    _c("GjIcon", {
                      staticClass: "svg__animate",
                      attrs: { name: "Loader" },
                    }),
                    _c("span", [_vm._v(_vm._s(_vm.$t("Cancel")))]),
                  ],
                  1
                )
              : _vm._e(),
            !_vm.response.loading
              ? _c("span", [
                  _vm._v(_vm._s(_vm.$t("shared.calculateAudienceSize"))),
                ])
              : _vm._e(),
          ]
        ),
      ],
      1
    ),
    _c("div", { staticClass: "info__text" }, [
      _vm.response.reach && !_vm.response.loading
        ? _c("span", { staticClass: "success" }, [
            _vm._v(
              _vm._s(_vm.$t("shared.audienceSegmentContains")) +
                " " +
                _vm._s(_vm.response.reach) +
                " " +
                _vm._s(_vm.$t("Users").toLowerCase())
            ),
          ])
        : _vm._e(),
      _vm.response.hasErrors && !_vm.response.loading
        ? _c("span", { staticClass: "danger" }, [
            _vm._v("Please fill out the required fields and try again"),
          ])
        : _vm._e(),
      _vm.response.loading
        ? _c("span", [_vm._v(_vm._s(_vm.$t("shared.calculatingAudienceSize")))])
        : _vm._e(),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }