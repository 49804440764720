var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "section",
    { staticClass: "segments-list" },
    [
      _c(
        "div",
        { staticClass: "table-list__header" },
        [
          _c("primary-create-dropdown", {
            attrs: {
              id: "create-segment",
              name: _vm.$t("accountManagement.createAudienceSegment"),
              "can-add": _vm.canCreate,
              "is-loading": _vm.isLoading,
              "create-new-handler": _vm.addNewSegment,
            },
          }),
          _c(
            "div",
            { staticClass: "export-and-search" },
            [
              _c("export-button", {
                attrs: {
                  id: "export-segments",
                  "is-loading": _vm.isLoading,
                  "get-file": _vm.getFile,
                  exporting: _vm.exporting,
                  "has-data": _vm.table.totalRecords > 0,
                },
              }),
              _c("FilterList", {
                ref: "filterSegments",
                attrs: {
                  filters: _vm.tableFilters,
                  "applied-filters": _vm.table.filters,
                  loading: _vm.filtersLoading,
                  "has-data": _vm.table.totalRecords > 0,
                },
                on: { "update-listing": _vm.getData },
              }),
              _c("SearchInputField", {
                attrs: { id: "search-segments", data: _vm.table },
              }),
            ],
            1
          ),
        ],
        1
      ),
      _vm.table.filters.length
        ? _c(
            "div",
            { staticClass: "table-list__header" },
            [
              _c("ActiveFilters", {
                attrs: {
                  "applied-filters": _vm.table.filters,
                  filters: _vm.tableFilters,
                },
                on: { "remove-filter": _vm.removeFilter },
              }),
            ],
            1
          )
        : _vm._e(),
      _c(
        "div",
        { staticClass: "table__wrapper" },
        [
          _c("CustomTable", {
            attrs: {
              data: _vm.table,
              "type-of-table": "segments",
              "is-pagination-enabled": true,
              "is-loading": _vm.isLoading,
              "empty-state-action": {
                canAdd: _vm.canCreate,
                action: () =>
                  _vm.$router.push({
                    name: "segments-create",
                    params: { organizationId: _vm.organizationId },
                  }),
              },
            },
            on: {
              "update-table": _vm.getData,
              "clear-filters": _vm.clearFilters,
            },
            scopedSlots: _vm._u([
              {
                key: "row-data",
                fn: function ({ data }) {
                  return [
                    _c("SegmentsRow", {
                      key: data.row.code,
                      attrs: { data: data },
                    }),
                  ]
                },
              },
            ]),
          }),
        ],
        1
      ),
      _c("CustomModal", {
        attrs: {
          "select-options": _vm.selectOptions,
          "modal-title": _vm.$t("audience.chooseSegmentTemplate"),
          type: "segment",
        },
        on: { "on-create": _vm.addNewSegment },
      }),
      _c("DeleteModal", {
        attrs: {
          title: _vm.$t("audience.deleteAudienceSegment"),
          type: _vm.$t("Segment"),
        },
        on: { delete: _vm.triggerDelete },
      }),
      _c(
        "div",
        { staticClass: "user-roles-tables__duplicate-modal" },
        [
          _c(
            "b-modal",
            {
              attrs: {
                id: "modal-small-duplicate",
                "ok-title": _vm.$t("yes"),
                "cancel-title": _vm.$t("no"),
                "modal-class": "duplicate__modal",
                centered: "",
                size: "md",
                title: _vm.$t("audience.duplicateSegmentTitle"),
                "no-enforce-focus": "",
              },
              on: { ok: _vm.triggerDuplicate },
            },
            [
              _c("span", [
                _vm._v(_vm._s(_vm.$t("audience.duplicateSegment")) + " ?"),
              ]),
            ]
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }