<script>
import { segments } from '@/api'
import store from '@/store'
import {
  DateColumn,
  DropdownActions,
  IconHeading,
  InformationSidebar,
  IntegrationColumn,
  // utility components
  ReadMore,
  // utility functions
  formatDate
} from '@nodus/utilities-front'
import { BFormCheckbox } from 'bootstrap-vue'
import { getCurrentInstance, ref } from 'vue'

export default {
  name: 'SegmentsRow',
  components: {
    ReadMore,
    BFormCheckbox,
    InformationSidebar,
    IconHeading,
    DateColumn,
    IntegrationColumn,
    DropdownActions
  },
  props: {
    data: {
      type: [Array, Object]
    }
  },
  setup(props) {
    const vm = getCurrentInstance().proxy
    const { organizationId } = vm.$route.params
    const { reprocess, setStatus } = segments

    const isLoading = ref(false)

    const changeProcessingStatus = async (code, processingStatus) => {
      isLoading.value = true
      await setStatus(organizationId, code, processingStatus)
      store.dispatch('segments/getTable', { id: organizationId }).then(() => {
        isLoading.value = false
      })
    }

    const reprocessCall = async (segment) => {
      if (segment.status !== 'Processing') {
        await reprocess(organizationId, segment.code)

        store.dispatch('segments/getTable', { id: organizationId })
      }
    }

    const { canReprocess, canEdit, canDuplicate, canDelete, code } =
      props.data.row

    const viewLocation = {
      name: 'segments-view',
      params: {
        organizationId: organizationId,
        segmentId: code
      }
    }

    const editLocation = {
      name: 'segments-edit',
      params: {
        organizationId: organizationId,
        segmentId: code
      }
    }

    const actionItems = [
      {
        id: 'view-segment',
        icon: 'IconparkPreviewOpen',
        text: vm.$t('View'),
        to: viewLocation
      },
      {
        id: 'reprocess-segment',
        icon: 'Refresh',
        text: vm.$t('shared.reprocess'),
        disabled: !canReprocess,
        action: () => reprocessCall(props.data.row)
      },
      {
        id: 'edit-segment',
        icon: 'Edit',
        text: vm.$t('Edit'),
        disabled: !canEdit,
        to: editLocation
      },
      {
        id: 'duplicate-segment',
        icon: 'Copy',
        text: vm.$t('duplicate'),
        disabled: !canDuplicate,
        modal: 'modal-small-duplicate',
        action: () => vm.$store.commit('segments/SET_ID_TO_DUPLICATE', code)
      },
      {
        id: 'delete-segment',
        icon: 'Delete',
        text: vm.$t('Delete'),
        type: 'danger',
        disabled: !canDelete,
        modal: 'modal-small-delete',
        action: () => vm.$store.commit('segments/SET_ID_TO_DELETE', code)
      }
    ]

    return {
      organizationId,
      formatDate,
      changeProcessingStatus,
      isLoading,
      actionItems,
      viewLocation
    }
  }
}
</script>
<template>
  <div>
    <!-- Column: Create and Update Date -->
    <div v-if="data.column.field === 'insertDateTime'">
      <DateColumn :data="data" />
    </div>

    <!-- Column: Name -->
    <span v-else-if="data.column.field === 'name'" class="dark-gray-color">
      <div class="name__wrapper">
        <IconHeading :id="data.row.code" :data="data" />
        <ReadMore :text="data.row.name" :to="viewLocation" />
      </div>
    </span>

    <!-- Column: Users -->
    <span v-else-if="data.column.field === 'userCount'">
      {{ data.row.userCount.toLocaleString() }}
    </span>

    <!-- Column: Description -->
    <span v-else-if="data.column.field === 'description'">
      <ReadMore :text="data.row.description" />
    </span>

    <!-- Column: Integration -->
    <span
      v-else-if="data.column.field === 'integrations'"
      class="integrations-and-actions"
    >
      <IntegrationColumn
        :row-id="data.row.code"
        :activations="data.row.activations"
      />
    </span>

    <!-- Column: Actions -->
    <span
      v-else-if="data.column.field === 'actions'"
      class="integrations-and-actions"
    >
      <div class="table-list-switch-and-actions">
        <div class="switch-and-information">
          <b-form-checkbox
            :id="`switch-status-${data.row.code}`"
            :disabled="!data.row.canActivate || isLoading"
            :checked="!!data.row.processingStatus"
            class="custom-control-primary"
            name="check-button"
            switch
            @change="
              data.row.canActivate
                ? changeProcessingStatus(
                    data.row.code,
                    data.row.processingStatus ? 0 : 1
                  )
                : {}
            "
          />
          <InformationSidebar
            :title="$t('audience.segmentDisable')"
            :content="$t('audience.segmentDisableInformation')"
            :code="data.row.code.toLocaleString()"
          />
        </div>
        <div class="table-list-actions-container">
          <DropdownActions :items="actionItems" />
        </div>
      </div>
    </span>

    <!-- Column: Common -->
    <span v-else>
      {{ data.formattedRow[data.column.field] }}
    </span>
  </div>
</template>

<style scoped lang="scss"></style>
