<template>
  <section>
    <div class="table-list__header">
      <primary-create-button
        id="create-query"
        :name="$t('queries.createNew')"
        :is-loading="isLoading"
        :can-add="canCreate"
        :click-handler="createQueryForm"
      />
      <div class="export-and-search">
        <export-button
          id="export-queries"
          :is-loading="isLoading"
          :get-file="getFile"
          :exporting="exporting"
          :has-data="table.totalRecords > 0"
        />
        <FilterList
          ref="filterQueries"
          :filters="filters"
          :applied-filters="table.filters"
          :loading="filtersLoading"
          :has-data="table.totalRecords > 0"
          @update-listing="getData"
        />
        <SearchInputField id="search-queries" :data="table" />
      </div>
    </div>
    <div v-if="table.filters.length" class="table-list__header">
      <ActiveFilters
        :applied-filters="table.filters"
        :filters="filters"
        @remove-filter="removeFilter"
      />
    </div>
    <div class="table__wrapper">
      <CustomTable
        :data="table"
        type-of-table="queries"
        :is-pagination-enabled="true"
        :is-loading="isLoading"
        :empty-state-action="{
          canAdd: canCreate,
          action: createQueryForm
        }"
        @update-table="getData"
        @clear-filters="clearFilters"
      >
        <template #row-data="{ data }">
          <QueriesRow :key="data.row.code" :data="data" />
        </template>
      </CustomTable>
    </div>

    <DeleteModal
      :title="$t('audience.deleteAudienceSegment')"
      :type="$t('Segment')"
      @delete="triggerDelete"
    />

    <!-- Duplicate Audience Modal -->
    <div class="user-roles-tables__duplicate-modal">
      <b-modal
        id="modal-small-duplicate"
        :ok-title="$t('yes')"
        :cancel-title="$t('no')"
        modal-class="duplicate__modal"
        centered
        size="md"
        :title="$t('audience.duplicateSegmentTitle')"
        no-enforce-focus
        @ok="triggerDuplicate"
      >
        <span>{{ $t('audience.duplicateSegment') }}?</span>
      </b-modal>
    </div>
  </section>
</template>

<script>
import { queries } from '@/api'
import QueriesRow from '@/components/table/table-data/QueriesRow.vue'
import router from '@/router'
import store from '@/store'
import {
  ActiveFilters,
  // utilitiy components
  CustomTable,
  DeleteModal,
  ExportButton,
  FilterList,
  PrimaryCreateButton,
  SearchInputField,
  // utility functions
  createDownloadLink,
  handleLastRowDelete
} from '@nodus/utilities-front'
import { computed, getCurrentInstance, onMounted, ref } from 'vue'

export default {
  name: 'Queries',
  components: {
    CustomTable,
    SearchInputField,
    PrimaryCreateButton,
    ExportButton,
    DeleteModal,
    FilterList,
    ActiveFilters,
    QueriesRow
  },
  setup() {
    const vm = getCurrentInstance().proxy
    const { organizationId } = vm.$route.params
    const isLoading = ref(true)
    const filtersLoading = ref(true)
    const { remove, duplicate } = queries

    const getData = async (filters) => {
      if (filters) {
        localStorage.setItem(
          `queryFilters-${organizationId}`,
          JSON.stringify(filters)
        )
        store.commit('queries/APPLY_TABLE_FILTERS', filters)
        store.commit('queries/SET_TABLE_PAGE', 1)
      }
      isLoading.value = true
      await store.dispatch('queries/getTable', { id: organizationId })
      isLoading.value = false
    }

    onMounted(async () => {
      await store
        .dispatch('queries/getTableFilters', { organizationId })
        .then(() => (filtersLoading.value = false))
      getData()
    })

    const table = computed(() => store.getters['queries/table'])
    const filters = computed(() => store.getters['queries/tableFilters'])
    const canCreate = computed(() => store.getters['queries/canCreate'])
    const IDtoDelete = computed(() => store.getters['queries/IDToDelete'])
    const IDtoDuplicate = computed(() => store.getters['queries/IDToDuplicate'])

    const createQueryForm = () => {
      router.push({
        name: 'query-create',
        params: { organizationId }
      })
    }

    const triggerDelete = async () => {
      handleLastRowDelete(table.value, store, 'queries/SET_TABLE_PAGE')
      await remove(organizationId, IDtoDelete.value)

      getData()
    }

    const triggerDuplicate = async () => {
      await duplicate(organizationId, IDtoDuplicate.value)

      getData()
    }

    const exporting = ref(false)

    const getFile = async (format) => {
      exporting.value = true
      const fileName = `Queries_${organizationId}.${
        format === 1 ? 'xlsx' : 'csv'
      }`
      const response = await store.dispatch('queries/getFile', {
        id: organizationId,
        format
      })
      createDownloadLink(response, fileName)
      exporting.value = false
    }

    const removeFilter = (filter) => vm.$refs.filterQueries.clearFilter(filter)
    const clearFilters = () => {
      isLoading.value = true
      vm.$refs.filterQueries.clearFilters()
    }

    return {
      isLoading,
      table,
      canCreate,
      createQueryForm,
      triggerDelete,
      triggerDuplicate,
      getData,
      getFile,
      exporting,
      filters,
      filtersLoading,
      removeFilter,
      clearFilters
    }
  }
}
</script>

<style lang="scss"></style>
