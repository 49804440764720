<template>
  <section class="create-segment-form-wrapper">
    <b-form novalidate class="form__wrapper">
      <div class="container__wrapper">
        <div class="container__header">
          <h5 class="container__header-title">{{ $t('data.basicInfo') }}</h5>
        </div>
        <div class="container__content">
          <b-col id="name" lg="6" class="input__wrapper pl-0">
            <label class="mr-1">{{ $t('Name') }} *</label>
            <b-form-input
              id="query-name"
              v-model.trim="query.name.value"
              :placeholder="$t('audience.enterAudienceName')"
              type="text"
              class="form__input"
              :class="{
                'form__input--invalid':
                  query.name.errors && query.name.errors.length > 0
              }"
              @input="handleFormInput(state, [query.name])"
            />
            <div
              v-for="(error, errIndex) in query.name.errors"
              :key="errIndex"
              class="container__schedule__info-error-message"
            >
              <span>{{ error }}</span>
            </div>
          </b-col>
          <b-col lg="6" class="input__wrapper pl-0">
            <label>{{ $t('Description') }}</label>
            <b-form-textarea
              id="query-description"
              v-model.trim="query.description.value"
              :placeholder="$t('audience.enterAudienceDescription')"
              class="form__input form__input-textarea"
              rows="3"
              :class="{
                'form__input--invalid':
                  query.description.errors &&
                  query.description.errors.length > 0
              }"
              @input="handleFormInput(state, [query.description])"
            />
            <div
              v-for="(error, errIndex) in query.description.errors"
              :key="errIndex"
              class="container__schedule__info-error-message"
            >
              <span>{{ error }}</span>
            </div>
          </b-col>
        </div>
      </div>
      <div class="container__wrapper container__wrapper--height">
        <div class="container__header documentation__header--padding">
          <h5 class="container__header-title">{{ $t('audience.code') }}</h5>
        </div>
        <div class="documentation__info-text mb-1">
          <GjIcon name="Info" class="mr-50" size="18" />
          <span
            >{{ $t('audience.segmentsDocumentationLink') }}
            <a
              :href="`${whitelabel.documentationUrl}/intro#custom-queries`"
              target="_blank"
              >{{ $t('Documentation').toLowerCase() }}</a
            >.</span
          >
        </div>
        <div class="container__content">
          <b-col id="query" class="input__wrapper pl-0 pr-0">
            <b-form-textarea
              id="textarea-query"
              v-model="query.query.value"
              rows="8"
              no-resize
              class="query__area"
              :class="{
                'form__input--invalid':
                  query.query.errors && query.query.errors.length > 0
              }"
              @input="textareaHandler(), handleFormInput(state, [query.query])"
            />
            <div
              v-for="(error, errIndex) in query.query.errors"
              :key="errIndex"
              class="container__schedule__info-error-message"
            >
              <span>{{ error }}</span>
            </div>
          </b-col>
          <b-col
            class="textarea__validation pl-0 pr-0"
            :class="{
              'textarea__validation--error':
                !queryValidation.isValid &&
                !queryValidation.isValidating &&
                queryValidation.isValidated,
              'textarea__validation--success':
                queryValidation.isValid &&
                !queryValidation.isValidating &&
                queryValidation.isValidated
            }"
          >
            <div class="textarea__validation--display">
              <GjIcon name="Info" class="mr-50" size="18"></GjIcon>
              <div
                v-if="queryValidation.message && queryValidation.isValidated"
              >
                <div v-for="(message, i) in queryValidation.message" :key="i">
                  <span>{{ message }}</span>
                </div>
              </div>
              <span v-else>{{ $t('audience.queryValidationInfo') }}!</span>
            </div>
            <b-button
              id="validate-query"
              variant="gradient-primary"
              class="create__button validate__button"
              :disabled="
                isButtonValid ||
                queryValidation.isDisabled ||
                queryValidation.isValidating
              "
              @click="validateQuery(query.query.value)"
            >
              <GjIcon
                name="RefreshAlt"
                :class="{ svg__animate: queryValidation.isValidating }"
              ></GjIcon>
              {{ $t('audience.validate') }}
            </b-button>
          </b-col>
        </div>
      </div>
      <div class="container__wrapper">
        <div class="container__header">
          <h5 class="container__header-title">{{ $t('audience.schedule') }}</h5>
        </div>
        <div class="container__schedule">
          <div class="schedule__inputs">
            <span class="container__schedule__label-text">{{
              $t('audience.runSegmentQuery')
            }}</span>
            <div class="input__wrapper">
              <v-select
                id="query-schedule"
                v-model="query.reprocessOperator"
                :options="reprocessOperatorOptions"
                label="text"
                class="form__input"
                :clearable="false"
                @input="
                  handleScheduleOperator(),
                    handleFormInput(state, [query.reprocessValue])
                "
              ></v-select>
            </div>
            <div
              v-if="
                query.reprocessOperator &&
                query.reprocessOperator.text === 'Every'
              "
              id="reprocessValue"
              class="input__wrapper"
            >
              <b-form-input
                id="schedule-number"
                v-model="query.reprocessValue.value"
                placeholder="2"
                type="number"
                min="1"
                class="form__input-number"
                :class="{
                  'form__input--invalid':
                    query.reprocessValue.errors &&
                    query.reprocessValue.errors.length > 0
                }"
                @blur="handleInput(query.reprocessValue.value)"
                @input="handleFormInput(state, [query.reprocessValue])"
              />
            </div>
            <div
              v-if="
                query.reprocessOperator &&
                query.reprocessOperator.text === 'Every'
              "
              class="input__wrapper"
            >
              <v-select
                id="schedule-time-unit"
                v-model="query.reprocessTimeUnit"
                :placeholder="$t('Select')"
                :options="reprocessTimeUnitOptions"
                label="text"
                class="form__input"
                :clearable="false"
                @input="handleFormInput(state, [query.reprocessValue])"
              ></v-select>
            </div>
          </div>
          <div
            v-for="(error, errIndex) in query.reprocessValue.errors"
            :key="errIndex"
            class="container__schedule__info-error-message"
          >
            <span>{{ error }}</span>
          </div>
          <div
            v-if="query.reprocessValue.errors.length === 0"
            class="container__schedule__info-text"
          >
            <span>
              {{ $t('audience.segmentQueryWillRun') }}
              {{
                query.reprocessOperator.text === 'Once'
                  ? 'only once'
                  : `every ${query.reprocessValue.value} ${query.reprocessTimeUnit.text}`
              }}
            </span>
          </div>
        </div>
      </div>
      <div class="container__wrapper">
        <AudienceSizeCalculator
          :data="{
            query: query.query.value,
            isValid: queryValidation.isValid,
            type: 'query'
          }"
        ></AudienceSizeCalculator>
      </div>
      <div
        v-if="query.activations && query.activations.length"
        class="container__wrapper"
      >
        <div class="container__header">
          <h5 class="container__header-title">{{ $t('Integrations') }}</h5>
        </div>
        <div class="container__integrations">
          <IntegrationCard
            v-for="activation in query.activations"
            :key="activation.id"
            :activation="activation"
            @state-change="handleFormInput(state)"
          />
        </div>
      </div>
      <Footer
        :is-loading="isLoading"
        :submitted="state.submitted"
        :cancel-id="`cancel-create-query`"
        :create-id="`create-query`"
        :edit-mode="!!segmentId"
        :disable-button="isComplete"
        @back="cancel()"
        @create="
          queryValidation.isValid
            ? segmentId
              ? openSaveModal()
              : saveCustomQuery(query)
            : {}
        "
      />
    </b-form>

    <SaveChangesModal :type="$t('Segment')" @save="saveCustomQuery(query)" />
  </section>
</template>

<script>
/* eslint-disable no-param-reassign */
import { queries } from '@/api'
import AudienceSizeCalculator from '@/components/shared/AudienceSizeCalculator.vue'
import router from '@/router'
import store from '@/store'
import { whitelabel } from '@/utils/constants'
import {
  // utility components
  Footer,
  GjIcon,
  IntegrationCard,
  SaveChangesModal,

  // utility functions
  appendErrors,
  confirmChanges,
  handleFormInput,
  isInteger,
  removeErrors,
  scrollToElement,
  unFlattenObject
} from '@nodus/utilities-front'
import { BButton, BCol, BForm, BFormInput, BFormTextarea } from 'bootstrap-vue'
import { computed, getCurrentInstance, onMounted, reactive, ref } from 'vue'
import vSelect from 'vue-select'
import {
  queryReprocessOperatorConst,
  queryReprocessOperatorDefaultConst,
  queryTimeUnitConst,
  queryTimeUnitDefaultConst
} from './queryConfig'

export default {
  name: 'QueryCreate',
  components: {
    AudienceSizeCalculator,
    BForm,
    BCol,
    BFormInput,
    BFormTextarea,
    BButton,
    GjIcon,
    vSelect,
    IntegrationCard,
    Footer,
    SaveChangesModal
  },
  async beforeRouteEnter(to, from, next) {
    const organizationId =
      to.params?.organizationId || from.params?.organizationId
    if (!to.params.segmentId) {
      if (store.getters['queries/canCreate'] === null) {
        await store.dispatch('queries/getTable', {
          id: organizationId
        })
      }
      if (store.getters['queries/canCreate']) next()
      else next('/error/401')
    } else {
      next()
    }
  },
  async beforeRouteLeave(to, from, next) {
    if (!this.state.submitted && !this.state.isPristine) {
      const response = await confirmChanges(this.$bvModal)
      next(response)
    } else {
      next()
    }
  },
  computed: {
    isButtonValid() {
      return !this.query.query.value?.length > 0
    },
    isComplete() {
      return (
        !this.queryValidation.isValid ||
        !isInteger(this.query.reprocessValue.value)
      )
    }
  },
  setup() {
    const vm = getCurrentInstance().proxy
    const { organizationId, segmentId } = vm.$route.params
    const isLoading = ref(false)
    const { validate } = queries

    const reprocessOperatorOptions = queryReprocessOperatorConst
    const reprocessOperatorDefault = queryReprocessOperatorDefaultConst
    const reprocessTimeUnitOptions = queryTimeUnitConst
    const reprocessTimeUnitDefault = queryTimeUnitDefaultConst

    const queryValidation = reactive({
      isValidating: false,
      isValidated: false,
      isValid: false,
      message: [''],
      isDisabled: false
    })

    let query = reactive({
      name: {
        value: '',
        errors: []
      },
      description: {
        value: '',
        errors: []
      },
      query: {
        value: '',
        errors: []
      },
      activations: {
        id: '',
        name: '',
        imageUrl: ''
      },
      reprocessOperator: reprocessOperatorDefault,
      reprocessTimeUnit: reprocessTimeUnitDefault,
      reprocessValue: {
        value: 2,
        errors: []
      }
    })

    const state = reactive({
      submitted: false,
      isPristine: true
    })

    onMounted(async () => {
      isLoading.value = true

      store.dispatch('segments/getActivations', { organizationId })

      if (segmentId) {
        await store.dispatch('queries/getQuerySegment', {
          id: organizationId,
          segmentId
        })
        queryValidation.isValid = true
        queryValidation.isDisabled = true
        if (!query.value.canEdit) router.push('/error/401')
      }

      isLoading.value = false
    })

    query.activations = computed(() => store.getters['segments/activations'])

    if (segmentId) {
      query = computed(() => store.getters['queries/querySegment'])
    }

    const formatBytes = (bytes, decimals = 2) => {
      if (bytes === 0) return '0 Bytes'

      const k = 1000
      const dm = decimals < 0 ? 0 : decimals
      const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB']

      const i = Math.floor(Math.log(bytes) / Math.log(k))

      return `${parseFloat((bytes / k ** i).toFixed(dm))} ${sizes[i]}`
    }

    const validateQuery = async (q) => {
      queryValidation.isValidating = true
      const {
        data: {
          data: { errors, isValid, bytesProcessed }
        }
      } = await validate(organizationId, q)
      if (!isValid) {
        queryValidation.message = Object.values(errors)
        queryValidation.isValidating = false
        queryValidation.isValidated = true
        queryValidation.isValid = false
        queryValidation.isDisabled = true
      } else {
        queryValidation.message = [
          `Query validated successfully, this query will process ${formatBytes(
            bytesProcessed
          )} of data.`
        ]
        queryValidation.isValidating = false
        queryValidation.isValidated = true
        queryValidation.isValid = true
        queryValidation.isDisabled = true
      }
    }

    const handleInput = (value) => {
      const reprocessValue = segmentId
        ? query.value.reprocessValue
        : query.reprocessValue
      if (!isInteger(value)) {
        reprocessValue.errors = [
          'Value should be a positive integer and not greater than 2,147,483,647'
        ]
      } else {
        reprocessValue.errors = []
      }
    }

    const saveCustomQuery = async (querySegment) => {
      state.submitted = true
      const request = {
        name: querySegment.name.value,
        description: querySegment.description.value,
        query: querySegment.query.value,
        activationIds: querySegment.activations
          ? querySegment.activations.filter((a) => a.enabled).map((ac) => ac.id)
          : [],
        reprocessOperator: querySegment.reprocessOperator.value,
        reprocessValue: querySegment.reprocessValue.value,
        reprocessTimeUnit: querySegment.reprocessTimeUnit.value
      }

      let response = null
      if (segmentId) {
        response = await queries.updateCustomQuery(
          organizationId,
          segmentId,
          request
        )
      } else {
        response = await queries.createCustomQuery(organizationId, request)
      }

      const { errors, success } = response.data

      if (!success) {
        const errorsObject = unFlattenObject(errors)
        removeErrors(segmentId ? query.value : query)
        appendErrors(segmentId ? query.value : query, errorsObject)
        setTimeout(
          () => scrollToElement('[class*="form__input--invalid"]'),
          200
        )
      } else {
        router.push({
          name: 'queries',
          params: { organizationId }
        })
      }
      state.submitted = false
    }

    const textareaHandler = () => {
      queryValidation.isDisabled = false
      queryValidation.isValidated = false
      queryValidation.isValid = false
      queryValidation.message = ['']
    }

    const handleScheduleOperator = () => {
      if (segmentId) {
        query.value.reprocessTimeUnit = reprocessTimeUnitDefault
        query.value.reprocessValue.value = 2
      } else {
        query.reprocessTimeUnit = reprocessTimeUnitDefault
        query.reprocessValue.value = 2
      }
    }

    const openSaveModal = () => {
      if (state.isPristine)
        vm.$router.push({
          name: 'queries',
          params: { organizationId }
        })
      else vm.$bvModal.show('save-changes-modal')
    }

    const cancel = () => {
      router.push({ name: 'queries' })
    }

    return {
      isLoading,
      query,
      state,
      segmentId,
      queryValidation,
      validateQuery,
      saveCustomQuery,
      textareaHandler,
      reprocessOperatorOptions,
      reprocessTimeUnitOptions,
      handleScheduleOperator,
      handleInput,
      handleFormInput,
      organizationId,
      openSaveModal,
      cancel,
      whitelabel
    }
  }
}
</script>

<style lang="scss">
@import '@/assets/scss/views/segments/segmentCreate.scss';

.query__area {
  border-radius: 6px;
  background-color: #fafafa;
  font-size: 14px;
  position: relative;
}

.query__area:focus {
  border-radius: 6px;
  background-color: #fafafa;
}

.textarea__validation {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  font-size: 13px;
  color: #052d61;
  line-height: 18px;

  .textarea__validation--display {
    display: flex;
    align-items: flex-start;
  }

  svg {
    fill: #052d61;
    min-width: 18px;
  }

  &.textarea__validation--error {
    color: $danger;

    svg {
      fill: $danger;
    }
  }

  &.textarea__validation--success {
    color: #36b37e;

    svg {
      fill: #36b37e;
    }
  }
}

.validate__button {
  color: #fff;

  svg {
    margin-right: 5px;
  }

  .svg__animate {
    animation: rotation 2s infinite linear;
  }

  @keyframes rotation {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }
}

.container__schedule__label-text {
  color: #667c99;
  margin-right: 16px;
  margin-bottom: 5px;
}

.container__schedule__info-text {
  font-size: 14px;
  color: #667c99;
}
.container__schedule__info-error-message {
  font-size: 13px;
  color: $danger;
}
.schedule__inputs {
  display: flex;
  //TODO check tablet
  //flex-wrap: wrap;
  align-items: center;
  color: $blue-700;
  font-size: 14px;
  width: 100%;
  margin-bottom: 10px;

  .input__wrapper {
    margin-right: 10px;
    margin-bottom: 5px;
    padding-bottom: 0;
    .form__input {
      height: 38px;
      font-size: 14px;
      width: 166px;
    }
    .form__input-number {
      height: 38px;
      font-size: 14px;
      width: 71px;
    }
    width: auto;
  }
}
</style>
