import axiosIns from '@/libs/axios'
import { destructJSONObject, formatRequestQuery } from '@nodus/utilities-front'

export default {
  namespaced: true,
  state: {
    table: {
      columns: [
        {
          label: 'Id',
          field: 'id',
          type: 'number',
          hidden: true
        },
        {
          label: 'Name',
          field: 'name',
          thClass: 'integration-name-col',
          width: '25%'
        },
        {
          label: 'Type',
          field: 'type',
          width: '20%'
        },
        {
          label: 'Created Date',
          field: 'created',
          width: '35%'
        },
        {
          label: 'Status',
          field: 'status',
          //   type: "number",
          sortable: false,
          thClass: 'not-sortable-headers',
          width: '20%'
        }
      ],
      rows: [],
      searchTerm: '',
      totalRecords: 0,
      page: 1,
      perPage: 10,
      orderField: '',
      orderType: ''
    },
    canCreate: false,
    tabs: [],
    parameters: [],
    integration: []
  },
  getters: {
    table: (state) => state.table,
    canCreate: (state) => state.canCreate,
    tabs: (state) => state.tabs,
    parameters: (state) => state.parameters,
    integration: (state) => state.integration
  },
  actions: {
    // eslint-disable-next-line no-unused-vars
    async getTable({ commit, state }, { id }) {
      const queryParamString = formatRequestQuery(
        state.table.searchTerm,
        state.table.page,
        state.table.perPage,
        state.table.orderField,
        state.table.orderType
      )
      const response = await axiosIns.get(
        `/api/v2/${id}/integrations/activations?${queryParamString}`
      )

      commit('SET_TABLE_ROWS', destructJSONObject(response, 'activations'))
      commit('SET_CAN_CREATE', destructJSONObject(response, 'canAdd'))
      commit('SET_TOTAL_RECORDS', destructJSONObject(response, 'totalCount'))
    },
    async getIntegration({ commit }, { id, activationId }) {
      const response = await axiosIns.get(
        `/api/v1/${id}/integrations/activations/${activationId}`
      )
      commit('SET_INTEGRATION', response.data.data)
    },
    async getTabs({ commit }, { id }) {
      const response = await axiosIns.get(`/api/v1/${id}/integrations`)

      commit('SET_TABS', destructJSONObject(response, 'integrations'))
    },
    async getParameters({ commit }, { id, integrationId }) {
      const response = await axiosIns.get(
        `/api/v1/${id}/integrations/${integrationId}/parameters`
      )
      commit('SET_PARAMETERS', response.data.data)
    }
  },
  mutations: {
    SET_TABLE_ROWS(state, segments) {
      state.table.rows = segments
    },
    SET_INTEGRATION(state, integration) {
      state.integration = integration
    },
    SET_CAN_CREATE(state, create) {
      state.canCreate = create
    },
    SET_TOTAL_RECORDS(state, totalRecords) {
      state.table.totalRecords = totalRecords
    },
    SET_TABS(state, create) {
      state.tabs = create
    },
    SET_PARAMETERS(state, parameters) {
      state.parameters = {
        name: {
          value: '',
          errors: []
        },
        description: parameters.description,
        imageUrl: parameters.imageUrl,
        integrationName: parameters.name,
        parameterValues: {
          errors: []
        },
        parameters: parameters.integrationParameters.reduce(
          (acc, curr) => [
            ...acc,
            {
              ...curr,
              parameterId: curr.parameterId,
              parameterName: curr.parameterName,
              required: curr.required,
              value: {
                value: '',
                errors: []
              }
            }
          ],
          []
        )
      }
    }
  }
}
