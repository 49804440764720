var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "section",
    {
      staticClass:
        "create-segment-form-wrapper segment-view view-screen__container",
    },
    [
      !_vm.formState.isFormLoaded
        ? _c(
            "div",
            { staticClass: "loading__container" },
            [_c("LoadingBar")],
            1
          )
        : _vm._e(),
      _vm.formState.isFormLoaded
        ? _c(
            "b-form",
            {
              staticClass: "needs-validation container--position",
              attrs: { novalidate: "" },
              on: {
                submit: function ($event) {
                  $event.preventDefault()
                },
              },
            },
            [
              _c(
                "div",
                {
                  staticClass: "container__wrapper container__wrapper--height",
                },
                [
                  _c(
                    "div",
                    {
                      staticClass:
                        "container__header segment-view__basic-info--header",
                    },
                    [
                      _c("h5", { staticClass: "container__header-title" }, [
                        _vm._v(" " + _vm._s(_vm.$t("data.basicInfo")) + " "),
                      ]),
                      _vm.segment.canEdit
                        ? _c(
                            "b-link",
                            {
                              staticClass: "segment-view__edit",
                              attrs: {
                                to: {
                                  name: "segments-edit",
                                  params: {
                                    organizationId: _vm.organizationId,
                                    segmentId: _vm.segmentId,
                                  },
                                },
                              },
                            },
                            [
                              _c("GjIcon", {
                                attrs: { name: "Edit", size: "18" },
                              }),
                              _c("span", [_vm._v(_vm._s(_vm.$t("Edit")))]),
                            ],
                            1
                          )
                        : _vm._e(),
                    ],
                    1
                  ),
                  _c(
                    "div",
                    {
                      staticClass:
                        "container__content segment-view__basic-info",
                    },
                    [
                      _c(
                        "b-col",
                        {
                          staticClass: "input__wrapper pl-0",
                          attrs: { lg: "8" },
                        },
                        [
                          _c("label", [_vm._v(_vm._s(_vm.$t("Name")))]),
                          _c("span", [_vm._v(_vm._s(_vm.segment.name))]),
                        ]
                      ),
                      _c(
                        "b-col",
                        {
                          staticClass: "input__wrapper pl-0",
                          attrs: { lg: "8" },
                        },
                        [
                          _c("label", [_vm._v(_vm._s(_vm.$t("Description")))]),
                          _c("ReadMore", {
                            attrs: {
                              text: _vm.segment.description,
                              mode: "view",
                            },
                          }),
                        ],
                        1
                      ),
                      _c(
                        "b-col",
                        {
                          staticClass: "input__wrapper pl-0",
                          attrs: { lg: "8" },
                        },
                        [
                          _c("label", [_vm._v(_vm._s(_vm.$t("Properties")))]),
                          _c("span", [
                            _vm._v(_vm._s(_vm.segment.properties.join(", "))),
                          ]),
                        ]
                      ),
                    ],
                    1
                  ),
                ]
              ),
              _c(
                "div",
                {
                  staticClass: "container__wrapper user__segmentation-wrapper",
                },
                [
                  _c(
                    "div",
                    {
                      staticClass:
                        "container__header segment-view__container_header",
                    },
                    [
                      _c("h5", { staticClass: "container__header-title" }, [
                        _vm._v(
                          " " +
                            _vm._s(_vm.$t("audience.userSegmentations")) +
                            " "
                        ),
                      ]),
                      _c("div", [
                        _vm._v(
                          _vm._s(_vm.$t("audience.segmentViewTitle")) + ":"
                        ),
                      ]),
                    ]
                  ),
                  _vm._l(_vm.segment.blocks, function (block, blockIndex) {
                    return _c(
                      "div",
                      { key: blockIndex, staticClass: "container__content" },
                      [
                        _vm._l(block.filters, function (filter, filterIndex) {
                          return _c(
                            "div",
                            {
                              key: filterIndex,
                              staticClass: "container__content--segments",
                            },
                            [
                              +filter.type === _vm.filterTypes.filter
                                ? _c("div", [
                                    block.filters.length > 1 &&
                                    filterIndex !== 0
                                      ? _c(
                                          "div",
                                          [
                                            _c(
                                              "b-button",
                                              {
                                                staticClass:
                                                  "segment__button--or-multiple",
                                              },
                                              [
                                                _c("span", [
                                                  _vm._v(
                                                    _vm._s(
                                                      _vm.$t("audience.or")
                                                    )
                                                  ),
                                                ]),
                                              ]
                                            ),
                                          ],
                                          1
                                        )
                                      : _vm._e(),
                                    _c(
                                      "div",
                                      { staticClass: "segment-view__row" },
                                      [
                                        _c("span", [
                                          _vm._v(
                                            " " +
                                              _vm._s(
                                                filter.isPerformed
                                                  ? _vm.$t("audience.performed")
                                                  : _vm.$t(
                                                      "audience.didntPerform"
                                                    )
                                              ) +
                                              " "
                                          ),
                                          _c("b", [
                                            _vm._v(" " + _vm._s(filter.table)),
                                          ]),
                                          _vm._v(" in "),
                                          _c("b", [
                                            _vm._v(
                                              " " +
                                                _vm._s(
                                                  filter.filterOrganization
                                                )
                                            ),
                                          ]),
                                          _vm._v(
                                            " " +
                                              _vm._s(
                                                filter.filterOrganization ===
                                                  "All"
                                                  ? "organizations"
                                                  : "organization"
                                              ) +
                                              " "
                                          ),
                                        ]),
                                        filter.isDisabled
                                          ? _c(
                                              "i",
                                              {
                                                staticClass:
                                                  "segment-view__disabled-label",
                                              },
                                              [_vm._v(" (disabled) ")]
                                            )
                                          : _vm._e(),
                                        filter.isDisabled
                                          ? _c("InformationSidebar", {
                                              attrs: {
                                                code: `${blockIndex}-${filterIndex}-disabled`,
                                                size: "18",
                                                title: _vm.$t(
                                                  "audience.disabledFilter"
                                                ),
                                                content: _vm.$t(
                                                  "audience.disabledFilterInfo"
                                                ),
                                              },
                                            })
                                          : _vm._e(),
                                      ],
                                      1
                                    ),
                                    filter.durationOperator
                                      ? _c(
                                          "div",
                                          { staticClass: "segment-view__row" },
                                          [
                                            _c("span", [
                                              _vm._v(
                                                " " +
                                                  _vm._s(
                                                    filter.durationOperator.text
                                                  ) +
                                                  " "
                                              ),
                                              !filter.isBetweenDate
                                                ? _c("b", [
                                                    _vm._v(
                                                      " " +
                                                        _vm._s(
                                                          filter.durationValue
                                                        )
                                                    ),
                                                  ])
                                                : _c("span", [
                                                    _c("b", [
                                                      _vm._v(
                                                        _vm._s(
                                                          filter.durationValue.split(
                                                            "_"
                                                          )[0]
                                                        )
                                                      ),
                                                    ]),
                                                    _vm._v(" and "),
                                                    _c("b", [
                                                      _vm._v(
                                                        _vm._s(
                                                          filter.durationValue.split(
                                                            "_"
                                                          )[1]
                                                        )
                                                      ),
                                                    ]),
                                                  ]),
                                            ]),
                                          ]
                                        )
                                      : _vm._e(),
                                    filter.frequencyOperator
                                      ? _c(
                                          "div",
                                          { staticClass: "segment-view__row" },
                                          [
                                            _c("span", [
                                              _vm._v(
                                                " " +
                                                  _vm._s(
                                                    filter.frequencyOperator
                                                  ) +
                                                  " "
                                              ),
                                              _c("b", [
                                                _vm._v(
                                                  " " +
                                                    _vm._s(
                                                      filter.frequencyFirstValue
                                                    )
                                                ),
                                              ]),
                                              !!filter.frequencySecondValue
                                                ? _c("span", [
                                                    _vm._v(" and "),
                                                    _c("b", [
                                                      _vm._v(
                                                        " " +
                                                          _vm._s(
                                                            filter.frequencySecondValue
                                                          )
                                                      ),
                                                    ]),
                                                  ])
                                                : _vm._e(),
                                              filter.frequencyFirstValue > 1 ||
                                              !!filter.frequencySecondValue
                                                ? _c("span", [_vm._v(" times")])
                                                : _c("span", [_vm._v(" time")]),
                                            ]),
                                          ]
                                        )
                                      : _vm._e(),
                                    !!filter.parameters.length
                                      ? _c(
                                          "div",
                                          { staticClass: "segment-view__row" },
                                          [
                                            _c(
                                              "div",
                                              { attrs: { id: "first-row" } },
                                              [
                                                _c("span", [
                                                  _vm._v(" With "),
                                                  _c("b", [
                                                    _vm._v(
                                                      _vm._s(
                                                        filter.parameterLogicalOperator
                                                      )
                                                    ),
                                                  ]),
                                                  _vm._v(
                                                    " " +
                                                      _vm._s(
                                                        _vm.$t(
                                                          "audience.ofFollowingParameters"
                                                        )
                                                      ) +
                                                      ": "
                                                  ),
                                                ]),
                                                _c(
                                                  "div",
                                                  {
                                                    staticClass:
                                                      "segment-view__parameters",
                                                  },
                                                  _vm._l(
                                                    filter.parameters,
                                                    function (
                                                      param,
                                                      paramIndex
                                                    ) {
                                                      return _c(
                                                        "div",
                                                        {
                                                          key: paramIndex,
                                                          staticClass:
                                                            "segment-view__parameter",
                                                        },
                                                        [
                                                          _c(
                                                            "div",
                                                            {
                                                              staticClass:
                                                                "segment-view__parameter--header",
                                                            },
                                                            [
                                                              _c("span", [
                                                                _vm._v(
                                                                  " " +
                                                                    _vm._s(
                                                                      param.name
                                                                    ) +
                                                                    " "
                                                                ),
                                                                param.key
                                                                  ? _c("i", [
                                                                      _vm._v(
                                                                        _vm._s(
                                                                          param.key
                                                                        ) + " "
                                                                      ),
                                                                    ])
                                                                  : _vm._e(),
                                                                _c("b", [
                                                                  _vm._v(
                                                                    _vm._s(
                                                                      param.operator
                                                                    )
                                                                  ),
                                                                ]),
                                                              ]),
                                                            ]
                                                          ),
                                                          _c(
                                                            "div",
                                                            {
                                                              staticClass:
                                                                "segment-view__parameter--values",
                                                            },
                                                            [
                                                              param.isBetweenDate
                                                                ? _c("span", [
                                                                    _c("i", [
                                                                      _vm._v(
                                                                        _vm._s(
                                                                          param.parameterValues[0].split(
                                                                            "_"
                                                                          )[0]
                                                                        )
                                                                      ),
                                                                    ]),
                                                                    _vm._v(
                                                                      " and "
                                                                    ),
                                                                    _c("i", [
                                                                      _vm._v(
                                                                        _vm._s(
                                                                          param.parameterValues[0].split(
                                                                            "_"
                                                                          )[1]
                                                                        )
                                                                      ),
                                                                    ]),
                                                                  ])
                                                                : _c("i", [
                                                                    _vm._v(
                                                                      _vm._s(
                                                                        param.parameterValues.join(
                                                                          "; "
                                                                        )
                                                                      )
                                                                    ),
                                                                  ]),
                                                              paramIndex !==
                                                              filter.parameters
                                                                .length -
                                                                1
                                                                ? _c("span", [
                                                                    _c("b", [
                                                                      _vm._v(
                                                                        " " +
                                                                          _vm._s(
                                                                            filter.parameterLogicalOperator ===
                                                                              "all"
                                                                              ? "and"
                                                                              : "or"
                                                                          )
                                                                      ),
                                                                    ]),
                                                                  ])
                                                                : _vm._e(),
                                                            ]
                                                          ),
                                                        ]
                                                      )
                                                    }
                                                  ),
                                                  0
                                                ),
                                              ]
                                            ),
                                          ]
                                        )
                                      : _vm._e(),
                                    filter.topics.length
                                      ? _c(
                                          "div",
                                          { staticClass: "segment-view__row" },
                                          [
                                            _c("span", [
                                              _vm._v(
                                                _vm._s(
                                                  _vm.$t(
                                                    "audience.topics.visitedFromTopics"
                                                  )
                                                ) + ": "
                                              ),
                                              _c("b", [
                                                _vm._v(
                                                  _vm._s(
                                                    filter.topics.join(", ")
                                                  )
                                                ),
                                              ]),
                                            ]),
                                          ]
                                        )
                                      : _vm._e(),
                                  ])
                                : +filter.type === _vm.filterTypes.segment
                                ? _c("div", [
                                    block.filters.length > 1 &&
                                    filterIndex !== 0
                                      ? _c(
                                          "div",
                                          [
                                            _c(
                                              "b-button",
                                              {
                                                staticClass:
                                                  "segment__button--or-multiple",
                                              },
                                              [
                                                _c("span", [
                                                  _vm._v(
                                                    _vm._s(
                                                      _vm.$t("audience.or")
                                                    )
                                                  ),
                                                ]),
                                              ]
                                            ),
                                          ],
                                          1
                                        )
                                      : _vm._e(),
                                    _c(
                                      "div",
                                      { staticClass: "segment-view__row" },
                                      [
                                        _c("span", [
                                          _vm._v(" Users from segment: "),
                                          _c("b", [
                                            _vm._v(_vm._s(filter.segment.name)),
                                          ]),
                                          filter.isDisabled
                                            ? _c(
                                                "i",
                                                {
                                                  staticClass:
                                                    "segment-view__disabled-label",
                                                },
                                                [
                                                  _vm._v(
                                                    " (" +
                                                      _vm._s(
                                                        filter.segment.label
                                                      ) +
                                                      ")"
                                                  ),
                                                ]
                                              )
                                            : _vm._e(),
                                        ]),
                                        filter.isDisabled
                                          ? _c("InformationSidebar", {
                                              staticClass:
                                                "segment-view__info-icon",
                                              attrs: {
                                                code: `${blockIndex}-${filterIndex}`,
                                                size: "18",
                                                title: _vm.$t(
                                                  "audience.disabledFilter"
                                                ),
                                                content: `''${
                                                  filter.segment.name
                                                }'' is ${filter.segment.label.toLowerCase()},
                  users targeted with this segment will no longer be part of ''${
                    _vm.segment.name
                  }''`,
                                              },
                                            })
                                          : _vm._e(),
                                      ],
                                      1
                                    ),
                                  ])
                                : _vm._e(),
                            ]
                          )
                        }),
                        _c(
                          "div",
                          {
                            staticClass: "segment__button-and__container",
                            class: {
                              "segment__button-and__container--multiple":
                                _vm.segment.blocks.length > 1 &&
                                blockIndex !== _vm.segment.blocks.length - 1,
                            },
                          },
                          [
                            blockIndex !== _vm.segment.blocks.length - 1
                              ? _c("div", {
                                  staticClass:
                                    "segment__arrow segment__arrow--multiple",
                                })
                              : _vm._e(),
                            _vm.segment.blocks.length > 1 &&
                            blockIndex !== _vm.segment.blocks.length - 1
                              ? _c(
                                  "div",
                                  [
                                    _c(
                                      "b-button",
                                      {
                                        staticClass:
                                          "segment__button--reversed segment__button--reversed--multiple",
                                      },
                                      [
                                        _c("span", [
                                          _vm._v(
                                            _vm._s(_vm.$t("audience.and"))
                                          ),
                                        ]),
                                      ]
                                    ),
                                  ],
                                  1
                                )
                              : _vm._e(),
                          ]
                        ),
                      ],
                      2
                    )
                  }),
                ],
                2
              ),
              _vm.segment.activations.length > 0
                ? _c("div", { staticClass: "container__wrapper" }, [
                    _c(
                      "div",
                      {
                        staticClass:
                          "container__header segment-view__container_header",
                      },
                      [
                        _c("h5", { staticClass: "container__header-title" }, [
                          _vm._v(" " + _vm._s(_vm.$t("Integrations")) + " "),
                        ]),
                      ]
                    ),
                    _c(
                      "div",
                      { staticClass: "container__integrations" },
                      _vm._l(_vm.segment.activations, function (activation) {
                        return _c("IntegrationCard", {
                          key: activation.id,
                          attrs: { activation: activation, "view-mode": "" },
                        })
                      }),
                      1
                    ),
                  ])
                : _vm._e(),
            ]
          )
        : _vm._e(),
      _vm.formState.isFormLoaded
        ? _c("Footer", {
            attrs: { "view-mode": "" },
            on: {
              back: function ($event) {
                return _vm.cancel()
              },
            },
          })
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }