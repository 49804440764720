<template>
  <section>
    <div v-if="!isLoading" class="view-screen__container">
      <div class="query-view__container">
        <div class="basic-information__container">
          <h5 class="container__title container__title--margin">
            {{ $t('data.basicInfo') }}
          </h5>
          <b-link
            v-if="query.canEdit"
            class="edit__button"
            :to="{
              name: 'query-segment-edit',
              params: {
                organizationId,
                segmentId
              }
            }"
          >
            <GjIcon name="Edit" size="18"></GjIcon>
            <span>{{ $t('Edit') }}</span>
          </b-link>

          <div v-if="query.name" class="item__container">
            <label class="template__text">{{ $t('Name') }}</label>
            <span class="query__text">{{ query.name.value }}</span>
          </div>
          <div v-if="query.description" class="item__container">
            <label class="template__text">{{ $t('Description') }}</label>
            <ReadMore
              :text="query.description.value"
              mode="view"
              :classes="['query__text']"
            />
          </div>
        </div>
        <div class="container__wrapper">
          <h5 class="container__title">{{ $t('audience.code') }}</h5>
          <div v-if="query.query" id="query">
            <b-form-textarea
              id="textarea-query"
              v-model="query.query.value"
              rows="8"
              no-resize
              class="query__area"
              disabled
            />
          </div>
        </div>
        <div class="container__wrapper">
          <div
            v-if="
              query.reprocessOperator &&
              query.reprocessValue &&
              query.reprocessTimeUnit
            "
          >
            <h5 class="container__title">{{ $t('audience.schedule') }}</h5>
            <p class="template__text template__text--unset">
              {{ $t('audience.segmentQueryWillRun') }}
              <span class="query__text">{{
                formatScheduleText(
                  query.reprocessOperator,
                  query.reprocessValue,
                  query.reprocessTimeUnit
                )
              }}</span>
            </p>
          </div>
        </div>
        <div class="container__wrapper">
          <h5 class="container__title">{{ $t('Integrations') }}</h5>
          <div class="container__integrations">
            <IntegrationCard
              v-for="activation in query.activations"
              :key="activation.id"
              :activation="activation"
              view-mode
            />
          </div>
        </div>
      </div>

      <Footer view-mode @back="cancel()" />
    </div>
    <div v-else class="loading__container">
      <LoadingBar />
    </div>
  </section>
</template>

<script>
import store from '@/store'
import {
  Footer,
  GjIcon,
  IntegrationCard,
  LoadingBar,
  ReadMore
} from '@nodus/utilities-front'
import { BFormTextarea, BLink } from 'bootstrap-vue'
import { computed, getCurrentInstance, onBeforeMount, ref } from 'vue'

export default {
  name: 'QueryView',
  components: {
    BFormTextarea,
    BLink,
    LoadingBar,
    GjIcon,
    IntegrationCard,
    Footer,
    ReadMore
  },
  setup() {
    const vm = getCurrentInstance().proxy
    const { organizationId, segmentId } = vm.$route.params
    const isLoading = ref(false)

    const query = computed(() => store.getters['queries/querySegment'])

    onBeforeMount(async () => {
      isLoading.value = true
      await store.dispatch('queries/getQuerySegment', {
        id: organizationId,
        segmentId
      })
      isLoading.value = false
    })

    const formatScheduleText = (operator, value, timeUnit) =>
      operator.text === 'Once'
        ? operator.text
        : `${operator.text.toLowerCase()} ${value.value} ${timeUnit.text}`

    const cancel = () => {
      vm.$router.push({ name: 'queries' })
    }

    return {
      isLoading,
      query,
      organizationId,
      segmentId,
      formatScheduleText,
      cancel
    }
  }
}
</script>

<style lang="scss">
@import '@/assets/scss/views/segments/segmentCreate.scss';

.template__text--unset {
  max-width: unset;
}

.query__text {
  display: inline-block;
  font-size: 14px;
  max-width: 450px;
  color: #052d61;
}

.query__area {
  border-radius: 6px;
  background-color: #fafafa;
  font-size: 14px;
  position: relative;

  &:disabled {
    background-color: #fafafa;
  }
}

.integration__title--inactive {
  color: #a3b0c2;
}

.dot {
  height: 6px;
  width: 6px;
  border-radius: 50%;
  display: inline-block;
  margin: 0 8px 2px 0;

  &--true {
    background-color: #36b37e;
  }

  &--false {
    background-color: #e34850;
  }
}
</style>
