var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "section",
    { staticClass: "create-segment-form-wrapper" },
    [
      _c(
        "b-form",
        { staticClass: "form__wrapper", attrs: { novalidate: "" } },
        [
          _c("div", { staticClass: "container__wrapper" }, [
            _c("div", { staticClass: "container__header" }, [
              _c("h5", { staticClass: "container__header-title" }, [
                _vm._v(_vm._s(_vm.$t("data.basicInfo"))),
              ]),
            ]),
            _c(
              "div",
              { staticClass: "container__content" },
              [
                _c(
                  "b-col",
                  {
                    staticClass: "input__wrapper pl-0",
                    attrs: { id: "name", lg: "6" },
                  },
                  [
                    _c("label", { staticClass: "mr-1" }, [
                      _vm._v(_vm._s(_vm.$t("Name")) + " *"),
                    ]),
                    _c("b-form-input", {
                      staticClass: "form__input",
                      class: {
                        "form__input--invalid":
                          _vm.query.name.errors &&
                          _vm.query.name.errors.length > 0,
                      },
                      attrs: {
                        id: "query-name",
                        placeholder: _vm.$t("audience.enterAudienceName"),
                        type: "text",
                      },
                      on: {
                        input: function ($event) {
                          return _vm.handleFormInput(_vm.state, [
                            _vm.query.name,
                          ])
                        },
                      },
                      model: {
                        value: _vm.query.name.value,
                        callback: function ($$v) {
                          _vm.$set(
                            _vm.query.name,
                            "value",
                            typeof $$v === "string" ? $$v.trim() : $$v
                          )
                        },
                        expression: "query.name.value",
                      },
                    }),
                    _vm._l(_vm.query.name.errors, function (error, errIndex) {
                      return _c(
                        "div",
                        {
                          key: errIndex,
                          staticClass:
                            "container__schedule__info-error-message",
                        },
                        [_c("span", [_vm._v(_vm._s(error))])]
                      )
                    }),
                  ],
                  2
                ),
                _c(
                  "b-col",
                  { staticClass: "input__wrapper pl-0", attrs: { lg: "6" } },
                  [
                    _c("label", [_vm._v(_vm._s(_vm.$t("Description")))]),
                    _c("b-form-textarea", {
                      staticClass: "form__input form__input-textarea",
                      class: {
                        "form__input--invalid":
                          _vm.query.description.errors &&
                          _vm.query.description.errors.length > 0,
                      },
                      attrs: {
                        id: "query-description",
                        placeholder: _vm.$t(
                          "audience.enterAudienceDescription"
                        ),
                        rows: "3",
                      },
                      on: {
                        input: function ($event) {
                          return _vm.handleFormInput(_vm.state, [
                            _vm.query.description,
                          ])
                        },
                      },
                      model: {
                        value: _vm.query.description.value,
                        callback: function ($$v) {
                          _vm.$set(
                            _vm.query.description,
                            "value",
                            typeof $$v === "string" ? $$v.trim() : $$v
                          )
                        },
                        expression: "query.description.value",
                      },
                    }),
                    _vm._l(
                      _vm.query.description.errors,
                      function (error, errIndex) {
                        return _c(
                          "div",
                          {
                            key: errIndex,
                            staticClass:
                              "container__schedule__info-error-message",
                          },
                          [_c("span", [_vm._v(_vm._s(error))])]
                        )
                      }
                    ),
                  ],
                  2
                ),
              ],
              1
            ),
          ]),
          _c(
            "div",
            { staticClass: "container__wrapper container__wrapper--height" },
            [
              _c(
                "div",
                {
                  staticClass:
                    "container__header documentation__header--padding",
                },
                [
                  _c("h5", { staticClass: "container__header-title" }, [
                    _vm._v(_vm._s(_vm.$t("audience.code"))),
                  ]),
                ]
              ),
              _c(
                "div",
                { staticClass: "documentation__info-text mb-1" },
                [
                  _c("GjIcon", {
                    staticClass: "mr-50",
                    attrs: { name: "Info", size: "18" },
                  }),
                  _c("span", [
                    _vm._v(
                      _vm._s(_vm.$t("audience.segmentsDocumentationLink")) + " "
                    ),
                    _c(
                      "a",
                      {
                        attrs: {
                          href: `${_vm.whitelabel.documentationUrl}/intro#custom-queries`,
                          target: "_blank",
                        },
                      },
                      [_vm._v(_vm._s(_vm.$t("Documentation").toLowerCase()))]
                    ),
                    _vm._v("."),
                  ]),
                ],
                1
              ),
              _c(
                "div",
                { staticClass: "container__content" },
                [
                  _c(
                    "b-col",
                    {
                      staticClass: "input__wrapper pl-0 pr-0",
                      attrs: { id: "query" },
                    },
                    [
                      _c("b-form-textarea", {
                        staticClass: "query__area",
                        class: {
                          "form__input--invalid":
                            _vm.query.query.errors &&
                            _vm.query.query.errors.length > 0,
                        },
                        attrs: {
                          id: "textarea-query",
                          rows: "8",
                          "no-resize": "",
                        },
                        on: {
                          input: function ($event) {
                            _vm.textareaHandler(),
                              _vm.handleFormInput(_vm.state, [_vm.query.query])
                          },
                        },
                        model: {
                          value: _vm.query.query.value,
                          callback: function ($$v) {
                            _vm.$set(_vm.query.query, "value", $$v)
                          },
                          expression: "query.query.value",
                        },
                      }),
                      _vm._l(
                        _vm.query.query.errors,
                        function (error, errIndex) {
                          return _c(
                            "div",
                            {
                              key: errIndex,
                              staticClass:
                                "container__schedule__info-error-message",
                            },
                            [_c("span", [_vm._v(_vm._s(error))])]
                          )
                        }
                      ),
                    ],
                    2
                  ),
                  _c(
                    "b-col",
                    {
                      staticClass: "textarea__validation pl-0 pr-0",
                      class: {
                        "textarea__validation--error":
                          !_vm.queryValidation.isValid &&
                          !_vm.queryValidation.isValidating &&
                          _vm.queryValidation.isValidated,
                        "textarea__validation--success":
                          _vm.queryValidation.isValid &&
                          !_vm.queryValidation.isValidating &&
                          _vm.queryValidation.isValidated,
                      },
                    },
                    [
                      _c(
                        "div",
                        { staticClass: "textarea__validation--display" },
                        [
                          _c("GjIcon", {
                            staticClass: "mr-50",
                            attrs: { name: "Info", size: "18" },
                          }),
                          _vm.queryValidation.message &&
                          _vm.queryValidation.isValidated
                            ? _c(
                                "div",
                                _vm._l(
                                  _vm.queryValidation.message,
                                  function (message, i) {
                                    return _c("div", { key: i }, [
                                      _c("span", [_vm._v(_vm._s(message))]),
                                    ])
                                  }
                                ),
                                0
                              )
                            : _c("span", [
                                _vm._v(
                                  _vm._s(
                                    _vm.$t("audience.queryValidationInfo")
                                  ) + "!"
                                ),
                              ]),
                        ],
                        1
                      ),
                      _c(
                        "b-button",
                        {
                          staticClass: "create__button validate__button",
                          attrs: {
                            id: "validate-query",
                            variant: "gradient-primary",
                            disabled:
                              _vm.isButtonValid ||
                              _vm.queryValidation.isDisabled ||
                              _vm.queryValidation.isValidating,
                          },
                          on: {
                            click: function ($event) {
                              return _vm.validateQuery(_vm.query.query.value)
                            },
                          },
                        },
                        [
                          _c("GjIcon", {
                            class: {
                              svg__animate: _vm.queryValidation.isValidating,
                            },
                            attrs: { name: "RefreshAlt" },
                          }),
                          _vm._v(
                            " " + _vm._s(_vm.$t("audience.validate")) + " "
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ]
          ),
          _c("div", { staticClass: "container__wrapper" }, [
            _c("div", { staticClass: "container__header" }, [
              _c("h5", { staticClass: "container__header-title" }, [
                _vm._v(_vm._s(_vm.$t("audience.schedule"))),
              ]),
            ]),
            _c(
              "div",
              { staticClass: "container__schedule" },
              [
                _c("div", { staticClass: "schedule__inputs" }, [
                  _c(
                    "span",
                    { staticClass: "container__schedule__label-text" },
                    [_vm._v(_vm._s(_vm.$t("audience.runSegmentQuery")))]
                  ),
                  _c(
                    "div",
                    { staticClass: "input__wrapper" },
                    [
                      _c("v-select", {
                        staticClass: "form__input",
                        attrs: {
                          id: "query-schedule",
                          options: _vm.reprocessOperatorOptions,
                          label: "text",
                          clearable: false,
                        },
                        on: {
                          input: function ($event) {
                            _vm.handleScheduleOperator(),
                              _vm.handleFormInput(_vm.state, [
                                _vm.query.reprocessValue,
                              ])
                          },
                        },
                        model: {
                          value: _vm.query.reprocessOperator,
                          callback: function ($$v) {
                            _vm.$set(_vm.query, "reprocessOperator", $$v)
                          },
                          expression: "query.reprocessOperator",
                        },
                      }),
                    ],
                    1
                  ),
                  _vm.query.reprocessOperator &&
                  _vm.query.reprocessOperator.text === "Every"
                    ? _c(
                        "div",
                        {
                          staticClass: "input__wrapper",
                          attrs: { id: "reprocessValue" },
                        },
                        [
                          _c("b-form-input", {
                            staticClass: "form__input-number",
                            class: {
                              "form__input--invalid":
                                _vm.query.reprocessValue.errors &&
                                _vm.query.reprocessValue.errors.length > 0,
                            },
                            attrs: {
                              id: "schedule-number",
                              placeholder: "2",
                              type: "number",
                              min: "1",
                            },
                            on: {
                              blur: function ($event) {
                                return _vm.handleInput(
                                  _vm.query.reprocessValue.value
                                )
                              },
                              input: function ($event) {
                                return _vm.handleFormInput(_vm.state, [
                                  _vm.query.reprocessValue,
                                ])
                              },
                            },
                            model: {
                              value: _vm.query.reprocessValue.value,
                              callback: function ($$v) {
                                _vm.$set(_vm.query.reprocessValue, "value", $$v)
                              },
                              expression: "query.reprocessValue.value",
                            },
                          }),
                        ],
                        1
                      )
                    : _vm._e(),
                  _vm.query.reprocessOperator &&
                  _vm.query.reprocessOperator.text === "Every"
                    ? _c(
                        "div",
                        { staticClass: "input__wrapper" },
                        [
                          _c("v-select", {
                            staticClass: "form__input",
                            attrs: {
                              id: "schedule-time-unit",
                              placeholder: _vm.$t("Select"),
                              options: _vm.reprocessTimeUnitOptions,
                              label: "text",
                              clearable: false,
                            },
                            on: {
                              input: function ($event) {
                                return _vm.handleFormInput(_vm.state, [
                                  _vm.query.reprocessValue,
                                ])
                              },
                            },
                            model: {
                              value: _vm.query.reprocessTimeUnit,
                              callback: function ($$v) {
                                _vm.$set(_vm.query, "reprocessTimeUnit", $$v)
                              },
                              expression: "query.reprocessTimeUnit",
                            },
                          }),
                        ],
                        1
                      )
                    : _vm._e(),
                ]),
                _vm._l(
                  _vm.query.reprocessValue.errors,
                  function (error, errIndex) {
                    return _c(
                      "div",
                      {
                        key: errIndex,
                        staticClass: "container__schedule__info-error-message",
                      },
                      [_c("span", [_vm._v(_vm._s(error))])]
                    )
                  }
                ),
                _vm.query.reprocessValue.errors.length === 0
                  ? _c(
                      "div",
                      { staticClass: "container__schedule__info-text" },
                      [
                        _c("span", [
                          _vm._v(
                            " " +
                              _vm._s(_vm.$t("audience.segmentQueryWillRun")) +
                              " " +
                              _vm._s(
                                _vm.query.reprocessOperator.text === "Once"
                                  ? "only once"
                                  : `every ${_vm.query.reprocessValue.value} ${_vm.query.reprocessTimeUnit.text}`
                              ) +
                              " "
                          ),
                        ]),
                      ]
                    )
                  : _vm._e(),
              ],
              2
            ),
          ]),
          _c(
            "div",
            { staticClass: "container__wrapper" },
            [
              _c("AudienceSizeCalculator", {
                attrs: {
                  data: {
                    query: _vm.query.query.value,
                    isValid: _vm.queryValidation.isValid,
                    type: "query",
                  },
                },
              }),
            ],
            1
          ),
          _vm.query.activations && _vm.query.activations.length
            ? _c("div", { staticClass: "container__wrapper" }, [
                _c("div", { staticClass: "container__header" }, [
                  _c("h5", { staticClass: "container__header-title" }, [
                    _vm._v(_vm._s(_vm.$t("Integrations"))),
                  ]),
                ]),
                _c(
                  "div",
                  { staticClass: "container__integrations" },
                  _vm._l(_vm.query.activations, function (activation) {
                    return _c("IntegrationCard", {
                      key: activation.id,
                      attrs: { activation: activation },
                      on: {
                        "state-change": function ($event) {
                          return _vm.handleFormInput(_vm.state)
                        },
                      },
                    })
                  }),
                  1
                ),
              ])
            : _vm._e(),
          _c("Footer", {
            attrs: {
              "is-loading": _vm.isLoading,
              submitted: _vm.state.submitted,
              "cancel-id": `cancel-create-query`,
              "create-id": `create-query`,
              "edit-mode": !!_vm.segmentId,
              "disable-button": _vm.isComplete,
            },
            on: {
              back: function ($event) {
                return _vm.cancel()
              },
              create: function ($event) {
                _vm.queryValidation.isValid
                  ? _vm.segmentId
                    ? _vm.openSaveModal()
                    : _vm.saveCustomQuery(_vm.query)
                  : {}
              },
            },
          }),
        ],
        1
      ),
      _c("SaveChangesModal", {
        attrs: { type: _vm.$t("Segment") },
        on: {
          save: function ($event) {
            return _vm.saveCustomQuery(_vm.query)
          },
        },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }