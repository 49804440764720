// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_GET_URL_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/getUrl.js";
var ___CSS_LOADER_URL_IMPORT_0___ = new URL("data:image/svg+xml,%3Csvg xmlns=%27http://www.w3.org/2000/svg%27 viewBox=%270 0 24 24%27 fill=%27none%27 stroke=%27%23A3B0C2%27 stroke-width=%272%27 stroke-linecap=%27round%27 stroke-linejoin=%27round%27 class=%27feather feather-chevron-right%27%3E%3Cpolyline points=%279 18 15 12 9 6%27%3E%3C/polyline%3E%3C/svg%3E", import.meta.url);
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/*
      ************ MEDIA QUERY MANAGER ******************

   0 - 576px:         Small devices (landscape phones, 576px and up)
   576px - 768px:     Medium devices (tablets, 768px and up)
   768px - 992px:     Large devices (desktops, 992px and up)
   992px - 1200px:    Big devices (large desktops, 1200px and up)
   1200px - *:        Extra large devices

*/
.bisko-app__audience .container__wrapper--height {
  height: 100%;
}
.bisko-app__audience .container__wrapper--padding {
  padding: 16px;
}
.bisko-app__audience .form__wrapper {
  margin: 0 auto;
  min-width: 1117px;
  max-width: 1117px;
}
.bisko-app__audience .container__content {
  display: flex;
  flex-direction: column;
  padding-top: 10px;
}
.bisko-app__audience .input__wrapper {
  padding-bottom: 15px;
}
.bisko-app__audience .input__wrapper label {
  font-size: 14px;
  line-height: 18px;
  color: #667c99;
  font-weight: 400;
}
.bisko-app__audience .input__wrapper select {
  font-size: 14px;
  line-height: 18px;
  color: #a3b0c2;
}
.bisko-app__audience .input__wrapper textarea {
  border-color: #e0e5eb;
}
.bisko-app__audience .input__wrapper .vs--disabled .vs__dropdown-toggle,
.bisko-app__audience .input__wrapper .vs--disabled .vs__clear,
.bisko-app__audience .input__wrapper .vs--disabled .vs__search,
.bisko-app__audience .input__wrapper .vs--disabled .vs__selected,
.bisko-app__audience .input__wrapper .vs--disabled .vs__open-indicator {
  background-color: #efefef !important;
}
.bisko-app__audience .input__wrapper .input__wrapper-properties {
  font-size: 14px;
}
.bisko-app__audience .input__wrapper .input__wrapper-properties .vs__selected {
  margin-top: 5px !important;
  background-color: white;
  border-radius: 20px !important;
  border: 1px solid #e0e5eb;
  color: #052d61;
  font-size: 14px !important;
}
.bisko-app__audience .input__wrapper .vs__actions button {
  display: none;
}
.bisko-app__audience .input__wrapper--margin {
  margin-top: 5px;
}
.bisko-app__audience .container__content--segments {
  width: 100%;
  align-items: flex-start;
  padding: 0 20px 10px;
  background-color: #fafafa;
  border-radius: 5px 5px 0 0;
  border: 1px solid #e0e5eb;
  position: relative;
  padding-top: 20px;
}
.bisko-app__audience .container__content--segments .btn-secondary {
  background-color: white !important;
}
.bisko-app__audience .container__content--segments.container__content--segments-last {
  border-radius: 0 0 5px 5px;
  padding-top: 10px;
}
.bisko-app__audience .container__content--segments.container__content--segments-last .segment__button--reversed {
  margin-left: 30px;
}
.bisko-app__audience .container__content--segments_remove {
  position: absolute;
  right: 20px;
  top: 30px;
}
.bisko-app__audience .segment__wrapper {
  display: flex;
  flex-wrap: wrap;
}
.bisko-app__audience .segment__wrapper-select {
  width: 220px;
  margin-right: 15px;
}
.bisko-app__audience .segment__button {
  font-size: 0.875rem;
  width: 126px;
  height: 34px;
  border-radius: 4px;
  background-color: #fafafa !important;
  border: 1px solid #e0e5eb !important;
  color: #052d61 !important;
  display: flex;
  justify-content: flex-start;
  margin-bottom: 10px;
}
.bisko-app__audience .segment__button:active, .bisko-app__audience .segment__button:focus {
  background-color: inherit !important;
}
.bisko-app__audience .segment__button:hover {
  background-color: #e0e5eb !important;
}
.bisko-app__audience .segment__button--icon {
  width: 28px;
}
.bisko-app__audience .segment__button--wrapper {
  display: inline-block;
}
.bisko-app__audience .segment__button--container {
  width: 126px;
  height: auto;
  margin-bottom: 10px;
}
.bisko-app__audience .segment__button--reversed {
  height: 34px;
  border-radius: 4px;
  background-color: white;
  border: 1px solid #e0e5eb !important;
  color: #052d61 !important;
  text-align: right;
  text-transform: uppercase;
}
.bisko-app__audience .segment__button--reversed:active, .bisko-app__audience .segment__button--reversed:focus {
  background-color: inherit !important;
}
.bisko-app__audience .segment__button--reversed:hover {
  background-color: #e0e5eb !important;
}
.bisko-app__audience .segment__button--reversed button {
  height: 100% !important;
  color: #052d61 !important;
  border: none;
}
.bisko-app__audience .segment__button--reversed button:not(:first-child) {
  padding: 0 4px 0 4px;
}
.bisko-app__audience .segment__button--reversed button:not(:first-child)::after {
  background-image: url(${___CSS_LOADER_URL_REPLACEMENT_0___}) !important;
}
.bisko-app__audience .segment__button--reversed button .segment__dropdown-divider {
  position: absolute;
  height: 24px;
  width: 0;
  border-left: 0.5px solid #e2e2e2;
  right: 2px;
}
.bisko-app__audience .segment__button--reversed ul.dropdown-menu {
  transform: translate3d(60px, -1px, 0px) !important;
  border: 1px solid #e0e5eb;
  min-width: 120px;
  padding: 6px 0;
  box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.12);
}
.bisko-app__audience .segment__button--reversed ul.dropdown-menu .dropdown-item {
  fill: #6e6b7b;
  padding: 4px 12px;
}
.bisko-app__audience .segment__button--reversed ul.dropdown-menu .dropdown-item span {
  font-size: 14px;
  text-transform: none;
  color: #6e6b7b;
}
.bisko-app__audience .segment__button--reversed--multiple {
  height: 26px;
  background-color: #667c99;
  color: #ffffff;
  position: relative;
  z-index: 1;
  margin: 10px 0 0 0 !important;
}
.bisko-app__audience .segment__button--reversed--multiple:active, .bisko-app__audience .segment__button--reversed--multiple:focus {
  background-color: #667c99 !important;
}
.bisko-app__audience .segment__button--reversed--multiple:hover {
  background-color: #667c99 !important;
}
.bisko-app__audience .segment__param {
  display: flex;
  align-items: center;
  color: #052d61;
  font-size: 14px;
  width: 100%;
  margin-bottom: 10px;
}
.bisko-app__audience .segment__param .input__wrapper {
  width: 230px;
  margin-right: 10px;
  margin-bottom: 5px;
  padding-bottom: 0;
}
.bisko-app__audience .segment__param .input__wrapper .form__input {
  height: 38px;
  font-size: 14px;
}
.bisko-app__audience .segment__param > .frequency__section {
  align-items: baseline;
}
.bisko-app__audience .segment__param-title {
  margin-right: 15px;
}
.bisko-app__audience .segment__param-title--width {
  max-width: 25px;
  width: 100%;
}
.bisko-app__audience .segment__icon {
  cursor: pointer !important;
}
.bisko-app__audience .segment__param-selects {
  display: flex;
  flex-direction: column;
  margin-bottom: 10px;
  align-items: flex-start;
}
.bisko-app__audience .segment__param-selects-content {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  margin-bottom: 5px;
}
.bisko-app__audience .input__wrapper--flex {
  display: flex;
  flex-wrap: wrap;
}
.bisko-app__audience .input__wrapper--width {
  width: 100% !important;
}
.bisko-app__audience .input__wrapper-parameters:first-child {
  margin-left: 70px;
}
.bisko-app__audience .segment__param-heading {
  display: flex;
  align-items: center;
}
.bisko-app__audience .vs__dropdown-option {
  padding-left: 25px;
  font-size: 14px;
}
.bisko-app__audience .vs__dropdown-option--disabled {
  padding-left: 15px;
  color: #667c99 !important;
  opacity: 1 !important;
}
.bisko-app__audience .input__wrapper--date {
  display: flex;
  align-items: center;
  width: 100% !important;
}
.bisko-app__audience .input__wrapper--date .form__input {
  height: 37px !important;
  display: flex !important;
  align-items: center !important;
  font-size: 14px;
  border: 1px solid #e0e5eb;
  max-width: 220px;
  width: 100%;
}
.bisko-app__audience .input__wrapper--date .b-calendar-grid-help {
  display: none;
}
.bisko-app__audience .input__wrapper--date span {
  margin: 0 5px;
}
.bisko-app__audience .time__last-param {
  width: 100% !important;
  display: flex;
  flex-wrap: wrap;
}
.bisko-app__audience .time__last-param .form__input {
  width: 150px;
}
.bisko-app__audience .time__last-param .form__input-time {
  width: 75px;
  margin-right: 5px;
}
.bisko-app__audience .parameters__custom .form__input {
  margin-right: 15px;
}
@media (max-width: 576px) {
.bisko-app__audience .parameters__custom .form__input {
    width: 180px;
}
}
.bisko-app__audience .user__segmentation-wrapper .form__input {
  width: 220px;
  height: 38px;
}
.bisko-app__audience .user__segmentation-wrapper .form__input.form__input--small {
  width: 135px;
}
.bisko-app__audience .segment__select .vs__selected-options {
  flex-wrap: nowrap;
  max-width: calc(100% - 40px);
}
.bisko-app__audience .segment__select .vs__selected {
  display: block;
  white-space: nowrap;
  text-overflow: ellipsis;
  max-width: 100%;
  overflow: hidden;
}
.bisko-app__audience .segment__select .vs__dropdown-option {
  white-space: normal;
  word-break: break-all;
}
.bisko-app__audience .segment__select .vs__dropdown-option--selected::after {
  right: 5px;
}
.bisko-app__audience .segment__select .disabled__prefix {
  font-size: 11px;
  color: gray;
  margin-left: 4px;
  display: inline-block;
  vertical-align: middle;
  font-style: italic;
}
.bisko-app__audience .segment__select .vs__dropdown-option--selected .disabled__prefix {
  color: #ffffff;
}
.bisko-app__audience .parameters__custom-content {
  display: flex;
  flex-wrap: wrap;
}
.bisko-app__audience .parameters__custom-content .form__input {
  margin-right: 5px;
}
.bisko-app__audience .form__input-params {
  width: 300px !important;
  padding-left: 5px !important;
  padding-right: 5px !important;
}
@media (max-width: 576px) {
.bisko-app__audience .form__input-params {
    width: 200px !important;
}
}
.bisko-app__audience .form__input-params .b-form-tags-button {
  display: none;
}
.bisko-app__audience .form__input-params .b-form-tag {
  margin-right: 5px;
  margin-top: 5px !important;
  background-color: white;
  border-radius: 20px;
  border: 1px solid #e0e5eb;
  color: #052d61;
  font-size: 14px;
  padding: 0 5px;
}
.bisko-app__audience .form__input-params .text-muted {
  display: block;
  margin-top: 4px;
  color: #e34850 !important;
  width: 100%;
  font-size: 0.857rem;
  color: #e34850;
}
.bisko-app__audience .segment__button-and__container {
  width: 100%;
  text-align: left;
  position: relative;
}
.bisko-app__audience .segment__button-and__container .segment__button--reversed {
  margin: 10px 0 0 50px;
  background-color: white !important;
}
.bisko-app__audience .segment__button-and__container .segment__button--reversed .btn-secondary {
  background-color: white !important;
}
.bisko-app__audience .segment__button-and__container .segment__button--reversed ul.dropdown-menu {
  transform: translate3d(70px, -1px, 0px) !important;
}
.bisko-app__audience .segment__button-and__container.segment__button-and__container--multiple {
  text-align: center;
}
.bisko-app__audience .segment__button-and__container.segment__button-and__container--multiple .segment__button--reversed.segment__button--reversed--multiple {
  background-color: #667c99 !important;
  color: white !important;
  border-color: #82868b !important;
}
.bisko-app__audience .segment__param-info {
  display: flex;
  flex-direction: column;
}
.bisko-app__audience .segment__param-info span {
  max-width: 300px;
}
.bisko-app__audience .segment__param-info input:disabled {
  display: none;
}
.bisko-app__audience .segment__param-span {
  margin-right: 10px;
}
.bisko-app__audience .add__parameters {
  display: inline-flex;
  margin-top: 25px;
  margin-left: 70px;
  width: -moz-fit-content;
  width: fit-content;
  cursor: pointer;
}
.bisko-app__audience .add__parameters--margin {
  margin-top: 0;
}
.bisko-app__audience .add__parameters-disabled {
  opacity: 0.5;
  cursor: default;
}
.bisko-app__audience .add__parameters-disabled .segment__icon {
  cursor: default !important;
}
.bisko-app__audience .parameters__operators {
  margin-right: 15px;
  margin-left: 30px;
  margin-top: 10px;
  max-width: 25px;
  width: 100%;
}
.bisko-app__audience .segments__remove {
  align-self: flex-start;
  margin-top: 9px;
}
.bisko-app__audience .params__remove {
  position: absolute;
  right: 20px;
  margin-top: 8px;
}
.bisko-app__audience .segment__arrow {
  width: 25px;
  height: 27px;
  border-left: 1px solid #e0e5eb;
  border-bottom: 1px solid #e0e5eb;
  position: absolute;
  top: 0;
  left: 25px;
}
.bisko-app__audience .segment__arrow--multiple {
  left: 50%;
  border-bottom: 0;
  height: 52px;
}
.bisko-app__audience .parameter__tag-help {
  margin-top: 2px;
  font-size: 11px;
  font-style: italic;
  color: #a3b0c2;
}
.bisko-app__audience .container__integrations {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 30px;
}
@media (max-width: 768) {
.bisko-app__audience .container__integrations {
    grid-template-columns: repeat(2, 1fr);
}
}
.bisko-app__audience .container__integrations-content {
  width: 100%;
  border-radius: 5px;
  border: 1px solid #e0e5eb;
  padding: 20px;
  font-size: 14px;
  color: #667c99;
}
.bisko-app__audience .container__integrations-content .integration__title {
  font-size: 16px;
  margin: 5px 0;
  font-weight: 600;
}
.bisko-app__audience .container__integrations-content .integration__title--inactive {
  color: #a3b0c2;
}
.bisko-app__audience .container__integrations-content-header {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  margin-bottom: 30px;
}
.bisko-app__audience .container__integrations-content-img {
  width: 64px;
  height: 64px;
  display: flex;
  align-items: center;
  padding: 5px;
  border: 1px solid #e0e5eb;
  border-radius: 4px;
}
.bisko-app__audience .container__integrations-content-img img {
  width: 100%;
}
.bisko-app__audience .segment__button--or-multiple {
  position: absolute;
  top: -14px;
  left: 48%;
  width: 44px;
  height: 26px;
  font-size: 14px;
}
.bisko-app__audience .segment__button--or-multiple.btn-secondary {
  background-color: #667c99 !important;
  color: white !important;
}
.bisko-app__audience .vs__selected-options span {
  white-space: nowrap;
}
.bisko-app__audience .parameter__dates {
  display: flex;
  align-items: flex-start;
}
.bisko-app__audience .parameter__dates .form__input {
  height: 37px !important;
  align-items: center !important;
  font-size: 14px;
  border: 1px solid #e0e5eb;
  width: 220px;
}
.bisko-app__audience .parameter__dates .form__input label {
  margin-top: 10px;
}
.bisko-app__audience .segment__param--wrapper {
  display: flex;
  flex-direction: column;
}
.bisko-app__audience .segment__topics {
  align-items: baseline;
}
.bisko-app__audience .segment__param .topics__select {
  width: unset;
  min-width: 280px;
}
.bisko-app__audience .segment__datetime-picker .vdpFloating > .vdpInnerWrap {
  max-width: 40rem;
  width: 15rem;
  min-height: 20rem;
}
.bisko-app__audience .segment__datetime-picker .vdpTable .vdpHeadCell {
  font-size: 12px;
}
.bisko-app__audience .segment__datetime-picker .vdpTable .vdpHeadCell::before {
  content: none;
}
.bisko-app__audience .segment__datetime-picker .vdpTable .vdpHeadCell::after {
  content: none;
}
.bisko-app__audience .segment__datetime-picker .vdpTable thead {
  transform: translateY(-25%);
}
.bisko-app__audience .segment__datetime-picker .vdpTable tr {
  transform: translateY(25%);
}
.bisko-app__audience .segment__datetime-picker .vdpTable tbody {
  transform: translateY(-5%);
}
.bisko-app__audience .segment__datetime-picker .vdpTable .vdpRow {
  font-size: 10px;
}
.bisko-app__audience .segment__datetime-picker .vdpInnerWrap > .vdpTable {
  margin-bottom: 0px;
}
.bisko-app__audience .segment__datetime-picker .vdpComponent.vdpWithInput > input {
  height: 37px;
  font-size: 14px;
  border: 1px solid #e0e5eb;
  border-radius: 4px;
  color: #6e6b7b;
  padding-left: 10px;
  padding-right: 5px;
}
.bisko-app__audience .segment__datetime-picker .vdpComponent.vdpWithInput > input[readonly=readonly] {
  background: #efefef;
}
.bisko-app__audience .segment__datetime-picker .vdpComponent.vdpWithInput > input:focus-visible {
  outline-width: 0px;
}
.bisko-app__audience .error__info-message {
  font-size: 13px;
  color: #e34850;
  height: 18px;
}
.bisko-app__audience .error__info-message span {
  position: absolute;
}
.bisko-app__audience .error__info-message_text-wrap span {
  max-width: 220px;
}
.bisko-app__audience .error__info-message_no-height {
  height: 0;
}
.bisko-app__audience .organization__select {
  display: inline-block;
}
.bisko-app__audience .organization__select .form__input {
  width: 220px;
  background: white;
}
.bisko-app__audience .segment__combine--input {
  width: auto !important;
}
.bisko-app__audience .segment__combine--input div:not(:first-child) {
  display: inline-block;
}
.bisko-app__audience .form__input-tags .b-form-tags-input {
  padding-left: 10px !important;
}
@media only screen and (max-width: 1440px) {
.bisko-app__audience .form__wrapper {
    width: 100%;
    min-width: auto;
}
.bisko-app__audience .parameters__custom {
    margin-bottom: 5px;
}
.bisko-app__audience .date-parameter-specific {
    margin-left: 70px;
}
.bisko-app__audience .segment__key-input {
    margin-left: 70px;
}
}
@media only screen and (max-width: 1350px) {
.bisko-app__audience .parameters__custom {
    margin-bottom: 5px;
}
.bisko-app__audience .parameters__custom-content {
    margin-left: 70px;
}
.bisko-app__audience .parameters__custom-content--no-margin {
    margin-left: unset !important;
}
.bisko-app__audience .segment__key-input {
    margin-left: unset;
}
.bisko-app__audience .date-parameter-specific {
    margin-left: unset;
}
}
@media only screen and (max-width: 992px) {
.bisko-app__audience .parameters__custom-content {
    margin-left: 70px;
}
.bisko-app__audience .parameters__custom-content .form__input {
    margin-bottom: 5px;
}
.bisko-app__audience .user__segmentation-wrapper .segment__key-input {
    margin-left: unset;
    width: 220px;
}
.bisko-app__audience .duration__wrapper {
    flex-wrap: wrap;
}
.bisko-app__audience .duration-dates__wrapper,
  .bisko-app__audience .time__last-param {
    margin: 5px 0 0 28px;
}
.bisko-app__audience .frequency__section {
    flex-wrap: wrap;
}
.bisko-app__audience .frequency__section > div:last-child {
    margin-left: 28px;
}
}
@media only screen and (max-width: 768px) {
.bisko-app__audience .parameters__custom-content {
    margin-left: unset;
}
.bisko-app__audience .parameters__custom-content .form__input {
    margin-bottom: 5px;
}
.bisko-app__audience .input__wrapper-parameters:first-child {
    margin-left: unset;
}
.bisko-app__audience .segment__param-title {
    font-size: 12px;
}
.bisko-app__audience .add__parameters {
    margin-left: unset;
}
}
@media only screen and (min-width: 1323px) and (max-width: 1350px), only screen and (min-width: 1060px) and (max-width: 1199px) {
.bisko-app__audience .parameter-date--no-margin {
    margin-left: 0px !important;
}
}
@media only screen and (min-width: 1018px) and (max-width: 1060px), only screen and (min-width: 1278px) and (max-width: 1322px) {
.bisko-app__audience .parameter-datetime--no-margin {
    margin-left: 0px !important;
}
}
@media only screen and (min-width: 1143px) and (max-width: 1199px) {
.bisko-app__audience .parameters-not-custom {
    margin-left: 0 !important;
}
}
@media only screen and (min-width: 837px) and (max-width: 1142px), only screen and (min-width: 1200px) and (max-width: 1350px) {
.bisko-app__audience .parameters__bolean-content {
    margin-left: 0px !important;
}
}
@media only screen and (min-width: 1063px) and (max-width: 1142px), only screen and (min-width: 1323px) and (max-width: 1350px) {
.bisko-app__audience .parameters__bolean-content-is {
    margin-left: 0px !important;
}
}
.bisko-app__audience .template__text--unset {
  max-width: unset;
}
.bisko-app__audience .query__text {
  display: inline-block;
  font-size: 14px;
  max-width: 450px;
  color: #052d61;
}
.bisko-app__audience .query__area {
  border-radius: 6px;
  background-color: #fafafa;
  font-size: 14px;
  position: relative;
}
.bisko-app__audience .query__area:disabled {
  background-color: #fafafa;
}
.bisko-app__audience .integration__title--inactive {
  color: #a3b0c2;
}
.bisko-app__audience .dot {
  height: 6px;
  width: 6px;
  border-radius: 50%;
  display: inline-block;
  margin: 0 8px 2px 0;
}
.bisko-app__audience .dot--true {
  background-color: #36b37e;
}
.bisko-app__audience .dot--false {
  background-color: #e34850;
}`, ""]);
// Exports
export default ___CSS_LOADER_EXPORT___;
