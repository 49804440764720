<template>
  <section>
    <div v-if="!isLoading" class="view-screen__container">
      <div class="container__wrapper container__wrapper--height">
        <div v-if="integration" class="integration-container__content">
          <div
            class="integration-container__content-header integration__header-container-view"
          >
            <div class="integration-container__content-header__image">
              <img :src="integration.imageUrl" />
            </div>
            <div class="integration-container__content-header__text">
              <span class="integration__header-name">{{
                integration.type
              }}</span>
              <span class="integration__description">{{
                integration.description
              }}</span>
            </div>
          </div>
          <div class="integration-container__content-body">
            <div class="item__container">
              <span class="template__text">{{ $t('Name') }}:</span>
              <span class="record__text">{{ integration.name }}</span>
            </div>
            <div
              v-for="(parameter, index) in integration.parameters"
              :key="index"
            >
              <div class="item__container">
                <span class="template__text">{{ parameter.name }}:</span>
                <span class="record__text">{{ parameter.value }}</span>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer view-mode @back="cancel()" />
    </div>
    <div v-else class="loading__container">
      <LoadingBar />
    </div>
  </section>
</template>

<script>
import store from '@/store'
import { Footer, LoadingBar } from '@nodus/utilities-front'
import { computed, getCurrentInstance, onMounted, ref } from 'vue'

export default {
  name: 'IntegrationView',
  components: {
    LoadingBar,
    Footer
  },
  setup() {
    const vm = getCurrentInstance().proxy
    const { organizationId, activationId } = vm.$route.params
    const isLoading = ref(false)

    onMounted(async () => {
      isLoading.value = true
      await store.dispatch('integrations/getIntegration', {
        id: organizationId,
        activationId
      })
      isLoading.value = false
    })

    const integration = computed(
      () => store.getters['integrations/integration']
    )

    const cancel = () => {
      vm.$router.push({ name: 'integrations' })
    }

    return {
      integration,
      isLoading,
      organizationId,
      cancel
    }
  }
}
</script>

<style lang="scss">
@import '@/assets/scss/views/integrations';
@import '@/assets/scss/views/shared';
</style>
