// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_GET_URL_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/getUrl.js";
var ___CSS_LOADER_URL_IMPORT_0___ = new URL("data:image/svg+xml,%3Csvg xmlns=%27http://www.w3.org/2000/svg%27 viewBox=%270 0 24 24%27 fill=%27none%27 stroke=%27%23A3B0C2%27 stroke-width=%272%27 stroke-linecap=%27round%27 stroke-linejoin=%27round%27 class=%27feather feather-chevron-right%27%3E%3Cpolyline points=%279 18 15 12 9 6%27%3E%3C/polyline%3E%3C/svg%3E", import.meta.url);
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `@charset "UTF-8";
/*
      ************ MEDIA QUERY MANAGER ******************

   0 - 576px:         Small devices (landscape phones, 576px and up)
   576px - 768px:     Medium devices (tablets, 768px and up)
   768px - 992px:     Large devices (desktops, 992px and up)
   992px - 1200px:    Big devices (large desktops, 1200px and up)
   1200px - *:        Extra large devices

*/
.bisko-app__audience .container__wrapper--height {
  height: 100%;
}
.bisko-app__audience .container__wrapper--padding {
  padding: 16px;
}
.bisko-app__audience .form__wrapper {
  margin: 0 auto;
  min-width: 1117px;
  max-width: 1117px;
}
.bisko-app__audience .container__content {
  display: flex;
  flex-direction: column;
  padding-top: 10px;
}
.bisko-app__audience .input__wrapper {
  padding-bottom: 15px;
}
.bisko-app__audience .input__wrapper label {
  font-size: 14px;
  line-height: 18px;
  color: #667c99;
  font-weight: 400;
}
.bisko-app__audience .input__wrapper select {
  font-size: 14px;
  line-height: 18px;
  color: #a3b0c2;
}
.bisko-app__audience .input__wrapper textarea {
  border-color: #e0e5eb;
}
.bisko-app__audience .input__wrapper .vs--disabled .vs__dropdown-toggle,
.bisko-app__audience .input__wrapper .vs--disabled .vs__clear,
.bisko-app__audience .input__wrapper .vs--disabled .vs__search,
.bisko-app__audience .input__wrapper .vs--disabled .vs__selected,
.bisko-app__audience .input__wrapper .vs--disabled .vs__open-indicator {
  background-color: #efefef !important;
}
.bisko-app__audience .input__wrapper .input__wrapper-properties {
  font-size: 14px;
}
.bisko-app__audience .input__wrapper .input__wrapper-properties .vs__selected {
  margin-top: 5px !important;
  background-color: white;
  border-radius: 20px !important;
  border: 1px solid #e0e5eb;
  color: #052d61;
  font-size: 14px !important;
}
.bisko-app__audience .input__wrapper .vs__actions button {
  display: none;
}
.bisko-app__audience .input__wrapper--margin {
  margin-top: 5px;
}
.bisko-app__audience .container__content--segments {
  width: 100%;
  align-items: flex-start;
  padding: 0 20px 10px;
  background-color: #fafafa;
  border-radius: 5px 5px 0 0;
  border: 1px solid #e0e5eb;
  position: relative;
  padding-top: 20px;
}
.bisko-app__audience .container__content--segments .btn-secondary {
  background-color: white !important;
}
.bisko-app__audience .container__content--segments.container__content--segments-last {
  border-radius: 0 0 5px 5px;
  padding-top: 10px;
}
.bisko-app__audience .container__content--segments.container__content--segments-last .segment__button--reversed {
  margin-left: 30px;
}
.bisko-app__audience .container__content--segments_remove {
  position: absolute;
  right: 20px;
  top: 30px;
}
.bisko-app__audience .segment__wrapper {
  display: flex;
  flex-wrap: wrap;
}
.bisko-app__audience .segment__wrapper-select {
  width: 220px;
  margin-right: 15px;
}
.bisko-app__audience .segment__button {
  font-size: 0.875rem;
  width: 126px;
  height: 34px;
  border-radius: 4px;
  background-color: #fafafa !important;
  border: 1px solid #e0e5eb !important;
  color: #052d61 !important;
  display: flex;
  justify-content: flex-start;
  margin-bottom: 10px;
}
.bisko-app__audience .segment__button:active, .bisko-app__audience .segment__button:focus {
  background-color: inherit !important;
}
.bisko-app__audience .segment__button:hover {
  background-color: #e0e5eb !important;
}
.bisko-app__audience .segment__button--icon {
  width: 28px;
}
.bisko-app__audience .segment__button--wrapper {
  display: inline-block;
}
.bisko-app__audience .segment__button--container {
  width: 126px;
  height: auto;
  margin-bottom: 10px;
}
.bisko-app__audience .segment__button--reversed {
  height: 34px;
  border-radius: 4px;
  background-color: white;
  border: 1px solid #e0e5eb !important;
  color: #052d61 !important;
  text-align: right;
  text-transform: uppercase;
}
.bisko-app__audience .segment__button--reversed:active, .bisko-app__audience .segment__button--reversed:focus {
  background-color: inherit !important;
}
.bisko-app__audience .segment__button--reversed:hover {
  background-color: #e0e5eb !important;
}
.bisko-app__audience .segment__button--reversed button {
  height: 100% !important;
  color: #052d61 !important;
  border: none;
}
.bisko-app__audience .segment__button--reversed button:not(:first-child) {
  padding: 0 4px 0 4px;
}
.bisko-app__audience .segment__button--reversed button:not(:first-child)::after {
  background-image: url(${___CSS_LOADER_URL_REPLACEMENT_0___}) !important;
}
.bisko-app__audience .segment__button--reversed button .segment__dropdown-divider {
  position: absolute;
  height: 24px;
  width: 0;
  border-left: 0.5px solid #e2e2e2;
  right: 2px;
}
.bisko-app__audience .segment__button--reversed ul.dropdown-menu {
  transform: translate3d(60px, -1px, 0px) !important;
  border: 1px solid #e0e5eb;
  min-width: 120px;
  padding: 6px 0;
  box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.12);
}
.bisko-app__audience .segment__button--reversed ul.dropdown-menu .dropdown-item {
  fill: #6e6b7b;
  padding: 4px 12px;
}
.bisko-app__audience .segment__button--reversed ul.dropdown-menu .dropdown-item span {
  font-size: 14px;
  text-transform: none;
  color: #6e6b7b;
}
.bisko-app__audience .segment__button--reversed--multiple {
  height: 26px;
  background-color: #667c99;
  color: #ffffff;
  position: relative;
  z-index: 1;
  margin: 10px 0 0 0 !important;
}
.bisko-app__audience .segment__button--reversed--multiple:active, .bisko-app__audience .segment__button--reversed--multiple:focus {
  background-color: #667c99 !important;
}
.bisko-app__audience .segment__button--reversed--multiple:hover {
  background-color: #667c99 !important;
}
.bisko-app__audience .segment__param {
  display: flex;
  align-items: center;
  color: #052d61;
  font-size: 14px;
  width: 100%;
  margin-bottom: 10px;
}
.bisko-app__audience .segment__param .input__wrapper {
  width: 230px;
  margin-right: 10px;
  margin-bottom: 5px;
  padding-bottom: 0;
}
.bisko-app__audience .segment__param .input__wrapper .form__input {
  height: 38px;
  font-size: 14px;
}
.bisko-app__audience .segment__param > .frequency__section {
  align-items: baseline;
}
.bisko-app__audience .segment__param-title {
  margin-right: 15px;
}
.bisko-app__audience .segment__param-title--width {
  max-width: 25px;
  width: 100%;
}
.bisko-app__audience .segment__icon {
  cursor: pointer !important;
}
.bisko-app__audience .segment__param-selects {
  display: flex;
  flex-direction: column;
  margin-bottom: 10px;
  align-items: flex-start;
}
.bisko-app__audience .segment__param-selects-content {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  margin-bottom: 5px;
}
.bisko-app__audience .input__wrapper--flex {
  display: flex;
  flex-wrap: wrap;
}
.bisko-app__audience .input__wrapper--width {
  width: 100% !important;
}
.bisko-app__audience .input__wrapper-parameters:first-child {
  margin-left: 70px;
}
.bisko-app__audience .segment__param-heading {
  display: flex;
  align-items: center;
}
.bisko-app__audience .vs__dropdown-option {
  padding-left: 25px;
  font-size: 14px;
}
.bisko-app__audience .vs__dropdown-option--disabled {
  padding-left: 15px;
  color: #667c99 !important;
  opacity: 1 !important;
}
.bisko-app__audience .input__wrapper--date {
  display: flex;
  align-items: center;
  width: 100% !important;
}
.bisko-app__audience .input__wrapper--date .form__input {
  height: 37px !important;
  display: flex !important;
  align-items: center !important;
  font-size: 14px;
  border: 1px solid #e0e5eb;
  max-width: 220px;
  width: 100%;
}
.bisko-app__audience .input__wrapper--date .b-calendar-grid-help {
  display: none;
}
.bisko-app__audience .input__wrapper--date span {
  margin: 0 5px;
}
.bisko-app__audience .time__last-param {
  width: 100% !important;
  display: flex;
  flex-wrap: wrap;
}
.bisko-app__audience .time__last-param .form__input {
  width: 150px;
}
.bisko-app__audience .time__last-param .form__input-time {
  width: 75px;
  margin-right: 5px;
}
.bisko-app__audience .parameters__custom .form__input {
  margin-right: 15px;
}
@media (max-width: 576px) {
.bisko-app__audience .parameters__custom .form__input {
    width: 180px;
}
}
.bisko-app__audience .user__segmentation-wrapper .form__input {
  width: 220px;
  height: 38px;
}
.bisko-app__audience .user__segmentation-wrapper .form__input.form__input--small {
  width: 135px;
}
.bisko-app__audience .segment__select .vs__selected-options {
  flex-wrap: nowrap;
  max-width: calc(100% - 40px);
}
.bisko-app__audience .segment__select .vs__selected {
  display: block;
  white-space: nowrap;
  text-overflow: ellipsis;
  max-width: 100%;
  overflow: hidden;
}
.bisko-app__audience .segment__select .vs__dropdown-option {
  white-space: normal;
  word-break: break-all;
}
.bisko-app__audience .segment__select .vs__dropdown-option--selected::after {
  right: 5px;
}
.bisko-app__audience .segment__select .disabled__prefix {
  font-size: 11px;
  color: gray;
  margin-left: 4px;
  display: inline-block;
  vertical-align: middle;
  font-style: italic;
}
.bisko-app__audience .segment__select .vs__dropdown-option--selected .disabled__prefix {
  color: #ffffff;
}
.bisko-app__audience .parameters__custom-content {
  display: flex;
  flex-wrap: wrap;
}
.bisko-app__audience .parameters__custom-content .form__input {
  margin-right: 5px;
}
.bisko-app__audience .form__input-params {
  width: 300px !important;
  padding-left: 5px !important;
  padding-right: 5px !important;
}
@media (max-width: 576px) {
.bisko-app__audience .form__input-params {
    width: 200px !important;
}
}
.bisko-app__audience .form__input-params .b-form-tags-button {
  display: none;
}
.bisko-app__audience .form__input-params .b-form-tag {
  margin-right: 5px;
  margin-top: 5px !important;
  background-color: white;
  border-radius: 20px;
  border: 1px solid #e0e5eb;
  color: #052d61;
  font-size: 14px;
  padding: 0 5px;
}
.bisko-app__audience .form__input-params .text-muted {
  display: block;
  margin-top: 4px;
  color: #e34850 !important;
  width: 100%;
  font-size: 0.857rem;
  color: #e34850;
}
.bisko-app__audience .segment__button-and__container {
  width: 100%;
  text-align: left;
  position: relative;
}
.bisko-app__audience .segment__button-and__container .segment__button--reversed {
  margin: 10px 0 0 50px;
  background-color: white !important;
}
.bisko-app__audience .segment__button-and__container .segment__button--reversed .btn-secondary {
  background-color: white !important;
}
.bisko-app__audience .segment__button-and__container .segment__button--reversed ul.dropdown-menu {
  transform: translate3d(70px, -1px, 0px) !important;
}
.bisko-app__audience .segment__button-and__container.segment__button-and__container--multiple {
  text-align: center;
}
.bisko-app__audience .segment__button-and__container.segment__button-and__container--multiple .segment__button--reversed.segment__button--reversed--multiple {
  background-color: #667c99 !important;
  color: white !important;
  border-color: #82868b !important;
}
.bisko-app__audience .segment__param-info {
  display: flex;
  flex-direction: column;
}
.bisko-app__audience .segment__param-info span {
  max-width: 300px;
}
.bisko-app__audience .segment__param-info input:disabled {
  display: none;
}
.bisko-app__audience .segment__param-span {
  margin-right: 10px;
}
.bisko-app__audience .add__parameters {
  display: inline-flex;
  margin-top: 25px;
  margin-left: 70px;
  width: -moz-fit-content;
  width: fit-content;
  cursor: pointer;
}
.bisko-app__audience .add__parameters--margin {
  margin-top: 0;
}
.bisko-app__audience .add__parameters-disabled {
  opacity: 0.5;
  cursor: default;
}
.bisko-app__audience .add__parameters-disabled .segment__icon {
  cursor: default !important;
}
.bisko-app__audience .parameters__operators {
  margin-right: 15px;
  margin-left: 30px;
  margin-top: 10px;
  max-width: 25px;
  width: 100%;
}
.bisko-app__audience .segments__remove {
  align-self: flex-start;
  margin-top: 9px;
}
.bisko-app__audience .params__remove {
  position: absolute;
  right: 20px;
  margin-top: 8px;
}
.bisko-app__audience .segment__arrow {
  width: 25px;
  height: 27px;
  border-left: 1px solid #e0e5eb;
  border-bottom: 1px solid #e0e5eb;
  position: absolute;
  top: 0;
  left: 25px;
}
.bisko-app__audience .segment__arrow--multiple {
  left: 50%;
  border-bottom: 0;
  height: 52px;
}
.bisko-app__audience .parameter__tag-help {
  margin-top: 2px;
  font-size: 11px;
  font-style: italic;
  color: #a3b0c2;
}
.bisko-app__audience .container__integrations {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 30px;
}
@media (max-width: 768) {
.bisko-app__audience .container__integrations {
    grid-template-columns: repeat(2, 1fr);
}
}
.bisko-app__audience .container__integrations-content {
  width: 100%;
  border-radius: 5px;
  border: 1px solid #e0e5eb;
  padding: 20px;
  font-size: 14px;
  color: #667c99;
}
.bisko-app__audience .container__integrations-content .integration__title {
  font-size: 16px;
  margin: 5px 0;
  font-weight: 600;
}
.bisko-app__audience .container__integrations-content .integration__title--inactive {
  color: #a3b0c2;
}
.bisko-app__audience .container__integrations-content-header {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  margin-bottom: 30px;
}
.bisko-app__audience .container__integrations-content-img {
  width: 64px;
  height: 64px;
  display: flex;
  align-items: center;
  padding: 5px;
  border: 1px solid #e0e5eb;
  border-radius: 4px;
}
.bisko-app__audience .container__integrations-content-img img {
  width: 100%;
}
.bisko-app__audience .segment__button--or-multiple {
  position: absolute;
  top: -14px;
  left: 48%;
  width: 44px;
  height: 26px;
  font-size: 14px;
}
.bisko-app__audience .segment__button--or-multiple.btn-secondary {
  background-color: #667c99 !important;
  color: white !important;
}
.bisko-app__audience .vs__selected-options span {
  white-space: nowrap;
}
.bisko-app__audience .parameter__dates {
  display: flex;
  align-items: flex-start;
}
.bisko-app__audience .parameter__dates .form__input {
  height: 37px !important;
  align-items: center !important;
  font-size: 14px;
  border: 1px solid #e0e5eb;
  width: 220px;
}
.bisko-app__audience .parameter__dates .form__input label {
  margin-top: 10px;
}
.bisko-app__audience .segment__param--wrapper {
  display: flex;
  flex-direction: column;
}
.bisko-app__audience .segment__topics {
  align-items: baseline;
}
.bisko-app__audience .segment__param .topics__select {
  width: unset;
  min-width: 280px;
}
.bisko-app__audience .segment__datetime-picker .vdpFloating > .vdpInnerWrap {
  max-width: 40rem;
  width: 15rem;
  min-height: 20rem;
}
.bisko-app__audience .segment__datetime-picker .vdpTable .vdpHeadCell {
  font-size: 12px;
}
.bisko-app__audience .segment__datetime-picker .vdpTable .vdpHeadCell::before {
  content: none;
}
.bisko-app__audience .segment__datetime-picker .vdpTable .vdpHeadCell::after {
  content: none;
}
.bisko-app__audience .segment__datetime-picker .vdpTable thead {
  transform: translateY(-25%);
}
.bisko-app__audience .segment__datetime-picker .vdpTable tr {
  transform: translateY(25%);
}
.bisko-app__audience .segment__datetime-picker .vdpTable tbody {
  transform: translateY(-5%);
}
.bisko-app__audience .segment__datetime-picker .vdpTable .vdpRow {
  font-size: 10px;
}
.bisko-app__audience .segment__datetime-picker .vdpInnerWrap > .vdpTable {
  margin-bottom: 0px;
}
.bisko-app__audience .segment__datetime-picker .vdpComponent.vdpWithInput > input {
  height: 37px;
  font-size: 14px;
  border: 1px solid #e0e5eb;
  border-radius: 4px;
  color: #6e6b7b;
  padding-left: 10px;
  padding-right: 5px;
}
.bisko-app__audience .segment__datetime-picker .vdpComponent.vdpWithInput > input[readonly=readonly] {
  background: #efefef;
}
.bisko-app__audience .segment__datetime-picker .vdpComponent.vdpWithInput > input:focus-visible {
  outline-width: 0px;
}
.bisko-app__audience .error__info-message {
  font-size: 13px;
  color: #e34850;
  height: 18px;
}
.bisko-app__audience .error__info-message span {
  position: absolute;
}
.bisko-app__audience .error__info-message_text-wrap span {
  max-width: 220px;
}
.bisko-app__audience .error__info-message_no-height {
  height: 0;
}
.bisko-app__audience .organization__select {
  display: inline-block;
}
.bisko-app__audience .organization__select .form__input {
  width: 220px;
  background: white;
}
.bisko-app__audience .segment__combine--input {
  width: auto !important;
}
.bisko-app__audience .segment__combine--input div:not(:first-child) {
  display: inline-block;
}
.bisko-app__audience .form__input-tags .b-form-tags-input {
  padding-left: 10px !important;
}
@media only screen and (max-width: 1440px) {
.bisko-app__audience .form__wrapper {
    width: 100%;
    min-width: auto;
}
.bisko-app__audience .parameters__custom {
    margin-bottom: 5px;
}
.bisko-app__audience .date-parameter-specific {
    margin-left: 70px;
}
.bisko-app__audience .segment__key-input {
    margin-left: 70px;
}
}
@media only screen and (max-width: 1350px) {
.bisko-app__audience .parameters__custom {
    margin-bottom: 5px;
}
.bisko-app__audience .parameters__custom-content {
    margin-left: 70px;
}
.bisko-app__audience .parameters__custom-content--no-margin {
    margin-left: unset !important;
}
.bisko-app__audience .segment__key-input {
    margin-left: unset;
}
.bisko-app__audience .date-parameter-specific {
    margin-left: unset;
}
}
@media only screen and (max-width: 992px) {
.bisko-app__audience .parameters__custom-content {
    margin-left: 70px;
}
.bisko-app__audience .parameters__custom-content .form__input {
    margin-bottom: 5px;
}
.bisko-app__audience .user__segmentation-wrapper .segment__key-input {
    margin-left: unset;
    width: 220px;
}
.bisko-app__audience .duration__wrapper {
    flex-wrap: wrap;
}
.bisko-app__audience .duration-dates__wrapper,
  .bisko-app__audience .time__last-param {
    margin: 5px 0 0 28px;
}
.bisko-app__audience .frequency__section {
    flex-wrap: wrap;
}
.bisko-app__audience .frequency__section > div:last-child {
    margin-left: 28px;
}
}
@media only screen and (max-width: 768px) {
.bisko-app__audience .parameters__custom-content {
    margin-left: unset;
}
.bisko-app__audience .parameters__custom-content .form__input {
    margin-bottom: 5px;
}
.bisko-app__audience .input__wrapper-parameters:first-child {
    margin-left: unset;
}
.bisko-app__audience .segment__param-title {
    font-size: 12px;
}
.bisko-app__audience .add__parameters {
    margin-left: unset;
}
}
@media only screen and (min-width: 1323px) and (max-width: 1350px), only screen and (min-width: 1060px) and (max-width: 1199px) {
.bisko-app__audience .parameter-date--no-margin {
    margin-left: 0px !important;
}
}
@media only screen and (min-width: 1018px) and (max-width: 1060px), only screen and (min-width: 1278px) and (max-width: 1322px) {
.bisko-app__audience .parameter-datetime--no-margin {
    margin-left: 0px !important;
}
}
@media only screen and (min-width: 1143px) and (max-width: 1199px) {
.bisko-app__audience .parameters-not-custom {
    margin-left: 0 !important;
}
}
@media only screen and (min-width: 837px) and (max-width: 1142px), only screen and (min-width: 1200px) and (max-width: 1350px) {
.bisko-app__audience .parameters__bolean-content {
    margin-left: 0px !important;
}
}
@media only screen and (min-width: 1063px) and (max-width: 1142px), only screen and (min-width: 1323px) and (max-width: 1350px) {
.bisko-app__audience .parameters__bolean-content-is {
    margin-left: 0px !important;
}
}
.bisko-app__audience .container__wrapper {
  flex: 1 0 auto;
  background: #ffffff;
  border-radius: 4px;
  padding-bottom: 20px;
  margin-bottom: 20px;
}
.bisko-app__audience .container__header {
  display: flex;
  padding-bottom: 16px;
}
.bisko-app__audience .container__header.container__header--padding {
  margin: 0 16px;
}
.bisko-app__audience .container__header-title {
  font-size: 16px;
  line-height: 18px;
  color: #052d61;
  margin-bottom: 0;
}
.bisko-app__audience .container__header.documentation__header--padding {
  padding-bottom: 8px;
}
.bisko-app__audience .input__wrapper,
.bisko-app__audience .connection__modal-input__wrapper {
  padding-bottom: 15px;
}
.bisko-app__audience .input__wrapper label,
.bisko-app__audience .connection__modal-input__wrapper label {
  font-size: 14px;
  line-height: 18px;
  color: #667c99;
  font-weight: 400;
}
.bisko-app__audience .input__wrapper select,
.bisko-app__audience .connection__modal-input__wrapper select {
  font-size: 14px;
  line-height: 18px;
  color: #a3b0c2;
}
.bisko-app__audience .input__wrapper textarea,
.bisko-app__audience .connection__modal-input__wrapper textarea {
  border-color: #e0e5eb;
}
.bisko-app__audience .input__wrapper .vs--disabled .vs__dropdown-toggle,
.bisko-app__audience .input__wrapper .vs--disabled .vs__clear,
.bisko-app__audience .input__wrapper .vs--disabled .vs__search,
.bisko-app__audience .input__wrapper .vs--disabled .vs__selected,
.bisko-app__audience .input__wrapper .vs--disabled .vs__open-indicator,
.bisko-app__audience .connection__modal-input__wrapper .vs--disabled .vs__dropdown-toggle,
.bisko-app__audience .connection__modal-input__wrapper .vs--disabled .vs__clear,
.bisko-app__audience .connection__modal-input__wrapper .vs--disabled .vs__search,
.bisko-app__audience .connection__modal-input__wrapper .vs--disabled .vs__selected,
.bisko-app__audience .connection__modal-input__wrapper .vs--disabled .vs__open-indicator {
  background-color: #efefef !important;
}
.bisko-app__audience .input__wrapper ::-webkit-input-placeholder,
.bisko-app__audience .connection__modal-input__wrapper ::-webkit-input-placeholder {
  /* Chrome/Opera/Safari */
  color: #a3b0c2;
  font-size: 14px;
  line-height: 18px;
}
.bisko-app__audience .input__wrapper ::-moz-placeholder,
.bisko-app__audience .connection__modal-input__wrapper ::-moz-placeholder {
  /* Firefox 19+ */
  color: #a3b0c2;
  font-size: 14px;
  line-height: 18px;
}
.bisko-app__audience .input__wrapper :-ms-input-placeholder,
.bisko-app__audience .connection__modal-input__wrapper :-ms-input-placeholder {
  /* IE 10+ */
  color: #a3b0c2;
  font-size: 14px;
  line-height: 18px;
}
.bisko-app__audience .input__wrapper :-moz-placeholder,
.bisko-app__audience .connection__modal-input__wrapper :-moz-placeholder {
  /* Firefox 18- */
  color: #a3b0c2;
  font-size: 14px;
  line-height: 18px;
}
.bisko-app__audience .loading__container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
.bisko-app__audience .delete-modal .modal-content .modal-body {
  font-size: 14px;
  padding: 2rem 1rem;
  text-align: center;
  color: #052d61;
}
.bisko-app__audience .delete-modal .modal-content .modal-footer > .btn {
  width: 80px;
  margin: 0 0 0 1rem;
}
.bisko-app__audience .modal-header {
  background-color: white !important;
  border-bottom: 1px solid #e0e5eb !important;
}
.bisko-app__audience .modal-footer > .btn {
  border-color: #e0e5eb !important;
  color: #052d61;
  width: 80px;
  margin: 0 0 0 1rem;
}

/*#region export and record view*/
.bisko-app__audience .item__container {
  margin-bottom: 24px;
  display: flex;
  justify-content: flex-start;
  align-items: baseline;
}
.bisko-app__audience .item__container--center {
  align-items: center;
}
.bisko-app__audience .item__container .icon__margin {
  margin-left: -3px;
  margin-right: 7px;
}
.bisko-app__audience .item__container .icon__margin--mobile {
  margin-left: -5px;
}
.bisko-app__audience .template__text {
  display: inline-block;
  margin: 0 24px 0 0;
  color: #667c99;
  font-size: 14px;
  min-width: 145px;
  max-width: 145px;
  vertical-align: top;
  word-break: break-all;
}
.bisko-app__audience .template__text--no-break {
  max-width: unset;
  word-break: normal;
}
.bisko-app__audience .dynamic-data__text {
  display: inline-block;
  font-size: 14px;
  max-width: 450px;
  color: #052d61;
  word-break: break-word;
}

/*#endregion */
.bisko-app__audience .container__wrapper--height {
  height: 100%;
  position: relative;
}
.bisko-app__audience .form__wrapper {
  margin: 0 auto;
  min-width: 1117px;
  max-width: 1117px;
}
@media (max-width: 1440px) {
.bisko-app__audience .form__wrapper {
    width: 100%;
    min-width: auto;
}
}
.bisko-app__audience .modal .modal-content .modal-title {
  font-weight: 400;
}
.bisko-app__audience .modal .modal-header .close {
  background-color: transparent !important;
}
.bisko-app__audience .modal .modal-header .close:hover {
  box-shadow: none !important;
}
.bisko-app__audience .modal .modal-footer {
  border-top: 1px solid #e0e5eb;
}
.bisko-app__audience .text--red {
  color: #e34850;
}
.bisko-app__audience .documentation__info-text {
  font-size: 13px;
  line-height: 18px;
  color: #7483a1;
  display: flex;
  align-items: center;
  text-align: left;
}
.bisko-app__audience .documentation__info-text a {
  color: #052d61;
  text-decoration: underline;
}
.bisko-app__audience .documentation__info-text a:hover {
  color: #052d61;
}
.bisko-app__audience .documentation__info-text svg {
  min-width: 18px;
}
.bisko-app__audience .dropdown .dropdown-menu .dropdown-item:active {
  background-color: rgba(29, 121, 242, 0.12);
}
.bisko-app__audience .navigation {
  font-family: "Inter", "SFProText";
  font-weight: 400;
}
.bisko-app__audience .tabs ul.nav.nav-tabs li a.nav-link[aria-selected=true] {
  font-weight: 400;
}
.bisko-app__audience section.audience__section {
  width: 100%;
}
.bisko-app__audience section.audience__section table.vgt-table.bordered {
  width: 100%;
}
.bisko-app__audience section.audience__section table tbody tr td span {
  font-size: 14px;
  line-height: 18px;
  color: #11254a;
}
.bisko-app__audience section.audience__section table tbody tr td span.dark-gray-color {
  color: #052d61;
  cursor: pointer;
}
.bisko-app__audience .segment__spinner {
  width: 15px;
  height: 15px;
  margin-right: 5px;
}
.bisko-app__audience .segments__list {
  padding: 0 10px;
}
.bisko-app__audience .export-and-search {
  display: flex;
  gap: 0.5rem;
}
.bisko-app__audience .export-and-search .table__search {
  padding: 0;
}
.bisko-app__audience .integration-status {
  height: 6px;
  width: 6px;
  border-radius: 50%;
  display: inline-block;
  margin: 0 8px 2px;
}
.bisko-app__audience .integration-status--active {
  background-color: #2d9d78;
}
.bisko-app__audience .integration-status--inactive {
  background-color: #e34850;
}
.bisko-app__audience .integration-status + span {
  color: #052d61;
}
.bisko-app__audience .dropdown-item {
  padding: 0.65rem 1.28rem;
}
.bisko-app__audience .dropdown-item .disabled {
  opacity: 0.6;
}
.bisko-app__audience .container__title {
  font-size: 16px;
  color: #173163;
  line-height: 24px;
  margin-bottom: 16px;
}
.bisko-app__audience .container__title--margin {
  margin-bottom: 24px;
}
.bisko-app__audience .basic-information__container {
  margin-bottom: 35px;
  position: relative;
}
.bisko-app__audience .basic-information__container .edit__button {
  position: absolute;
  right: 0;
  top: 0;
  display: flex;
  align-items: center;
  color: #2c3e50;
  text-decoration: none;
}
.bisko-app__audience .basic-information__container .edit__button:hover {
  cursor: pointer;
}
.bisko-app__audience .basic-information__container .edit__button span {
  font-size: 14px;
  color: #667c99;
  margin-left: 8px;
}
.bisko-app__audience .record__text {
  display: inline-block;
  font-size: 14px;
  max-width: 450px;
  color: #052d61;
  word-break: break-word;
}
.bisko-app__audience .view-screen__container {
  max-width: 926px;
  min-width: 926px;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  min-height: calc(100vh - 120px);
}
@media only screen and (max-width: 1440px) {
.bisko-app__audience .view-screen__container {
    width: 100%;
    min-width: auto;
}
}
@keyframes vdpSlideFromLeft {
from {
    opacity: 0;
    transform: translate3d(-0.5em, 0, 0);
}
to {
    opacity: 1;
    transform: translate3d(0, 0, 0);
}
}
@keyframes vdpSlideFromRight {
from {
    opacity: 0;
    transform: translate3d(0.5em, 0, 0);
}
to {
    opacity: 1;
    transform: translate3d(0, 0, 0);
}
}
@keyframes vdpToggleCalendar {
from {
    opacity: 0;
    transform: scale(0.5);
}
to {
    opacity: 1;
    transform: scale(1);
}
}
@keyframes vdpFadeCalendar {
from {
    opacity: 0;
}
to {
    opacity: 1;
}
}
.bisko-app__audience .vdp-toggle-calendar-enter-active.vdpPositionReady {
  transform-origin: top left;
  animation: vdpToggleCalendar 0.2s;
}
.bisko-app__audience .vdp-toggle-calendar-leave-active {
  animation: vdpToggleCalendar 0.15s reverse;
}
.bisko-app__audience .vdp-toggle-calendar-enter-active.vdpPositionFixed {
  animation: vdpFadeCalendar 0.3s;
}
.bisko-app__audience .vdp-toggle-calendar-leave-active.vdpPositionFixed {
  animation: vdpFadeCalendar 0.3s reverse;
}
.bisko-app__audience .vdpComponent {
  position: relative;
  display: inline-block;
  font-size: 10px;
  color: #303030;
  /*font-family: Helvetica, Arial, sans-serif;*/
}
.bisko-app__audience .vdpComponent.vdpWithInput > input {
  padding-right: 30px;
}
.bisko-app__audience .vdpClearInput {
  font-size: 1em;
  position: absolute;
  top: 0;
  bottom: 0;
  right: 0;
  width: 3em;
}
.bisko-app__audience .vdpClearInput:before {
  content: "×";
  width: 1.4em;
  height: 1.4em;
  line-height: 1.1em;
  box-sizing: border-box;
  position: absolute;
  left: 50%;
  top: 50%;
  margin: -0.7em 0 0 -0.7em;
  color: rgba(0, 0, 0, 0.3);
  border: 1px solid rgba(0, 0, 0, 0.15);
  border-radius: 50%;
  background-color: #fff;
}
.bisko-app__audience .vdpClearInput:hover:before {
  box-shadow: 0 0.2em 0.5em rgba(0, 0, 0, 0.15);
}
.bisko-app__audience .vdpOuterWrap.vdpFloating {
  position: absolute;
  padding: 0.5em 0;
  z-index: 220;
}
.bisko-app__audience .vdpOuterWrap.vdpPositionFixed {
  position: fixed;
  left: 0;
  top: 0;
  bottom: 0;
  right: 0;
  padding: 2em;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: rgba(0, 0, 0, 0.3);
}
.bisko-app__audience .vdpFloating .vdpInnerWrap {
  max-width: 30em;
}
.bisko-app__audience .vdpPositionFixed .vdpInnerWrap {
  max-width: 30em;
  margin: 0 auto;
  border: 0;
  animation: vdpToggleCalendar 0.3s;
}
.bisko-app__audience .vdpFloating.vdpPositionTop {
  top: 100%;
}
.bisko-app__audience .vdpFloating.vdpPositionBottom {
  bottom: 100%;
}
.bisko-app__audience .vdpFloating.vdpPositionLeft {
  left: 0;
}
.bisko-app__audience .vdpFloating.vdpPositionRight {
  right: 0;
}
.bisko-app__audience .vdpPositionTop.vdpPositionLeft {
  transform-origin: top left;
}
.bisko-app__audience .vdpPositionTop.vdpPositionRight {
  transform-origin: top right;
}
.bisko-app__audience .vdpPositionBottom.vdpPositionLeft {
  transform-origin: bottom left;
}
.bisko-app__audience .vdpPositionBottom.vdpPositionRight {
  transform-origin: bottom right;
}
.bisko-app__audience .vdpInnerWrap {
  overflow: hidden;
  min-width: 28em;
  box-sizing: border-box;
  padding: 1em;
  background: #fff;
  box-shadow: 0 0.2em 1.5em rgba(0, 0, 0, 0.06);
  border-radius: 0.5em;
  border: 1px solid rgba(0, 0, 0, 0.15);
}
.bisko-app__audience .vdpHeader {
  position: relative;
  padding: 0 1em 2.5em;
  margin: -1em -1em -2.5em;
  text-align: center;
  background: #f5f5f5;
}
.bisko-app__audience .vdpClearInput,
.bisko-app__audience .vdpArrow,
.bisko-app__audience .vdpPeriodControl > button,
.bisko-app__audience .vdp12HourToggleBtn {
  margin: 0;
  padding: 0;
  border: 0;
  cursor: pointer;
  background: none;
}
.bisko-app__audience .vdpArrow::-moz-focus-inner,
.bisko-app__audience .vdpClearInput::-moz-focus-inner,
.bisko-app__audience .vdpPeriodControl::-moz-focus-inner,
.bisko-app__audience .vdp12HourToggleBtn::-moz-focus-inner {
  padding: 0;
  border: 0;
}
.bisko-app__audience .vdpArrow {
  font-size: 1em;
  width: 5em;
  text-indent: -999em;
  overflow: hidden;
  position: absolute;
  top: 0;
  bottom: 2.5em;
  text-align: left;
}
.bisko-app__audience .vdpArrow:before {
  content: "";
  width: 2.2em;
  height: 2.2em;
  position: absolute;
  left: 50%;
  top: 50%;
  margin: -1.1em 0 0 -1.1em;
  border-radius: 100%;
  transition: background-color 0.2s;
}
.bisko-app__audience .vdpArrow:hover,
.bisko-app__audience .vdpArrow:focus,
.bisko-app__audience .vdpArrow:active {
  outline: 0;
}
.bisko-app__audience .vdpArrow:hover:before,
.bisko-app__audience .vdpArrow:focus:before {
  background-color: rgba(0, 0, 0, 0.03);
}
.bisko-app__audience .vdpArrow:active:before {
  background-color: rgba(0, 0, 0, 0.07);
}
.bisko-app__audience .vdpArrowNext:before {
  margin-left: -1.4em;
}
.bisko-app__audience .vdpArrow:after {
  content: "";
  position: absolute;
  left: 50%;
  top: 50%;
  margin-top: -0.5em;
  width: 0;
  height: 0;
  border: 0.5em solid transparent;
}
.bisko-app__audience .vdpArrowPrev {
  left: -0.3em;
}
.bisko-app__audience .vdpArrowPrev:after {
  margin-left: -0.8em;
  border-right-color: #7485c2;
}
.bisko-app__audience .vdpArrowNext {
  right: -0.6em;
}
.bisko-app__audience .vdpArrowNext:after {
  margin-left: -0.5em;
  border-left-color: #7485c2;
}
.bisko-app__audience .vdpPeriodControl {
  display: inline-block;
  position: relative;
}
.bisko-app__audience .vdpPeriodControl > button {
  font-size: 1.5em;
  padding: 1em 0.4em;
  display: inline-block;
}
.bisko-app__audience .vdpPeriodControl > select {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  cursor: pointer;
  opacity: 0;
  font-size: 1.6em;
  -webkit-appearance: none;
}
.bisko-app__audience .vdpTable {
  width: 100%;
  table-layout: fixed;
  position: relative;
  z-index: 5;
}
.bisko-app__audience .vdpNextDirection {
  animation: vdpSlideFromRight 0.5s;
}
.bisko-app__audience .vdpPrevDirection {
  animation: vdpSlideFromLeft 0.5s;
}
.bisko-app__audience .vdpCell, .bisko-app__audience .vdpHeadCell {
  text-align: center;
  box-sizing: border-box;
}
.bisko-app__audience .vdpCell {
  padding: 0.5em 0;
}
.bisko-app__audience .vdpHeadCell {
  padding: 0.3em 0.5em 1.8em;
}
.bisko-app__audience .vdpHeadCellContent {
  font-size: 1.3em;
  font-weight: normal;
  color: #848484;
}
.bisko-app__audience .vdpCellContent {
  font-size: 1.4em;
  display: block;
  margin: 0 auto;
  width: 1.857em;
  line-height: 1.857em;
  text-align: center;
  border-radius: 100%;
  transition: background 0.1s, color 0.1s;
}
.bisko-app__audience .vdpCell.outOfRange {
  color: #c7c7c7;
}
.bisko-app__audience .vdpCell.today {
  color: #7485c2;
}
.bisko-app__audience .vdpCell.selected .vdpCellContent {
  color: #fff;
  background: #7485c2;
}
@media (hover: hover) {
.bisko-app__audience .vdpCell.selectable:hover .vdpCellContent {
    color: #fff;
    background: #7485c2;
}
}
.bisko-app__audience .vdpCell.selectable {
  cursor: pointer;
}
.bisko-app__audience .vdpCell.disabled {
  opacity: 0.5;
}
.bisko-app__audience .vdpTimeControls {
  padding: 1.2em 2em;
  position: relative;
  margin: 1em -1em -1em;
  text-align: center;
  background: #f5f5f5;
  /*border-top: 1px solid rgba(0,0,0,0.15);*/
}
.bisko-app__audience .vdpTimeUnit {
  display: inline-block;
  position: relative;
  vertical-align: middle;
}
.bisko-app__audience .vdpTimeUnit > pre,
.bisko-app__audience .vdpTimeUnit > input {
  font-size: 1.7em;
  line-height: 1.3;
  padding: 0.1em 0.1em;
  word-wrap: break-word;
  white-space: pre-wrap;
  resize: none;
  margin: 0;
  box-sizing: border-box;
  color: #000;
  border: 0;
  border-bottom: 1px solid transparent;
  text-align: center;
}
.bisko-app__audience .vdpTimeUnit > pre {
  visibility: hidden;
  font-family: inherit;
}
.bisko-app__audience .vdpTimeUnit > input {
  position: absolute;
  top: 0;
  left: 0;
  overflow: hidden;
  height: 100%;
  width: 100%;
  outline: none;
  padding: 0;
  -webkit-appearance: none;
     -moz-appearance: none;
          appearance: none;
  border-radius: 0;
  background: transparent;
  -webkit-appearance: textfield;
  -moz-appearance: textfield;
  appearance: textfield;
}
.bisko-app__audience .vdpTimeUnit > input::-moz-selection {
  background-color: rgba(116, 133, 194, 0.15);
}
.bisko-app__audience .vdpTimeUnit > input::selection {
  background-color: rgba(116, 133, 194, 0.15);
}
.bisko-app__audience .vdpTimeUnit > input:hover,
.bisko-app__audience .vdpTimeUnit > input:focus {
  border-bottom-color: #7485c2;
}
.bisko-app__audience .vdpTimeUnit > input:disabled {
  border-bottom-color: transparent;
}
.bisko-app__audience .vdpTimeUnit > input::-webkit-inner-spin-button,
.bisko-app__audience .vdpTimeUnit > input::-webkit-outer-spin-button {
  margin: 0;
  -webkit-appearance: none;
}
.bisko-app__audience .vdpTimeSeparator, .bisko-app__audience .vdpTimeCaption {
  display: inline-block;
  vertical-align: middle;
  font-size: 1.3em;
  color: #848484;
}
.bisko-app__audience .vdpTimeCaption {
  margin-right: 0.5em;
}
.bisko-app__audience .vdp12HourToggleBtn {
  display: inline-block;
  vertical-align: middle;
  font-size: 1.3em;
  padding: 0 0.4em;
  color: #303030;
}
.bisko-app__audience .vdp12HourToggleBtn:hover,
.bisko-app__audience .vdp12HourToggleBtn:focus {
  color: #7485c2;
  outline: 0;
}
.bisko-app__audience .vdp12HourToggleBtn:disabled {
  color: #303030;
}`, ""]);
// Exports
export default ___CSS_LOADER_EXPORT___;
