var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "section",
    { staticClass: "create-segment-form-wrapper" },
    [
      !_vm.formState.isFormLoaded && _vm.newSegment
        ? _c(
            "div",
            { staticClass: "loading__container" },
            [_c("LoadingBar")],
            1
          )
        : _vm._e(),
      _vm.formState.isFormLoaded || !_vm.newSegment
        ? _c(
            "b-form",
            {
              staticClass: "form__wrapper",
              attrs: { novalidate: "" },
              on: {
                submit: function ($event) {
                  $event.preventDefault()
                },
              },
            },
            [
              _c("div", { staticClass: "container__wrapper" }, [
                _c("div", { staticClass: "container__header" }, [
                  _c("h5", { staticClass: "container__header-title" }, [
                    _vm._v(_vm._s(_vm.$t("data.basicInfo"))),
                  ]),
                ]),
                _c(
                  "div",
                  { staticClass: "container__content" },
                  [
                    _c(
                      "b-col",
                      {
                        staticClass: "input__wrapper pl-0",
                        attrs: { lg: "6" },
                      },
                      [
                        _c("label", { staticClass: "mr-1" }, [
                          _vm._v(_vm._s(_vm.$t("Name")) + " *"),
                        ]),
                        _c("b-form-input", {
                          staticClass: "form__input",
                          class: {
                            "form__input--invalid":
                              _vm.segment.name.errors.length > 0,
                          },
                          attrs: {
                            id: "segment-name",
                            placeholder: _vm.$t("audience.enterAudienceName"),
                            type: "text",
                          },
                          on: {
                            input: function ($event) {
                              return _vm.handleFormInput(_vm.formState, [
                                _vm.segment.name,
                              ])
                            },
                          },
                          model: {
                            value: _vm.segment.name.value,
                            callback: function ($$v) {
                              _vm.$set(
                                _vm.segment.name,
                                "value",
                                typeof $$v === "string" ? $$v.trim() : $$v
                              )
                            },
                            expression: "segment.name.value",
                          },
                        }),
                        _vm._l(
                          _vm.segment.name.errors,
                          function (error, errIndex) {
                            return _c(
                              "div",
                              {
                                key: errIndex,
                                staticClass: "error__info-message",
                              },
                              [_c("span", [_vm._v(_vm._s(error))])]
                            )
                          }
                        ),
                      ],
                      2
                    ),
                    _c(
                      "b-col",
                      {
                        staticClass: "input__wrapper pl-0",
                        attrs: { lg: "6" },
                      },
                      [
                        _c("label", { attrs: { for: "textarea-default" } }, [
                          _vm._v(_vm._s(_vm.$t("Description"))),
                        ]),
                        _c("b-form-textarea", {
                          staticClass: "form__input form__input-textarea",
                          class: {
                            "form__input--invalid":
                              _vm.segment.description.errors.length > 0,
                          },
                          attrs: {
                            id: "segment-descripton",
                            placeholder: _vm.$t(
                              "audience.enterAudienceDescription"
                            ),
                            rows: "3",
                          },
                          on: {
                            input: function ($event) {
                              return _vm.handleFormInput(_vm.formState, [
                                _vm.segment.description,
                              ])
                            },
                          },
                          model: {
                            value: _vm.segment.description.value,
                            callback: function ($$v) {
                              _vm.$set(
                                _vm.segment.description,
                                "value",
                                typeof $$v === "string" ? $$v.trim() : $$v
                              )
                            },
                            expression: "segment.description.value",
                          },
                        }),
                        _vm._l(
                          _vm.segment.description.errors,
                          function (error, errIndex) {
                            return _c(
                              "div",
                              {
                                key: errIndex,
                                staticClass: "error__info-message",
                              },
                              [_c("span", [_vm._v(_vm._s(error))])]
                            )
                          }
                        ),
                      ],
                      2
                    ),
                    _c(
                      "b-col",
                      {
                        staticClass: "input__wrapper pl-0",
                        attrs: { lg: "6" },
                      },
                      [
                        _c(
                          "div",
                          { staticClass: "input__wrapper-properties" },
                          [
                            _c("label", { staticClass: "mr-1" }, [
                              _vm._v(_vm._s(_vm.$t("Properties"))),
                            ]),
                            _c(
                              "MultipleProperties",
                              _vm._b(
                                {
                                  attrs: {
                                    id: "segment-propreties",
                                    options: _vm.allProperties,
                                    "tags-to-show": 3,
                                    "no-debounce": "",
                                    classes: ["multiple-properties--three"],
                                    "all-selected-classes": [
                                      "multiple-properties--all",
                                    ],
                                  },
                                  on: { "update-data": _vm.updateProperties },
                                },
                                "MultipleProperties",
                                !!_vm.segmentId
                                  ? { selected: _vm.segment.audienceProperties }
                                  : {},
                                false
                              )
                            ),
                          ],
                          1
                        ),
                      ]
                    ),
                  ],
                  1
                ),
              ]),
              _c(
                "div",
                {
                  staticClass: "container__wrapper user__segmentation-wrapper",
                },
                [
                  _c(
                    "div",
                    {
                      staticClass:
                        "container__header documentation__header--padding",
                    },
                    [
                      _c("h5", { staticClass: "container__header-title" }, [
                        _vm._v(
                          " " +
                            _vm._s(_vm.$t("audience.userSegmentations")) +
                            " "
                        ),
                      ]),
                    ]
                  ),
                  _c(
                    "div",
                    { staticClass: "documentation__info-text mb-2" },
                    [
                      _c("GjIcon", {
                        staticClass: "mr-50",
                        attrs: { name: "Info", size: "18" },
                      }),
                      _c("span", [
                        _vm._v(
                          _vm._s(_vm.$t("audience.segmentsDocumentationLink")) +
                            " "
                        ),
                        _c(
                          "a",
                          {
                            attrs: {
                              href: `${_vm.whitelabel.documentationUrl}/intro#introduction-to-segments`,
                              target: "_blank",
                            },
                          },
                          [
                            _vm._v(
                              _vm._s(_vm.$t("Documentation").toLowerCase())
                            ),
                          ]
                        ),
                        _vm._v("."),
                      ]),
                    ],
                    1
                  ),
                  _vm._l(_vm.segment.blocks, function (block, blockIndex) {
                    return _c(
                      "div",
                      { key: blockIndex, staticClass: "container__content" },
                      [
                        _vm._l(block.filters, function (filter, filterIndex) {
                          return _c(
                            "div",
                            {
                              key: filterIndex,
                              staticClass: "container__content--segments",
                            },
                            [
                              filter.filterType === _vm.filterTypes.filter
                                ? _c(
                                    "div",
                                    [
                                      block.filters.length > 1 &&
                                      filterIndex !== 0
                                        ? _c(
                                            "div",
                                            [
                                              _c(
                                                "b-button",
                                                {
                                                  staticClass:
                                                    "segment__button--or-multiple",
                                                },
                                                [
                                                  _c("span", [
                                                    _vm._v(
                                                      _vm._s(
                                                        _vm.$t("audience.or")
                                                      )
                                                    ),
                                                  ]),
                                                ]
                                              ),
                                            ],
                                            1
                                          )
                                        : _vm._e(),
                                      !(
                                        _vm.segment.blocks.length === 1 &&
                                        block.filters.length === 1
                                      )
                                        ? _c(
                                            "div",
                                            {
                                              staticClass:
                                                "container__content--segments_remove",
                                              on: {
                                                click: function ($event) {
                                                  return _vm.removeBlock(
                                                    blockIndex,
                                                    filterIndex
                                                  )
                                                },
                                              },
                                            },
                                            [
                                              _c("GjIcon", {
                                                staticClass:
                                                  "mr-50 segment__icon",
                                                attrs: {
                                                  name: "Close",
                                                  size: "20",
                                                },
                                              }),
                                            ],
                                            1
                                          )
                                        : _vm._e(),
                                      _c("div", {
                                        class: {
                                          "form__input--invalid":
                                            filter.filters.errors.length > 0,
                                        },
                                        staticStyle: { display: "hidden" },
                                      }),
                                      _c(
                                        "div",
                                        {
                                          staticClass:
                                            "input__wrapper input__wrapper--margin",
                                        },
                                        [
                                          _c(
                                            "div",
                                            {
                                              staticClass:
                                                "segment__wrapper-select organization__select",
                                            },
                                            [
                                              _c("v-select", {
                                                staticClass:
                                                  "form__input segment__select",
                                                attrs: {
                                                  id: "segment-organization",
                                                  options: _vm.organizations,
                                                  label: "name",
                                                  placeholder: _vm.organizations
                                                    .length
                                                    ? "Select organization"
                                                    : "Loading...",
                                                  disabled:
                                                    !_vm.organizations.length ||
                                                    filter.isDisabled,
                                                  loading:
                                                    !_vm.organizations.length,
                                                  clearable: false,
                                                },
                                                on: {
                                                  input: function ($event) {
                                                    _vm.onFilterOrganizationChange(
                                                      filter
                                                    ),
                                                      _vm.handleFormInput(
                                                        _vm.formState
                                                      )
                                                  },
                                                },
                                                scopedSlots: _vm._u(
                                                  [
                                                    {
                                                      key: "spinner",
                                                      fn: function ({
                                                        loading,
                                                      }) {
                                                        return [
                                                          loading
                                                            ? _c("LoadingBar", {
                                                                attrs: {
                                                                  classList:
                                                                    "small mr-1",
                                                                },
                                                              })
                                                            : _vm._e(),
                                                        ]
                                                      },
                                                    },
                                                  ],
                                                  null,
                                                  true
                                                ),
                                                model: {
                                                  value:
                                                    filter.filterOrganization
                                                      .value,
                                                  callback: function ($$v) {
                                                    _vm.$set(
                                                      filter.filterOrganization,
                                                      "value",
                                                      $$v
                                                    )
                                                  },
                                                  expression:
                                                    "filter.filterOrganization.value",
                                                },
                                              }),
                                            ],
                                            1
                                          ),
                                          filter.isDisabled
                                            ? _c("InformationSidebar", {
                                                attrs: {
                                                  code: `${blockIndex}-${filterIndex}`,
                                                  title: _vm.$t(
                                                    "audience.disabledFilter"
                                                  ),
                                                  content: _vm.$t(
                                                    "audience.disabledFilterInfo"
                                                  ),
                                                },
                                              })
                                            : _vm._e(),
                                        ],
                                        1
                                      ),
                                      _c(
                                        "div",
                                        { staticClass: "input__wrapper" },
                                        [
                                          _c(
                                            "div",
                                            { staticClass: "segment__wrapper" },
                                            [
                                              _c(
                                                "div",
                                                {
                                                  staticClass:
                                                    "segment__wrapper-select",
                                                },
                                                [
                                                  _c("v-select", {
                                                    staticClass:
                                                      "form__input segment__select",
                                                    class: {
                                                      "form__input--invalid":
                                                        filter.tableId.errors
                                                          .length > 0,
                                                    },
                                                    attrs: {
                                                      id: "segment-table",
                                                      disabled:
                                                        filter.segmentationTableOptions ===
                                                          null ||
                                                        filter.isDisabled,
                                                      options:
                                                        filter.segmentationTableOptions ===
                                                        null
                                                          ? []
                                                          : filter.segmentationTableOptions,
                                                      placeholder:
                                                        filter.segmentationTableOptions !==
                                                        null
                                                          ? "Table"
                                                          : "Loading...",
                                                      label: "name",
                                                      loading:
                                                        filter.segmentationTableOptions ===
                                                          null &&
                                                        !filter.segmentationTableModel,
                                                      clearable: false,
                                                    },
                                                    on: {
                                                      input: function ($event) {
                                                        _vm.onSegmentTableChange(
                                                          filter
                                                        ),
                                                          _vm.handleFormInput(
                                                            _vm.formState,
                                                            [filter.tableId]
                                                          )
                                                      },
                                                    },
                                                    scopedSlots: _vm._u(
                                                      [
                                                        {
                                                          key: "spinner",
                                                          fn: function ({
                                                            loading,
                                                          }) {
                                                            return [
                                                              loading
                                                                ? _c(
                                                                    "LoadingBar",
                                                                    {
                                                                      attrs: {
                                                                        classList:
                                                                          "small mr-1",
                                                                      },
                                                                    }
                                                                  )
                                                                : _vm._e(),
                                                            ]
                                                          },
                                                        },
                                                        {
                                                          key: "option",
                                                          fn: function (item) {
                                                            return [
                                                              _vm._v(
                                                                " " +
                                                                  _vm._s(
                                                                    item.name
                                                                  ) +
                                                                  " "
                                                              ),
                                                              !item.enabled
                                                                ? _c(
                                                                    "span",
                                                                    {
                                                                      staticClass:
                                                                        "disabled__prefix",
                                                                    },
                                                                    [
                                                                      _vm._v(
                                                                        "disabled"
                                                                      ),
                                                                    ]
                                                                  )
                                                                : _vm._e(),
                                                            ]
                                                          },
                                                        },
                                                      ],
                                                      null,
                                                      true
                                                    ),
                                                    model: {
                                                      value:
                                                        filter.segmentationTableModel,
                                                      callback: function ($$v) {
                                                        _vm.$set(
                                                          filter,
                                                          "segmentationTableModel",
                                                          $$v
                                                        )
                                                      },
                                                      expression:
                                                        "filter.segmentationTableModel",
                                                    },
                                                  }),
                                                  _vm._l(
                                                    filter.tableId.errors,
                                                    function (error, errIndex) {
                                                      return _c(
                                                        "div",
                                                        {
                                                          key: errIndex,
                                                          staticClass:
                                                            "error__info-message",
                                                        },
                                                        [
                                                          _c("span", [
                                                            _vm._v(
                                                              _vm._s(error)
                                                            ),
                                                          ]),
                                                        ]
                                                      )
                                                    }
                                                  ),
                                                ],
                                                2
                                              ),
                                              _c(
                                                "div",
                                                {
                                                  staticClass:
                                                    "segment__wrapper-select",
                                                },
                                                [
                                                  _c("v-select", {
                                                    staticClass: "form__input",
                                                    attrs: {
                                                      id: "segment-state",
                                                      options:
                                                        filter.eventState,
                                                      disabled:
                                                        filter.isDisabled,
                                                      label: "text",
                                                      clearable: false,
                                                    },
                                                    on: {
                                                      input: function ($event) {
                                                        return _vm.handleFormInput(
                                                          _vm.formState
                                                        )
                                                      },
                                                    },
                                                    model: {
                                                      value:
                                                        filter.eventStateDefault,
                                                      callback: function ($$v) {
                                                        _vm.$set(
                                                          filter,
                                                          "eventStateDefault",
                                                          $$v
                                                        )
                                                      },
                                                      expression:
                                                        "filter.eventStateDefault",
                                                    },
                                                  }),
                                                ],
                                                1
                                              ),
                                            ]
                                          ),
                                        ]
                                      ),
                                      _c(
                                        "div",
                                        {
                                          staticClass:
                                            "segment__button--container",
                                          attrs: {
                                            id: `segment__button--time-${blockIndex}-${filterIndex}`,
                                          },
                                        },
                                        [
                                          !filter.isTimeParamSet
                                            ? _c(
                                                "b-button",
                                                {
                                                  staticClass:
                                                    "segment__button",
                                                  attrs: {
                                                    id: "add-time",
                                                    disabled:
                                                      !filter.segmentationTableModel ||
                                                      filter.isDisabled,
                                                  },
                                                  on: {
                                                    click: function ($event) {
                                                      return _vm.addFilterParam(
                                                        filter,
                                                        "time"
                                                      )
                                                    },
                                                  },
                                                },
                                                [
                                                  _c(
                                                    "div",
                                                    {
                                                      staticClass:
                                                        "segment__button--icon",
                                                    },
                                                    [
                                                      _c("GjIcon", {
                                                        staticClass: "mr-50",
                                                        attrs: {
                                                          name: "Plus",
                                                          size: "20",
                                                        },
                                                      }),
                                                    ],
                                                    1
                                                  ),
                                                  _c(
                                                    "span",
                                                    {
                                                      staticClass:
                                                        "align-middle",
                                                    },
                                                    [
                                                      _vm._v(
                                                        _vm._s(_vm.$t("Time"))
                                                      ),
                                                    ]
                                                  ),
                                                  !filter.segmentationTableModel
                                                    ? _c(
                                                        "b-tooltip",
                                                        {
                                                          attrs: {
                                                            target: `segment__button--time-${blockIndex}-${filterIndex}`,
                                                            placement: "right",
                                                          },
                                                        },
                                                        [
                                                          _vm._v(
                                                            " " +
                                                              _vm._s(
                                                                _vm.$t(
                                                                  "audience.parameterTooltipMessage"
                                                                )
                                                              ) +
                                                              " "
                                                          ),
                                                        ]
                                                      )
                                                    : _vm._e(),
                                                ],
                                                1
                                              )
                                            : _vm._e(),
                                        ],
                                        1
                                      ),
                                      filter.isTimeParamSet
                                        ? _c(
                                            "div",
                                            { staticClass: "segment__param" },
                                            [
                                              _c(
                                                "div",
                                                {
                                                  staticClass: "segment__param",
                                                  class: {
                                                    duration__wrapper:
                                                      filter.isDurationLastParamShown ||
                                                      filter.isDurationBetweenParamShown,
                                                  },
                                                },
                                                [
                                                  _c(
                                                    "div",
                                                    {
                                                      staticClass:
                                                        "icon__wrapper",
                                                    },
                                                    [
                                                      _c("GjIcon", {
                                                        staticClass: "mr-50",
                                                        attrs: {
                                                          name: "Time",
                                                          size: "20",
                                                        },
                                                      }),
                                                    ],
                                                    1
                                                  ),
                                                  _c(
                                                    "div",
                                                    {
                                                      staticClass:
                                                        "input__wrapper",
                                                      staticStyle: {
                                                        "margin-bottom": "0",
                                                      },
                                                    },
                                                    [
                                                      _c("v-select", {
                                                        staticClass:
                                                          "form__input segment__select",
                                                        class: {
                                                          "form__input--invalid":
                                                            filter
                                                              .durationOperator
                                                              .errors.length >
                                                            0,
                                                        },
                                                        attrs: {
                                                          id: "duration-operator",
                                                          placeholder:
                                                            _vm.$t("Select"),
                                                          options:
                                                            filter.durationOptions,
                                                          disabled:
                                                            filter.isDisabled,
                                                          label: "text",
                                                          clearable: false,
                                                        },
                                                        on: {
                                                          input: function (
                                                            $event
                                                          ) {
                                                            _vm.getSelectedDuration(
                                                              $event,
                                                              filter
                                                            ),
                                                              _vm.handleFormInput(
                                                                _vm.formState,
                                                                [
                                                                  filter.durationOperator,
                                                                  filter.durationValue,
                                                                ]
                                                              )
                                                          },
                                                        },
                                                        model: {
                                                          value:
                                                            filter
                                                              .durationOperator
                                                              .value,
                                                          callback: function (
                                                            $$v
                                                          ) {
                                                            _vm.$set(
                                                              filter.durationOperator,
                                                              "value",
                                                              $$v
                                                            )
                                                          },
                                                          expression:
                                                            "filter.durationOperator.value",
                                                        },
                                                      }),
                                                      _vm._l(
                                                        filter.durationOperator
                                                          .errors,
                                                        function (
                                                          error,
                                                          errIndex
                                                        ) {
                                                          return _c(
                                                            "div",
                                                            {
                                                              key: errIndex,
                                                              staticClass:
                                                                "error__info-message error__info-message_no-height",
                                                            },
                                                            [
                                                              _c("span", [
                                                                _vm._v(
                                                                  _vm._s(error)
                                                                ),
                                                              ]),
                                                            ]
                                                          )
                                                        }
                                                      ),
                                                    ],
                                                    2
                                                  ),
                                                  filter.isDurationDatepickerShown ||
                                                  filter.isDurationBetweenParamShown
                                                    ? _c(
                                                        "div",
                                                        {
                                                          staticClass:
                                                            "input__wrapper--date",
                                                          class: {
                                                            "duration-dates__wrapper":
                                                              filter.isDurationBetweenParamShown,
                                                          },
                                                        },
                                                        [
                                                          _c(
                                                            "div",
                                                            [
                                                              _c(
                                                                "b-form-datepicker",
                                                                {
                                                                  staticClass:
                                                                    "form__input",
                                                                  class: {
                                                                    "form__input--invalid":
                                                                      filter
                                                                        .durationValue
                                                                        .errors
                                                                        .length >
                                                                      0,
                                                                  },
                                                                  attrs: {
                                                                    id: `duration-first-date-${blockIndex}-${filterIndex}`,
                                                                    "calendar-width":
                                                                      "100%",
                                                                    disabled:
                                                                      filter.isDisabled,
                                                                    max: _vm.maxDate,
                                                                    "date-format-options":
                                                                      {
                                                                        year: "numeric",
                                                                        month:
                                                                          "short",
                                                                        day: "2-digit",
                                                                        weekday:
                                                                          "short",
                                                                      },
                                                                  },
                                                                  on: {
                                                                    input:
                                                                      function (
                                                                        $event
                                                                      ) {
                                                                        _vm.datePickerValueChanged(
                                                                          filter
                                                                        ),
                                                                          _vm.handleFormInput(
                                                                            _vm.formState,
                                                                            [
                                                                              filter.durationValue,
                                                                            ]
                                                                          )
                                                                      },
                                                                  },
                                                                  model: {
                                                                    value:
                                                                      filter.durationFirstDate,
                                                                    callback:
                                                                      function (
                                                                        $$v
                                                                      ) {
                                                                        _vm.$set(
                                                                          filter,
                                                                          "durationFirstDate",
                                                                          $$v
                                                                        )
                                                                      },
                                                                    expression:
                                                                      "filter.durationFirstDate",
                                                                  },
                                                                }
                                                              ),
                                                              _vm._l(
                                                                filter
                                                                  .durationValue
                                                                  .errors,
                                                                function (
                                                                  error,
                                                                  errIndex
                                                                ) {
                                                                  return _c(
                                                                    "div",
                                                                    {
                                                                      key: errIndex,
                                                                      staticClass:
                                                                        "error__info-message error__info-message_no-height",
                                                                    },
                                                                    [
                                                                      _c(
                                                                        "span",
                                                                        [
                                                                          _vm._v(
                                                                            _vm._s(
                                                                              error
                                                                            )
                                                                          ),
                                                                        ]
                                                                      ),
                                                                    ]
                                                                  )
                                                                }
                                                              ),
                                                            ],
                                                            2
                                                          ),
                                                          filter.isDurationBetweenParamShown
                                                            ? _c(
                                                                "div",
                                                                {
                                                                  staticClass:
                                                                    "input__wrapper--date",
                                                                },
                                                                [
                                                                  _c("span", [
                                                                    _vm._v(
                                                                      _vm._s(
                                                                        _vm
                                                                          .$t(
                                                                            "audience.and"
                                                                          )
                                                                          .toLowerCase()
                                                                      )
                                                                    ),
                                                                  ]),
                                                                  _c(
                                                                    "b-form-datepicker",
                                                                    {
                                                                      staticClass:
                                                                        "form__input",
                                                                      class: {
                                                                        "form__input--invalid":
                                                                          filter
                                                                            .durationValue
                                                                            .errors
                                                                            .length >
                                                                          0,
                                                                      },
                                                                      attrs: {
                                                                        id: `duration-second-date-${blockIndex}-${filterIndex}`,
                                                                        disabled:
                                                                          filter.isDisabled,
                                                                        "calendar-width":
                                                                          "100%",
                                                                        max: _vm.maxDate,
                                                                        min: filter.minDate,
                                                                        "date-format-options":
                                                                          {
                                                                            year: "numeric",
                                                                            month:
                                                                              "short",
                                                                            day: "2-digit",
                                                                            weekday:
                                                                              "short",
                                                                          },
                                                                      },
                                                                      on: {
                                                                        input:
                                                                          function (
                                                                            $event
                                                                          ) {
                                                                            return _vm.handleFormInput(
                                                                              _vm.formState,
                                                                              [
                                                                                filter.durationValue,
                                                                              ]
                                                                            )
                                                                          },
                                                                      },
                                                                      model: {
                                                                        value:
                                                                          filter.durationSecondDate,
                                                                        callback:
                                                                          function (
                                                                            $$v
                                                                          ) {
                                                                            _vm.$set(
                                                                              filter,
                                                                              "durationSecondDate",
                                                                              $$v
                                                                            )
                                                                          },
                                                                        expression:
                                                                          "filter.durationSecondDate",
                                                                      },
                                                                    }
                                                                  ),
                                                                ],
                                                                1
                                                              )
                                                            : _vm._e(),
                                                        ]
                                                      )
                                                    : _vm._e(),
                                                  filter.isDurationLastParamShown
                                                    ? _c(
                                                        "div",
                                                        {
                                                          staticClass:
                                                            "input__wrapper time__last-param",
                                                          staticStyle: {
                                                            "margin-bottom":
                                                              "0",
                                                          },
                                                        },
                                                        [
                                                          _c(
                                                            "div",
                                                            [
                                                              _c(
                                                                "b-form-input",
                                                                {
                                                                  staticClass:
                                                                    "form__input form__input-time form__input--small",
                                                                  class: {
                                                                    "form__input--invalid":
                                                                      filter
                                                                        .durationValue
                                                                        .errors
                                                                        .length >
                                                                      0,
                                                                  },
                                                                  attrs: {
                                                                    id: "duration-number",
                                                                    disabled:
                                                                      filter.isDisabled,
                                                                    placeholder:
                                                                      "0",
                                                                    type: "number",
                                                                    min: "1",
                                                                  },
                                                                  on: {
                                                                    input:
                                                                      function (
                                                                        $event
                                                                      ) {
                                                                        return _vm.handleFormInput(
                                                                          _vm.formState,
                                                                          [
                                                                            filter.durationValue,
                                                                          ]
                                                                        )
                                                                      },
                                                                  },
                                                                  model: {
                                                                    value:
                                                                      filter.durationNumberParam,
                                                                    callback:
                                                                      function (
                                                                        $$v
                                                                      ) {
                                                                        _vm.$set(
                                                                          filter,
                                                                          "durationNumberParam",
                                                                          $$v
                                                                        )
                                                                      },
                                                                    expression:
                                                                      "filter.durationNumberParam",
                                                                  },
                                                                }
                                                              ),
                                                              _vm._l(
                                                                filter
                                                                  .durationValue
                                                                  .errors,
                                                                function (
                                                                  error,
                                                                  errIndex
                                                                ) {
                                                                  return _c(
                                                                    "div",
                                                                    {
                                                                      key: errIndex,
                                                                      staticClass:
                                                                        "error__info-message error__info-message_no-height",
                                                                    },
                                                                    [
                                                                      _c(
                                                                        "span",
                                                                        [
                                                                          _vm._v(
                                                                            _vm._s(
                                                                              error
                                                                            )
                                                                          ),
                                                                        ]
                                                                      ),
                                                                    ]
                                                                  )
                                                                }
                                                              ),
                                                            ],
                                                            2
                                                          ),
                                                          _c("v-select", {
                                                            staticClass:
                                                              "form__input",
                                                            class: {
                                                              "form__input--invalid":
                                                                filter
                                                                  .durationValue
                                                                  .errors
                                                                  .length > 0,
                                                            },
                                                            attrs: {
                                                              id: "duration-number-time-unit",
                                                              placeholder:
                                                                _vm.$t(
                                                                  "Select"
                                                                ),
                                                              disabled:
                                                                filter.isDisabled,
                                                              options:
                                                                filter.timeLastOptions,
                                                              clearable: false,
                                                            },
                                                            on: {
                                                              input: function (
                                                                $event
                                                              ) {
                                                                return _vm.handleFormInput(
                                                                  _vm.formState,
                                                                  [
                                                                    filter.durationValue,
                                                                  ]
                                                                )
                                                              },
                                                            },
                                                            model: {
                                                              value:
                                                                filter.durationNumberFormatParam,
                                                              callback:
                                                                function ($$v) {
                                                                  _vm.$set(
                                                                    filter,
                                                                    "durationNumberFormatParam",
                                                                    $$v
                                                                  )
                                                                },
                                                              expression:
                                                                "filter.durationNumberFormatParam",
                                                            },
                                                          }),
                                                        ],
                                                        1
                                                      )
                                                    : _vm._e(),
                                                ]
                                              ),
                                              !filter.isDisabled
                                                ? _c(
                                                    "div",
                                                    {
                                                      staticClass:
                                                        "segments__remove",
                                                      attrs: {
                                                        id: "remove-time-parameter",
                                                      },
                                                      on: {
                                                        click: function (
                                                          $event
                                                        ) {
                                                          return _vm.removeFilterParam(
                                                            "time",
                                                            filter
                                                          )
                                                        },
                                                      },
                                                    },
                                                    [
                                                      _c("GjIcon", {
                                                        staticClass:
                                                          "mr-50 segment__icon",
                                                        attrs: {
                                                          name: "Close",
                                                          size: "20",
                                                        },
                                                      }),
                                                    ],
                                                    1
                                                  )
                                                : _vm._e(),
                                            ]
                                          )
                                        : _vm._e(),
                                      _c(
                                        "div",
                                        {
                                          staticClass:
                                            "segment__button--container",
                                          attrs: {
                                            id: `segment__button--frequency-${blockIndex}-${filterIndex}`,
                                          },
                                        },
                                        [
                                          !filter.isFrequencyParamSet
                                            ? _c(
                                                "b-button",
                                                {
                                                  staticClass:
                                                    "segment__button",
                                                  attrs: {
                                                    id: "add-frequency",
                                                    disabled:
                                                      !filter.segmentationTableModel ||
                                                      filter.isDisabled,
                                                  },
                                                  on: {
                                                    click: function ($event) {
                                                      return _vm.addFilterParam(
                                                        filter,
                                                        "frequency"
                                                      )
                                                    },
                                                  },
                                                },
                                                [
                                                  _c(
                                                    "div",
                                                    {
                                                      staticClass:
                                                        "segment__button--icon",
                                                    },
                                                    [
                                                      _c("GjIcon", {
                                                        staticClass: "mr-50",
                                                        attrs: {
                                                          name: "Plus",
                                                          size: "20",
                                                        },
                                                      }),
                                                    ],
                                                    1
                                                  ),
                                                  _c(
                                                    "span",
                                                    {
                                                      staticClass:
                                                        "align-middle",
                                                    },
                                                    [
                                                      _vm._v(
                                                        _vm._s(
                                                          _vm.$t(
                                                            "audience.frequency"
                                                          )
                                                        )
                                                      ),
                                                    ]
                                                  ),
                                                  !filter.segmentationTableModel
                                                    ? _c(
                                                        "b-tooltip",
                                                        {
                                                          attrs: {
                                                            target: `segment__button--frequency-${blockIndex}-${filterIndex}`,
                                                            placement: "right",
                                                          },
                                                        },
                                                        [
                                                          _vm._v(
                                                            " " +
                                                              _vm._s(
                                                                _vm.$t(
                                                                  "audience.parameterTooltipMessage"
                                                                )
                                                              ) +
                                                              " "
                                                          ),
                                                        ]
                                                      )
                                                    : _vm._e(),
                                                ],
                                                1
                                              )
                                            : _vm._e(),
                                        ],
                                        1
                                      ),
                                      filter.isFrequencyParamSet
                                        ? _c(
                                            "div",
                                            { staticClass: "segment__param" },
                                            [
                                              _c(
                                                "div",
                                                {
                                                  staticClass:
                                                    "segment__param frequency__section",
                                                },
                                                [
                                                  _c(
                                                    "div",
                                                    {
                                                      staticClass:
                                                        "icon__wrapper",
                                                    },
                                                    [
                                                      _c("GjIcon", {
                                                        staticClass: "mr-50",
                                                        attrs: {
                                                          name: "Grid",
                                                          size: "20",
                                                        },
                                                      }),
                                                    ],
                                                    1
                                                  ),
                                                  _c(
                                                    "div",
                                                    {
                                                      staticClass:
                                                        "input__wrapper",
                                                    },
                                                    [
                                                      _c("v-select", {
                                                        staticClass:
                                                          "form__input",
                                                        class: {
                                                          "form__input--invalid":
                                                            filter
                                                              .frequencyOperator
                                                              .errors.length >
                                                            0,
                                                        },
                                                        attrs: {
                                                          id: "frequency-operator",
                                                          options:
                                                            filter.frequencyOptions,
                                                          placeholder:
                                                            _vm.$t("Select"),
                                                          disabled:
                                                            filter.isDisabled,
                                                          label: "text",
                                                          clearable: false,
                                                        },
                                                        on: {
                                                          input: function (
                                                            $event
                                                          ) {
                                                            _vm.getSelectedFrequency(
                                                              $event,
                                                              filter
                                                            ),
                                                              _vm.handleFormInput(
                                                                _vm.formState,
                                                                [
                                                                  filter.frequencyOperator,
                                                                  filter.frequencyFirstValue,
                                                                  filter.frequencySecondValue,
                                                                ]
                                                              )
                                                          },
                                                        },
                                                        model: {
                                                          value:
                                                            filter
                                                              .frequencyOperator
                                                              .value,
                                                          callback: function (
                                                            $$v
                                                          ) {
                                                            _vm.$set(
                                                              filter.frequencyOperator,
                                                              "value",
                                                              $$v
                                                            )
                                                          },
                                                          expression:
                                                            "filter.frequencyOperator.value",
                                                        },
                                                      }),
                                                      _vm._l(
                                                        filter.frequencyOperator
                                                          .errors,
                                                        function (
                                                          error,
                                                          errIndex
                                                        ) {
                                                          return _c(
                                                            "div",
                                                            {
                                                              key: errIndex,
                                                              staticClass:
                                                                "error__info-message",
                                                            },
                                                            [
                                                              _c("span", [
                                                                _vm._v(
                                                                  _vm._s(error)
                                                                ),
                                                              ]),
                                                            ]
                                                          )
                                                        }
                                                      ),
                                                    ],
                                                    2
                                                  ),
                                                  _c(
                                                    "div",
                                                    {
                                                      staticClass:
                                                        "input__wrapper",
                                                    },
                                                    [
                                                      _c("b-form-input", {
                                                        staticClass:
                                                          "form__input",
                                                        class: {
                                                          "form__input--invalid":
                                                            filter
                                                              .frequencyFirstValue
                                                              .errors.length >
                                                            0,
                                                        },
                                                        attrs: {
                                                          id: "frequency-first-value",
                                                          placeholder: "0",
                                                          disabled:
                                                            filter.isDisabled,
                                                          type: "number",
                                                        },
                                                        on: {
                                                          input: function (
                                                            $event
                                                          ) {
                                                            return _vm.handleFormInput(
                                                              _vm.formState,
                                                              [
                                                                filter.frequencyFirstValue,
                                                              ]
                                                            )
                                                          },
                                                        },
                                                        model: {
                                                          value:
                                                            filter
                                                              .frequencyFirstValue
                                                              .value,
                                                          callback: function (
                                                            $$v
                                                          ) {
                                                            _vm.$set(
                                                              filter.frequencyFirstValue,
                                                              "value",
                                                              $$v
                                                            )
                                                          },
                                                          expression:
                                                            "filter.frequencyFirstValue.value",
                                                        },
                                                      }),
                                                      _vm._l(
                                                        filter
                                                          .frequencyFirstValue
                                                          .errors,
                                                        function (
                                                          error,
                                                          errIndex
                                                        ) {
                                                          return _c(
                                                            "div",
                                                            {
                                                              key: errIndex,
                                                              staticClass:
                                                                "error__info-message error__info-message_text-wrap",
                                                            },
                                                            [
                                                              _c("span", [
                                                                _vm._v(
                                                                  _vm._s(error)
                                                                ),
                                                              ]),
                                                            ]
                                                          )
                                                        }
                                                      ),
                                                    ],
                                                    2
                                                  ),
                                                  !filter.showFrequencySecondParam
                                                    ? _c(
                                                        "span",
                                                        {
                                                          staticClass:
                                                            "segment__param-span",
                                                        },
                                                        [
                                                          _vm._v(
                                                            _vm._s(
                                                              _vm.$t(
                                                                "audience.time"
                                                              )
                                                            )
                                                          ),
                                                        ]
                                                      )
                                                    : _vm._e(),
                                                  filter.showFrequencySecondParam
                                                    ? _c(
                                                        "span",
                                                        {
                                                          staticClass:
                                                            "segment__param-span",
                                                        },
                                                        [
                                                          _vm._v(
                                                            _vm._s(
                                                              _vm
                                                                .$t(
                                                                  "audience.and"
                                                                )
                                                                .toLowerCase()
                                                            )
                                                          ),
                                                        ]
                                                      )
                                                    : _vm._e(),
                                                  filter.showFrequencySecondParam
                                                    ? _c(
                                                        "div",
                                                        {
                                                          staticClass:
                                                            "input__wrapper",
                                                        },
                                                        [
                                                          _c("b-form-input", {
                                                            staticClass:
                                                              "form__input",
                                                            class: {
                                                              "form__input--invalid":
                                                                filter
                                                                  .frequencySecondValue
                                                                  .errors
                                                                  .length > 0,
                                                            },
                                                            attrs: {
                                                              id: "frequency-second-value",
                                                              placeholder: "0",
                                                              disabled:
                                                                filter.isDisabled,
                                                              type: "number",
                                                            },
                                                            on: {
                                                              input: function (
                                                                $event
                                                              ) {
                                                                return _vm.handleFormInput(
                                                                  _vm.formState,
                                                                  [
                                                                    filter.frequencySecondValue,
                                                                  ]
                                                                )
                                                              },
                                                            },
                                                            model: {
                                                              value:
                                                                filter
                                                                  .frequencySecondValue
                                                                  .value,
                                                              callback:
                                                                function ($$v) {
                                                                  _vm.$set(
                                                                    filter.frequencySecondValue,
                                                                    "value",
                                                                    $$v
                                                                  )
                                                                },
                                                              expression:
                                                                "filter.frequencySecondValue.value",
                                                            },
                                                          }),
                                                          _vm._l(
                                                            filter
                                                              .frequencySecondValue
                                                              .errors,
                                                            function (
                                                              error,
                                                              errIndex
                                                            ) {
                                                              return _c(
                                                                "div",
                                                                {
                                                                  key: errIndex,
                                                                  staticClass:
                                                                    "error__info-message error__info-message_text-wrap",
                                                                },
                                                                [
                                                                  _c("span", [
                                                                    _vm._v(
                                                                      _vm._s(
                                                                        error
                                                                      )
                                                                    ),
                                                                  ]),
                                                                ]
                                                              )
                                                            }
                                                          ),
                                                        ],
                                                        2
                                                      )
                                                    : _vm._e(),
                                                ]
                                              ),
                                              !filter.isDisabled
                                                ? _c(
                                                    "div",
                                                    {
                                                      staticClass:
                                                        "segments__remove",
                                                      attrs: {
                                                        id: "remove-frequency",
                                                      },
                                                      on: {
                                                        click: function (
                                                          $event
                                                        ) {
                                                          return _vm.removeFilterParam(
                                                            "frequency",
                                                            filter
                                                          )
                                                        },
                                                      },
                                                    },
                                                    [
                                                      _c("GjIcon", {
                                                        staticClass:
                                                          "mr-50 segment__icon",
                                                        attrs: {
                                                          name: "Close",
                                                          size: "20",
                                                        },
                                                      }),
                                                    ],
                                                    1
                                                  )
                                                : _vm._e(),
                                            ]
                                          )
                                        : _vm._e(),
                                      _c(
                                        "div",
                                        {
                                          staticClass:
                                            "segment__button--container",
                                          attrs: {
                                            id: `segment__button--parameters-${blockIndex}-${filterIndex}`,
                                          },
                                        },
                                        [
                                          !filter.isParametersParamSet
                                            ? _c(
                                                "b-button",
                                                {
                                                  staticClass:
                                                    "segment__button",
                                                  attrs: {
                                                    id: "add-parameter",
                                                    disabled:
                                                      filter
                                                        .segmentationTableModel
                                                        .length === 0 ||
                                                      filter.isDisabled,
                                                  },
                                                  on: {
                                                    click: function ($event) {
                                                      return _vm.addFilterParam(
                                                        filter,
                                                        "parameters"
                                                      )
                                                    },
                                                  },
                                                },
                                                [
                                                  _c(
                                                    "div",
                                                    {
                                                      staticClass:
                                                        "segment__button--icon",
                                                    },
                                                    [
                                                      _c("GjIcon", {
                                                        staticClass: "mr-50",
                                                        attrs: {
                                                          name: "Plus",
                                                          size: "20",
                                                        },
                                                      }),
                                                    ],
                                                    1
                                                  ),
                                                  _c(
                                                    "span",
                                                    {
                                                      staticClass:
                                                        "align-middle",
                                                    },
                                                    [
                                                      _vm._v(
                                                        _vm._s(
                                                          _vm.$t(
                                                            "audience.parameters"
                                                          )
                                                        )
                                                      ),
                                                    ]
                                                  ),
                                                  !filter.segmentationTableModel
                                                    ? _c(
                                                        "b-tooltip",
                                                        {
                                                          attrs: {
                                                            target: `segment__button--parameters-${blockIndex}-${filterIndex}`,
                                                            placement: "right",
                                                          },
                                                        },
                                                        [
                                                          _vm._v(
                                                            " " +
                                                              _vm._s(
                                                                _vm.$t(
                                                                  "audience.parameterTooltipMessage"
                                                                )
                                                              ) +
                                                              " "
                                                          ),
                                                        ]
                                                      )
                                                    : _vm._e(),
                                                ],
                                                1
                                              )
                                            : _vm._e(),
                                        ],
                                        1
                                      ),
                                      filter.isParametersParamSet
                                        ? _c(
                                            "div",
                                            { staticClass: "segment__param" },
                                            [
                                              _c(
                                                "div",
                                                {
                                                  staticClass: "segment__param",
                                                },
                                                [
                                                  _c(
                                                    "div",
                                                    {
                                                      staticClass:
                                                        "segment__param-selects",
                                                    },
                                                    [
                                                      _c(
                                                        "div",
                                                        {
                                                          staticClass:
                                                            "segment__param-selects-content",
                                                        },
                                                        [
                                                          _c("GjIcon", {
                                                            staticClass:
                                                              "mr-50",
                                                            attrs: {
                                                              name: "AlignLeftAlt",
                                                              size: "20",
                                                            },
                                                          }),
                                                          _c(
                                                            "span",
                                                            {
                                                              staticClass:
                                                                "segment__param-title segment__param-title--width",
                                                            },
                                                            [
                                                              _vm._v(
                                                                _vm._s(
                                                                  _vm.$t(
                                                                    "audience.with"
                                                                  )
                                                                )
                                                              ),
                                                            ]
                                                          ),
                                                          _c(
                                                            "div",
                                                            {
                                                              staticClass:
                                                                "segment__param-heading",
                                                            },
                                                            [
                                                              _c(
                                                                "div",
                                                                {
                                                                  staticClass:
                                                                    "input__wrapper",
                                                                },
                                                                [
                                                                  _c(
                                                                    "v-select",
                                                                    {
                                                                      staticClass:
                                                                        "form__input",
                                                                      attrs: {
                                                                        id: "parameters-default",
                                                                        options:
                                                                          filter.withParameters,
                                                                        disabled:
                                                                          filter.isDisabled,
                                                                        label:
                                                                          "text",
                                                                        clearable: false,
                                                                      },
                                                                      on: {
                                                                        input:
                                                                          function (
                                                                            $event
                                                                          ) {
                                                                            return _vm.handleFormInput(
                                                                              _vm.formState
                                                                            )
                                                                          },
                                                                      },
                                                                      model: {
                                                                        value:
                                                                          filter.withParametersDefault,
                                                                        callback:
                                                                          function (
                                                                            $$v
                                                                          ) {
                                                                            _vm.$set(
                                                                              filter,
                                                                              "withParametersDefault",
                                                                              $$v
                                                                            )
                                                                          },
                                                                        expression:
                                                                          "filter.withParametersDefault",
                                                                      },
                                                                    }
                                                                  ),
                                                                ],
                                                                1
                                                              ),
                                                              _c(
                                                                "span",
                                                                {
                                                                  staticClass:
                                                                    "segment__param-title",
                                                                },
                                                                [
                                                                  _vm._v(
                                                                    _vm._s(
                                                                      _vm.$t(
                                                                        "audience.ofFollowingParameters"
                                                                      )
                                                                    ) + ":"
                                                                  ),
                                                                ]
                                                              ),
                                                            ]
                                                          ),
                                                        ],
                                                        1
                                                      ),
                                                      _vm._l(
                                                        filter.parameters,
                                                        function (
                                                          param,
                                                          paramIndex
                                                        ) {
                                                          return _c(
                                                            "div",
                                                            {
                                                              key: param.paramIndex,
                                                              staticClass:
                                                                "segment__param--wrapper",
                                                            },
                                                            [
                                                              _c(
                                                                "div",
                                                                {
                                                                  staticClass:
                                                                    "input__wrapper input__wrapper--flex input__wrapper--width",
                                                                },
                                                                [
                                                                  paramIndex >
                                                                    0 &&
                                                                  filter
                                                                    .withParametersDefault
                                                                    .value === 1
                                                                    ? _c(
                                                                        "span",
                                                                        {
                                                                          staticClass:
                                                                            "parameters__operators",
                                                                        },
                                                                        [
                                                                          _vm._v(
                                                                            " " +
                                                                              _vm._s(
                                                                                _vm.$t(
                                                                                  "audience.and"
                                                                                )
                                                                              ) +
                                                                              " "
                                                                          ),
                                                                        ]
                                                                      )
                                                                    : _vm._e(),
                                                                  paramIndex >
                                                                    0 &&
                                                                  filter
                                                                    .withParametersDefault
                                                                    .value === 2
                                                                    ? _c(
                                                                        "span",
                                                                        {
                                                                          staticClass:
                                                                            "parameters__operators",
                                                                        },
                                                                        [
                                                                          _vm._v(
                                                                            " " +
                                                                              _vm._s(
                                                                                _vm.$t(
                                                                                  "audience.or"
                                                                                )
                                                                              ) +
                                                                              " "
                                                                          ),
                                                                        ]
                                                                      )
                                                                    : _vm._e(),
                                                                  _c(
                                                                    "div",
                                                                    {
                                                                      staticClass:
                                                                        "parameters__custom input__wrapper-parameters",
                                                                    },
                                                                    [
                                                                      _c(
                                                                        "v-select",
                                                                        {
                                                                          staticClass:
                                                                            "form__input segment__select",
                                                                          class:
                                                                            {
                                                                              "form__input--invalid":
                                                                                param
                                                                                  .columnId
                                                                                  .errors
                                                                                  .length >
                                                                                0,
                                                                            },
                                                                          attrs:
                                                                            {
                                                                              id: `parameter-options-${paramIndex}`,
                                                                              placeholder:
                                                                                _vm.$t(
                                                                                  "audience.parameters"
                                                                                ),
                                                                              options:
                                                                                param.parameterOptions,
                                                                              disabled:
                                                                                filter.isDisabled,
                                                                              label:
                                                                                "parameterName",
                                                                              selectable:
                                                                                (
                                                                                  option
                                                                                ) =>
                                                                                  !option.isParent,
                                                                              "menu-props":
                                                                                {
                                                                                  top: true,
                                                                                  offsetY: true,
                                                                                },
                                                                              clearable: false,
                                                                            },
                                                                          on: {
                                                                            input:
                                                                              function (
                                                                                $event
                                                                              ) {
                                                                                _vm.getSelectedParameter(
                                                                                  $event,
                                                                                  param
                                                                                )
                                                                                _vm.setParameterOptions(
                                                                                  param
                                                                                )
                                                                                _vm.handleFormInput(
                                                                                  _vm.formState,
                                                                                  [
                                                                                    param.columnId,
                                                                                    param.operator,
                                                                                    param.parameterValues,
                                                                                  ]
                                                                                )
                                                                              },
                                                                          },
                                                                          model:
                                                                            {
                                                                              value:
                                                                                param.parameterOptionsModel,
                                                                              callback:
                                                                                function (
                                                                                  $$v
                                                                                ) {
                                                                                  _vm.$set(
                                                                                    param,
                                                                                    "parameterOptionsModel",
                                                                                    $$v
                                                                                  )
                                                                                },
                                                                              expression:
                                                                                "param.parameterOptionsModel",
                                                                            },
                                                                        }
                                                                      ),
                                                                      _vm._l(
                                                                        param
                                                                          .columnId
                                                                          .errors,
                                                                        function (
                                                                          error,
                                                                          errIndex
                                                                        ) {
                                                                          return _c(
                                                                            "div",
                                                                            {
                                                                              key: errIndex,
                                                                              staticClass:
                                                                                "error__info-message",
                                                                            },
                                                                            [
                                                                              _c(
                                                                                "span",
                                                                                [
                                                                                  _vm._v(
                                                                                    _vm._s(
                                                                                      error
                                                                                    )
                                                                                  ),
                                                                                ]
                                                                              ),
                                                                            ]
                                                                          )
                                                                        }
                                                                      ),
                                                                    ],
                                                                    2
                                                                  ),
                                                                  _c(
                                                                    "div",
                                                                    {
                                                                      staticClass:
                                                                        "parameters__custom-content",
                                                                      class: [
                                                                        param.isDateParam &&
                                                                        !param.selectedParameterOptionModel
                                                                          ? "parameters__custom-content--no-margin"
                                                                          : "",
                                                                        param.isDateParam &&
                                                                        param.selectedParameterOptionModel &&
                                                                        !param.isParameterBetweenOperatorShown
                                                                          ? "parameter-date--no-margin"
                                                                          : "",
                                                                        !param.isCustomParam &&
                                                                        !param.isDateParam
                                                                          ? "parameters-not-custom"
                                                                          : "",
                                                                        param.isBooleanParam &&
                                                                        !param.selectedParameterOptionModel
                                                                          ? "parameters__bolean-content"
                                                                          : "",
                                                                        param.isBooleanParam &&
                                                                        param.selectedParameterOptionModel
                                                                          ? "parameters__bolean-content-is"
                                                                          : "",
                                                                        _vm.isDateTimeType(
                                                                          param
                                                                            .parameterOptionsModel
                                                                            .columnType
                                                                        ) &&
                                                                        param.selectedParameterOptionModel &&
                                                                        !param.isParameterBetweenOperatorShown
                                                                          ? "parameter-datetime--no-margin"
                                                                          : "",
                                                                      ],
                                                                    },
                                                                    [
                                                                      param.isCustomParam &&
                                                                      param
                                                                        .parameterOptionsModel
                                                                        .columnType !==
                                                                        16
                                                                        ? _c(
                                                                            "div",
                                                                            [
                                                                              _c(
                                                                                "b-form-input",
                                                                                {
                                                                                  staticClass:
                                                                                    "form__input form__input--small segment__key-input",
                                                                                  class:
                                                                                    {
                                                                                      "form__input--invalid":
                                                                                        param
                                                                                          .parameterValues
                                                                                          .errors
                                                                                          .length >
                                                                                        0,
                                                                                    },
                                                                                  attrs:
                                                                                    {
                                                                                      id: `parameter-stringKey-${paramIndex}`,
                                                                                      placeholder:
                                                                                        _vm.$t(
                                                                                          "key"
                                                                                        ),
                                                                                      disabled:
                                                                                        filter.isDisabled,
                                                                                      type: "text",
                                                                                    },
                                                                                  on: {
                                                                                    input:
                                                                                      function (
                                                                                        $event
                                                                                      ) {
                                                                                        return _vm.handleFormInput(
                                                                                          _vm.formState,
                                                                                          [
                                                                                            param.parameterValues,
                                                                                          ]
                                                                                        )
                                                                                      },
                                                                                  },
                                                                                  model:
                                                                                    {
                                                                                      value:
                                                                                        param.parametersStringKeyModel,
                                                                                      callback:
                                                                                        function (
                                                                                          $$v
                                                                                        ) {
                                                                                          _vm.$set(
                                                                                            param,
                                                                                            "parametersStringKeyModel",
                                                                                            $$v
                                                                                          )
                                                                                        },
                                                                                      expression:
                                                                                        "param.parametersStringKeyModel",
                                                                                    },
                                                                                }
                                                                              ),
                                                                            ],
                                                                            1
                                                                          )
                                                                        : _vm._e(),
                                                                      param.parameterOptionsModel &&
                                                                      (param
                                                                        .parameterOptionsModel
                                                                        .length >
                                                                        0 ||
                                                                        param
                                                                          .parameterOptionsModel
                                                                          .columnId
                                                                          .length >
                                                                          0)
                                                                        ? _c(
                                                                            "div",
                                                                            {
                                                                              staticClass:
                                                                                "input__wrapper--flex",
                                                                            },
                                                                            [
                                                                              _c(
                                                                                "div",
                                                                                {
                                                                                  class:
                                                                                    {
                                                                                      "date-parameter-specific":
                                                                                        param.isDateParam &&
                                                                                        param.isParameterBetweenOperatorShown,
                                                                                    },
                                                                                },
                                                                                [
                                                                                  _c(
                                                                                    "v-select",
                                                                                    {
                                                                                      staticClass:
                                                                                        "form__input",
                                                                                      class:
                                                                                        {
                                                                                          "form__input--invalid":
                                                                                            param
                                                                                              .operator
                                                                                              .errors
                                                                                              .length >
                                                                                            0,
                                                                                        },
                                                                                      attrs:
                                                                                        {
                                                                                          id: `selected-parameter-option-${paramIndex}`,
                                                                                          placeholder:
                                                                                            _vm.$t(
                                                                                              "Select"
                                                                                            ),
                                                                                          options:
                                                                                            param.selectedParameterOptions,
                                                                                          disabled:
                                                                                            filter.isDisabled,
                                                                                          label:
                                                                                            "name",
                                                                                          clearable: false,
                                                                                        },
                                                                                      on: {
                                                                                        input:
                                                                                          function (
                                                                                            $event
                                                                                          ) {
                                                                                            _vm.handleInputsBasedOnOperatorChosen(
                                                                                              param
                                                                                            ),
                                                                                              _vm.handleFormInput(
                                                                                                _vm.formState,
                                                                                                [
                                                                                                  param.operator,
                                                                                                  param.parameterValues,
                                                                                                ]
                                                                                              )
                                                                                          },
                                                                                      },
                                                                                      model:
                                                                                        {
                                                                                          value:
                                                                                            param.selectedParameterOptionModel,
                                                                                          callback:
                                                                                            function (
                                                                                              $$v
                                                                                            ) {
                                                                                              _vm.$set(
                                                                                                param,
                                                                                                "selectedParameterOptionModel",
                                                                                                $$v
                                                                                              )
                                                                                            },
                                                                                          expression:
                                                                                            "param.selectedParameterOptionModel",
                                                                                        },
                                                                                    }
                                                                                  ),
                                                                                  _vm._l(
                                                                                    param
                                                                                      .operator
                                                                                      .errors,
                                                                                    function (
                                                                                      error,
                                                                                      errIndex
                                                                                    ) {
                                                                                      return _c(
                                                                                        "div",
                                                                                        {
                                                                                          key: errIndex,
                                                                                          staticClass:
                                                                                            "error__info-message",
                                                                                        },
                                                                                        [
                                                                                          _c(
                                                                                            "span",
                                                                                            [
                                                                                              _vm._v(
                                                                                                _vm._s(
                                                                                                  error
                                                                                                )
                                                                                              ),
                                                                                            ]
                                                                                          ),
                                                                                        ]
                                                                                      )
                                                                                    }
                                                                                  ),
                                                                                ],
                                                                                2
                                                                              ),
                                                                              param.isDateParam &&
                                                                              !!param.selectedParameterOptionModel
                                                                                ? _c(
                                                                                    "div",
                                                                                    {
                                                                                      staticClass:
                                                                                        "parameter__dates",
                                                                                    },
                                                                                    [
                                                                                      _c(
                                                                                        "div",
                                                                                        {
                                                                                          staticClass:
                                                                                            "segment__datetime-picker",
                                                                                        },
                                                                                        [
                                                                                          _c(
                                                                                            "div",
                                                                                            [
                                                                                              !_vm.isDateTimeType(
                                                                                                param
                                                                                                  .parameterOptionsModel
                                                                                                  .columnType
                                                                                              )
                                                                                                ? _c(
                                                                                                    "b-form-datepicker",
                                                                                                    {
                                                                                                      staticClass:
                                                                                                        "form__input",
                                                                                                      class:
                                                                                                        {
                                                                                                          "form__input--invalid":
                                                                                                            param
                                                                                                              .parameterValues
                                                                                                              .errors
                                                                                                              .length >
                                                                                                            0,
                                                                                                        },
                                                                                                      attrs:
                                                                                                        {
                                                                                                          id: `parameter-first-date-${blockIndex}-${filterIndex}-${paramIndex}`,
                                                                                                          "calendar-width":
                                                                                                            "100%",
                                                                                                          disabled:
                                                                                                            filter.isDisabled,
                                                                                                          max: _vm.maxDate,
                                                                                                          "date-format-options":
                                                                                                            {
                                                                                                              year: "numeric",
                                                                                                              month:
                                                                                                                "short",
                                                                                                              day: "2-digit",
                                                                                                              weekday:
                                                                                                                "short",
                                                                                                            },
                                                                                                        },
                                                                                                      on: {
                                                                                                        input:
                                                                                                          function (
                                                                                                            $event
                                                                                                          ) {
                                                                                                            _vm.parameterDatePickerValueChanged(
                                                                                                              param
                                                                                                            ),
                                                                                                              _vm.handleFormInput(
                                                                                                                _vm.formState,
                                                                                                                [
                                                                                                                  param.parameterValues,
                                                                                                                ]
                                                                                                              )
                                                                                                          },
                                                                                                      },
                                                                                                      model:
                                                                                                        {
                                                                                                          value:
                                                                                                            param.parameterFirstDate,
                                                                                                          callback:
                                                                                                            function (
                                                                                                              $$v
                                                                                                            ) {
                                                                                                              _vm.$set(
                                                                                                                param,
                                                                                                                "parameterFirstDate",
                                                                                                                $$v
                                                                                                              )
                                                                                                            },
                                                                                                          expression:
                                                                                                            "param.parameterFirstDate",
                                                                                                        },
                                                                                                    }
                                                                                                  )
                                                                                                : _c(
                                                                                                    "div",
                                                                                                    {
                                                                                                      class:
                                                                                                        {
                                                                                                          "form__input--invalid":
                                                                                                            param
                                                                                                              .parameterValues
                                                                                                              .errors
                                                                                                              .length >
                                                                                                            0,
                                                                                                        },
                                                                                                    },
                                                                                                    [
                                                                                                      _c(
                                                                                                        "date-pick",
                                                                                                        {
                                                                                                          attrs:
                                                                                                            {
                                                                                                              id: `parameter-first-datetime-${blockIndex}-${filterIndex}-${paramIndex}`,
                                                                                                              editable:
                                                                                                                !filter.isDisabled,
                                                                                                              "pick-time": true,
                                                                                                              "use12hour-clock": true,
                                                                                                              format:
                                                                                                                "YYYY-MM-DD HH:mm",
                                                                                                              "display-format":
                                                                                                                "YYYY.MM.DD H:mm A",
                                                                                                              "is-date-disabled":
                                                                                                                _vm.maximumDate,
                                                                                                              "input-attributes":
                                                                                                                {
                                                                                                                  placeholder:
                                                                                                                    "Select",
                                                                                                                },
                                                                                                            },
                                                                                                          on: {
                                                                                                            input:
                                                                                                              function (
                                                                                                                $event
                                                                                                              ) {
                                                                                                                _vm.parameterDatePickerValueChanged(
                                                                                                                  param
                                                                                                                ),
                                                                                                                  _vm.handleFormInput(
                                                                                                                    _vm.formState,
                                                                                                                    [
                                                                                                                      param.parameterValues,
                                                                                                                    ]
                                                                                                                  )
                                                                                                              },
                                                                                                          },
                                                                                                          model:
                                                                                                            {
                                                                                                              value:
                                                                                                                param.parameterFirstDate,
                                                                                                              callback:
                                                                                                                function (
                                                                                                                  $$v
                                                                                                                ) {
                                                                                                                  _vm.$set(
                                                                                                                    param,
                                                                                                                    "parameterFirstDate",
                                                                                                                    $$v
                                                                                                                  )
                                                                                                                },
                                                                                                              expression:
                                                                                                                "param.parameterFirstDate",
                                                                                                            },
                                                                                                        }
                                                                                                      ),
                                                                                                    ],
                                                                                                    1
                                                                                                  ),
                                                                                              _vm._l(
                                                                                                param
                                                                                                  .parameterValues
                                                                                                  .errors,
                                                                                                function (
                                                                                                  error,
                                                                                                  errIndex
                                                                                                ) {
                                                                                                  return _c(
                                                                                                    "div",
                                                                                                    {
                                                                                                      key: errIndex,
                                                                                                      staticClass:
                                                                                                        "error__info-message",
                                                                                                    },
                                                                                                    [
                                                                                                      _c(
                                                                                                        "span",
                                                                                                        [
                                                                                                          _vm._v(
                                                                                                            _vm._s(
                                                                                                              error
                                                                                                            )
                                                                                                          ),
                                                                                                        ]
                                                                                                      ),
                                                                                                    ]
                                                                                                  )
                                                                                                }
                                                                                              ),
                                                                                            ],
                                                                                            2
                                                                                          ),
                                                                                        ]
                                                                                      ),
                                                                                      param.isParameterBetweenOperatorShown
                                                                                        ? _c(
                                                                                            "div",
                                                                                            {
                                                                                              staticClass:
                                                                                                "input__wrapper--date segment__datetime-picker",
                                                                                            },
                                                                                            [
                                                                                              _c(
                                                                                                "span",
                                                                                                [
                                                                                                  _vm._v(
                                                                                                    _vm._s(
                                                                                                      _vm
                                                                                                        .$t(
                                                                                                          "audience.and"
                                                                                                        )
                                                                                                        .toLowerCase()
                                                                                                    )
                                                                                                  ),
                                                                                                ]
                                                                                              ),
                                                                                              !_vm.isDateTimeType(
                                                                                                param
                                                                                                  .parameterOptionsModel
                                                                                                  .columnType
                                                                                              )
                                                                                                ? _c(
                                                                                                    "b-form-datepicker",
                                                                                                    {
                                                                                                      staticClass:
                                                                                                        "form__input",
                                                                                                      class:
                                                                                                        {
                                                                                                          "form__input--invalid":
                                                                                                            param
                                                                                                              .parameterValues
                                                                                                              .errors
                                                                                                              .length >
                                                                                                            0,
                                                                                                        },
                                                                                                      attrs:
                                                                                                        {
                                                                                                          id: `parameter-second-date-${blockIndex}-${filterIndex}-${paramIndex}`,
                                                                                                          disabled:
                                                                                                            filter.isDisabled,
                                                                                                          "calendar-width":
                                                                                                            "100%",
                                                                                                          max: _vm.maxDate,
                                                                                                          min: param.parameterFirstDate,
                                                                                                          "date-format-options":
                                                                                                            {
                                                                                                              year: "numeric",
                                                                                                              month:
                                                                                                                "short",
                                                                                                              day: "2-digit",
                                                                                                              weekday:
                                                                                                                "short",
                                                                                                            },
                                                                                                        },
                                                                                                      on: {
                                                                                                        input:
                                                                                                          function (
                                                                                                            $event
                                                                                                          ) {
                                                                                                            return _vm.handleFormInput(
                                                                                                              _vm.formState,
                                                                                                              [
                                                                                                                param.parameterValues,
                                                                                                              ]
                                                                                                            )
                                                                                                          },
                                                                                                      },
                                                                                                      model:
                                                                                                        {
                                                                                                          value:
                                                                                                            param.parameterSecondDate,
                                                                                                          callback:
                                                                                                            function (
                                                                                                              $$v
                                                                                                            ) {
                                                                                                              _vm.$set(
                                                                                                                param,
                                                                                                                "parameterSecondDate",
                                                                                                                $$v
                                                                                                              )
                                                                                                            },
                                                                                                          expression:
                                                                                                            "param.parameterSecondDate",
                                                                                                        },
                                                                                                    }
                                                                                                  )
                                                                                                : _c(
                                                                                                    "div",
                                                                                                    {
                                                                                                      class:
                                                                                                        {
                                                                                                          "form__input--invalid":
                                                                                                            param
                                                                                                              .parameterValues
                                                                                                              .errors
                                                                                                              .length >
                                                                                                            0,
                                                                                                        },
                                                                                                    },
                                                                                                    [
                                                                                                      _c(
                                                                                                        "date-pick",
                                                                                                        {
                                                                                                          attrs:
                                                                                                            {
                                                                                                              id: `parameter-second-datetime-${blockIndex}-${filterIndex}-${paramIndex}`,
                                                                                                              editable:
                                                                                                                !filter.isDisabled,
                                                                                                              "pick-time": true,
                                                                                                              "use12hour-clock": true,
                                                                                                              format:
                                                                                                                "YYYY-MM-DD HH:mm",
                                                                                                              "display-format":
                                                                                                                "YYYY.MM.DD H:mm A",
                                                                                                              "is-date-disabled":
                                                                                                                (
                                                                                                                  date
                                                                                                                ) =>
                                                                                                                  _vm.secondDateHandler(
                                                                                                                    date,
                                                                                                                    param.parameterFirstDate
                                                                                                                  ),
                                                                                                              "input-attributes":
                                                                                                                {
                                                                                                                  placeholder:
                                                                                                                    "Select",
                                                                                                                },
                                                                                                            },
                                                                                                          on: {
                                                                                                            input:
                                                                                                              function (
                                                                                                                $event
                                                                                                              ) {
                                                                                                                return _vm.handleFormInput(
                                                                                                                  _vm.formState,
                                                                                                                  [
                                                                                                                    param.parameterValues,
                                                                                                                  ]
                                                                                                                )
                                                                                                              },
                                                                                                          },
                                                                                                          model:
                                                                                                            {
                                                                                                              value:
                                                                                                                param.parameterSecondDate,
                                                                                                              callback:
                                                                                                                function (
                                                                                                                  $$v
                                                                                                                ) {
                                                                                                                  _vm.$set(
                                                                                                                    param,
                                                                                                                    "parameterSecondDate",
                                                                                                                    $$v
                                                                                                                  )
                                                                                                                },
                                                                                                              expression:
                                                                                                                "param.parameterSecondDate",
                                                                                                            },
                                                                                                        }
                                                                                                      ),
                                                                                                    ],
                                                                                                    1
                                                                                                  ),
                                                                                            ],
                                                                                            1
                                                                                          )
                                                                                        : _vm._e(),
                                                                                    ]
                                                                                  )
                                                                                : _vm._e(),
                                                                              param.isBooleanParam &&
                                                                              !!param.selectedParameterOptionModel
                                                                                ? _c(
                                                                                    "div",
                                                                                    [
                                                                                      _c(
                                                                                        "v-select",
                                                                                        {
                                                                                          staticClass:
                                                                                            "form__input",
                                                                                          class:
                                                                                            {
                                                                                              "form__input--invalid":
                                                                                                param
                                                                                                  .parameterValues
                                                                                                  .errors
                                                                                                  .length >
                                                                                                0,
                                                                                            },
                                                                                          attrs:
                                                                                            {
                                                                                              id: `parameter-boolean-${paramIndex}`,
                                                                                              disabled:
                                                                                                filter.isDisabled,
                                                                                              placeholder:
                                                                                                _vm.$t(
                                                                                                  "Select"
                                                                                                ),
                                                                                              options:
                                                                                                param.parametersBooleanValues,
                                                                                              label:
                                                                                                "text",
                                                                                              clearable: false,
                                                                                            },
                                                                                          on: {
                                                                                            input:
                                                                                              function (
                                                                                                $event
                                                                                              ) {
                                                                                                _vm.handleInputsBasedOnOperatorChosen(
                                                                                                  param
                                                                                                ),
                                                                                                  _vm.handleFormInput(
                                                                                                    _vm.formState,
                                                                                                    [
                                                                                                      param.parameterValues,
                                                                                                    ]
                                                                                                  )
                                                                                              },
                                                                                          },
                                                                                          model:
                                                                                            {
                                                                                              value:
                                                                                                param.parametersBooleanModel,
                                                                                              callback:
                                                                                                function (
                                                                                                  $$v
                                                                                                ) {
                                                                                                  _vm.$set(
                                                                                                    param,
                                                                                                    "parametersBooleanModel",
                                                                                                    $$v
                                                                                                  )
                                                                                                },
                                                                                              expression:
                                                                                                "param.parametersBooleanModel",
                                                                                            },
                                                                                        }
                                                                                      ),
                                                                                      _vm._l(
                                                                                        param
                                                                                          .parameterValues
                                                                                          .errors,
                                                                                        function (
                                                                                          error,
                                                                                          errIndex
                                                                                        ) {
                                                                                          return _c(
                                                                                            "div",
                                                                                            {
                                                                                              key: errIndex,
                                                                                              staticClass:
                                                                                                "error__info-message",
                                                                                            },
                                                                                            [
                                                                                              _c(
                                                                                                "span",
                                                                                                [
                                                                                                  _vm._v(
                                                                                                    _vm._s(
                                                                                                      error
                                                                                                    )
                                                                                                  ),
                                                                                                ]
                                                                                              ),
                                                                                            ]
                                                                                          )
                                                                                        }
                                                                                      ),
                                                                                    ],
                                                                                    2
                                                                                  )
                                                                                : _vm._e(),
                                                                              !param.isDateParam &&
                                                                              !param.isBooleanParam
                                                                                ? _c(
                                                                                    "div",
                                                                                    {
                                                                                      staticClass:
                                                                                        "segment__param-info",
                                                                                    },
                                                                                    [
                                                                                      _c(
                                                                                        "b-form-tags",
                                                                                        {
                                                                                          staticClass:
                                                                                            "form__input form__input-params form__input-tags",
                                                                                          class:
                                                                                            {
                                                                                              "form__input--invalid":
                                                                                                param
                                                                                                  .parameterValues
                                                                                                  .errors
                                                                                                  .length >
                                                                                                0,
                                                                                            },
                                                                                          attrs:
                                                                                            {
                                                                                              id: `parameter-tags-${paramIndex}`,
                                                                                              disabled:
                                                                                                filter.isDisabled,
                                                                                              separator:
                                                                                                ";",
                                                                                              placeholder:
                                                                                                filter.isDisabled
                                                                                                  ? ""
                                                                                                  : _vm.$t(
                                                                                                      "value"
                                                                                                    ),
                                                                                              "remove-on-delete":
                                                                                                "",
                                                                                              "add-on-change":
                                                                                                "",
                                                                                              required:
                                                                                                "",
                                                                                            },
                                                                                          on: {
                                                                                            input:
                                                                                              function (
                                                                                                $event
                                                                                              ) {
                                                                                                return _vm.handleFormInput(
                                                                                                  _vm.formState,
                                                                                                  [
                                                                                                    param.parameterValues,
                                                                                                  ]
                                                                                                )
                                                                                              },
                                                                                          },
                                                                                          model:
                                                                                            {
                                                                                              value:
                                                                                                param.paramTagsModel,
                                                                                              callback:
                                                                                                function (
                                                                                                  $$v
                                                                                                ) {
                                                                                                  _vm.$set(
                                                                                                    param,
                                                                                                    "paramTagsModel",
                                                                                                    $$v
                                                                                                  )
                                                                                                },
                                                                                              expression:
                                                                                                "param.paramTagsModel",
                                                                                            },
                                                                                        }
                                                                                      ),
                                                                                      param
                                                                                        .parameterValues
                                                                                        .errors
                                                                                        .length <
                                                                                        1 &&
                                                                                      !filter.isDisabled
                                                                                        ? _c(
                                                                                            "span",
                                                                                            {
                                                                                              staticClass:
                                                                                                "parameter__tag-help",
                                                                                            },
                                                                                            [
                                                                                              _vm._v(
                                                                                                " * " +
                                                                                                  _vm._s(
                                                                                                    _vm.$t(
                                                                                                      "audience.enterTags"
                                                                                                    )
                                                                                                  ) +
                                                                                                  " "
                                                                                              ),
                                                                                            ]
                                                                                          )
                                                                                        : _vm._e(),
                                                                                      _vm._l(
                                                                                        param
                                                                                          .parameterValues
                                                                                          .errors,
                                                                                        function (
                                                                                          error,
                                                                                          errIndex
                                                                                        ) {
                                                                                          return _c(
                                                                                            "div",
                                                                                            {
                                                                                              key: errIndex,
                                                                                              staticClass:
                                                                                                "error__info-message",
                                                                                            },
                                                                                            [
                                                                                              _c(
                                                                                                "span",
                                                                                                [
                                                                                                  _vm._v(
                                                                                                    _vm._s(
                                                                                                      error
                                                                                                    )
                                                                                                  ),
                                                                                                ]
                                                                                              ),
                                                                                            ]
                                                                                          )
                                                                                        }
                                                                                      ),
                                                                                    ],
                                                                                    2
                                                                                  )
                                                                                : _vm._e(),
                                                                            ]
                                                                          )
                                                                        : _vm._e(),
                                                                    ]
                                                                  ),
                                                                  filter
                                                                    .parameters
                                                                    .length >
                                                                    0 &&
                                                                  !filter.isDisabled
                                                                    ? _c(
                                                                        "div",
                                                                        {
                                                                          staticClass:
                                                                            "params__remove",
                                                                          attrs:
                                                                            {
                                                                              id: `remove-parameter-${paramIndex}`,
                                                                            },
                                                                          on: {
                                                                            click:
                                                                              function (
                                                                                $event
                                                                              ) {
                                                                                return _vm.removeParameter(
                                                                                  blockIndex,
                                                                                  filterIndex,
                                                                                  paramIndex
                                                                                )
                                                                              },
                                                                          },
                                                                        },
                                                                        [
                                                                          _c(
                                                                            "GjIcon",
                                                                            {
                                                                              staticClass:
                                                                                "mr-50 segment__icon",
                                                                              attrs:
                                                                                {
                                                                                  name: "Close",
                                                                                  size: "20",
                                                                                },
                                                                            }
                                                                          ),
                                                                        ],
                                                                        1
                                                                      )
                                                                    : _vm._e(),
                                                                ]
                                                              ),
                                                              paramIndex ===
                                                              filter.parameters
                                                                .length -
                                                                1
                                                                ? _c(
                                                                    "div",
                                                                    _vm._g(
                                                                      {
                                                                        class: `add__parameters add__parameters-${
                                                                          !!param.parameterOptionsModel &&
                                                                          !filter.isDisabled
                                                                            ? "-margin"
                                                                            : "disabled"
                                                                        }`,
                                                                        attrs: {
                                                                          id: "add_parameters",
                                                                        },
                                                                      },
                                                                      !!param.parameterOptionsModel &&
                                                                        !filter.isDisabled
                                                                        ? {
                                                                            click:
                                                                              () =>
                                                                                _vm.addParameter(
                                                                                  blockIndex,
                                                                                  filterIndex
                                                                                ),
                                                                          }
                                                                        : {}
                                                                    ),
                                                                    [
                                                                      _c(
                                                                        "GjIcon",
                                                                        {
                                                                          staticClass:
                                                                            "mr-50 segment__icon",
                                                                          attrs:
                                                                            {
                                                                              name: "Plus",
                                                                              size: "20",
                                                                            },
                                                                        }
                                                                      ),
                                                                      _vm._v(
                                                                        " " +
                                                                          _vm._s(
                                                                            _vm.$t(
                                                                              "audience.parameters"
                                                                            )
                                                                          ) +
                                                                          " "
                                                                      ),
                                                                    ],
                                                                    1
                                                                  )
                                                                : _vm._e(),
                                                            ]
                                                          )
                                                        }
                                                      ),
                                                    ],
                                                    2
                                                  ),
                                                ]
                                              ),
                                              !filter.isDisabled
                                                ? _c(
                                                    "div",
                                                    {
                                                      staticClass:
                                                        "segments__remove",
                                                      attrs: {
                                                        id: "remove-parameter",
                                                      },
                                                      on: {
                                                        click: function (
                                                          $event
                                                        ) {
                                                          return _vm.removeFilterParam(
                                                            "parameters",
                                                            filter
                                                          )
                                                        },
                                                      },
                                                    },
                                                    [
                                                      _c("GjIcon", {
                                                        staticClass:
                                                          "mr-50 segment__icon",
                                                        attrs: {
                                                          name: "Close",
                                                          size: "20",
                                                        },
                                                      }),
                                                    ],
                                                    1
                                                  )
                                                : _vm._e(),
                                            ]
                                          )
                                        : _vm._e(),
                                      _c(
                                        "div",
                                        {
                                          staticClass:
                                            "segment__button--container",
                                          attrs: {
                                            id: `segment__button--topics-${blockIndex}-${filterIndex}`,
                                          },
                                        },
                                        [
                                          !filter.isTopicsParamSet
                                            ? _c(
                                                "b-button",
                                                {
                                                  staticClass:
                                                    "segment__button",
                                                  attrs: {
                                                    id: "add-topics",
                                                    disabled:
                                                      !filter.segmentationTableModel ||
                                                      filter.isDisabled ||
                                                      !_vm.isContextualTargetingEnabled,
                                                  },
                                                  on: {
                                                    click: function ($event) {
                                                      return _vm.addFilterParam(
                                                        filter,
                                                        "topics"
                                                      )
                                                    },
                                                  },
                                                },
                                                [
                                                  _c(
                                                    "div",
                                                    {
                                                      staticClass:
                                                        "segment__button--icon",
                                                    },
                                                    [
                                                      _c("GjIcon", {
                                                        staticClass: "mr-50",
                                                        attrs: {
                                                          name: "Plus",
                                                          size: "20",
                                                        },
                                                      }),
                                                    ],
                                                    1
                                                  ),
                                                  _c(
                                                    "span",
                                                    {
                                                      staticClass:
                                                        "align-middle",
                                                    },
                                                    [
                                                      _vm._v(
                                                        _vm._s(_vm.$t("Topics"))
                                                      ),
                                                    ]
                                                  ),
                                                  !filter.segmentationTableModel
                                                    ? _c(
                                                        "b-tooltip",
                                                        {
                                                          attrs: {
                                                            target: `segment__button--topics-${blockIndex}-${filterIndex}`,
                                                            placement: "right",
                                                          },
                                                        },
                                                        [
                                                          _vm._v(
                                                            " " +
                                                              _vm._s(
                                                                _vm.$t(
                                                                  "audience.parameterTooltipMessage"
                                                                )
                                                              ) +
                                                              " "
                                                          ),
                                                        ]
                                                      )
                                                    : _vm._e(),
                                                  !_vm.isContextualTargetingEnabled &&
                                                  filter.segmentationTableModel
                                                    ? _c(
                                                        "b-tooltip",
                                                        {
                                                          attrs: {
                                                            target: `segment__button--topics-${blockIndex}-${filterIndex}`,
                                                            placement: "right",
                                                          },
                                                        },
                                                        [
                                                          _vm._v(
                                                            _vm._s(
                                                              _vm.$t(
                                                                "audience.topics.notEnabledMessage"
                                                              )
                                                            )
                                                          ),
                                                        ]
                                                      )
                                                    : _vm._e(),
                                                ],
                                                1
                                              )
                                            : _vm._e(),
                                        ],
                                        1
                                      ),
                                      filter.isTopicsParamSet
                                        ? _c(
                                            "div",
                                            { staticClass: "segment__param" },
                                            [
                                              _c(
                                                "div",
                                                {
                                                  staticClass:
                                                    "segment__param segment__topics",
                                                },
                                                [
                                                  _c(
                                                    "div",
                                                    {
                                                      staticClass:
                                                        "icon__wrapper",
                                                    },
                                                    [
                                                      _c("GjIcon", {
                                                        staticClass: "mr-50",
                                                        attrs: {
                                                          name: "File",
                                                          size: "20",
                                                        },
                                                      }),
                                                    ],
                                                    1
                                                  ),
                                                  _c("div"),
                                                  _c(
                                                    "div",
                                                    {
                                                      staticClass:
                                                        "input__wrapper topics__select",
                                                    },
                                                    [
                                                      _c(
                                                        "div",
                                                        {
                                                          staticClass:
                                                            "input__wrapper-properties",
                                                        },
                                                        [
                                                          _c(
                                                            "MultipleProperties",
                                                            _vm._b(
                                                              {
                                                                attrs: {
                                                                  id: `topics-operator-${blockIndex}-${filterIndex}`,
                                                                  options:
                                                                    _vm.allTopics,
                                                                  "tags-to-show": 3,
                                                                  "no-debounce":
                                                                    "",
                                                                  classes: [
                                                                    "multiple-properties--three",
                                                                  ],
                                                                  "all-selected-classes":
                                                                    [
                                                                      "multiple-properties--all",
                                                                    ],
                                                                  "is-topic":
                                                                    "",
                                                                  "is-disabled":
                                                                    filter.isDisabled ||
                                                                    !_vm
                                                                      .allTopics
                                                                      .length,
                                                                  "invalid-input":
                                                                    !!filter
                                                                      .topics
                                                                      .errors
                                                                      .length,
                                                                },
                                                                on: {
                                                                  "update-data":
                                                                    (
                                                                      selected
                                                                    ) => {
                                                                      _vm.updateTopics(
                                                                        selected,
                                                                        filter
                                                                      )
                                                                    },
                                                                },
                                                              },
                                                              "MultipleProperties",
                                                              !!_vm.segmentId
                                                                ? {
                                                                    selected:
                                                                      filter
                                                                        .topics
                                                                        .value,
                                                                  }
                                                                : {},
                                                              false
                                                            )
                                                          ),
                                                          !_vm.allTopics.length
                                                            ? _c(
                                                                "b-tooltip",
                                                                {
                                                                  attrs: {
                                                                    target: `topics-operator-${blockIndex}-${filterIndex}`,
                                                                    placement:
                                                                      "right",
                                                                  },
                                                                },
                                                                [
                                                                  _vm._v(
                                                                    _vm._s(
                                                                      _vm.$t(
                                                                        "audience.topics.noTopicCreated"
                                                                      )
                                                                    )
                                                                  ),
                                                                ]
                                                              )
                                                            : _vm._e(),
                                                          _vm._l(
                                                            filter.topics
                                                              .errors,
                                                            function (
                                                              error,
                                                              errIndex
                                                            ) {
                                                              return _c(
                                                                "div",
                                                                {
                                                                  key: errIndex,
                                                                  staticClass:
                                                                    "error__info-message",
                                                                },
                                                                [
                                                                  _c("span", [
                                                                    _vm._v(
                                                                      _vm._s(
                                                                        error
                                                                      )
                                                                    ),
                                                                  ]),
                                                                ]
                                                              )
                                                            }
                                                          ),
                                                        ],
                                                        2
                                                      ),
                                                    ]
                                                  ),
                                                ]
                                              ),
                                              !filter.isDisabled
                                                ? _c(
                                                    "div",
                                                    {
                                                      staticClass:
                                                        "segments__remove",
                                                      attrs: {
                                                        id: "remove-topics-parameter",
                                                      },
                                                      on: {
                                                        click: function (
                                                          $event
                                                        ) {
                                                          return _vm.removeFilterParam(
                                                            "topics",
                                                            filter
                                                          )
                                                        },
                                                      },
                                                    },
                                                    [
                                                      _c("GjIcon", {
                                                        staticClass:
                                                          "mr-50 segment__icon",
                                                        attrs: {
                                                          name: "Close",
                                                          size: "20",
                                                        },
                                                      }),
                                                    ],
                                                    1
                                                  )
                                                : _vm._e(),
                                            ]
                                          )
                                        : _vm._e(),
                                      _vm._l(
                                        filter.filters.errors,
                                        function (error, errIndex) {
                                          return _c(
                                            "div",
                                            {
                                              key: errIndex,
                                              staticClass:
                                                "error__info-message",
                                            },
                                            [
                                              _c("span", [
                                                _vm._v(_vm._s(error)),
                                              ]),
                                            ]
                                          )
                                        }
                                      ),
                                    ],
                                    2
                                  )
                                : _vm._e(),
                              filter.filterType === _vm.filterTypes.segment
                                ? _c(
                                    "div",
                                    [
                                      block.filters.length > 1 &&
                                      filterIndex !== 0
                                        ? _c(
                                            "div",
                                            [
                                              _c(
                                                "b-button",
                                                {
                                                  staticClass:
                                                    "segment__button--or-multiple",
                                                },
                                                [
                                                  _c("span", [
                                                    _vm._v(
                                                      _vm._s(
                                                        _vm.$t("audience.or")
                                                      )
                                                    ),
                                                  ]),
                                                ]
                                              ),
                                            ],
                                            1
                                          )
                                        : _vm._e(),
                                      !(
                                        _vm.segment.blocks.length === 1 &&
                                        block.filters.length === 1
                                      )
                                        ? _c(
                                            "div",
                                            {
                                              staticClass:
                                                "container__content--segments_remove",
                                              attrs: { id: `remove-block` },
                                              on: {
                                                click: function ($event) {
                                                  return _vm.removeBlock(
                                                    blockIndex,
                                                    filterIndex
                                                  )
                                                },
                                              },
                                            },
                                            [
                                              _c("GjIcon", {
                                                staticClass:
                                                  "mr-50 segment__icon",
                                                attrs: {
                                                  name: "Close",
                                                  size: "20",
                                                },
                                              }),
                                            ],
                                            1
                                          )
                                        : _vm._e(),
                                      _c(
                                        "div",
                                        {
                                          staticClass:
                                            "input__wrapper input__wrapper--margin",
                                        },
                                        [
                                          _c(
                                            "div",
                                            {
                                              staticClass:
                                                "segment__wrapper-select organization__select segment__combine--input",
                                            },
                                            [
                                              _c(
                                                "label",
                                                { staticClass: "mr-1" },
                                                [_vm._v("Segment")]
                                              ),
                                              _c("v-select", {
                                                staticClass:
                                                  "form__input segment__select mr-1",
                                                class: {
                                                  "form__input--invalid":
                                                    filter.segment.errors
                                                      .length > 0,
                                                },
                                                attrs: {
                                                  id: "selected-segment",
                                                  options: !!_vm.segmentId
                                                    ? _vm.filterSegments.filter(
                                                        (s) =>
                                                          s.id !== _vm.segmentId
                                                      )
                                                    : _vm.filterSegments,
                                                  label: "name",
                                                  placeholder: "Select segment",
                                                  disabled: filter.isDisabled,
                                                  clearable: false,
                                                },
                                                on: {
                                                  input: function ($event) {
                                                    return _vm.handleFormInput(
                                                      _vm.formState,
                                                      [filter.segment]
                                                    )
                                                  },
                                                },
                                                model: {
                                                  value: filter.segment.value,
                                                  callback: function ($$v) {
                                                    _vm.$set(
                                                      filter.segment,
                                                      "value",
                                                      $$v
                                                    )
                                                  },
                                                  expression:
                                                    "filter.segment.value",
                                                },
                                              }),
                                              filter.isDisabled
                                                ? _c("InformationSidebar", {
                                                    attrs: {
                                                      code: `${blockIndex}-${filterIndex}`,
                                                      title: _vm.$t(
                                                        "audience.disabledFilter"
                                                      ),
                                                      content: `''${
                                                        filter.segment.value
                                                          .name
                                                      }'' is ${filter.segment.value.label.toLowerCase()},
                    users targeted with this segment will no longer be part of ''${
                      _vm.segment.name.value
                    }''`,
                                                    },
                                                  })
                                                : _vm._e(),
                                            ],
                                            1
                                          ),
                                        ]
                                      ),
                                      _vm._l(
                                        filter.segment.errors,
                                        function (error, errIndex) {
                                          return _c(
                                            "div",
                                            {
                                              key: errIndex,
                                              staticClass:
                                                "error__info-message",
                                            },
                                            [
                                              _c("span", [
                                                _vm._v(_vm._s(error)),
                                              ]),
                                            ]
                                          )
                                        }
                                      ),
                                    ],
                                    2
                                  )
                                : _vm._e(),
                            ]
                          )
                        }),
                        _c(
                          "div",
                          {
                            staticClass:
                              "container__content--segments container__content--segments-last",
                          },
                          [
                            _c("div", { staticClass: "segment__arrow" }),
                            _c(
                              "div",
                              {
                                staticClass: "segment__button--wrapper",
                                attrs: {
                                  id: `segment__button--or-${blockIndex}`,
                                },
                              },
                              [
                                _c(
                                  "b-dropdown",
                                  {
                                    staticClass: "segment__button--reversed",
                                    attrs: {
                                      id: `add-or-dropdown`,
                                      disabled: !_vm.isComplete,
                                      split: "",
                                      size: "sm",
                                      dropright: "",
                                    },
                                    on: {
                                      click: function ($event) {
                                        return _vm.addFilter("or", blockIndex)
                                      },
                                    },
                                    scopedSlots: _vm._u(
                                      [
                                        {
                                          key: "button-content",
                                          fn: function () {
                                            return [
                                              _c(
                                                "span",
                                                { staticClass: "align-middle" },
                                                [
                                                  _vm._v(
                                                    _vm._s(
                                                      _vm.$t("audience.or")
                                                    )
                                                  ),
                                                ]
                                              ),
                                              _c("div", {
                                                staticClass:
                                                  "segment__dropdown-divider",
                                              }),
                                            ]
                                          },
                                          proxy: true,
                                        },
                                      ],
                                      null,
                                      true
                                    ),
                                  },
                                  [
                                    _c(
                                      "b-dropdown-item",
                                      {
                                        attrs: { id: "add-or-filter" },
                                        on: {
                                          click: function ($event) {
                                            return _vm.addFilter(
                                              "or",
                                              blockIndex
                                            )
                                          },
                                        },
                                      },
                                      [
                                        _c("GjIcon", {
                                          staticClass: "mr-50",
                                          attrs: { name: "Plus", size: "16" },
                                        }),
                                        _c(
                                          "span",
                                          { staticClass: "align-middle" },
                                          [_vm._v("Filter")]
                                        ),
                                      ],
                                      1
                                    ),
                                    _c(
                                      "b-dropdown-item",
                                      {
                                        attrs: { id: "add-or-segment" },
                                        on: {
                                          click: function ($event) {
                                            return _vm.addSegment(
                                              "or",
                                              blockIndex
                                            )
                                          },
                                        },
                                      },
                                      [
                                        _c("GjIcon", {
                                          staticClass: "mr-50",
                                          attrs: { name: "Plus", size: "16" },
                                        }),
                                        _c(
                                          "span",
                                          { staticClass: "align-middle" },
                                          [_vm._v("Segment")]
                                        ),
                                      ],
                                      1
                                    ),
                                    !_vm.isComplete
                                      ? _c(
                                          "b-tooltip",
                                          {
                                            attrs: {
                                              target: `segment__button--or-${blockIndex}`,
                                              placement: "right",
                                            },
                                          },
                                          [
                                            _vm._v(
                                              _vm._s(
                                                _vm.$t(
                                                  "audience.operatorTooltipMessage"
                                                )
                                              )
                                            ),
                                          ]
                                        )
                                      : _vm._e(),
                                  ],
                                  1
                                ),
                              ],
                              1
                            ),
                          ]
                        ),
                        _c(
                          "div",
                          {
                            staticClass: "segment__button-and__container",
                            class: {
                              "segment__button-and__container--multiple":
                                _vm.segment.blocks.length > 1 &&
                                blockIndex !== _vm.segment.blocks.length - 1,
                            },
                          },
                          [
                            _c("div", {
                              staticClass: "segment__arrow",
                              class: {
                                "segment__arrow--multiple":
                                  _vm.segment.blocks.length > 1 &&
                                  blockIndex !== _vm.segment.blocks.length - 1,
                              },
                            }),
                            blockIndex === _vm.segment.blocks.length - 1
                              ? _c(
                                  "div",
                                  {
                                    staticClass: "segment__button--wrapper",
                                    attrs: {
                                      id: `segment__button--and-${blockIndex}`,
                                    },
                                  },
                                  [
                                    _c(
                                      "b-dropdown",
                                      _vm._g(
                                        {
                                          staticClass:
                                            "segment__button--reversed",
                                          attrs: {
                                            id: "add-and-dropdown",
                                            disabled: !_vm.isComplete,
                                            split: "",
                                            size: "sm",
                                            dropright: "",
                                          },
                                          scopedSlots: _vm._u(
                                            [
                                              {
                                                key: "button-content",
                                                fn: function () {
                                                  return [
                                                    _c(
                                                      "span",
                                                      {
                                                        staticClass:
                                                          "align-middle",
                                                      },
                                                      [
                                                        _vm._v(
                                                          _vm._s(
                                                            _vm.$t(
                                                              "audience.and"
                                                            )
                                                          )
                                                        ),
                                                      ]
                                                    ),
                                                    _c("div", {
                                                      staticClass:
                                                        "segment__dropdown-divider",
                                                    }),
                                                  ]
                                                },
                                                proxy: true,
                                              },
                                            ],
                                            null,
                                            true
                                          ),
                                        },
                                        blockIndex ===
                                          _vm.segment.blocks.length - 1
                                          ? {
                                              click: () =>
                                                _vm.addFilter(
                                                  "and",
                                                  blockIndex
                                                ),
                                            }
                                          : {}
                                      ),
                                      [
                                        _c(
                                          "b-dropdown-item",
                                          {
                                            attrs: { id: "add-and-filter" },
                                            on: {
                                              click: function ($event) {
                                                return _vm.addFilter(
                                                  "and",
                                                  blockIndex
                                                )
                                              },
                                            },
                                          },
                                          [
                                            _c("GjIcon", {
                                              staticClass: "mr-50",
                                              attrs: {
                                                name: "Plus",
                                                size: "16",
                                              },
                                            }),
                                            _c(
                                              "span",
                                              { staticClass: "align-middle" },
                                              [_vm._v("Filter")]
                                            ),
                                          ],
                                          1
                                        ),
                                        _c(
                                          "b-dropdown-item",
                                          {
                                            attrs: { id: "add-and-segment" },
                                            on: {
                                              click: function ($event) {
                                                return _vm.addSegment(
                                                  "and",
                                                  blockIndex
                                                )
                                              },
                                            },
                                          },
                                          [
                                            _c("GjIcon", {
                                              staticClass: "mr-50",
                                              attrs: {
                                                name: "Plus",
                                                size: "16",
                                              },
                                            }),
                                            _c(
                                              "span",
                                              { staticClass: "align-middle" },
                                              [_vm._v("Segment")]
                                            ),
                                          ],
                                          1
                                        ),
                                        !_vm.isComplete &&
                                        !(
                                          _vm.segment.blocks.length > 1 &&
                                          blockIndex !==
                                            _vm.segment.blocks.length - 1
                                        )
                                          ? _c(
                                              "b-tooltip",
                                              {
                                                attrs: {
                                                  target: `segment__button--and-${blockIndex}`,
                                                  placement: "right",
                                                  offset: "5",
                                                },
                                              },
                                              [
                                                _vm._v(
                                                  _vm._s(
                                                    _vm.$t(
                                                      "audience.operatorTooltipMessage"
                                                    )
                                                  )
                                                ),
                                              ]
                                            )
                                          : _vm._e(),
                                      ],
                                      1
                                    ),
                                  ],
                                  1
                                )
                              : _vm._e(),
                            _vm.segment.blocks.length > 1 &&
                            blockIndex !== _vm.segment.blocks.length - 1
                              ? _c(
                                  "div",
                                  [
                                    _c(
                                      "b-button",
                                      {
                                        staticClass:
                                          "segment__button--reversed segment__button--reversed--multiple",
                                      },
                                      [
                                        _c("span", [
                                          _vm._v(
                                            _vm._s(_vm.$t("audience.and"))
                                          ),
                                        ]),
                                      ]
                                    ),
                                  ],
                                  1
                                )
                              : _vm._e(),
                          ]
                        ),
                      ],
                      2
                    )
                  }),
                ],
                2
              ),
              _c(
                "div",
                { staticClass: "container__wrapper" },
                [
                  _c("AudienceSizeCalculator", {
                    attrs: {
                      data: {
                        segment: _vm.segment,
                        query: _vm.createRequestObject(_vm.segment),
                        isValid: _vm.isComplete,
                        type: "segment",
                      },
                    },
                  }),
                ],
                1
              ),
              _vm.segment.activations.length > 0
                ? _c("div", { staticClass: "container__wrapper" }, [
                    _c("div", { staticClass: "container__header" }, [
                      _c("h5", { staticClass: "container__header-title" }, [
                        _vm._v(_vm._s(_vm.$t("Integrations"))),
                      ]),
                    ]),
                    _c(
                      "div",
                      { staticClass: "container__integrations" },
                      _vm._l(_vm.segment.activations, function (activation) {
                        return _c("IntegrationCard", {
                          key: activation.id,
                          attrs: { activation: activation },
                          on: {
                            "state-change": function ($event) {
                              return _vm.handleFormInput(_vm.formState)
                            },
                          },
                        })
                      }),
                      1
                    ),
                  ])
                : _vm._e(),
              _c("Footer", {
                attrs: {
                  "is-loading": _vm.formState.isLoading,
                  submitted: _vm.formState.isLoading,
                  "cancel-id": `cancel-create-segment`,
                  "create-id": `add-segment`,
                  "edit-mode": !!_vm.segmentId,
                },
                on: {
                  back: function ($event) {
                    return _vm.cancel()
                  },
                  create: function ($event) {
                    _vm.segmentId ? _vm.openSaveModal() : _vm.triggerSave()
                  },
                },
              }),
            ],
            1
          )
        : _vm._e(),
      _c("SaveChangesModal", {
        attrs: { type: _vm.$t("Segment") },
        on: {
          save: function ($event) {
            return _vm.triggerSave(_vm.segment.id)
          },
        },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }