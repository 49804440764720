/* eslint-disable */
import { segments } from '@/api'
import {
  appendErrors,
  removeErrors,
  scrollToElement,
  unFlattenObject,
} from '@nodus/utilities-front'
import {
  columnTypes,
  filterTypes,
  parametersBooleanValuesConst,
} from './segmentConfig'
/* eslint import/prefer-default-export: "off" */
/* eslint-disable no-return-await */
const getParamTags = (param) => {
  const parameterTagsObject = []
  if (param.isBooleanParam) {
    parameterTagsObject.push({
      key: '',
      value: param.parametersBooleanModel?.value,
    })
  }

  if (param.isDateParam) {
    let date = ''
    if (param.parameterOptionsModel.columnType === columnTypes.datetime) {
      date = param.parameterSecondDate
        ? `${param.parameterFirstDate.replace(
            / /g,
            't'
          )}_${param.parameterSecondDate.replace(/ /g, 't')}`
        : `${param.parameterFirstDate.replace(/ /g, 't')}`
    } else {
      date = param.parameterSecondDate
        ? `${param.parameterFirstDate}_${param.parameterSecondDate}`
        : `${param.parameterFirstDate}`
    }

    parameterTagsObject.push({
      key: '',
      value: date,
    })
  }

  if (param.paramTagsModel && !param.isBooleanParam && !param.isDateParam) {
    param.paramTagsModel.forEach((tag) => {
      parameterTagsObject.push({
        key: param.parametersStringKeyModel,
        value: tag,
      })
    })
  }

  return parameterTagsObject
}

const getParameterTagsModel = (param) => {
  const tags = []
  if (param && param.parameterValues) {
    param.parameterValues.forEach((val) => {
      tags.push(val.value)
    })
    return tags
  }
}

const getDurationValue = (filter) => {
  let durationVal = ''
  if (filter.durationFirstDate) {
    durationVal = filter.durationFirstDate.toString()
    if (filter.durationSecondDate) {
      durationVal = `${filter.durationFirstDate}_${filter.durationSecondDate}`
    }
  } else if (filter.durationNumberParam) {
    durationVal = filter.durationNumberFormatParam.toString()
    if (filter.durationNumberFormatParam) {
      durationVal = `${filter.durationNumberParam}_${filter.durationNumberFormatParam}`
    }
  }
  return durationVal
}

const getParameterBooleanValue = (param) => {
  let booleanValue = {}
  if (param && param.parameterValues && param.columnType === 14) {
    param.parameterValues.forEach((result) => {
      booleanValue = parametersBooleanValuesConst.find(
        (b) => b.value === result.value
      )
    })
    return booleanValue
  }
}

const createRequestObject = (segment) => {
  const requestObject = []
  const blocksObject = []
  segment.blocks.forEach((block) => {
    const filtersObject = []
    block.filters.forEach((filter) => {
      if (filter.filterType === filterTypes.segment) {
        filtersObject.push({
          type: filter.filterType,
          segmentId: filter.segment.value?.id,
        })
      } else {
        const paramsObject = []
        filter.parameters.forEach((param) => {
          paramsObject.push({
            columnId: param.parameterOptionsModel.columnId,
            operator: param.selectedParameterOptionModel?.value,
            parameterValues: getParamTags(param),
          })
        })
        filtersObject.push({
          type: filter.filterType,
          filterOrganizationCode: filter.filterOrganization.value.code,
          tableId: filter.segmentationTableModel.id,
          isPerformed: filter.eventStateDefault?.value,
          frequencyOperator: filter.isFrequencyParamSet
            ? filter.frequencyOperator.value?.value
            : null,
          frequencyFirstValue: filter.frequencyFirstValue.value,
          frequencySecondValue: filter.frequencySecondValue.value,
          durationValue: getDurationValue(filter),
          durationOperator: filter.isTimeParamSet
            ? filter.durationOperator.value?.value
            : null,
          parameterLogicalOperator: filter.isParametersParamSet
            ? filter.withParametersDefault?.value
            : null,
          ...(filter.isParametersParamSet && {
            parameters: paramsObject,
          }),
          ...(filter.isTopicsParamSet && {
            topics: filter.topics.value.length
              ? filter.topics.value.map((topic) => topic.id)
              : null,
          }),
        })
      }
    })
    blocksObject.push({
      filters: filtersObject,
    })
  })
  requestObject.push({
    name: segment.name.value,
    description: segment.description.value,
    propertyIds: segment.audienceProperties.map((p2) => p2.id),
    blocks: blocksObject,
    activationIds: segment.activations
      .filter((a) => a.enabled)
      .map((ac) => ac.id),
  })

  return requestObject
}

const formatDurationParams = (filter) => {
  const getDurationNumberParam = filter.durationValue.split('_')
  return {
    durationNumberParam: getDurationNumberParam[0],
    durationNumberFormatParam: getDurationNumberParam[1],
    durationFirstDate: '',
    durationSecondDate: '',
  }
}

const formatDurationDates = (filter) => {
  const getDurationDateParam = filter.durationValue.split('_')
  return {
    durationFirstDate: getDurationDateParam[0],
    durationSecondDate: getDurationDateParam[1],
    minDate: getDurationDateParam[0],
  }
}

const getSegmentDurationValues = (filter) => {
  const filters = {
    1: { durationFirstDate: filter.durationValue, durationSecondDate: '' },
    2: { durationFirstDate: filter.durationValue, durationSecondDate: '' },
    3: { durationFirstDate: filter.durationValue, durationSecondDate: '' },
    4: formatDurationParams(filter),
    5: formatDurationDates(filter),
    6: formatDurationParams(filter),
  }
  return filters[filter.durationOperator]
}

const formatParameterDuration = (p) => {
  let date = ''
  if (
    p &&
    p.parameterValues &&
    (p.operator === columnTypes.datetime || p.operator === 13)
  ) {
    p.parameterValues.forEach((result) => {
      date = result.value
    })
    return {
      parameterFirstDate:
        p.columnType !== columnTypes.datetime ? date : date.replace('t', ' '),
      parameterSecondDate: '',
    }
  }
}

const formatParameterDurations = (p) => {
  let date = ''
  if (p && p.parameterValues && p.operator === 14) {
    p.parameterValues.forEach((result) => {
      date = result.value.split('_')
    })

    return {
      parameterFirstDate:
        p.columnType !== columnTypes.datetime
          ? date[0].split('t')[0]
          : date[0].replace('t', ' '),
      parameterSecondDate:
        p.columnType !== columnTypes.datetime
          ? date[1].split('t')[0]
          : date[1].replace('t', ' '),
    }
  }
}

const getSegmentParameterDurationValues = (param) => {
  const parameters = {
    12: formatParameterDuration(param),
    13: formatParameterDuration(param),
    14: formatParameterDurations(param),
  }
  return parameters[param.operator]
}

const maximumDate = (date) => {
  const currentDate = new Date()
  return date > currentDate
}

const secondDateHandler = (date, paramFirstDate) => {
  const firstDate = new Date(paramFirstDate)
  const currentDate = new Date()
  return date < firstDate || date > currentDate
}

const isDateTimeType = (columnType) => {
  return columnType === columnTypes.datetime
}

const saveSegment = async (organizationId, segment, id, context, formState) => {
  const requestObject = createRequestObject(segment)

  let response = null
  if (id) {
    response = await segments.updateSegment(
      organizationId,
      id,
      ...requestObject
    )
  } else {
    response = await segments.createSegment(organizationId, ...requestObject)
  }

  const {
    data: { errors, success },
  } = response
  if (!success) {
    formState.isLoading = false
    formState.isSubmitted = false
    const errorsObject = unFlattenObject(errors)
    removeErrors(segment)
    appendErrors(segment, errorsObject)

    setTimeout(() => scrollToElement('[class*="form__input--invalid"]'), 200)
  } else {
    context.$router.push({ name: 'segments' })
  }
}

const integerInputHandler = (filter, modeledProperty) => {
  const regex = /^[1-9]\d*$/
  let number = filter[modeledProperty]

  if (!regex.test(number) || number > 2147483647) {
    if (number > 2147483647) number = 2147483647
    // eslint-disable-next-line no-param-reassign
    filter[modeledProperty] = (number > 0 ? Math.round(number) : 1).toString()
  }
}

const formatDurationValues = (value, operator) => {
  const durationValue = getSegmentDurationValues({
    durationValue: value,
    durationOperator: operator,
  })
  let formattedDate

  const formatDateString = (date) => {
    const time = date.split('t')[1]
    const dateObj = new Date(Date.parse(date))
    const strDate = dateObj.toDateString().split(' ')
    return `${strDate[0]}, ${strDate[1]} ${strDate[2]}, ${strDate[3]} ${
      time || ''
    }`
  }
  if (durationValue.durationFirstDate) {
    formattedDate = formatDateString(durationValue.durationFirstDate)
    if (durationValue.durationSecondDate) {
      formattedDate += `_${formatDateString(durationValue.durationSecondDate)}`
    }
  } else {
    formattedDate = `${durationValue.durationNumberParam} ${durationValue.durationNumberFormatParam}`
  }

  return formattedDate
}

export {
  createRequestObject,
  formatDurationValues,
  getParamTags,
  getParameterBooleanValue,
  getParameterTagsModel,
  getSegmentDurationValues,
  getSegmentParameterDurationValues,
  integerInputHandler,
  isDateTimeType,
  maximumDate,
  saveSegment,
  secondDateHandler,
}
