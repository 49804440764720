<script>
import { integrations } from '@/api'
import store from '@/store'
import { ReadMore, formatDate } from '@nodus/utilities-front'
import { BFormCheckbox } from 'bootstrap-vue'
import { getCurrentInstance, ref } from 'vue'

export default {
  name: 'IntegrationsRow',
  components: {
    BFormCheckbox,
    ReadMore
  },
  props: {
    data: {
      type: [Array, Object]
    }
  },
  setup() {
    const vm = getCurrentInstance().proxy
    const { organizationId } = vm.$route.params
    const isLoading = ref(false)

    const setStatus = async (id, status) => {
      isLoading.value = true
      await integrations.setStatus(organizationId, id, { status })
      store
        .dispatch('integrations/getTable', { id: organizationId })
        .then(() => {
          isLoading.value = false
        })
    }

    return {
      formatDate,
      setStatus,
      organizationId,
      isLoading
    }
  }
}
</script>
<template>
  <div>
    <!-- Column: Create and Update Date -->
    <div v-if="data.column.field === 'created'" class="block-elem">
      <span v-if="data.row.created"> {{ formatDate(data.row.created) }}</span>
    </div>

    <!-- Column: Name -->
    <span
      v-else-if="data.column.field === 'name'"
      class="integration__table-img-container"
      :class="{ 'dark-gray-color': data.row.canEdit }"
    >
      <div class="integration__table-img-content">
        <img :src="data.row.imageUrl" />
      </div>
      <ReadMore
        :text="data.row.name"
        :to="
          data.row.canEdit
            ? {
                name: 'integration-view',
                params: {
                  organizationId,
                  activationId: data.row.id
                }
              }
            : null
        "
      />
    </span>

    <!-- Column: status -->
    <span v-else-if="data.column.field === 'status'" class="status-and-actions">
      <div>
        <b-form-checkbox
          :id="`switch-status-${data.row.id}`"
          :disabled="!data.row.canEdit || isLoading"
          :checked="data.row.status ? true : false"
          class="custom-control-primary"
          name="check-button"
          switch
          @change="
            data.row.canEdit
              ? setStatus(data.row.id, data.row.status ? 0 : 1)
              : {}
          "
        />
      </div>
    </span>

    <!-- Column: Common -->
    <span v-else>
      {{ data.formattedRow[data.column.field] }}
    </span>
  </div>
</template>

<style scoped></style>
