var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.data.column.field === "include"
    ? _c(
        "span",
        [
          !_vm.isViewTopic
            ? _c("b-form-radio", {
                attrs: {
                  id: `include-${_vm.data.row.keyword}`,
                  value: true,
                  checked: _vm.data.row.included,
                },
                on: {
                  change: function ($event) {
                    return _vm.includeChanged(_vm.data.row.originalIndex, true)
                  },
                },
              })
            : _c("span", [
                _vm.data.row.included
                  ? _c("img", {
                      attrs: {
                        src: require("@/assets/svgs/checkIcon.svg"),
                        alt: "check",
                      },
                    })
                  : _vm._e(),
              ]),
        ],
        1
      )
    : _vm.data.column.field === "exclude"
    ? _c("span", [
        !_vm.isViewTopic
          ? _c(
              "div",
              { staticClass: "exclude__column" },
              [
                _c("b-form-radio", {
                  attrs: {
                    id: `exclude-${_vm.data.row.keyword}`,
                    value: false,
                    checked: _vm.data.row.included,
                  },
                  on: {
                    change: function ($event) {
                      return _vm.includeChanged(
                        _vm.data.row.originalIndex,
                        false
                      )
                    },
                  },
                }),
                _c(
                  "div",
                  {
                    staticClass: "remove-keyword",
                    on: {
                      click: function ($event) {
                        return _vm.removeRow(_vm.data.row)
                      },
                    },
                  },
                  [
                    _c("GjIcon", {
                      staticClass: "mr-50",
                      attrs: { name: "Close", size: "22" },
                    }),
                  ],
                  1
                ),
              ],
              1
            )
          : _c("span", [
              !_vm.data.row.included
                ? _c("img", {
                    attrs: {
                      src: require("@/assets/svgs/checkIcon.svg"),
                      alt: "check",
                    },
                  })
                : _vm._e(),
            ]),
      ])
    : _vm.isNumberColumn(_vm.data.column.field)
    ? _c("span", [
        _vm._v(
          " " +
            _vm._s(
              _vm.formatNumber(_vm.data.formattedRow[_vm.data.column.field])
            ) +
            " "
        ),
      ])
    : _vm.data.column.field === "keyword"
    ? _c("span", { attrs: { id: _vm.data.row.keyword } }, [
        _vm._v(" " + _vm._s(_vm.data.row.keyword) + " "),
      ])
    : _c("span", [
        _vm._v(
          " " + _vm._s(_vm.data.formattedRow[_vm.data.column.field]) + " "
        ),
      ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }