var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return !_vm.isLoading
    ? _c(
        "section",
        { staticClass: "create-topic__container" },
        [
          _c("div", [
            _c(
              "div",
              { staticClass: "bacic-information__container" },
              [
                _c("div", { staticClass: "container__header" }, [
                  _c("h5", { staticClass: "container__header-title" }, [
                    _vm._v(_vm._s(_vm.$t("data.basicInfo"))),
                  ]),
                ]),
                _c(
                  "b-col",
                  {
                    staticClass: "input__wrapper topic-name__input",
                    attrs: { id: "name", lg: "6" },
                  },
                  [
                    _c("label", { staticClass: "mr-1" }, [
                      _vm._v(_vm._s(_vm.$t("Name")) + " *"),
                    ]),
                    _c("b-form-input", {
                      staticClass: "form__input",
                      class: {
                        "form__input--invalid":
                          _vm.topicObject.name.errors.length > 0,
                      },
                      attrs: {
                        id: "topic-name",
                        placeholder: _vm.$t("audience.topics.enterTopicName"),
                        type: "text",
                      },
                      on: {
                        input: function ($event) {
                          return _vm.handleFormInput(_vm.state, [
                            _vm.topicObject.name,
                          ])
                        },
                      },
                      model: {
                        value: _vm.topicObject.name.value,
                        callback: function ($$v) {
                          _vm.$set(_vm.topicObject.name, "value", $$v)
                        },
                        expression: "topicObject.name.value",
                      },
                    }),
                    _vm.topicObject && _vm.topicObject.name.errors.length > 0
                      ? _c(
                          "div",
                          _vm._l(
                            _vm.topicObject.name.errors,
                            function (error, index) {
                              return _c(
                                "span",
                                {
                                  key: index,
                                  staticClass: "error__info-message",
                                },
                                [_vm._v(" " + _vm._s(error) + " ")]
                              )
                            }
                          ),
                          0
                        )
                      : _vm._e(),
                  ],
                  1
                ),
                _c(
                  "b-col",
                  {
                    staticClass: "input__wrapper topic-name__input",
                    attrs: { id: "description", lg: "6" },
                  },
                  [
                    _c("label", { staticClass: "mr-1" }, [
                      _vm._v(_vm._s(_vm.$t("Description"))),
                    ]),
                    _c("b-form-textarea", {
                      staticClass: "form__input form__input-textarea",
                      class: {
                        "form__input--invalid":
                          _vm.topicObject.description.errors.length > 0,
                      },
                      attrs: {
                        id: "topic-description",
                        placeholder: _vm.$t(
                          "audience.topics.enterTopicDescription"
                        ),
                        rows: "3",
                      },
                      on: {
                        input: function ($event) {
                          return _vm.handleFormInput(_vm.state, [
                            _vm.topicObject.description,
                          ])
                        },
                      },
                      model: {
                        value: _vm.topicObject.description.value,
                        callback: function ($$v) {
                          _vm.$set(_vm.topicObject.description, "value", $$v)
                        },
                        expression: "topicObject.description.value",
                      },
                    }),
                    _vm.topicObject &&
                    _vm.topicObject.description.errors.length > 0
                      ? _c(
                          "div",
                          _vm._l(
                            _vm.topicObject.description.errors,
                            function (error, index) {
                              return _c(
                                "span",
                                {
                                  key: index,
                                  staticClass: "error__info-message",
                                },
                                [_vm._v(" " + _vm._s(error) + " ")]
                              )
                            }
                          ),
                          0
                        )
                      : _vm._e(),
                  ],
                  1
                ),
                _c(
                  "b-col",
                  {
                    staticClass: "input__wrapper topic-name__input",
                    attrs: { id: "match-type", lg: "6" },
                  },
                  [
                    _c("div", { staticClass: "keyword__match-type" }, [
                      _c("label", { staticClass: "total-analytics__title" }, [
                        _vm._v(
                          _vm._s(_vm.$t("audience.topics.keywordMatchType"))
                        ),
                      ]),
                      _c(
                        "div",
                        { attrs: { id: "matching-type-tooltip" } },
                        [
                          _c("GjIcon", { attrs: { name: "Info", size: "21" } }),
                          _c(
                            "b-tooltip",
                            {
                              attrs: {
                                target: "matching-type-tooltip",
                                triggers: "hover",
                                placement: "top",
                              },
                            },
                            [
                              _vm.topicObject.keywordMatchType === "Exact"
                                ? _c("span", [
                                    _vm._v(
                                      _vm._s(
                                        _vm.$t(
                                          "audience.topics.exactTypeMatchingInfo"
                                        )
                                      )
                                    ),
                                  ])
                                : _vm._e(),
                              _vm.topicObject.keywordMatchType === "Phrase"
                                ? _c("span", [
                                    _vm._v(
                                      _vm._s(
                                        _vm.$t(
                                          "audience.topics.phraseTypeMatchingInfo"
                                        )
                                      )
                                    ),
                                  ])
                                : _vm._e(),
                              _vm.topicObject.keywordMatchType === "Broad"
                                ? _c("span", [
                                    _vm._v(
                                      _vm._s(
                                        _vm.$t(
                                          "audience.topics.broadTypeMatchingInfo"
                                        )
                                      )
                                    ),
                                  ])
                                : _vm._e(),
                            ]
                          ),
                        ],
                        1
                      ),
                    ]),
                    _c(
                      "div",
                      { staticClass: "input__wrapper" },
                      [
                        _c("v-select", {
                          staticClass: "form__input",
                          attrs: {
                            id: "keyword-match-type",
                            clearable: false,
                            options: _vm.keywordMatchingTypes,
                            disabled: false,
                            label: "text",
                          },
                          on: {
                            input: function ($event) {
                              _vm.onMatchTypeChange(),
                                _vm.handleFormInput(_vm.state)
                            },
                          },
                          model: {
                            value: _vm.topicObject.keywordMatchType,
                            callback: function ($$v) {
                              _vm.$set(_vm.topicObject, "keywordMatchType", $$v)
                            },
                            expression: "topicObject.keywordMatchType",
                          },
                        }),
                      ],
                      1
                    ),
                  ]
                ),
              ],
              1
            ),
            _c("div", { staticClass: "add-keywords__container" }, [
              _c("div", { staticClass: "add-keywords__header" }, [
                _c("div", [
                  _c("h5", { staticClass: "container__header-title" }, [
                    _vm._v(
                      " " + _vm._s(_vm.$t("audience.topics.addKeywords")) + " "
                    ),
                  ]),
                  _c(
                    "span",
                    { staticClass: "add-keywords__information" },
                    [
                      _c("GjIcon", { attrs: { name: "Info", size: "18" } }),
                      _c("span", [
                        _vm._v(
                          _vm._s(
                            _vm.$t("audience.topics.addKeywordsInformation")
                          ) + "."
                        ),
                      ]),
                    ],
                    1
                  ),
                  _c(
                    "span",
                    { staticClass: "documentation__info-text topic-add__info" },
                    [
                      _vm._v(_vm._s(_vm.$t("forMoreInformation")) + " "),
                      _c(
                        "a",
                        {
                          attrs: {
                            href: `${_vm.whitelabel.documentationUrl}/intro#introduction-to-contextual-targeting`,
                            target: "_blank",
                          },
                        },
                        [_vm._v(_vm._s(_vm.$t("Documentation").toLowerCase()))]
                      ),
                      _vm._v("."),
                    ]
                  ),
                ]),
              ]),
              _c(
                "div",
                { staticClass: "suggested-and-manual__container" },
                [
                  _c("Keywords", {
                    attrs: {
                      "keywords-error": _vm.topicObject.keywords.errors,
                      "keywords-table": _vm.keywordsTable,
                      "table-loading": _vm.tableLoading,
                    },
                    on: {
                      "add-manual-keyword": _vm.addKeyword,
                      "add-suggested-keyword": _vm.addKeyword,
                      "add-file-keyword": _vm.addFileKeyword,
                      "handle-form-input": function ($event) {
                        return _vm.handleFormInput(_vm.state, [
                          _vm.topicObject.keywords,
                        ])
                      },
                    },
                  }),
                  _c("KeywordsSuggested", {
                    attrs: {
                      "properties-list": _vm.propertiesList,
                      "suggested-keyword-list": _vm.suggestedKeywordList,
                      "is-suggested-loading": _vm.isSuggestedLoading,
                      "keywords-list": _vm.keywordsTable.rows,
                    },
                    on: {
                      "add-suggested-keyword": _vm.addSuggestedKeyword,
                      "add-all-suggested": _vm.addAllSuggested,
                      "handle-form-input": function ($event) {
                        return _vm.handleFormInput(_vm.state, [
                          _vm.topicObject.keywords,
                        ])
                      },
                      "update-data": _vm.updatePropertiesApi,
                    },
                  }),
                ],
                1
              ),
            ]),
            _vm.topicObject.activations && _vm.topicObject.activations.length
              ? _c("div", { staticClass: "container__wrapper" }, [
                  _c("div", { staticClass: "container__header" }, [
                    _c("h5", { staticClass: "container__header-title" }, [
                      _vm._v(_vm._s(_vm.$t("Integrations"))),
                    ]),
                  ]),
                  _c(
                    "div",
                    { staticClass: "container__integrations" },
                    _vm._l(_vm.topicObject.activations, function (activation) {
                      return _c("IntegrationCard", {
                        key: activation.id,
                        attrs: { activation: activation },
                        on: {
                          "state-change": function ($event) {
                            return _vm.handleFormInput(_vm.state)
                          },
                        },
                      })
                    }),
                    1
                  ),
                ])
              : _vm._e(),
          ]),
          _c("Footer", {
            attrs: {
              "is-loading": _vm.isLoading,
              submitted: _vm.state.submitted,
              "cancel-id": `cancel-create-topic`,
              "create-id": `add-topic`,
              "edit-mode": !!_vm.topicId,
            },
            on: {
              back: function ($event) {
                return _vm.cancel()
              },
              create: function ($event) {
                _vm.topicId ? _vm.openSaveModal() : _vm.createTopic()
              },
            },
          }),
          _c("DeleteModal", {
            attrs: {
              id: "modal-small-keywords",
              title: _vm.$t("audience.topics.deleteAllKeywordsTitle"),
              "custom-content": _vm.$t("audience.topics.deleteAllKeywords"),
            },
            on: {
              delete: function ($event) {
                _vm.deleteAllKeywords(), _vm.handleFormInput(_vm.state)
              },
            },
          }),
          _c("SaveChangesModal", {
            attrs: { type: _vm.$t("Topic") },
            on: {
              save: function ($event) {
                return _vm.createTopic()
              },
            },
          }),
        ],
        1
      )
    : _c("div", { staticClass: "loading__container" }, [_c("LoadingBar")], 1)
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }