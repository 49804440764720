<template>
  <div id="app" class="bisko-app__container">
    <div class="content__container">
      <app-bread-crumb module="audience" />
      <transition name="zoom-fade" mode="out-in">
        <router-view />
      </transition>
    </div>
  </div>
</template>

<script>
import { AppBreadCrumb } from '@nodus/utilities-front'

export default {
  components: {
    AppBreadCrumb
  }
}
</script>

<style lang="scss">
.bisko-app__audience {
  opacity: 1;
}
</style>
