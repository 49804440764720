<template>
  <section class="segmentation-list">
    <div class="table-list__header">
      <primary-create-button
        id="create-integration"
        :name="$t('audience.addIntegration')"
        :can-add="canCreate"
        :is-loading="isLoading && !selectOptions.length"
        :click-handler="() => $bvModal.show('modal-lg-create-integration')"
      />
      <SearchInputField id="search-integrations" :data="table" />
    </div>
    <div class="table__wrapper">
      <CustomTable
        :data="table"
        type-of-table="integrations"
        :is-pagination-enabled="true"
        :is-loading="isLoading"
        :empty-state-action="{
          canAdd: canCreate,
          action: () => $bvModal.show('modal-lg-create-integration')
        }"
        @update-table="getData"
      >
        <template #row-data="{ data }">
          <IntegrationsRow :data="data" />
        </template>
      </CustomTable>
    </div>

    <!-- Create New Audience Modal -->
    <CustomModal
      :select-options="selectOptions"
      :modal-title="$t('audience.newIntegration')"
      :modal-text="$t('audience.chooseIntegration')"
      type="integration"
      :documentation-link="`${whitelabel.documentationUrl}/intro#introduction-to-integrations`"
      :documentation-text="$t('audience.integrationDocumentationLink')"
      @on-create="triggerCreateNewIntegrationModal"
    />
  </section>
</template>

<script>
import IntegrationsRow from '@/components/table/table-data/IntegrationsRow.vue'
import {
  CustomModal,
  CustomTable,
  PrimaryCreateButton,
  SearchInputField
} from '@nodus/utilities-front'
import { computed, getCurrentInstance, onMounted, ref } from 'vue'

import router from '@/router'
import store from '@/store'
import { whitelabel } from '@/utils/constants'

export default {
  components: {
    CustomTable,
    SearchInputField,
    PrimaryCreateButton,
    CustomModal,
    IntegrationsRow
  },
  setup() {
    const vm = getCurrentInstance().proxy
    const { organizationId } = vm.$route.params

    const table = computed(() => store.getters['integrations/table'])
    const canCreate = computed(() => store.getters['integrations/canCreate'])
    const isLoading = ref(false)

    onMounted(async () => {
      isLoading.value = true
      store.dispatch('integrations/getTabs', { id: organizationId })
      await store.dispatch('integrations/getTable', { id: organizationId })
      isLoading.value = false
    })

    const getData = async () => {
      isLoading.value = true
      await store.dispatch('integrations/getTable', { id: organizationId })
      isLoading.value = false
    }

    const selectOptions = computed(() => store.getters['integrations/tabs'])

    const triggerCreateNewIntegrationModal = (selected) =>
      router.push({
        name: 'integration-create',
        params: { organizationId, integrationId: selected }
      })

    return {
      organizationId,
      table,
      canCreate,
      triggerCreateNewIntegrationModal,
      selectOptions,
      isLoading,
      getData,
      whitelabel
    }
  }
}
</script>

<style lang="scss">
@import '@/assets/scss/views/integrations';
</style>
