var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "section",
    { staticClass: "segmentation-list" },
    [
      _c(
        "div",
        { staticClass: "table-list__header" },
        [
          _c("primary-create-button", {
            attrs: {
              id: "create-integration",
              name: _vm.$t("audience.addIntegration"),
              "can-add": _vm.canCreate,
              "is-loading": _vm.isLoading && !_vm.selectOptions.length,
              "click-handler": () =>
                _vm.$bvModal.show("modal-lg-create-integration"),
            },
          }),
          _c("SearchInputField", {
            attrs: { id: "search-integrations", data: _vm.table },
          }),
        ],
        1
      ),
      _c(
        "div",
        { staticClass: "table__wrapper" },
        [
          _c("CustomTable", {
            attrs: {
              data: _vm.table,
              "type-of-table": "integrations",
              "is-pagination-enabled": true,
              "is-loading": _vm.isLoading,
              "empty-state-action": {
                canAdd: _vm.canCreate,
                action: () => _vm.$bvModal.show("modal-lg-create-integration"),
              },
            },
            on: { "update-table": _vm.getData },
            scopedSlots: _vm._u([
              {
                key: "row-data",
                fn: function ({ data }) {
                  return [_c("IntegrationsRow", { attrs: { data: data } })]
                },
              },
            ]),
          }),
        ],
        1
      ),
      _c("CustomModal", {
        attrs: {
          "select-options": _vm.selectOptions,
          "modal-title": _vm.$t("audience.newIntegration"),
          "modal-text": _vm.$t("audience.chooseIntegration"),
          type: "integration",
          "documentation-link": `${_vm.whitelabel.documentationUrl}/intro#introduction-to-integrations`,
          "documentation-text": _vm.$t("audience.integrationDocumentationLink"),
        },
        on: { "on-create": _vm.triggerCreateNewIntegrationModal },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }