<template>
  <section v-if="!isLoading" class="create-topic__container">
    <div>
      <div class="bacic-information__container">
        <div class="container__header">
          <h5 class="container__header-title">{{ $t('data.basicInfo') }}</h5>
        </div>
        <b-col id="name" lg="6" class="input__wrapper topic-name__input">
          <label class="mr-1">{{ $t('Name') }} *</label>
          <b-form-input
            id="topic-name"
            v-model="topicObject.name.value"
            :placeholder="$t('audience.topics.enterTopicName')"
            type="text"
            class="form__input"
            :class="{
              'form__input--invalid': topicObject.name.errors.length > 0
            }"
            @input="handleFormInput(state, [topicObject.name])"
          />
          <div v-if="topicObject && topicObject.name.errors.length > 0">
            <span
              v-for="(error, index) in topicObject.name.errors"
              :key="index"
              class="error__info-message"
            >
              {{ error }}
            </span>
          </div>
        </b-col>
        <b-col id="description" lg="6" class="input__wrapper topic-name__input">
          <label class="mr-1">{{ $t('Description') }}</label>
          <b-form-textarea
            id="topic-description"
            v-model="topicObject.description.value"
            :placeholder="$t('audience.topics.enterTopicDescription')"
            rows="3"
            class="form__input form__input-textarea"
            :class="{
              'form__input--invalid': topicObject.description.errors.length > 0
            }"
            @input="handleFormInput(state, [topicObject.description])"
          />
          <div v-if="topicObject && topicObject.description.errors.length > 0">
            <span
              v-for="(error, index) in topicObject.description.errors"
              :key="index"
              class="error__info-message"
            >
              {{ error }}
            </span>
          </div>
        </b-col>

        <b-col id="match-type" lg="6" class="input__wrapper topic-name__input">
          <div class="keyword__match-type">
            <label class="total-analytics__title">{{
              $t('audience.topics.keywordMatchType')
            }}</label>
            <div id="matching-type-tooltip">
              <GjIcon name="Info" size="21" />
              <b-tooltip
                target="matching-type-tooltip"
                triggers="hover"
                placement="top"
              >
                <span v-if="topicObject.keywordMatchType === 'Exact'">{{
                  $t('audience.topics.exactTypeMatchingInfo')
                }}</span>
                <span v-if="topicObject.keywordMatchType === 'Phrase'">{{
                  $t('audience.topics.phraseTypeMatchingInfo')
                }}</span>
                <span v-if="topicObject.keywordMatchType === 'Broad'">{{
                  $t('audience.topics.broadTypeMatchingInfo')
                }}</span>
              </b-tooltip>
            </div>
          </div>
          <div class="input__wrapper">
            <v-select
              id="keyword-match-type"
              v-model="topicObject.keywordMatchType"
              :clearable="false"
              :options="keywordMatchingTypes"
              :disabled="false"
              label="text"
              class="form__input"
              @input="onMatchTypeChange(), handleFormInput(state)"
            ></v-select>
          </div>
        </b-col>
      </div>
      <div class="add-keywords__container">
        <div class="add-keywords__header">
          <div>
            <h5 class="container__header-title">
              {{ $t('audience.topics.addKeywords') }}
            </h5>
            <span class="add-keywords__information">
              <GjIcon name="Info" size="18" />
              <span>{{ $t('audience.topics.addKeywordsInformation') }}.</span>
            </span>
            <span class="documentation__info-text topic-add__info"
              >{{ $t('forMoreInformation') }}
              <a
                :href="`${whitelabel.documentationUrl}/intro#introduction-to-contextual-targeting`"
                target="_blank"
                >{{ $t('Documentation').toLowerCase() }}</a
              >.</span
            >
          </div>
        </div>
        <div class="suggested-and-manual__container">
          <Keywords
            :keywords-error="topicObject.keywords.errors"
            :keywords-table="keywordsTable"
            :table-loading="tableLoading"
            @add-manual-keyword="addKeyword"
            @add-suggested-keyword="addKeyword"
            @add-file-keyword="addFileKeyword"
            @handle-form-input="handleFormInput(state, [topicObject.keywords])"
          />

          <KeywordsSuggested
            :properties-list="propertiesList"
            :suggested-keyword-list="suggestedKeywordList"
            :is-suggested-loading="isSuggestedLoading"
            :keywords-list="keywordsTable.rows"
            @add-suggested-keyword="addSuggestedKeyword"
            @add-all-suggested="addAllSuggested"
            @handle-form-input="handleFormInput(state, [topicObject.keywords])"
            @update-data="updatePropertiesApi"
          />
        </div>
      </div>

      <div
        v-if="topicObject.activations && topicObject.activations.length"
        class="container__wrapper"
      >
        <div class="container__header">
          <h5 class="container__header-title">{{ $t('Integrations') }}</h5>
        </div>
        <div class="container__integrations">
          <IntegrationCard
            v-for="activation in topicObject.activations"
            :key="activation.id"
            :activation="activation"
            @state-change="handleFormInput(state)"
          />
        </div>
      </div>
    </div>

    <Footer
      :is-loading="isLoading"
      :submitted="state.submitted"
      :cancel-id="`cancel-create-topic`"
      :create-id="`add-topic`"
      :edit-mode="!!topicId"
      @back="cancel()"
      @create="topicId ? openSaveModal() : createTopic()"
    />

    <!-- Delete all keywords modal -->
    <DeleteModal
      id="modal-small-keywords"
      :title="$t('audience.topics.deleteAllKeywordsTitle')"
      :custom-content="$t('audience.topics.deleteAllKeywords')"
      @delete="deleteAllKeywords(), handleFormInput(state)"
    />

    <SaveChangesModal :type="$t('Topic')" @save="createTopic()" />
  </section>
  <div v-else class="loading__container">
    <LoadingBar />
  </div>
</template>

<script>
import { topics } from '@/api'
import router from '@/router'
import store from '@/store'
import { whitelabel } from '@/utils/constants'
import {
  // utility components
  DeleteModal,
  Footer,
  GjIcon,
  IntegrationCard,
  LoadingBar,
  SaveChangesModal,
  // utility functions
  confirmChanges,
  handleFormInput,
  scrollToElement,
  scrollToParentElement
} from '@nodus/utilities-front'
import { BCol, BFormInput, BFormTextarea, BTooltip } from 'bootstrap-vue'
import {
  computed,
  getCurrentInstance,
  onMounted,
  onUnmounted,
  reactive,
  ref
} from 'vue'
import vSelect from 'vue-select'
import Keywords from './Keywords.vue'
import KeywordsSuggested from './KeywordsSuggested.vue'

export default {
  name: 'TopicCreate',
  components: {
    BCol,
    BFormInput,
    BFormTextarea,
    LoadingBar,
    BTooltip,
    GjIcon,
    vSelect,

    // Custom Components
    KeywordsSuggested,
    Keywords,
    Footer,
    DeleteModal,
    SaveChangesModal,
    IntegrationCard
  },
  async beforeRouteLeave(to, from, next) {
    if (
      !this.state.submitted &&
      (!this.state.isPristine || !this.istopicPristine)
    ) {
      const response = await confirmChanges(this.$bvModal)
      next(response)
    } else {
      next()
    }
  },
  async beforeRouteEnter(to, from, next) {
    const organizationId =
      to.params?.organizationId || from.params?.organizationId
    if (!to.params.topicId) {
      if (store.getters['topics/canCreate'] === null) {
        await store.dispatch('topics/getTable', {
          id: organizationId
        })
      }
      if (store.getters['topics/canCreate']) next()
      else next('/error/401')
    } else {
      next()
    }
  },
  setup() {
    const vm = getCurrentInstance().proxy
    const { organizationId, topicId } = vm.$route.params
    const isLoading = ref(false)
    const tableLoading = ref(false)
    const isSuggestedLoading = ref(false)
    const istopicPristine = computed(
      () => store.getters['topics/topicPristine']
    )

    const state = reactive({
      submitted: false,
      isPristine: true
    })

    const topicObject = reactive({
      name: {
        value: '',
        errors: ''
      },
      description: {
        value: '',
        errors: ''
      },
      keywordMatchType: 'Exact',
      keywords: {
        value: [],
        errors: ''
      },
      activations: []
    })

    const keywordMatchingTypes = ['Exact', 'Phrase', 'Broad']

    const suggestedKeywordList = ref([])

    const suggestedKeywords = computed(
      () => store.getters['topics/suggestedKeywordsList']
    )
    const propertiesList = computed(() => store.getters.allProperties)

    const keywordsTable = computed(() => store.getters['topics/keywordsTable'])

    onMounted(async () => {
      isSuggestedLoading.value = true
      if (topicId) {
        isLoading.value = true
        tableLoading.value = true
        store.commit('topics/SET_TOTAL_LOADING', true)
        const response = await store.dispatch('topics/getTopic', {
          id: organizationId,
          topicId
        })
        topicObject.name.value = response.name
        topicObject.description.value = response.description
        topicObject.keywordMatchType = response.keywordMatchType
        topicObject.activations = response.activations

        if (!response.canEdit) router.push('/error/401')
        isLoading.value = false

        const keywords = response.keywords.map((el) => el.keyword)
        store
          .dispatch('topics/getKeywordsAnalytics', {
            organizationId,
            keywords,
            keywordMatchType: response.keywordMatchType,
            type: 'existing'
          })
          .then(() => {
            tableLoading.value = false
          })
        store.dispatch('topics/getTotalAnalytics', {
          organizationId,
          keywordMatchType: response.keywordMatchType
        })
      }

      if (!topicId) {
        store.dispatch('topics/getActivations', { id: organizationId })
        topicObject.activations = computed(
          () => store.getters['topics/activations']
        )
        store.commit('topics/SET_TOTAL_ANALYTICS', {
          totalItems: 0,
          totalUsers: 0,
          totalEvents: 0
        })
        keywordsTable.value.rows = []
      }
      store.dispatch('getProperties', { id: organizationId })
      await store.dispatch('topics/getSuggestedKeywords', {
        id: organizationId
      })
      suggestedKeywordList.value = suggestedKeywords.value
      isSuggestedLoading.value = false
    })

    const onMatchTypeChange = async () => {
      store.commit(
        'topics/SET_KEYWORD_MATCH_TYPE',
        topicObject.keywordMatchType
      )

      const keywords = []
      keywordsTable.value.rows.forEach((el) => {
        keywords.push(el.keyword)
      })

      if (keywords && keywords.length > 0) {
        tableLoading.value = true
        store.commit('topics/SET_TOTAL_LOADING', true)
        await store.dispatch('topics/getKeywordsAnalytics', {
          organizationId,
          keywords,
          keywordMatchType: topicObject.keywordMatchType,
          type: 'existing'
        })
        tableLoading.value = false
        await store.dispatch('topics/getTotalAnalytics', {
          organizationId,
          keywordMatchType: topicObject.keywordMatchType
        })
      }
    }

    const addKeyword = async (keyword) => {
      tableLoading.value = true
      store.commit('topics/SET_TOTAL_LOADING', true)

      const keywords = [keyword]
      await store.dispatch('topics/getKeywordsAnalytics', {
        organizationId,
        keywords,
        keywordMatchType: topicObject.keywordMatchType
      })
      tableLoading.value = false
      await store.dispatch('topics/getTotalAnalytics', {
        organizationId,
        keywordMatchType: topicObject.keywordMatchType
      })

      scrollToParentElement('keywords-list', keyword, 50)
    }

    const addSuggestedKeyword = (keyword, index) => {
      addKeyword(keyword)
      suggestedKeywordList.value.splice(index, 1)
    }

    const addAllSuggested = async (filteredKeywords) => {
      const keywords = []
      suggestedKeywordList.value.forEach((keyword) => {
        if (!filteredKeywords.includes(keyword)) keywords.push(keyword)
      })
      suggestedKeywordList.value = filteredKeywords

      tableLoading.value = true
      store.commit('topics/SET_TOTAL_LOADING', true)
      await store.dispatch('topics/getKeywordsAnalytics', {
        organizationId,
        keywords,
        keywordMatchType: topicObject.keywordMatchType
      })
      tableLoading.value = false
      await store.dispatch('topics/getTotalAnalytics', {
        organizationId,
        keywordMatchType: topicObject.keywordMatchType
      })
    }

    const addFileKeyword = (keywordData) => {
      store.commit('topics/ADD_FILE_KEYWORD', keywordData)
    }

    const deleteAllKeywords = () => {
      store.commit('topics/DELETE_ALL_KEYWORDS')
    }

    const createTopic = async () => {
      state.submitted = true
      const requestKeywords = []
      keywordsTable.value.rows.forEach((row) => {
        requestKeywords.push({
          keyword: row.keyword,
          included: row.included
        })
      })
      const requestObject = {
        name: topicObject.name.value,
        description: topicObject.description.value,
        keywords: requestKeywords,
        keywordMatchType: topicObject.keywordMatchType,
        activationIds: topicObject.activations
          ? topicObject.activations
              .filter((ac) => ac.enabled)
              .map((ac) => ac.id)
          : []
      }
      let response = null
      if (topicId)
        response = await topics.updateTopic(
          organizationId,
          topicId,
          requestObject
        )
      else response = await topics.addTopic(organizationId, requestObject)

      const { errors, success } = response.data

      if (!success) {
        state.submitted = false
        Object.keys(errors).forEach((key) => {
          topicObject[key].errors = errors[key]
        })
        setTimeout(() => scrollToElement('[class*="form__input--invalid"]'))
      } else {
        state.submitted = true
        router.push({
          name: 'topics',
          params: { organizationId }
        })
      }
    }

    const cancel = () => {
      router.push({ name: 'topics' })
    }

    const openSaveModal = () => {
      if (state.isPristine && istopicPristine.value) cancel()
      else vm.$bvModal.show('save-changes-modal')
    }

    const property = ref('')

    const propertyIds = computed(() =>
      property.value ? property.value.map((p) => p.id) : []
    )

    const updatePropertiesApi = async (properties) => {
      isSuggestedLoading.value = true
      property.value = properties
      await store.dispatch('topics/getSuggestedKeywords', {
        id: organizationId,
        propertyIds: propertyIds.value
      })
      suggestedKeywordList.value = suggestedKeywords.value
      isSuggestedLoading.value = false
    }

    onUnmounted(() => {
      store.commit('topics/SET_TOTAL_ANALYTICS', {
        totalItems: 0,
        totalUsers: 0,
        totalEvents: 0
      })
    })

    return {
      organizationId,
      isLoading,
      isSuggestedLoading,
      state,
      topicObject,
      propertiesList,
      suggestedKeywordList,
      deleteAllKeywords,
      addSuggestedKeyword,
      addAllSuggested,
      createTopic,
      handleFormInput,
      cancel,
      topicId,
      openSaveModal,
      updatePropertiesApi,
      addKeyword,
      keywordsTable,
      addFileKeyword,
      istopicPristine,
      keywordMatchingTypes,
      onMatchTypeChange,
      tableLoading,
      whitelabel
    }
  }
}
</script>

<style lang="scss">
@import '@/assets/scss/views/segments/segmentCreate.scss';
@import '@/assets/scss/views/topics/topicCreate.scss';
</style>
