import {API_GATEWAY_URL} from "@/utils/constants";
import axios from "@axios";

const segments = {
  remove: (organizationId = "", segmentationId = "") =>
    axios.delete(`/api/v1/${organizationId}/segments/${segmentationId}`),
  duplicate: (organizationId = "", segmentationId = "") =>
    axios.post(`/api/v1/${organizationId}/segments/duplicate`, {
      segmentId: segmentationId,
    }),
  create: (organizationId = "", segmentationId = "") =>
    axios.post(`/api/v1/${organizationId}/segments/duplicate`, {
      segmentId: segmentationId,
    }),
  getSegment: (organizationId, segmentId) =>
    axios.get(`/api/v1/${organizationId}/segments/${segmentId}`),
  getSegmentParameters: async (organizationId) =>
    axios.get(`/api/v1/${organizationId}/segments/filter/parameters`),
  getSegmentTable: (organizationId) =>
    axios.get(`/api/v1/${organizationId}/segments/filter/tables`),
  getSegmentTemplate: (organizationId, templateId) =>
    axios.get(`/api/v1/${organizationId}/segments/templates/${templateId}`),
  createSegment: (organizationId, data) =>
    axios.post(`/api/v1/${organizationId}/segments`, data),
  updateSegment: (organizationId, segmentId, data) =>
    axios.patch(`/api/v1/${organizationId}/segments/${segmentId}`, data),
  reprocess: (organizationId = "", segmentId = "") =>
    axios.post(`/api/v1/${organizationId}/segments/reprocess`, {
      id: segmentId,
    }),
  calculateSegment: (organizationId = "", segment = "", cancelToken = "") =>
    axios.post(`/api/v1/${organizationId}/segments/calculate`, segment, {
      cancelToken,
    }),
  setStatus: (organizationId = "", code = "", processingStatus = 0) =>
    axios.put(
      `/api/v1/${organizationId}/segments/${code}/activate/${processingStatus}`
    ),
};

const queries = {
  createCustomQuery: (organizationId = "", data = []) =>
    axios.post(`/api/v1/${organizationId}/segments/customquery`, data),
  updateCustomQuery: (organizationId = "", segmentId = "", data = []) =>
    axios.patch(
      `/api/v1/${organizationId}/segments/${segmentId}/customquery`,
      data
    ),
  remove: (organizationId = "", segmentId = "") =>
    axios.delete(`/api/v1/${organizationId}/segments/${segmentId}/customquery`),
  duplicate: (organizationId = "", segmentId = "") =>
    axios.post(`/api/v1/${organizationId}/segments/customquery/duplicate`, {
      segmentId,
    }),
  validate: (organizationId = "", query = "") =>
    axios.post(`/api/v1/${organizationId}/segments/customquery/validate`, {
      query,
    }),
  calculateQuery: (organizationId = "", query = "", cancelToken = "") =>
    axios.post(
      `/api/v1/${organizationId}/segments/customquery/calculate`,
      { query },
      { cancelToken }
    ),
  setStatus: (organizationId = "", code = "", processingStatus = 0) =>
    axios.put(
      `/api/v1/${organizationId}/segments/${code}/customquery/activate/${processingStatus}`
    ),
};

const topics = {
  uploadFile: (organizationId = "", data = "", keywordMatchType = "") =>
    axios.post(
      `${API_GATEWAY_URL}/api/v1/${organizationId}/contextual-targeting/topics/file/keywords?KeywordMatchType=${keywordMatchType}`,
      data
    ),
  addTopic: (organizationId = "", data = []) =>
    axios.post(
      `${API_GATEWAY_URL}/api/v1/${organizationId}/contextual-targeting/topics`,
      data
    ),
  remove: (organizationId = "", id = "") =>
    axios.delete(`/api/v1/${organizationId}/contextual-targeting/topics/${id}`, {
      baseURL: API_GATEWAY_URL,
    }),
  updateTopic: (organizationId = "", id = "", data = "") =>
    axios.put(
      `${API_GATEWAY_URL}/api/v1/${organizationId}/contextual-targeting/topics/${id}`,
      data
    ),
};

const integrations = {
  setStatus: (organizationId = "", activationId = "", status = 0) =>
    axios.patch(
      `/api/v1/${organizationId}/integrations/activations/${activationId}`,
      status
    ),
  createIntegration: (organizationId = "", data = []) =>
    axios.post(`/api/v1/${organizationId}/integrations/activations`, data),
};

export {integrations, queries, segments, topics};

