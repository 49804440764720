var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "add-keyword add-keywords-manual" }, [
    _c("div", { staticClass: "manual-keywords__content" }, [
      _c("div", { staticClass: "enter-keyword__container input__wrapper" }, [
        _c("span", { staticClass: "enter-keyword__header" }, [
          _vm._v(_vm._s(_vm.$t("audience.topics.Keywords"))),
        ]),
        _c(
          "div",
          { staticClass: "manual-and-upload__container" },
          [
            _c("b-form-input", {
              staticClass: "form__input manual__input",
              class: { "form__input--invalid": _vm.manualInputError },
              attrs: {
                id: "manual-input",
                placeholder: "Enter keyword",
                type: "text",
              },
              on: {
                keyup: function ($event) {
                  if (
                    !$event.type.indexOf("key") &&
                    _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")
                  )
                    return null
                  _vm.isTotalAnalyticsLoading ? {} : _vm.addManualKeyword()
                },
                input: function ($event) {
                  return _vm.handleFormInput()
                },
              },
              model: {
                value: _vm.keywordInput,
                callback: function ($$v) {
                  _vm.keywordInput = typeof $$v === "string" ? $$v.trim() : $$v
                },
                expression: "keywordInput",
              },
            }),
            _c("UploadListModal", {
              attrs: {
                "upload-type": "keywords",
                "upload-function": _vm.topics.uploadFile,
                "upload-parameters": [_vm.keywordMatchType],
                "upload-button-text": _vm.$t("audience.topics.uploadKeywords"),
                "upload-documentation": `${_vm.whitelabel.documentationUrl}/intro#introduction-to-contextual-targeting`,
                "upload-documentation-text": _vm.$t(
                  "audience.topics.uploadFileInformation"
                ),
                "file-types": ["CSV", "TXT"],
                "upload-info": _vm.$t("audience.topics.fileWordPerRow"),
                "removed-entries-info": _vm.$t(
                  "audience.topics.removedKeywordsInformation"
                ),
              },
              on: { "add-file-entries": _vm.addKeywordsFromFile },
              scopedSlots: _vm._u([
                {
                  key: "fileContent",
                  fn: function (data) {
                    return [
                      _c(
                        "div",
                        { staticClass: "keywords__container" },
                        [
                          _c(
                            "transition-group",
                            { attrs: { name: "modal-keyword-list" } },
                            _vm._l(data.fileContent, function (entry, index) {
                              return _c(
                                "div",
                                {
                                  key: entry.keyword,
                                  staticClass: "keyword__row",
                                },
                                [
                                  _c(
                                    "span",
                                    { staticClass: "keyword__index" },
                                    [_vm._v(_vm._s(index + 1) + ".")]
                                  ),
                                  _c("span", { staticClass: "keyword__name" }, [
                                    _vm._v(" " + _vm._s(entry.keyword) + " "),
                                    _c(
                                      "span",
                                      {
                                        staticClass: "remove__keyword-list",
                                        on: {
                                          click: function ($event) {
                                            return data.removeEntry(index)
                                          },
                                        },
                                      },
                                      [
                                        _c("img", {
                                          attrs: {
                                            src: require("@/assets/svgs/minus.svg"),
                                          },
                                        }),
                                      ]
                                    ),
                                  ]),
                                ]
                              )
                            }),
                            0
                          ),
                        ],
                        1
                      ),
                    ]
                  },
                },
              ]),
            }),
          ],
          1
        ),
        !_vm.manualInputError && !_vm.duplicateError
          ? _c("span", { staticClass: "enter-keyword__information-text" }, [
              _vm._v(
                "*" + _vm._s(_vm.$t("audience.topics.enterKeywordInformation"))
              ),
            ])
          : _vm._e(),
        _vm.manualInputError
          ? _c("span", { staticClass: "error__info-message" }, [
              _vm._v(_vm._s(_vm.$t("audience.topics.invalidKeyword")) + " "),
              _c(
                "a",
                {
                  attrs: {
                    href: `${_vm.whitelabel.documentationUrl}/intro#introduction-to-contextual-targeting`,
                    target: "_blank",
                  },
                },
                [_vm._v(_vm._s(_vm.$t("Documentation").toLowerCase()))]
              ),
              _vm._v(
                " " + _vm._s(_vm.$t("audience.topics.forInstructions")) + ". "
              ),
            ])
          : _vm._e(),
        _vm.duplicateError
          ? _c("span", { staticClass: "error__info-message" }, [
              _vm._v(
                _vm._s(_vm.$t("audience.topics.keywordAlreadyExists")) + "!"
              ),
            ])
          : _vm._e(),
      ]),
      _vm.keywordsError && _vm.keywordsError.length > 0
        ? _c(
            "div",
            { staticClass: "keywords-error__container" },
            _vm._l(_vm.keywordsError, function (error, index) {
              return _c(
                "span",
                {
                  key: index,
                  staticClass: "error__info-message form__input--invalid",
                },
                [_vm._v(" " + _vm._s(error) + " ")]
              )
            }),
            0
          )
        : _vm._e(),
      _c("div", [
        _c(
          "div",
          { staticClass: "total-analytics__container" },
          [
            _vm._l(_vm.analyticItems, function (item, index) {
              return _c(
                "div",
                { key: index, staticClass: "total-analytics__item" },
                [
                  _c("span", { staticClass: "item__name-and-icon" }, [
                    _vm._v(" " + _vm._s(item.name) + " "),
                    _c(
                      "div",
                      { attrs: { id: `${item.name}-anaytics` } },
                      [
                        _c("GjIcon", { attrs: { name: "Info", size: "21" } }),
                        _c(
                          "b-tooltip",
                          {
                            attrs: {
                              target: `${item.name}-anaytics`,
                              triggers: "hover",
                              placement: "top",
                            },
                          },
                          [_vm._v(" " + _vm._s(item.tooltipText) + ". ")]
                        ),
                      ],
                      1
                    ),
                  ]),
                  _c(
                    "span",
                    {
                      staticClass: "item__number",
                      class: {
                        "item__number--disabled": _vm.isTotalAnalyticsLoading,
                      },
                    },
                    [
                      _vm._v(
                        _vm._s(_vm.getAnalyticItem(item.name).toLocaleString())
                      ),
                    ]
                  ),
                ]
              )
            }),
            _vm.isTotalAnalyticsLoading
              ? _c(
                  "span",
                  { staticClass: "total-analytics__loading" },
                  [_c("LoadingBar", { attrs: { classList: "medium" } })],
                  1
                )
              : _vm._e(),
          ],
          2
        ),
      ]),
      _c(
        "div",
        {
          staticClass: "keywords-scrollable__area",
          attrs: { id: "keywords-list" },
        },
        [
          _vm.keywordsTable.rows.length
            ? _c(
                "section",
                { staticClass: "table__wrapper keywords__table" },
                [
                  _c("Table", {
                    class: { "keywords__table--loading": _vm.tableLoading },
                    attrs: {
                      data: _vm.keywordsTable,
                      "type-of-table": "keywords",
                    },
                    scopedSlots: _vm._u(
                      [
                        {
                          key: "row-data",
                          fn: function ({ data }) {
                            return [
                              _c("KeywordsRow", { attrs: { data: data } }),
                            ]
                          },
                        },
                      ],
                      null,
                      false,
                      1397137417
                    ),
                  }),
                ],
                1
              )
            : !_vm.keywordsTable.rows.length && !_vm.tableLoading
            ? _c("div", { staticClass: "no-keywords__container" }, [
                _vm._m(0),
                _c("span", { staticClass: "no-keywords__text" }, [
                  _vm._v(
                    _vm._s(_vm.$t("audience.topics.noKeywordsAdded")) + "..."
                  ),
                ]),
              ])
            : _vm._e(),
        ]
      ),
      _vm.tableLoading
        ? _c(
            "div",
            { staticClass: "keywords__table-overlay" },
            [_c("LoadingBar")],
            1
          )
        : _vm._e(),
    ]),
    _c("div", { staticClass: "add-keywords__footer" }, [
      _c(
        "span",
        {
          class: { "span--disabled": _vm.keywordsTable.rows.length < 1 },
          attrs: { id: "delete-all-keywords" },
          on: {
            click: function ($event) {
              _vm.keywordsTable.rows.length > 0
                ? _vm.$bvModal.show("modal-small-keywords")
                : {}
            },
          },
        },
        [_vm._v(_vm._s(_vm.$t("deleteAll")))]
      ),
    ]),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("span", { staticClass: "no-keywords__icon" }, [
      _c("img", { attrs: { src: require("@/assets/svgs/noKeywords.svg") } }),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }