var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("section", [
    !_vm.isLoading
      ? _c(
          "div",
          { staticClass: "view-screen__container" },
          [
            _c("div", { staticClass: "query-view__container" }, [
              _c(
                "div",
                { staticClass: "basic-information__container" },
                [
                  _c(
                    "h5",
                    {
                      staticClass: "container__title container__title--margin",
                    },
                    [_vm._v(" " + _vm._s(_vm.$t("data.basicInfo")) + " ")]
                  ),
                  _vm.query.canEdit
                    ? _c(
                        "b-link",
                        {
                          staticClass: "edit__button",
                          attrs: {
                            to: {
                              name: "query-segment-edit",
                              params: {
                                organizationId: _vm.organizationId,
                                segmentId: _vm.segmentId,
                              },
                            },
                          },
                        },
                        [
                          _c("GjIcon", { attrs: { name: "Edit", size: "18" } }),
                          _c("span", [_vm._v(_vm._s(_vm.$t("Edit")))]),
                        ],
                        1
                      )
                    : _vm._e(),
                  _vm.query.name
                    ? _c("div", { staticClass: "item__container" }, [
                        _c("label", { staticClass: "template__text" }, [
                          _vm._v(_vm._s(_vm.$t("Name"))),
                        ]),
                        _c("span", { staticClass: "query__text" }, [
                          _vm._v(_vm._s(_vm.query.name.value)),
                        ]),
                      ])
                    : _vm._e(),
                  _vm.query.description
                    ? _c(
                        "div",
                        { staticClass: "item__container" },
                        [
                          _c("label", { staticClass: "template__text" }, [
                            _vm._v(_vm._s(_vm.$t("Description"))),
                          ]),
                          _c("ReadMore", {
                            attrs: {
                              text: _vm.query.description.value,
                              mode: "view",
                              classes: ["query__text"],
                            },
                          }),
                        ],
                        1
                      )
                    : _vm._e(),
                ],
                1
              ),
              _c("div", { staticClass: "container__wrapper" }, [
                _c("h5", { staticClass: "container__title" }, [
                  _vm._v(_vm._s(_vm.$t("audience.code"))),
                ]),
                _vm.query.query
                  ? _c(
                      "div",
                      { attrs: { id: "query" } },
                      [
                        _c("b-form-textarea", {
                          staticClass: "query__area",
                          attrs: {
                            id: "textarea-query",
                            rows: "8",
                            "no-resize": "",
                            disabled: "",
                          },
                          model: {
                            value: _vm.query.query.value,
                            callback: function ($$v) {
                              _vm.$set(_vm.query.query, "value", $$v)
                            },
                            expression: "query.query.value",
                          },
                        }),
                      ],
                      1
                    )
                  : _vm._e(),
              ]),
              _c("div", { staticClass: "container__wrapper" }, [
                _vm.query.reprocessOperator &&
                _vm.query.reprocessValue &&
                _vm.query.reprocessTimeUnit
                  ? _c("div", [
                      _c("h5", { staticClass: "container__title" }, [
                        _vm._v(_vm._s(_vm.$t("audience.schedule"))),
                      ]),
                      _c(
                        "p",
                        { staticClass: "template__text template__text--unset" },
                        [
                          _vm._v(
                            " " +
                              _vm._s(_vm.$t("audience.segmentQueryWillRun")) +
                              " "
                          ),
                          _c("span", { staticClass: "query__text" }, [
                            _vm._v(
                              _vm._s(
                                _vm.formatScheduleText(
                                  _vm.query.reprocessOperator,
                                  _vm.query.reprocessValue,
                                  _vm.query.reprocessTimeUnit
                                )
                              )
                            ),
                          ]),
                        ]
                      ),
                    ])
                  : _vm._e(),
              ]),
              _c("div", { staticClass: "container__wrapper" }, [
                _c("h5", { staticClass: "container__title" }, [
                  _vm._v(_vm._s(_vm.$t("Integrations"))),
                ]),
                _c(
                  "div",
                  { staticClass: "container__integrations" },
                  _vm._l(_vm.query.activations, function (activation) {
                    return _c("IntegrationCard", {
                      key: activation.id,
                      attrs: { activation: activation, "view-mode": "" },
                    })
                  }),
                  1
                ),
              ]),
            ]),
            _c("Footer", {
              attrs: { "view-mode": "" },
              on: {
                back: function ($event) {
                  return _vm.cancel()
                },
              },
            }),
          ],
          1
        )
      : _c("div", { staticClass: "loading__container" }, [_c("LoadingBar")], 1),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }