<template>
  <div v-if="isLoading" class="loading__container">
    <LoadingBar />
  </div>
  <section v-else class="topics-view__wrapper view-screen__container">
    <div class="topics-view__container">
      <div class="container__wrapper topics-view__basic-info">
        <div class="container__header segment-view__basic-info--header">
          <h5 class="container__header-title">{{ $t('data.basicInfo') }}</h5>
          <b-link
            v-if="topic.canEdit"
            class="segment-view__edit"
            :to="{
              name: 'topics-edit',
              params: {
                organizationId,
                topicId
              }
            }"
          >
            <GjIcon name="Edit" size="18"></GjIcon>
            <span>{{ $t('Edit') }}</span>
          </b-link>
        </div>
        <div class="container__content topic-basic__container">
          <div class="segment-view__basic-info">
            <b-col class="input__wrapper pl-0">
              <label>{{ $t('Name') }}</label>
              <span class="topic__text">{{ topic.name }}</span>
            </b-col>
            <b-col class="input__wrapper pl-0">
              <label>{{ $t('Description') }}</label>
              <ReadMore :text="topic.description" mode="view" />
            </b-col>
            <b-col class="input__wrapper pl-0">
              <div class="keyword__match-type">
                <label class="total-analytics__title">{{
                  $t('audience.topics.keywordMatchType')
                }}</label>
                <div id="keywordMatchType-tooltip`">
                  <GjIcon name="Info" size="21" />
                  <b-tooltip
                    target="keywordMatchType-tooltip`"
                    triggers="hover"
                    placement="top"
                  >
                    <span v-if="topic.keywordMatchType === 'Exact'">{{
                      $t('audience.topics.exactTypeMatchingInfo')
                    }}</span>
                    <span v-if="topic.keywordMatchType === 'Phrase'">{{
                      $t('audience.topics.phraseTypeMatchingInfo')
                    }}</span>
                    <span v-if="topic.keywordMatchType === 'Broad'">{{
                      $t('audience.topics.broadTypeMatchingInfo')
                    }}</span>
                  </b-tooltip>
                </div>
              </div>
              <span class="topic__text">{{ topic.keywordMatchType }}</span>
            </b-col>
          </div>
        </div>
      </div>
      <div class="container__wrapper topics-view__selected-keywords">
        <div class="container__header segment-view__container_header">
          <h5 class="container__header-title">
            {{ $t('audience.topics.addedKeywords') }}
          </h5>
        </div>
        <div class="selected-keywords__container">
          <div class="selected-keywords__header">
            <div v-if="topic.keywords" class="selected-keywords__total">
              {{
                `${$t('audience.topics.totalOf')} ${topic.keywords.length} ${$t(
                  'audience.topics.keywordsAdded'
                )}`
              }}
            </div>
            <SearchInputField id="keywords-search" :data="keywordsTable" />
          </div>
          <div class="total-analytics__container">
            <div
              v-for="(item, index) in analyticItems"
              :key="index"
              class="total-analytics__item"
            >
              <span class="item__name-and-icon">
                {{ item.name }}
                <div :id="`${item.name}-anaytics`">
                  <GjIcon name="Info" size="21" />
                  <b-tooltip
                    :target="`${item.name}-anaytics`"
                    triggers="hover"
                    placement="top"
                  >
                    {{ item.tooltipText }}.
                  </b-tooltip>
                </div>
              </span>
              <span
                class="item__number"
                :class="{ 'item__number--disabled': isTotalAnalyticsLoading }"
                >{{ getAnalyticItem(item.name).toLocaleString() }}</span
              >
            </div>
            <span
              v-if="isTotalAnalyticsLoading"
              class="total-analytics__loading"
            >
              <LoadingBar classList="medium" />
            </span>
          </div>
          <div class="selected-keywords__content">
            <section
              v-if="keywordsTable.rows.length > 0"
              class="table__wrapper keywords__table"
            >
              <Table
                :data="keywordsTable"
                type-of-table="keywords"
                :is-loading="isLoading"
                :class="{ 'keywords__table--loading': tableLoading }"
              >
                <template #row-data="{ data }">
                  <KeywordsRow :data="data" is-view-topic />
                </template>
              </Table>
            </section>
          </div>
          <div v-if="tableLoading" class="keywords__table-overlay">
            <LoadingBar />
          </div>
        </div>
      </div>
      <div
        v-if="topic.activations && topic.activations.length"
        class="container__wrapper"
      >
        <div class="container__header segment-view__container_header">
          <h5 class="container__header-title">{{ $t('Integrations') }}</h5>
        </div>
        <div class="container__integrations">
          <IntegrationCard
            v-for="activation in topic.activations"
            :key="activation.id"
            :activation="activation"
            view-mode
          />
        </div>
      </div>
    </div>
    <Footer view-mode @back="cancel()" />
  </section>
</template>

<script>
import KeywordsRow from '@/components/table/table-data/KeywordsRow.vue'
import { t } from '@/libs/i18n/utils'
import store from '@/store'
import {
  Footer,
  GjIcon,
  IntegrationCard,
  LoadingBar,
  ReadMore,
  SearchInputField,
  Table
} from '@nodus/utilities-front'
import { BCol, BLink, BTooltip } from 'bootstrap-vue'
import { computed, getCurrentInstance, onMounted, onUnmounted, ref } from 'vue'

export default {
  name: 'TopicView',
  components: {
    SearchInputField,
    GjIcon,
    BCol,
    BTooltip,
    BLink,
    Footer,
    ReadMore,
    IntegrationCard,
    Table,
    KeywordsRow,
    LoadingBar
  },
  setup() {
    const vm = getCurrentInstance().proxy
    const { organizationId, topicId } = vm.$route.params
    const isLoading = ref(true)
    const tableLoading = ref(true)
    const isTotalAnalyticsLoading = computed(
      () => store.getters['topics/totalAnalyticsLoading']
    )

    const topic = computed(() => store.getters['topics/topic'])
    const keywordsTable = computed(() => store.getters['topics/keywordsTable'])
    const totalAnalytics = computed(
      () => store.getters['topics/totalAnalytics']
    )

    onMounted(async () => {
      store.commit('topics/SET_TOTAL_LOADING', true)
      await store.dispatch('topics/getTopic', { id: organizationId, topicId })
      isLoading.value = false
      const keywords = topic.value.keywords.map((el) => el.keyword)
      store
        .dispatch('topics/getKeywordsAnalytics', {
          organizationId,
          keywords,
          keywordMatchType: topic.value.keywordMatchType,
          type: 'existing'
        })
        .then(() => {
          tableLoading.value = false
        })
      await store.dispatch('topics/getTotalAnalytics', {
        organizationId,
        keywordMatchType: topic.value.keywordMatchType
      })
    })

    const analyticItems = [
      {
        name: t('Items'),
        tooltipText: t('audience.topics.itemsAnalyticsInfo')
      },
      {
        name: t('Users'),
        tooltipText: t('audience.topics.usersAnalyticsInfo')
      },
      {
        name: t('Events'),
        tooltipText: t('audience.topics.eventsAnalyticsInfo')
      }
    ]

    const getAnalyticItem = (item) => {
      const items = {
        Items: totalAnalytics.value.totalItems,
        Users: totalAnalytics.value.totalUsers,
        Events: totalAnalytics.value.totalEvents
      }
      return items[item]
    }

    onUnmounted(() => {
      store.commit('topics/SET_TOTAL_ANALYTICS', {
        totalItems: 0,
        totalUsers: 0,
        totalEvents: 0
      })
    })

    const cancel = () => {
      vm.$router.push({ name: 'topics' })
    }

    return {
      organizationId,
      topicId,
      topic,
      cancel,
      isLoading,
      isTotalAnalyticsLoading,
      keywordsTable,
      analyticItems,
      getAnalyticItem,
      tableLoading
    }
  }
}
</script>

<style lang="scss">
@import '@/assets/scss/views/segments/segmentCreate.scss';
@import '@/assets/scss/views/segments/segmentView.scss';
@import '@/assets/scss/views/topics/topicView.scss';
@import '@/assets/scss/views/topics/topicCreate.scss';
</style>
