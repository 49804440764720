// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_GET_URL_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/getUrl.js";
var ___CSS_LOADER_URL_IMPORT_0___ = new URL("data:image/svg+xml,%3Csvg xmlns=%27http://www.w3.org/2000/svg%27 viewBox=%270 0 24 24%27 fill=%27none%27 stroke=%27%23A3B0C2%27 stroke-width=%272%27 stroke-linecap=%27round%27 stroke-linejoin=%27round%27 class=%27feather feather-chevron-right%27%3E%3Cpolyline points=%279 18 15 12 9 6%27%3E%3C/polyline%3E%3C/svg%3E", import.meta.url);
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/*
      ************ MEDIA QUERY MANAGER ******************

   0 - 576px:         Small devices (landscape phones, 576px and up)
   576px - 768px:     Medium devices (tablets, 768px and up)
   768px - 992px:     Large devices (desktops, 992px and up)
   992px - 1200px:    Big devices (large desktops, 1200px and up)
   1200px - *:        Extra large devices

*/
.bisko-app__audience .container__wrapper--height {
  height: 100%;
}
.bisko-app__audience .container__wrapper--padding {
  padding: 16px;
}
.bisko-app__audience .form__wrapper {
  margin: 0 auto;
  min-width: 1117px;
  max-width: 1117px;
}
.bisko-app__audience .container__content {
  display: flex;
  flex-direction: column;
  padding-top: 10px;
}
.bisko-app__audience .input__wrapper {
  padding-bottom: 15px;
}
.bisko-app__audience .input__wrapper label {
  font-size: 14px;
  line-height: 18px;
  color: #667c99;
  font-weight: 400;
}
.bisko-app__audience .input__wrapper select {
  font-size: 14px;
  line-height: 18px;
  color: #a3b0c2;
}
.bisko-app__audience .input__wrapper textarea {
  border-color: #e0e5eb;
}
.bisko-app__audience .input__wrapper .vs--disabled .vs__dropdown-toggle,
.bisko-app__audience .input__wrapper .vs--disabled .vs__clear,
.bisko-app__audience .input__wrapper .vs--disabled .vs__search,
.bisko-app__audience .input__wrapper .vs--disabled .vs__selected,
.bisko-app__audience .input__wrapper .vs--disabled .vs__open-indicator {
  background-color: #efefef !important;
}
.bisko-app__audience .input__wrapper .input__wrapper-properties {
  font-size: 14px;
}
.bisko-app__audience .input__wrapper .input__wrapper-properties .vs__selected {
  margin-top: 5px !important;
  background-color: white;
  border-radius: 20px !important;
  border: 1px solid #e0e5eb;
  color: #052d61;
  font-size: 14px !important;
}
.bisko-app__audience .input__wrapper .vs__actions button {
  display: none;
}
.bisko-app__audience .input__wrapper--margin {
  margin-top: 5px;
}
.bisko-app__audience .container__content--segments {
  width: 100%;
  align-items: flex-start;
  padding: 0 20px 10px;
  background-color: #fafafa;
  border-radius: 5px 5px 0 0;
  border: 1px solid #e0e5eb;
  position: relative;
  padding-top: 20px;
}
.bisko-app__audience .container__content--segments .btn-secondary {
  background-color: white !important;
}
.bisko-app__audience .container__content--segments.container__content--segments-last {
  border-radius: 0 0 5px 5px;
  padding-top: 10px;
}
.bisko-app__audience .container__content--segments.container__content--segments-last .segment__button--reversed {
  margin-left: 30px;
}
.bisko-app__audience .container__content--segments_remove {
  position: absolute;
  right: 20px;
  top: 30px;
}
.bisko-app__audience .segment__wrapper {
  display: flex;
  flex-wrap: wrap;
}
.bisko-app__audience .segment__wrapper-select {
  width: 220px;
  margin-right: 15px;
}
.bisko-app__audience .segment__button {
  font-size: 0.875rem;
  width: 126px;
  height: 34px;
  border-radius: 4px;
  background-color: #fafafa !important;
  border: 1px solid #e0e5eb !important;
  color: #052d61 !important;
  display: flex;
  justify-content: flex-start;
  margin-bottom: 10px;
}
.bisko-app__audience .segment__button:active, .bisko-app__audience .segment__button:focus {
  background-color: inherit !important;
}
.bisko-app__audience .segment__button:hover {
  background-color: #e0e5eb !important;
}
.bisko-app__audience .segment__button--icon {
  width: 28px;
}
.bisko-app__audience .segment__button--wrapper {
  display: inline-block;
}
.bisko-app__audience .segment__button--container {
  width: 126px;
  height: auto;
  margin-bottom: 10px;
}
.bisko-app__audience .segment__button--reversed {
  height: 34px;
  border-radius: 4px;
  background-color: white;
  border: 1px solid #e0e5eb !important;
  color: #052d61 !important;
  text-align: right;
  text-transform: uppercase;
}
.bisko-app__audience .segment__button--reversed:active, .bisko-app__audience .segment__button--reversed:focus {
  background-color: inherit !important;
}
.bisko-app__audience .segment__button--reversed:hover {
  background-color: #e0e5eb !important;
}
.bisko-app__audience .segment__button--reversed button {
  height: 100% !important;
  color: #052d61 !important;
  border: none;
}
.bisko-app__audience .segment__button--reversed button:not(:first-child) {
  padding: 0 4px 0 4px;
}
.bisko-app__audience .segment__button--reversed button:not(:first-child)::after {
  background-image: url(${___CSS_LOADER_URL_REPLACEMENT_0___}) !important;
}
.bisko-app__audience .segment__button--reversed button .segment__dropdown-divider {
  position: absolute;
  height: 24px;
  width: 0;
  border-left: 0.5px solid #e2e2e2;
  right: 2px;
}
.bisko-app__audience .segment__button--reversed ul.dropdown-menu {
  transform: translate3d(60px, -1px, 0px) !important;
  border: 1px solid #e0e5eb;
  min-width: 120px;
  padding: 6px 0;
  box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.12);
}
.bisko-app__audience .segment__button--reversed ul.dropdown-menu .dropdown-item {
  fill: #6e6b7b;
  padding: 4px 12px;
}
.bisko-app__audience .segment__button--reversed ul.dropdown-menu .dropdown-item span {
  font-size: 14px;
  text-transform: none;
  color: #6e6b7b;
}
.bisko-app__audience .segment__button--reversed--multiple {
  height: 26px;
  background-color: #667c99;
  color: #ffffff;
  position: relative;
  z-index: 1;
  margin: 10px 0 0 0 !important;
}
.bisko-app__audience .segment__button--reversed--multiple:active, .bisko-app__audience .segment__button--reversed--multiple:focus {
  background-color: #667c99 !important;
}
.bisko-app__audience .segment__button--reversed--multiple:hover {
  background-color: #667c99 !important;
}
.bisko-app__audience .segment__param {
  display: flex;
  align-items: center;
  color: #052d61;
  font-size: 14px;
  width: 100%;
  margin-bottom: 10px;
}
.bisko-app__audience .segment__param .input__wrapper {
  width: 230px;
  margin-right: 10px;
  margin-bottom: 5px;
  padding-bottom: 0;
}
.bisko-app__audience .segment__param .input__wrapper .form__input {
  height: 38px;
  font-size: 14px;
}
.bisko-app__audience .segment__param > .frequency__section {
  align-items: baseline;
}
.bisko-app__audience .segment__param-title {
  margin-right: 15px;
}
.bisko-app__audience .segment__param-title--width {
  max-width: 25px;
  width: 100%;
}
.bisko-app__audience .segment__icon {
  cursor: pointer !important;
}
.bisko-app__audience .segment__param-selects {
  display: flex;
  flex-direction: column;
  margin-bottom: 10px;
  align-items: flex-start;
}
.bisko-app__audience .segment__param-selects-content {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  margin-bottom: 5px;
}
.bisko-app__audience .input__wrapper--flex {
  display: flex;
  flex-wrap: wrap;
}
.bisko-app__audience .input__wrapper--width {
  width: 100% !important;
}
.bisko-app__audience .input__wrapper-parameters:first-child {
  margin-left: 70px;
}
.bisko-app__audience .segment__param-heading {
  display: flex;
  align-items: center;
}
.bisko-app__audience .vs__dropdown-option {
  padding-left: 25px;
  font-size: 14px;
}
.bisko-app__audience .vs__dropdown-option--disabled {
  padding-left: 15px;
  color: #667c99 !important;
  opacity: 1 !important;
}
.bisko-app__audience .input__wrapper--date {
  display: flex;
  align-items: center;
  width: 100% !important;
}
.bisko-app__audience .input__wrapper--date .form__input {
  height: 37px !important;
  display: flex !important;
  align-items: center !important;
  font-size: 14px;
  border: 1px solid #e0e5eb;
  max-width: 220px;
  width: 100%;
}
.bisko-app__audience .input__wrapper--date .b-calendar-grid-help {
  display: none;
}
.bisko-app__audience .input__wrapper--date span {
  margin: 0 5px;
}
.bisko-app__audience .time__last-param {
  width: 100% !important;
  display: flex;
  flex-wrap: wrap;
}
.bisko-app__audience .time__last-param .form__input {
  width: 150px;
}
.bisko-app__audience .time__last-param .form__input-time {
  width: 75px;
  margin-right: 5px;
}
.bisko-app__audience .parameters__custom .form__input {
  margin-right: 15px;
}
@media (max-width: 576px) {
.bisko-app__audience .parameters__custom .form__input {
    width: 180px;
}
}
.bisko-app__audience .user__segmentation-wrapper .form__input {
  width: 220px;
  height: 38px;
}
.bisko-app__audience .user__segmentation-wrapper .form__input.form__input--small {
  width: 135px;
}
.bisko-app__audience .segment__select .vs__selected-options {
  flex-wrap: nowrap;
  max-width: calc(100% - 40px);
}
.bisko-app__audience .segment__select .vs__selected {
  display: block;
  white-space: nowrap;
  text-overflow: ellipsis;
  max-width: 100%;
  overflow: hidden;
}
.bisko-app__audience .segment__select .vs__dropdown-option {
  white-space: normal;
  word-break: break-all;
}
.bisko-app__audience .segment__select .vs__dropdown-option--selected::after {
  right: 5px;
}
.bisko-app__audience .segment__select .disabled__prefix {
  font-size: 11px;
  color: gray;
  margin-left: 4px;
  display: inline-block;
  vertical-align: middle;
  font-style: italic;
}
.bisko-app__audience .segment__select .vs__dropdown-option--selected .disabled__prefix {
  color: #ffffff;
}
.bisko-app__audience .parameters__custom-content {
  display: flex;
  flex-wrap: wrap;
}
.bisko-app__audience .parameters__custom-content .form__input {
  margin-right: 5px;
}
.bisko-app__audience .form__input-params {
  width: 300px !important;
  padding-left: 5px !important;
  padding-right: 5px !important;
}
@media (max-width: 576px) {
.bisko-app__audience .form__input-params {
    width: 200px !important;
}
}
.bisko-app__audience .form__input-params .b-form-tags-button {
  display: none;
}
.bisko-app__audience .form__input-params .b-form-tag {
  margin-right: 5px;
  margin-top: 5px !important;
  background-color: white;
  border-radius: 20px;
  border: 1px solid #e0e5eb;
  color: #052d61;
  font-size: 14px;
  padding: 0 5px;
}
.bisko-app__audience .form__input-params .text-muted {
  display: block;
  margin-top: 4px;
  color: #e34850 !important;
  width: 100%;
  font-size: 0.857rem;
  color: #e34850;
}
.bisko-app__audience .segment__button-and__container {
  width: 100%;
  text-align: left;
  position: relative;
}
.bisko-app__audience .segment__button-and__container .segment__button--reversed {
  margin: 10px 0 0 50px;
  background-color: white !important;
}
.bisko-app__audience .segment__button-and__container .segment__button--reversed .btn-secondary {
  background-color: white !important;
}
.bisko-app__audience .segment__button-and__container .segment__button--reversed ul.dropdown-menu {
  transform: translate3d(70px, -1px, 0px) !important;
}
.bisko-app__audience .segment__button-and__container.segment__button-and__container--multiple {
  text-align: center;
}
.bisko-app__audience .segment__button-and__container.segment__button-and__container--multiple .segment__button--reversed.segment__button--reversed--multiple {
  background-color: #667c99 !important;
  color: white !important;
  border-color: #82868b !important;
}
.bisko-app__audience .segment__param-info {
  display: flex;
  flex-direction: column;
}
.bisko-app__audience .segment__param-info span {
  max-width: 300px;
}
.bisko-app__audience .segment__param-info input:disabled {
  display: none;
}
.bisko-app__audience .segment__param-span {
  margin-right: 10px;
}
.bisko-app__audience .add__parameters {
  display: inline-flex;
  margin-top: 25px;
  margin-left: 70px;
  width: -moz-fit-content;
  width: fit-content;
  cursor: pointer;
}
.bisko-app__audience .add__parameters--margin {
  margin-top: 0;
}
.bisko-app__audience .add__parameters-disabled {
  opacity: 0.5;
  cursor: default;
}
.bisko-app__audience .add__parameters-disabled .segment__icon {
  cursor: default !important;
}
.bisko-app__audience .parameters__operators {
  margin-right: 15px;
  margin-left: 30px;
  margin-top: 10px;
  max-width: 25px;
  width: 100%;
}
.bisko-app__audience .segments__remove {
  align-self: flex-start;
  margin-top: 9px;
}
.bisko-app__audience .params__remove {
  position: absolute;
  right: 20px;
  margin-top: 8px;
}
.bisko-app__audience .segment__arrow {
  width: 25px;
  height: 27px;
  border-left: 1px solid #e0e5eb;
  border-bottom: 1px solid #e0e5eb;
  position: absolute;
  top: 0;
  left: 25px;
}
.bisko-app__audience .segment__arrow--multiple {
  left: 50%;
  border-bottom: 0;
  height: 52px;
}
.bisko-app__audience .parameter__tag-help {
  margin-top: 2px;
  font-size: 11px;
  font-style: italic;
  color: #a3b0c2;
}
.bisko-app__audience .container__integrations {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 30px;
}
@media (max-width: 768) {
.bisko-app__audience .container__integrations {
    grid-template-columns: repeat(2, 1fr);
}
}
.bisko-app__audience .container__integrations-content {
  width: 100%;
  border-radius: 5px;
  border: 1px solid #e0e5eb;
  padding: 20px;
  font-size: 14px;
  color: #667c99;
}
.bisko-app__audience .container__integrations-content .integration__title {
  font-size: 16px;
  margin: 5px 0;
  font-weight: 600;
}
.bisko-app__audience .container__integrations-content .integration__title--inactive {
  color: #a3b0c2;
}
.bisko-app__audience .container__integrations-content-header {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  margin-bottom: 30px;
}
.bisko-app__audience .container__integrations-content-img {
  width: 64px;
  height: 64px;
  display: flex;
  align-items: center;
  padding: 5px;
  border: 1px solid #e0e5eb;
  border-radius: 4px;
}
.bisko-app__audience .container__integrations-content-img img {
  width: 100%;
}
.bisko-app__audience .segment__button--or-multiple {
  position: absolute;
  top: -14px;
  left: 48%;
  width: 44px;
  height: 26px;
  font-size: 14px;
}
.bisko-app__audience .segment__button--or-multiple.btn-secondary {
  background-color: #667c99 !important;
  color: white !important;
}
.bisko-app__audience .vs__selected-options span {
  white-space: nowrap;
}
.bisko-app__audience .parameter__dates {
  display: flex;
  align-items: flex-start;
}
.bisko-app__audience .parameter__dates .form__input {
  height: 37px !important;
  align-items: center !important;
  font-size: 14px;
  border: 1px solid #e0e5eb;
  width: 220px;
}
.bisko-app__audience .parameter__dates .form__input label {
  margin-top: 10px;
}
.bisko-app__audience .segment__param--wrapper {
  display: flex;
  flex-direction: column;
}
.bisko-app__audience .segment__topics {
  align-items: baseline;
}
.bisko-app__audience .segment__param .topics__select {
  width: unset;
  min-width: 280px;
}
.bisko-app__audience .segment__datetime-picker .vdpFloating > .vdpInnerWrap {
  max-width: 40rem;
  width: 15rem;
  min-height: 20rem;
}
.bisko-app__audience .segment__datetime-picker .vdpTable .vdpHeadCell {
  font-size: 12px;
}
.bisko-app__audience .segment__datetime-picker .vdpTable .vdpHeadCell::before {
  content: none;
}
.bisko-app__audience .segment__datetime-picker .vdpTable .vdpHeadCell::after {
  content: none;
}
.bisko-app__audience .segment__datetime-picker .vdpTable thead {
  transform: translateY(-25%);
}
.bisko-app__audience .segment__datetime-picker .vdpTable tr {
  transform: translateY(25%);
}
.bisko-app__audience .segment__datetime-picker .vdpTable tbody {
  transform: translateY(-5%);
}
.bisko-app__audience .segment__datetime-picker .vdpTable .vdpRow {
  font-size: 10px;
}
.bisko-app__audience .segment__datetime-picker .vdpInnerWrap > .vdpTable {
  margin-bottom: 0px;
}
.bisko-app__audience .segment__datetime-picker .vdpComponent.vdpWithInput > input {
  height: 37px;
  font-size: 14px;
  border: 1px solid #e0e5eb;
  border-radius: 4px;
  color: #6e6b7b;
  padding-left: 10px;
  padding-right: 5px;
}
.bisko-app__audience .segment__datetime-picker .vdpComponent.vdpWithInput > input[readonly=readonly] {
  background: #efefef;
}
.bisko-app__audience .segment__datetime-picker .vdpComponent.vdpWithInput > input:focus-visible {
  outline-width: 0px;
}
.bisko-app__audience .error__info-message {
  font-size: 13px;
  color: #e34850;
  height: 18px;
}
.bisko-app__audience .error__info-message span {
  position: absolute;
}
.bisko-app__audience .error__info-message_text-wrap span {
  max-width: 220px;
}
.bisko-app__audience .error__info-message_no-height {
  height: 0;
}
.bisko-app__audience .organization__select {
  display: inline-block;
}
.bisko-app__audience .organization__select .form__input {
  width: 220px;
  background: white;
}
.bisko-app__audience .segment__combine--input {
  width: auto !important;
}
.bisko-app__audience .segment__combine--input div:not(:first-child) {
  display: inline-block;
}
.bisko-app__audience .form__input-tags .b-form-tags-input {
  padding-left: 10px !important;
}
@media only screen and (max-width: 1440px) {
.bisko-app__audience .form__wrapper {
    width: 100%;
    min-width: auto;
}
.bisko-app__audience .parameters__custom {
    margin-bottom: 5px;
}
.bisko-app__audience .date-parameter-specific {
    margin-left: 70px;
}
.bisko-app__audience .segment__key-input {
    margin-left: 70px;
}
}
@media only screen and (max-width: 1350px) {
.bisko-app__audience .parameters__custom {
    margin-bottom: 5px;
}
.bisko-app__audience .parameters__custom-content {
    margin-left: 70px;
}
.bisko-app__audience .parameters__custom-content--no-margin {
    margin-left: unset !important;
}
.bisko-app__audience .segment__key-input {
    margin-left: unset;
}
.bisko-app__audience .date-parameter-specific {
    margin-left: unset;
}
}
@media only screen and (max-width: 992px) {
.bisko-app__audience .parameters__custom-content {
    margin-left: 70px;
}
.bisko-app__audience .parameters__custom-content .form__input {
    margin-bottom: 5px;
}
.bisko-app__audience .user__segmentation-wrapper .segment__key-input {
    margin-left: unset;
    width: 220px;
}
.bisko-app__audience .duration__wrapper {
    flex-wrap: wrap;
}
.bisko-app__audience .duration-dates__wrapper,
  .bisko-app__audience .time__last-param {
    margin: 5px 0 0 28px;
}
.bisko-app__audience .frequency__section {
    flex-wrap: wrap;
}
.bisko-app__audience .frequency__section > div:last-child {
    margin-left: 28px;
}
}
@media only screen and (max-width: 768px) {
.bisko-app__audience .parameters__custom-content {
    margin-left: unset;
}
.bisko-app__audience .parameters__custom-content .form__input {
    margin-bottom: 5px;
}
.bisko-app__audience .input__wrapper-parameters:first-child {
    margin-left: unset;
}
.bisko-app__audience .segment__param-title {
    font-size: 12px;
}
.bisko-app__audience .add__parameters {
    margin-left: unset;
}
}
@media only screen and (min-width: 1323px) and (max-width: 1350px), only screen and (min-width: 1060px) and (max-width: 1199px) {
.bisko-app__audience .parameter-date--no-margin {
    margin-left: 0px !important;
}
}
@media only screen and (min-width: 1018px) and (max-width: 1060px), only screen and (min-width: 1278px) and (max-width: 1322px) {
.bisko-app__audience .parameter-datetime--no-margin {
    margin-left: 0px !important;
}
}
@media only screen and (min-width: 1143px) and (max-width: 1199px) {
.bisko-app__audience .parameters-not-custom {
    margin-left: 0 !important;
}
}
@media only screen and (min-width: 837px) and (max-width: 1142px), only screen and (min-width: 1200px) and (max-width: 1350px) {
.bisko-app__audience .parameters__bolean-content {
    margin-left: 0px !important;
}
}
@media only screen and (min-width: 1063px) and (max-width: 1142px), only screen and (min-width: 1323px) and (max-width: 1350px) {
.bisko-app__audience .parameters__bolean-content-is {
    margin-left: 0px !important;
}
}
.bisko-app__audience .segment-view .container__wrapper {
  margin-bottom: 0;
}
.bisko-app__audience .segment-view .container__wrapper--height {
  min-height: unset;
}
.bisko-app__audience .segment-view__container_header {
  border-bottom: none !important;
  flex-direction: column;
}
.bisko-app__audience .segment-view__container_header h5 {
  font-size: 16px;
}
.bisko-app__audience .segment-view__container_header div {
  font-size: 14px;
}
.bisko-app__audience .segment-view__edit {
  display: flex;
  align-items: center;
  cursor: pointer;
  color: #2c3e50;
  text-decoration: none;
}
.bisko-app__audience .segment-view__edit span:not(:first-child) {
  margin-left: 8px;
  font-size: 14px;
}
.bisko-app__audience .segment-view__basic-info {
  align-items: flex-start !important;
}
.bisko-app__audience .segment-view__basic-info--header {
  border-bottom: none !important;
  margin-bottom: 5px !important;
  justify-content: space-between;
}
.bisko-app__audience .segment-view__basic-info--header h5 {
  font-size: 16px;
}
.bisko-app__audience .segment-view__basic-info .input__wrapper {
  display: flex;
  text-align: start;
  align-items: baseline;
}
.bisko-app__audience .segment-view__basic-info .input__wrapper:not(:last-child) {
  padding-bottom: 24px;
}
.bisko-app__audience .segment-view__basic-info .input__wrapper label {
  min-width: 140px;
  margin-bottom: 0px;
}
.bisko-app__audience .segment-view__basic-info .input__wrapper span {
  font-size: 14px;
  color: #052D61;
}
.bisko-app__audience .segment-view__info-icon {
  margin-left: 6px;
}
.bisko-app__audience .segment-view__disabled-label {
  font-size: 12px;
  text-transform: lowercase;
}
.bisko-app__audience .segment-view__row {
  margin-top: 16px;
  font-size: 14px;
  color: #173163;
}
.bisko-app__audience .segment-view__row:is(:last-child) {
  margin-bottom: 16px;
}
.bisko-app__audience .segment-view__parameters {
  margin-left: 32px;
}
.bisko-app__audience .segment-view__parameter {
  margin-top: 8px;
  display: flex;
  color: #052D61;
}
@media screen and (max-width: 600px) {
.bisko-app__audience .segment-view__parameter {
    flex-wrap: wrap;
}
.bisko-app__audience .segment-view__parameter--values {
    margin-left: 16px;
}
}
.bisko-app__audience .segment-view__parameter div {
  vertical-align: top;
}
.bisko-app__audience .segment-view__parameter--header {
  margin-right: 5px;
  white-space: nowrap;
}
@media screen and (max-width: 750px) {
.bisko-app__audience .segment-view__parameter--header {
    white-space: normal;
}
}
.bisko-app__audience .segment-view .integration__title--inactive {
  color: #A3B0C2;
}
.bisko-app__audience .topics-view__wrapper .segment-view__basic-info .input__wrapper label {
  min-width: 180px;
}
.bisko-app__audience .topics-view__wrapper .total-analytics__container {
  max-width: 530px;
}
.bisko-app__audience .keyword__match-type {
  display: flex;
  align-items: center;
  min-width: 180px;
}
.bisko-app__audience .total-analytics__title {
  min-width: unset !important;
}
.bisko-app__audience .item__name-and-icon {
  display: flex;
  align-items: center;
  -moz-column-gap: 6px;
       column-gap: 6px;
  color: #667c99;
}
.bisko-app__audience .item__number {
  font-weight: 500;
  color: #052d61;
}
.bisko-app__audience .selected-keywords__container {
  border: 1px solid #edeff3;
  border-radius: 4px;
  display: flex;
  flex-direction: column;
  position: relative;
}
.bisko-app__audience .selected-keywords__container .keywords__table-overlay {
  top: 130px;
}
.bisko-app__audience .selected-keywords__header {
  display: flex;
  justify-content: space-between;
  padding: 16px 24px;
  margin-bottom: 8px;
  align-items: center;
}
.bisko-app__audience .selected-keywords__header .form-group {
  margin-bottom: 0;
}
.bisko-app__audience .selected-keywords__total {
  color: #a3b0c2;
  font-size: 14px;
}
.bisko-app__audience .selected-keywords__content {
  padding: 0 24px;
  height: 350px;
  overflow-y: auto;
}
.bisko-app__audience .selected-keywords__content .keywords__table {
  height: 100%;
}
.bisko-app__audience .selected-keywords__content .keywords__table .vgt-wrap {
  height: 100%;
}
.bisko-app__audience .selected-keywords__content .keywords__table .vgt-wrap .vgt-inner-wrap {
  height: 100%;
  min-height: unset;
}
.bisko-app__audience .selected-keywords__content .keyword-tag {
  background: #f6f8f9;
  border: 1px solid #e0e5eb;
  border-radius: 50px;
  padding: 7px 24px;
  margin: 0 8px 12px 0;
  font-size: 13px;
  font-weight: 500;
  color: #052d61;
  display: inline-block;
  word-break: break-all;
  max-width: 100%;
}
.bisko-app__audience .selected-keywords__no-results {
  color: #a3b0c2;
}
.bisko-app__audience .keywords-empty-state {
  min-height: 250px;
}
.bisko-app__audience .keywords-empty-state .empty-content__icon {
  display: none;
}
.bisko-app__audience .topic__text {
  word-break: break-all;
}
.bisko-app__audience .create-topic__container {
  min-width: 926px;
  max-width: 926px;
  margin: 0 auto;
  flex-direction: column;
  justify-content: space-between;
}
.bisko-app__audience .create-topic__container .container__header {
  padding-bottom: 24px;
}
.bisko-app__audience .topic-name__input {
  padding-left: 0 !important;
  padding-bottom: 24px;
  width: 80%;
}
.bisko-app__audience .total-analytics__loading {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  display: flex;
  justify-content: center;
}
.bisko-app__audience .total-analytics__container {
  border: 1px solid #EDEFF3;
  border-radius: 4px;
  margin: 0 24px 16px 24px;
  padding: 10px 16px;
  display: flex;
  justify-content: space-between;
  position: relative;
}
.bisko-app__audience .total-analytics__item {
  font-weight: 500;
  font-size: 14px;
  line-height: 18px;
  color: #052D61;
  width: 100%;
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: baseline;
}
.bisko-app__audience .total-analytics__item:not(:last-of-type)::after {
  content: "";
  height: 26px;
  width: 2px;
  background: #E0E5EB;
  position: absolute;
  right: 20px;
  top: 10px;
}
.bisko-app__audience .keyword__match-type {
  display: flex;
  align-items: center;
  -moz-column-gap: 4px;
       column-gap: 4px;
  margin-bottom: 4px;
}
.bisko-app__audience .keyword__match-type label {
  margin-bottom: 0;
}
.bisko-app__audience .match-type__container {
  margin-top: 8px;
  min-height: unset !important;
}
.bisko-app__audience .match-type__container .input__wrapper {
  padding-bottom: 0px;
}
.bisko-app__audience .keywords__table--loading .vgt-wrap .vgt-responsive table tbody td:not(:first-child) {
  color: rgba(102, 124, 153, 0.4980392157) !important;
}
.bisko-app__audience .keywords__table-overlay {
  position: absolute;
  top: 180px;
  width: 100%;
  height: 70%;
  display: flex;
  justify-content: center;
}
.bisko-app__audience .item__name-and-icon {
  color: #667C99;
  width: 80px;
  display: inline-flex;
  -moz-column-gap: 5px;
       column-gap: 5px;
  align-items: center;
}
.bisko-app__audience .item__name-and-icon svg {
  fill: #667C99 !important;
}
.bisko-app__audience .item__name-and-icon div {
  display: inline;
}
.bisko-app__audience .item__number {
  color: #052D61;
  font-size: 16px;
  margin-top: 5px;
}
.bisko-app__audience .item__number--disabled {
  color: rgba(102, 124, 153, 0.4980392157) !important;
}
.bisko-app__audience .add-keywords__container {
  margin: 48px 0;
  margin-top: 8px;
}
.bisko-app__audience .add-keywords__container .add-keywords__header {
  word-break: break-word;
}
.bisko-app__audience .add-keywords__container .container__header-title {
  margin-bottom: 6px;
}
.bisko-app__audience .add-keywords__container .add-keywords__information {
  color: #7483A1;
  font-size: 13px;
  line-height: 18px;
  display: flex;
}
.bisko-app__audience .add-keywords__container .add-keywords__information svg {
  min-width: 18px;
  margin: 0 8px 1px 0;
}
.bisko-app__audience .topic-add__info {
  margin: 0 0 0 27px;
  display: block;
}
.bisko-app__audience .suggested-and-manual__container {
  display: grid;
  grid-template-columns: 65% 33.5%;
  -moz-column-gap: 1.5%;
       column-gap: 1.5%;
  margin-top: 16px;
}
.bisko-app__audience .add-keyword {
  border: 1px solid #EDEFF3;
  border-radius: 4px;
  min-height: 634px;
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  padding: 0;
}
.bisko-app__audience .add-keyword-suggested {
  position: relative;
}
.bisko-app__audience .add-keyword-suggested .suggested__container--flex {
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  height: 100%;
}
.bisko-app__audience .suggested-keywords__content {
  padding: 16px 0px 0px 24px;
}
.bisko-app__audience .suggested-keywords__content .select-property {
  padding-right: 38px;
  margin-bottom: 16px;
}
.bisko-app__audience .suggested-keywords__content .select-property .form__input {
  width: 238px;
}
.bisko-app__audience .suggested-keywords__content .select-property .segment__select .vs__dropdown-option {
  word-break: break-all;
}
.bisko-app__audience .suggested-keywords__content .select-property .v-select.vs--single.vs--searchable .vs__dropdown-toggle .vs__actions > svg {
  margin-left: 5px;
}
.bisko-app__audience .suggested-keywords__content .no-suggested__container {
  margin-top: 170px;
  padding-right: 16px;
  text-align: center;
}
.bisko-app__audience .suggested-keywords__content .no-suggested__container .no-suggested__text {
  color: #7483A1;
  font-size: 14px;
}
.bisko-app__audience .suggested-keywords__content .keywords-scrollable__area .keyword__row {
  margin-right: 5px;
}
.bisko-app__audience .suggested-keywords__content .keywords-scrollable__area .keyword__name {
  width: 238px;
  border-radius: 40px;
  color: #1D79F2;
  position: relative;
  font-size: 13px;
}
.bisko-app__audience .suggested-keywords__content .keywords-scrollable__area .keyword__name span:first-child {
  white-space: nowrap;
  text-overflow: ellipsis;
  max-width: 100%;
  overflow: hidden;
  display: block;
}
.bisko-app__audience .suggested-keywords__content .keywords-scrollable__area .add-suggested__keyword {
  position: absolute;
  top: -3px;
  right: -6px;
}
.bisko-app__audience .suggested-keywords__content .keywords-scrollable__area .add-suggested__keyword:hover {
  cursor: pointer;
}
.bisko-app__audience .suggested-keywords__content .keywords-scrollable__area .add-suggested__keyword-info {
  color: red;
  border-radius: 50%;
  background: white;
}
.bisko-app__audience .manual-keywords__content {
  padding: 16px 0 24px 0;
  max-height: 594px;
  position: relative;
}
.bisko-app__audience .manual-keywords__content .enter-keyword__container {
  padding: 0 24px 16px 24px;
}
.bisko-app__audience .manual-keywords__content .enter-keyword__container .error__info-message a {
  color: #e34850;
  text-decoration: underline;
  font-weight: 600;
}
.bisko-app__audience .manual-keywords__content .enter-keyword__information-text {
  color: #7483A1;
  font-size: 11px;
}
.bisko-app__audience .manual-keywords__content .keywords-error__container {
  margin: -8px 0 10px 24px;
}
.bisko-app__audience .manual-keywords__content .keywords-scrollable__area {
  max-height: 382px;
  padding-left: 24px;
}
.bisko-app__audience .manual-keywords__content .keywords-scrollable__area .keywords__table {
  height: 100%;
}
.bisko-app__audience .manual-keywords__content .keywords-scrollable__area .keywords__table .vgt-wrap {
  height: 100%;
}
.bisko-app__audience .manual-keywords__content .keywords-scrollable__area .table__wrapper .vgt-wrap .vgt-inner-wrap {
  min-height: -moz-fit-content;
  min-height: fit-content;
  height: 100%;
}
.bisko-app__audience .manual-keywords__content .keywords-list__container {
  display: flex;
  justify-content: flex-start;
  flex-wrap: wrap;
  row-gap: 12px;
}
.bisko-app__audience .manual-keywords__content .keyword__item {
  background: #FFFFFF;
  border: 1px solid #E0E5EB;
  border-radius: 50px;
  padding: 2.5px 12px;
  margin-right: 8px;
  max-width: 100%;
  word-break: break-all;
  display: flex;
  align-items: center;
}
.bisko-app__audience .manual-keywords__content .keyword__item :last-child:hover {
  cursor: pointer;
}
.bisko-app__audience .manual-keywords__content .keyword__item-name {
  margin-right: 12px;
  font-size: 13px;
  color: #052D61;
  line-height: 18px;
}
.bisko-app__audience .manual-keywords__content .keyword__item--padding {
  padding-left: 20px;
}
.bisko-app__audience .manual-keywords__content .keyword__item svg {
  color: #A3B0C2;
}
.bisko-app__audience .manual-keywords__content .no-keywords__container {
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  align-items: center;
  margin-right: 10px;
}
.bisko-app__audience .manual-keywords__content .no-keywords__container .no-keywords__icon {
  margin: 80px 0 16px 0;
}
.bisko-app__audience .manual-keywords__content .no-keywords__container .no-keywords__text {
  color: #7483A1;
  font-size: 14px;
}
.bisko-app__audience .manual-and-upload__container {
  display: flex;
  justify-content: space-between;
}
.bisko-app__audience .enter-keyword__header {
  color: #11254A;
  font-size: 14px;
  font-weight: 500;
  display: block;
  padding-bottom: 16px;
}
.bisko-app__audience .add-keywords__footer {
  border-top: 1px solid #EDEFF3;
  height: 42px;
  position: relative;
}
.bisko-app__audience .add-keywords__footer span {
  color: #1D79F2;
  font-size: 12px;
  line-height: 18px;
  position: absolute;
  padding: 12px 0;
  right: 12px;
  bottom: 0;
}
.bisko-app__audience .add-keywords__footer span:hover {
  cursor: pointer;
}
.bisko-app__audience .add-keywords__footer .span--disabled {
  color: #A3B0C2;
  cursor: default !important;
}
.bisko-app__audience .manual__input {
  width: 371px;
  border: 1px solid #E0E5EB;
}
.bisko-app__audience .keywords-scrollable__area {
  height: 484px;
  overflow: auto;
  overflow-x: hidden;
}
.bisko-app__audience .keyword__row {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  margin-bottom: 4px;
}
.bisko-app__audience .keyword__row:first-child {
  margin-top: 2px;
}
.bisko-app__audience .keyword__row .keyword__name {
  background: #FFFFFF;
  color: #052D61;
  padding: 6px;
  font-size: 14px;
  line-height: 18px;
  border: 1px solid #E0E5EB;
  border-radius: 4px;
  text-align: center;
  word-break: break-all;
  min-height: 32px;
}
.bisko-app__audience .keyword__row .keyword__name--disabled {
  background: #efefef;
}
.bisko-app__audience .keyword__row .keyword__name--disabled span:first-child {
  color: #6e6b7b;
}
.bisko-app__audience .tooltip .arrow:before {
  border-top-color: #4B4B4B !important;
}
@keyframes keyword-animation {
0% {
    opacity: 1;
    transform: translateX(0);
}
100% {
    opacity: 0;
    transform: translateX(40px);
}
}
@keyframes suggested-keyword-animation {
0% {
    opacity: 1;
    transform: translateX(0px);
}
100% {
    opacity: 0;
    transform: translateX(-40px);
}
}
.bisko-app__audience .keyword-list-move, .bisko-app__audience .suggested-keyword-list-move, .bisko-app__audience .modal-keyword-list-move {
  transition: all 0.3s;
}
.bisko-app__audience .modal-keyword-list-enter-active {
  animation: keyword-animation 0.3s ease-out reverse;
}
.bisko-app__audience .modal-keyword-list-leave-active {
  animation: keyword-animation 0.3s ease-out;
}
.bisko-app__audience .keyword-list-leave-active {
  animation: suggested-keyword-animation 0.3s ease-out;
}
.bisko-app__audience .keyword-list-enter-active, .bisko-app__audience .suggested-keyword-list-enter-active {
  animation: suggested-keyword-animation 0.3s ease-out reverse;
}
.bisko-app__audience .suggested-keyword-list-leave-active {
  animation: suggested-keyword-animation 0.3s ease-out;
}
@media screen and (max-width: 1440px) {
.bisko-app__audience .create-topic__container {
    min-width: auto;
    width: 100%;
}
}
@media only screen and (max-width: 991px) {
.bisko-app__audience .topic-name__input {
    width: 100%;
}
.bisko-app__audience .suggested-keywords__content .select-property {
    padding-right: 20px;
}
.bisko-app__audience .suggested-keywords__content .select-property .form__input {
    width: 100%;
}
.bisko-app__audience .suggested-keywords__content .keywords-scrollable__area {
    padding-right: 16px;
}
.bisko-app__audience .suggested-keywords__content .keyword__name {
    width: 100%;
}
.bisko-app__audience .manual-keywords__content .manual__input {
    width: 100%;
}
}`, ""]);
// Exports
export default ___CSS_LOADER_EXPORT___;
